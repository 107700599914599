import ContentHeader from "../../components/content-header/ContentHeader";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "../../services/common/globalvars";
import { addUserRoleManagementApi, updateUserRoleManagementApi } from "../../services/data/userrolemanagement";
import { hasPermission } from "../../utils/permissions";

const UserRoleManagement = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  // User Role Management Form data
  const [userdata, setUserData] = useState({
    name: "",
    permissions: [],
    status: "",
  });

  useEffect(() => {
    console.log(location.state);
    if (location.state) {
      setUserData({
        ...userdata,
        name: location.state.roleManagement.name,
        permissions: location.state.roleManagement.permissions,
      });
      setIsEdit(true);
    }
  }, [location]);

  const userPermissionsChange = (e) => {
    const { value, checked } = e.target;
    const { permissions } = userdata;

    console.log(`${value} is ${checked}`);
    if (checked) {
      setUserData({
        ...userdata,
        permissions: [...permissions, value],
      });
    } else {
      setUserData({
        ...userdata,
        permissions: permissions.filter((e) => e !== value),
      });
    }
  };

  const { name, permissions, status } = userdata;

  const dataHandle = (e) => {
    console.log(e);
    setUserData({ ...userdata, [e.target.name]: e.target.value });
  };

  const handleReset = () => {
    setUserData({
      name: "",
      permissions: [],
      status: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(userdata);
    if (isEdit) {
      console.log(userdata, "edit");
      updateUserRoleManagementApi(location.state.roleManagement.id, userdata).then((response)=>{
        if(response.status >= 200) {
          toast.success(response.data.message)
          navigate("/user-roles")
        }
        else {
          toast.error(response.data.message);
          setIsLoading(false);
        }
      })
    } else {
      addUserRoleManagementApi(userdata).then((response) => {
        if (response.status === 201) {
          toast.success(response.data.message);
          navigate("/user-roles");
        } else {
          toast.error(response.data.message);
          setIsLoading(false);
        }
      });
    }
  };

  return (
    <>
      <div>
        <ContentHeader title="User Role Management" />
        <section className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">User Role Management Form</h3>
                <div className="float-right">
                  <Link to="/user-roles" className="btn btn-info">
                    Back
                  </Link>
                </div>
              </div>
              <div className="card-body">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="name">User Role Name</label>
                        <input
                          type="text"
                          name="name"
                          value={name}
                          placeholder="User Role Name"
                          onChange={dataHandle}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="customers">Customers</label>
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="add"
                            defaultValue="customers.create"
                            checked={permissions.includes("customers.create")}
                            onChange={userPermissionsChange}
                          />
                          <label htmlFor="add" className="custom-control-label">
                            Add
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="edit"
                            defaultValue="customers.edit"
                            checked={permissions.includes("customers.edit")}
                            onChange={userPermissionsChange}
                          />
                          <label
                            htmlFor="edit"
                            className="custom-control-label"
                          >
                            Edit
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="delete"
                            defaultValue="customers.delete"
                            checked={permissions.includes("customers.delete")}
                            onChange={userPermissionsChange}
                          />
                          <label
                            htmlFor="delete"
                            className="custom-control-label"
                          >
                            Delete
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="view"
                            defaultValue="customers.view"
                            checked={permissions.includes("customers.view")}
                            onChange={userPermissionsChange}
                          />
                          <label
                            htmlFor="view"
                            className="custom-control-label"
                          >
                            View
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="due_diligence"
                            defaultValue="customers.due_diligence"
                            checked={permissions.includes("customers.due_diligence")}
                            onChange={userPermissionsChange}
                          />
                          <label
                            htmlFor="view"
                            className="custom-control-label"
                          >
                            Due Diligence
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="transporters">Transporters</label>
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="transadd"
                            defaultValue="transporters.create"
                            checked={permissions.includes("transporters.create")}
                            onChange={userPermissionsChange}
                          />
                          <label
                            htmlFor="transadd"
                            className="custom-control-label"
                          >
                            Add
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="transedit"
                            defaultValue="transporters.edit"
                            checked={permissions.includes("transporters.edit")}
                            onChange={userPermissionsChange}
                          />
                          <label
                            htmlFor="transedit"
                            className="custom-control-label"
                          >
                            Edit
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="transdelete"
                            defaultValue="transporters.delete"
                            checked={permissions.includes(
                              "transporters.delete"
                            )}
                            onChange={userPermissionsChange}
                          />
                          <label
                            htmlFor="transdelete"
                            className="custom-control-label"
                          >
                            Delete
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="transview"
                            defaultValue="transporters.view"
                            checked={permissions.includes("transporters.view")}
                            onChange={userPermissionsChange}
                          />
                          <label
                            htmlFor="transview"
                            className="custom-control-label"
                          >
                            View
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="due_diligence"
                            defaultValue="transporters.due_diligence"
                            checked={permissions.includes("transporters.due_diligence")}
                            onChange={userPermissionsChange}
                          />
                          <label
                            htmlFor="view"
                            className="custom-control-label"
                          >
                            Due Diligence
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="spoc">Spoc</label>
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="spocadd"
                            defaultValue="spoc.create"
                            checked={permissions.includes("spoc.create")}
                            onChange={userPermissionsChange}
                          />
                          <label
                            htmlFor="spocadd"
                            className="custom-control-label"
                          >
                            Add
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="spocedit"
                            defaultValue="spoc.edit"
                            checked={permissions.includes("spoc.edit")}
                            onChange={userPermissionsChange}
                          />
                          <label
                            htmlFor="spocedit"
                            className="custom-control-label"
                          >
                            Edit
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="spocdelete"
                            defaultValue="spoc.delete"
                            checked={permissions.includes("spoc.delete")}
                            onChange={userPermissionsChange}
                          />
                          <label
                            htmlFor="spocdelete"
                            className="custom-control-label"
                          >
                            Delete
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="spocview"
                            defaultValue="spoc.view"
                            checked={permissions.includes("spoc.view")}
                            onChange={userPermissionsChange}
                          />
                          <label
                            htmlFor="spocview"
                            className="custom-control-label"
                          >
                            View
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="trucks">Trucks</label>
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="truckadd"
                            defaultValue="trucks.create"
                            checked={permissions.includes("trucks.create")}
                            onChange={userPermissionsChange}
                          />
                          <label
                            htmlFor="truckadd"
                            className="custom-control-label"
                          >
                            Add
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="truckedit"
                            defaultValue="trucks.edit"
                            checked={permissions.includes("trucks.edit")}
                            onChange={userPermissionsChange}
                          />
                          <label
                            htmlFor="truckedit"
                            className="custom-control-label"
                          >
                            Edit
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="truckdelete"
                            defaultValue="trucks.delete"
                            checked={permissions.includes("trucks.delete")}
                            onChange={userPermissionsChange}
                          />
                          <label
                            htmlFor="truckdelete"
                            className="custom-control-label"
                          >
                            Delete
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="truckview"
                            defaultValue="trucks.view"
                            checked={permissions.includes("trucks.view")}
                            onChange={userPermissionsChange}
                          />
                          <label
                            htmlFor="truckview"
                            className="custom-control-label"
                          >
                            View
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="driver">Drivers</label>
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="driveradd"
                            defaultValue="drivers.create"
                            checked={permissions.includes("drivers.create")}
                            onChange={userPermissionsChange}
                          />
                          <label
                            htmlFor="driveradd"
                            className="custom-control-label"
                          >
                            Add
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="driveredit"
                            defaultValue="drivers.edit"
                            checked={permissions.includes("drivers.edit")}
                            onChange={userPermissionsChange}
                          />
                          <label
                            htmlFor="driveredit"
                            className="custom-control-label"
                          >
                            Edit
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="driverdelete"
                            defaultValue="drivers.delete"
                            checked={permissions.includes("drivers.delete")}
                            onChange={userPermissionsChange}
                          />
                          <label
                            htmlFor="driverdelete"
                            className="custom-control-label"
                          >
                            Delete
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="driverview"
                            defaultValue="drivers.view"
                            checked={permissions.includes("drivers.view")}
                            onChange={userPermissionsChange}
                          />
                          <label
                            htmlFor="driverview"
                            className="custom-control-label"
                          >
                            View
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="bookings">Bookings</label>
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="bookingadd"
                            defaultValue="bookings.create"
                            checked={permissions.includes("bookings.create")}
                            onChange={userPermissionsChange}
                          />
                          <label
                            htmlFor="bookingadd"
                            className="custom-control-label"
                          >
                            Add
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="bookingedit"
                            defaultValue="bookings.edit"
                            checked={permissions.includes("bookings.edit")}
                            onChange={userPermissionsChange}
                          />
                          <label
                            htmlFor="bookingedit"
                            className="custom-control-label"
                          >
                            Edit
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="bookingdelete"
                            defaultValue="bookings.delete"
                            checked={permissions.includes("bookings.delete")}
                            onChange={userPermissionsChange}
                          />
                          <label
                            htmlFor="bookingdelete"
                            className="custom-control-label"
                          >
                            Delete
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="bookingview"
                            defaultValue="bookings.view"
                            checked={permissions.includes("bookings.view")}
                            onChange={userPermissionsChange}
                          />
                          <label
                            htmlFor="bookingview"
                            className="custom-control-label"
                          >
                            View
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="user_role">User & Role</label>
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="useradd"
                            defaultValue="users.create"
                            checked={permissions.includes("users.create")}
                            onChange={userPermissionsChange}
                          />
                          <label
                            htmlFor="useradd"
                            className="custom-control-label"
                          >
                            Add
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="usersedit"
                            defaultValue="users.edit"
                            checked={permissions.includes("users.edit")}
                            onChange={userPermissionsChange}
                          />
                          <label
                            htmlFor="usersedit"
                            className="custom-control-label"
                          >
                            Edit
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="usersdelete"
                            defaultValue="users.delete"
                            checked={permissions.includes("users.delete")}
                            onChange={userPermissionsChange}
                          />
                          <label
                            htmlFor="usersdelete"
                            className="custom-control-label"
                          >
                            Delete
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="usersview"
                            defaultValue="users.view"
                            checked={permissions.includes("users.view")}
                            onChange={userPermissionsChange}
                          />
                          <label
                            htmlFor="usersview"
                            className="custom-control-label"
                          >
                            View
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="master">Master</label>
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="masteradd"
                            defaultValue="master.create"
                            checked={permissions.includes("master.create")}
                            onChange={userPermissionsChange}
                          />
                          <label
                            htmlFor="masteradd"
                            className="custom-control-label"
                          >
                            Add
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="masteredit"
                            defaultValue="master.edit"
                            checked={permissions.includes("master.edit")}
                            onChange={userPermissionsChange}
                          />
                          <label
                            htmlFor="masteredit"
                            className="custom-control-label"
                          >
                            Edit
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="masterdelete"
                            defaultValue="master.delete"
                            checked={permissions.includes("master.delete")}
                            onChange={userPermissionsChange}
                          />
                          <label
                            htmlFor="masterdelete"
                            className="custom-control-label"
                          >
                            Delete
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="masterview"
                            defaultValue="master.view"
                            checked={permissions.includes("master.view")}
                            onChange={userPermissionsChange}
                          />
                          <label
                            htmlFor="masterview"
                            className="custom-control-label"
                          >
                            View
                          </label>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
              {(hasPermission("users.create") || hasPermission("users.edit")) && (
                <div className="card-footer">
                  <button
                    type="reset"
                    className="btn btn-default"
                    onClick={handleReset}
                  >
                    Reset
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary float-right"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default UserRoleManagement;
