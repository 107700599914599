// import ContentHeader from "../../components/content-header/ContentHeader";
// import "../../styles/Customer.css";
// import React, { useState, useRef, useEffect } from "react";
// import MaterialTable, { Column } from "@material-table/core";
// import { ExportCsv, ExportPdf } from "@material-table/exporters";
// import PageLoader from "../../components/PageLoader";
// import {
//   getAllPrivacyPolicy,  
//   deletePrivacyPolicy,
// } from "../../services/data/masters/privacypolicy";
// import { hasPermission } from "../../utils/permissions";
// import { Link } from "react-router-dom";
// import DeleteConfirmModal from "../../components/DeleteConfirmModal";
// import { toast } from "react-toastify";


// const PrivacyPolicy = () => {
//   const [data, setData] = useState([]); 
//   const [isLoading, setIsLoading] = useState(true);
//   const [isPageLoading, setIsPageLoading] = useState(true);
//   const [delteCustomerId, setDelteCustomerId] = useState();
//   const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

//   function onConfirm() {    
//     // toast.warn("Not Implemented!");
//     deletePrivacyPolicy(delteCustomerId).then((response) => {
//       toast.success("Privacy Policy Deleted");
//       setShowDeleteConfirm(false);
//     });
//   }
  
//   function onClose() {
//     setShowDeleteConfirm(false);
//   }

//   const columns = [
//     { title: "Message", 
//       render :(rowData)=>{
//         return(
//           <>
//           <b>{rowData.message}</b>
//           </>
//         )
//       }
//     },
//     { 
//       title: "Action", 
//       render :(rowData)=>{
//         return(
//           <div>
//               <p>
//               <Link
//                 className="btn btn-link p-0"
//                 to={`/manageprivacypolicy/${rowData.id}`}
//               >
//                 {/* <i className="fas fa-edit text-info" /> */}
//                 <i className="fas fa-pencil-alt text-info"></i>
//               </Link>
//               {hasPermission("users.delete") && (
//                 <button
//                   className="btn btn-link"
//                   onClick={(e) => {
//                     setDelteCustomerId(rowData.id);
//                     setShowDeleteConfirm(true);
//                   }}
//                 >
//                   <i className="fas fa-times text-danger"></i>                  
//                 </button>
//               )}
//             </p>
//           </div>
//         )
//       } 
//     },
//     { 
//       title: "Status", 
//       render:(rowData)=>{
//         return(
//           <>
//           {rowData.status ?? '-'}
//           </>
//         )
//       }
//     },
//   ]
//   useEffect(() => {
//     setIsLoading(true);
//     getAllPrivacyPolicy().then((response) => {
//       setData(response.data);
//       setIsPageLoading(false);
//       setIsLoading(false);
//     });
//   }, [showDeleteConfirm]);
//   return (
//     <div>
//       <ContentHeader title="Privacy Policy" />
//       {
//         isPageLoading?
//         (PageLoader):(
//           <section className="content">
//             <div className="container-fluid">
//               <div className="card">
//                 <div className="card-header">
//                   {/* <h3 className="card-title">Title</h3> */}
//                   <div className="float-right">
//                     <Link
//                       to="/manageprivacypolicy"
//                       className="btn btn-info"
//                     >
//                       <i className="fas fa-plus" /> Add Privacy Policy
//                     </Link>
//                   </div>
//                 </div>
//                 <div className="card-body p-0">
//                   <MaterialTable
//                     // id="pdfDatas"
//                     style={{ backgroundColor: "white" }}
//                     columns={columns}
//                     isLoading={isLoading}
//                     data={data}
//                     title=""
//                     options={{
//                       exportAllData: true,
//                       exportMenu: [
//                         {
//                           label: "Export CSV",
//                           exportFunc: (cols, datas) =>
//                             ExportCsv(cols, datas, "trux"),
//                         },
//                       ],
//                     }}
//                   />
//                 </div>
//                 {/* <div className="card-footer">Footer</div> */}
//               </div>
//             </div>
//             {showDeleteConfirm && (
//             <DeleteConfirmModal
//               show={showDeleteConfirm}
//               onClose={onClose}
//               onConfirm={onConfirm}
//             />
//             )} 
//           </section>
//         )
//       }
     
//     </div>
//   );
// };

// export default PrivacyPolicy;



import React, {useState, useEffect} from 'react'
import ContentHeader from "../../components/content-header/ContentHeader";
import PageLoader from '../../components/PageLoader';
import {
  getAllPrivacyPolicy, 
} from "../../services/data/masters/privacypolicy";
import { Link } from "react-router-dom";
import ReactQuill from 'react-quill';
import ContentDisplay from '../../components/content-header/ContentDisplay';

const PrivacyPolicy = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);  
  console.log("Reterival All Data P&P", data)
    
  useEffect(() => {    
    getAllPrivacyPolicy().then((response) => {    
      setData(response.data);      
      setIsLoading(false);
    });
  }, []);
  return (
    <div>
      <ContentHeader title="Privacy Policy" />
        {
          isLoading ? (<PageLoader />)
          :
          (
            <section className="content">
              <div className="container-fluid">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title"><b>Privacy Policy</b></h3>
                    <div className="float-right">
                      <Link
                        to="/manageprivacypolicy"
                        className="btn btn-info"
                      >
                        <i className="fas fa-plus" /> Add Privacy Policy
                      </Link>
                    </div>
                  </div>
                  <div className="card-body">
                  <ContentDisplay  content={data.message} />                    
                  {/* {data && data.map((item) => (
                    <ContentDisplay  content={item.message} />
                  ))} */}
                  </div>
                  {/* <div className="card-footer">Footer</div> */}
                </div>
              </div>
          </section>
          )
        }        
    </div>    
  )
}
export default PrivacyPolicy

