import ContentHeader from "../../components/content-header/ContentHeader";
import "../../styles/Drivers.css";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import MaterialTable from "@material-table/core";
import { ExportCsv } from "@material-table/exporters";
import "jspdf-autotable";
import { useSelector } from "react-redux";
import { hasPermission } from "../../utils/permissions";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";
import { getAllSpoc,
         updateSpocApi,
         deletespocApi} from "../../services/data/spoc";


const Spoc = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [delteSpocId, setDelteSpocId] = useState();
  const [activeStatus, SetActiveStatus] = useState("");
 console.log("All Spoc Details", data)

 function onConfirm() {
  setShowDeleteConfirm(false);
  deletespocApi(delteSpocId).then((response) => {
    toast.success("Spoc Deleted");
  });
}

 const changeStatus = (isActive, id) => {
  const payload = {
    is_active: isActive,
  };
  updateSpocApi(id, payload).then((response) => {
    if (isActive) {
      toast.success("Spoc Activated!");
    } else {
      toast.info("Spoc Deactivated!");
    }
    getAllSpoc().then((response) => {
      setData(response.data);
      SetActiveStatus(isActive);
    });
  });
};

  const columns = [
    // { title: "id", field: "id" },
    { 
      title: "Name", 
      render : (rowData)=>{
        return(
          <div>
            <p className="mb-0 pb-0">
              <Link 
                className="nav-link"
                to={`/spocprofile/${rowData.id}`}
              >
                <i className="fas fa-user nav-icon"></i> <b>{rowData.name}</b>
              </Link>
            </p>            
            <p>
              { hasPermission("spoc.edit") && (
                <Link
                  className="btn mr-0 pr-0"
                  to={`/manage-spoc/${rowData.id}`}
                >
                  {/* <i className="fas fa-edit text-info" /> */}
                  <i className="fas fa-pencil-alt text-info"></i>
                </Link>
              )} {' '}
              {/* {hasPermission("spoc.delete") && (
                <button
                  className="btn  mr-0 pr-0"
                  onClick={(e) => {
                    setDelteSpocId(rowData.id);
                    setShowDeleteConfirm(true);
                  }}
                >
                  <i className="fas fa-times text-danger"></i>                  
                </button>
              )}{" "} */}
              <Link
                className="btn mr-0 pr-0"
                to={`/spocprofile/${rowData.id}`}
              >
                <i class="fas fa-eye text-success"></i>
              </Link>                
            </p> 
                     
          </div>          
        )
      }
    },
    { title: "Email", field: "email" },
    {
      title: "Contact No",
      render: (rowData) => {
        return (
          <>
            +{rowData.country_code}-{rowData.contact_no}
          </>
        );
      },
    },    
    {
      title: "Status",
      render: (rowData) => {
        return (
          <p>
            {hasPermission("customers.edit") ? (
              <>
                {rowData.status === "In Active" && (
                  <button
                    type="button"
                    className="btn btn-warning"
                    onClick={(e) => changeStatus(true, rowData.id)}
                  >
                    In Active
                  </button>
                )}
                {rowData.status === "Active" && (
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={(e) => changeStatus(false, rowData.id)}
                  >
                    Active
                  </button>
                )}
              </>
            ) : (
              <>{rowData.status}</>
            )}
          </p>
        );
      },
    },   
  ];

  useEffect(() => {
    getAllSpoc().then((response) => {
      setData(response.data);
      setIsLoading(false);      
    });
  }, [showDeleteConfirm,activeStatus]);

  function onClose() {
    setShowDeleteConfirm(false);
  }

  

  return (
    <>
      <div>
        <ContentHeader title="SPOC" />
        {isLoading ? (
          <section className="content">
            <Spinner animation="grow" className="bg-info" />
            <Spinner animation="grow" className="bg-primary" />
            <Spinner animation="grow" className="bg-success" />
          </section>
        ) : (
          <section className="content">
            <div className="container-fluid">
              <div className="card">
                <div className="card-header">
                   <h3 className="card-title">Spoc Details</h3> 
                  <div className="card-tools">
                     {hasPermission("spoc.create") && (
                      <Link
                        type="button"
                        className="btn btn-tool bg-info"
                        data-card-widget="collapse"
                        title="Collapse"
                        to="/manage-spoc"
                      >
                        <i className="fas fa-plus" /> New Spoc
                      </Link>
                    )} 
                  </div>
                </div>
                {/* <div className="card-body"> */}
                <MaterialTable
                  style={{ backgroundColor: "white" }}
                  columns={columns}                  
                  data={data}
                  title=""
                  options={{
                    exportAllData: true,
                    exportMenu: [
                      {
                        label: "Export CSV",
                        exportFunc: (cols, datas) =>
                          ExportCsv(cols, datas, "trux"),
                      },
                    ],
                  }}
                />
                {/* </div>
              <div className="card-footer">Footer</div> */}
              </div>
            </div>
            <DeleteConfirmModal
              show={showDeleteConfirm}
              onClose={onClose}
              onConfirm={onConfirm}
            />
          </section>
        )}
      </div>
    </>
  );
};

export default Spoc;
