// import ContentHeader from "../../components/content-header/ContentHeader";
// import "../../styles/Customer.css";
// import React, { useState, useRef, useEffect } from "react";
// import MaterialTable, { Column } from "@material-table/core";
// import { ExportCsv, ExportPdf } from "@material-table/exporters";
// import PageLoader from "../../components/PageLoader";
// import {
//   getAllTermsandConditions,
//   updateTermsandConditionsdetails,
//   deleteTermsandConditions
// } from "../../services/data/masters/termsandconditions";
// import { hasPermission } from "../../utils/permissions";
// import { Link } from "react-router-dom";
// import DeleteConfirmModal from "../../components/DeleteConfirmModal";
// import { toast } from "react-toastify";


// const TermsConditions = () => {
//   const [data, setData] = useState([]);
//   console.log('termsandconditions',data)
//   const [isLoading, setIsLoading] = useState(true);
//   const [isPageLoading, setIsPageLoading] = useState(true);
//   const [delteCustomerId, setDelteCustomerId] = useState();
//   const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

//   function onConfirm() {    
//     // toast.warn("Not Implemented!");
//     deleteTermsandConditions(delteCustomerId).then((response) => {
//       toast.success("Terms And Condition Deleted");
//       setShowDeleteConfirm(false);
//     });
//   }
  
//   function onClose() {
//     setShowDeleteConfirm(false);
//   }

//   const columns = [
//     { title: "Message", 
//       render :(rowData)=>{
//         return(
//           <>
//           <b>{rowData.message}</b>
//           </>
//         )
//       }
//     },
//     { 
//       title: "Action", 
//       render :(rowData)=>{
//         return(
//           <div>
//               <p>
//               <Link
//                 className="btn btn-link p-0"
//                 to={`/manageprivacypolicy/${rowData.id}`}
//               >
//                 {/* <i className="fas fa-edit text-info" /> */}
//                 <i className="fas fa-pencil-alt text-info"></i>
//               </Link>
//               {hasPermission("users.delete") && (
//                 <button
//                   className="btn btn-link"
//                   onClick={(e) => {
//                     setDelteCustomerId(rowData.id);
//                     setShowDeleteConfirm(true);
//                   }}
//                 >
//                   <i className="fas fa-times text-danger"></i>                  
//                 </button>
//               )}
//             </p>
//           </div>
//         )
//       } 
//     },
//     { 
//       title: "Status", 
//       render:(rowData)=>{
//         return(
//           <>
//           {rowData.status ?? '-'}
//           </>
//         )
//       }
//     },
//   ]
//   useEffect(() => {
//     setIsLoading(true);
//     getAllTermsandConditions().then((response) => {
//       setData(response.data);
//       setIsPageLoading(false);
//       setIsLoading(false);
//     });
//   }, [showDeleteConfirm]);
//   return (
//     <div>
//       <ContentHeader title="Terms and Conditions" />
//       {
//         isPageLoading?
//         (PageLoader):(
//           <section className="content">
//             <div className="container-fluid">
//               <div className="card">
//                 <div className="card-header">
//                   {/* <h3 className="card-title">Title</h3> */}
//                   <div className="float-right">
//                     <Link
//                       to="/manage-terms-and-conditions"
//                       className="btn btn-info"
//                     >
//                       <i className="fas fa-plus" /> Add Terms And Conditions
//                     </Link>
//                   </div>
//                 </div>
//                 <div className="card-body p-0">
//                   <MaterialTable
//                     // id="pdfDatas"
//                     style={{ backgroundColor: "white" }}
//                     columns={columns}
//                     isLoading={isLoading}
//                     data={data}
//                     title=""
//                     options={{
//                       exportAllData: true,
//                       exportMenu: [
//                         {
//                           label: "Export CSV",
//                           exportFunc: (cols, datas) =>
//                             ExportCsv(cols, datas, "trux"),
//                         },
//                       ],
//                     }}
//                   />
//                 </div>
//                 {/* <div className="card-footer">Footer</div> */}
//               </div>
//             </div>
//             {showDeleteConfirm && (
//             <DeleteConfirmModal
//               show={showDeleteConfirm}
//               onClose={onClose}
//               onConfirm={onConfirm}
//             />
//             )} 
//           </section>
//         )
//       }
     
//     </div>
//   );
// };

// export default TermsConditions;




// ---------------------------------------------------------------------------------------------

// import React, { useState, useEffect } from 'react';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
// import ContentHeader from "../../components/content-header/ContentHeader";
// import PageLoader from '../../components/PageLoader';
// import {
//   addMastertermsandconditionsApi,
//   getAllTermsandConditions,
//   getTermsandConditionsdetails,
//   updateTermsandConditionsdetails,
// } from "../../services/data/masters/termsandconditions";
// import { toast } from "react-toastify";
// import { Link, useNavigate, useParams } from "react-router-dom";

// const TermsConditions = () => {
//   const [userdata, setUserData] = useState({message: "",});
//   const {message} = userdata;
//   const [isloading,setIsLoading] = useState(false)  
//   const navigate = useNavigate();

//   const handleChange = (newValue) => {
//     setUserData({...userdata, message: newValue});
//   };
 
//   // useEffect(()=>{
//   //   getAllTermsandConditions().then((res)=>{
//   //     setUserData({ message: res.data});
//   //     setIsLoading(false)
//   //     }) 
//   // },[])
 
//   const handleSubmit = async (e) => { 
//       if (message){
//         await addMastertermsandconditionsApi(userdata).then((response) => {       
//           if (response.status === 200) {
//             toast.success(response.data.message);                      
//           } else {
//             toast.error(response.data.message);
//             setIsLoading(false);
//           }
//       });   
//       } 
//       else{
//         alert("No Data")
//       }              
//   };

//   return (
//     <>
//     <ContentHeader title={`Terms & Conditions` } />
//     {
//       isloading ? (<PageLoader/>)
//       :
//       (
//         <section className="content">
//         <div className='card'>
//           <div className='card-body'>
//             <ReactQuill
//             value={userdata.message}
//             onChange={handleChange}
//             modules={{
//               toolbar: [
//                 [{ header: [1, 2, false] }],
//                 ['bold', 'italic', 'underline', 'strike', 'blockquote'],
//                 [
//                   { list: 'ordered' },
//                   { list: 'bullet' },
//                   { indent: '-1' },
//                   { indent: '+1' },
//                 ],
//                 ['link', 'image'],
//                 [{ color: ['#FF0000', '#00FF00', '#0000FF', '#FFA500', '#800080', 'black'] }, { background: ['#FFFF00', '#00FFFF', '#FF00FF', '#FFC0CB', '#D3D3D3'] }],
//                 ['clean'],
//               ],
//             }}
//             formats={[
//               'header',
//               'bold',
//               'italic',
//               'underline',
//               'strike',
//               'blockquote',
//               'list',
//               'bullet',
//               'indent',
//               'link',
//               'image',
//               'color', 
//               'background', 
//             ]}
//           />
//           </div>
//           <div className='card-footer text-center'>
//           <button
//             type="button"
//             className="btn btn-info mr-3"
//             onClick={handleSubmit}
//             >
//             Submit
//           </button>
//           </div>       
//         </div>
//         </section>
//       )
//     }   
//     </>
    
//   );
// };

// export default TermsConditions;


import React, {useState, useEffect} from 'react'
import ContentHeader from "../../components/content-header/ContentHeader";
import PageLoader from '../../components/PageLoader';
import {
  getAllTermsandConditions, 
} from "../../services/data/masters/termsandconditions";
import { Link } from "react-router-dom";
import ReactQuill from 'react-quill';
import ContentDisplay from '../../components/content-header/ContentDisplay';

const TermsConditions = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);  
  console.log("Reterival All Data t&c ", data)
    
  useEffect(() => {    
    getAllTermsandConditions().then((response) => {
      setData(response.data);      
      setIsLoading(false);
    });
  }, []);
  return (
    <div>
      <ContentHeader title="Terms and Conditions" />
        {
          isLoading ? (<PageLoader />)
          :
          (
            <section className="content">
              <div className="container-fluid">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title"><b>Terms and Conditions</b></h3>
                    <div className="float-right">
                      {/* <Link
                        to="/edit-terms-and-conditions"
                        className="btn btn-info mr-3"
                      >
                         Edit
                      </Link> */}
                      <Link
                        to="/manage-terms-and-conditions"
                        className="btn btn-info"
                      >
                        <i className="fas fa-plus" /> Add Terms And Conditions
                      </Link>
                    </div>
                  </div>
                  <div className="card-body"> 
                  <ContentDisplay  content={data.message} />                 
                  {/* {data && data.map((item) => (
                    <ContentDisplay  content={item.message} />
                  ))} */}
                  </div>
                  {/* <div className="card-footer">Footer</div> */}
                </div>
              </div>
          </section>
          )
        }        
    </div>    
  )
}
export default TermsConditions

