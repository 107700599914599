import http from "../common/http-common";

export async function getAllTrucksApi() {
  return await http.get("/api/v1/trucks",{
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export async function addTrucksApi(data) {
  data["compilances"] = JSON.stringify(data["compilances"])
  return await http.post("/api/v1/trucks", data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-type": "multipart/form-data",
    },
  });
}

export async function updateTrucksApi(id, data) {
  return await http.put(`/api/v1/trucks/${id}`, data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-type": "multipart/form-data",
    },
  });
}

export async function getTruckApi(id){
  return await http.get(`/api/v1/trucks/${id}`, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
  }})
}

export async function deleteTruckApi(id){
  return await http.delete(`/api/v1/trucks/${id}`, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
  }})
}


export async function getTrucksInsurance(id, i, ext) {
  await http
    .get(`/api/v1/trucks/${id}/insurance/${i}`, {
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then((response) => {
      const fileType = response.headers["content-type"];
      const blob = new Blob([response.data], { type: fileType });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
      window.URL.revokeObjectURL(url);
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function getTrucksRegistrationCard(id, i, ext) {
  await http
    .get(`/api/v1/trucks/${id}/reg_card/${i}`, {
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then((response) => {
      const fileType = response.headers["content-type"];
      const blob = new Blob([response.data], { type: fileType });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
      window.URL.revokeObjectURL(url);
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function getTrucksCargoInsurance(id, i, ext) {
  await http
    .get(`/api/v1/trucks/${id}/cargo_insurance/${i}`, {
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then((response) => {
      const fileType = response.headers["content-type"];
      const blob = new Blob([response.data], { type: fileType });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
      window.URL.revokeObjectURL(url);
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function getTrucksPhoto(id, i, ext) {
  await http
    .get(`/api/v1/trucks/${id}/truck_photo/${i}`, {
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then((response) => {
      const fileType = response.headers["content-type"];
      const blob = new Blob([response.data], { type: fileType });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
      window.URL.revokeObjectURL(url);
    })
    .catch((err) => {
      console.log(err);
    });
}