import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getAllTruckTypeApi } from "../../services/data/trucktype";
import { getAllTrucksApi } from "../../services/data/trucks";

import {
  addQuotationApi,
  updateQuotationApi,
} from "../../services/data/notification";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const QuoteModal = ({ show, handleClose, notification }) => {
  // console.log("notification:", notification);
  const token = localStorage.getItem("token");
  const { userid } = jwtDecode(token);

  const { id } = useParams();
  const [totalCost, setTotalCost] = useState(0);
  const [truck, settruck] = useState([]);
  // const [formattedDate, setFormattedDate] = useState("");

  useEffect(() => {
    const fetchtruck = async () => {
      try {
        const response = await getAllTrucksApi();
        console.log(response.data)
        settruck(response.data);
      } catch (error) {
        console.error("Error fetching truck types:", error);
      }
    };
    fetchtruck();
  }, []);

  // useEffect(() => {
  //   if (notification && notification.dateTime) {
  //     const date = new Date(notification.dateTime);
  //     const formatted = date.toLocaleString();
  //     setFormattedDate(formatted);
  //   }
  // }, [notification]);

  const [initialValues, setInitialValues] = useState({
    truckType: "",
    quote: "",
    numOfTrips: "",
  });

  useEffect(() => {
    if (notification && notification.truck_type && notification.truck_type.id) {
      setInitialValues({
        truckType: notification.truck_type.id.toString(),
        quote: "",
        numOfTrips: "",
      });
    } else {
      setInitialValues({
        truckType: "",
        quote: "",
        numOfTrips: "",
      });
    }
  }, [notification]);

  const validationSchema = Yup.object({
    truckType: Yup.string().required("Required"),
    quote: Yup.number().required("Required"),
    numOfTrips: Yup.number().required("Required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const formattedValues = {
        booking_id: notification.booking_id || "",
        transporter_id: notification.transporter_id || userid,
        driver_id: notification.driver_id || "",
        quotation_status: "Quotation Sent",
        truck_id: values.truckType,
        number_of_trips: values.numOfTrips,
        quotation_for_trip: values.quote,
      };
      console.log("formattedValues :", formattedValues);

      if (id) {
        await updateQuotationApi(id, formattedValues);
        toast.success("Quotation updated successfully!");
      } else {
        await addQuotationApi(formattedValues);
        toast.success("Quotation added successfully!");
      }
      setSubmitting(false);
      handleClose();
    } catch (error) {
      console.error("Error submitting form:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === "Booking is over"
      ) {
        toast.error("Booking is over.");
      } else {
        toast.error("Error submitting form. Please try again later.");
      }
      setSubmitting(false);
    }
  };

  const handleFieldChange = (setFieldValue, field, value, values) => {
    setFieldValue(field, value);
    if (field === "quote" || field === "numOfTrips") {
      const newTotalCost =
        (field === "quote" ? value : values.quote) *
        (field === "numOfTrips" ? value : values.numOfTrips);
      setTotalCost(newTotalCost);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Quote For The Order</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form>
              {notification && (
                <>
                  <Row>
                    <Col lg={4}>
                      <strong>Booking No:</strong>
                    </Col>
                    <Col lg={8}>
                      {notification.bookingNo ? notification.bookingNo : "Null"}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <strong>Date and Time:</strong>
                    </Col>
                    <Col lg={8}>
                      {notification.dateTime ? notification.dateTime : " Null"}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <strong>Origin:</strong>
                    </Col>
                    <Col lg={8}>
                      {notification.origin ? notification.origin : "Null"}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <strong>Destination:</strong>
                    </Col>
                    <Col lg={8}>
                      {notification.destination?.destination1
                        ? `${notification.destination.destination1}${
                            notification.destination.destination2
                              ? `, ${notification.destination.destination2}`
                              : ""
                          }`
                        : "Null"}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <strong>Truck Type:</strong>
                    </Col>
                    <Col lg={8}>
                      <Field
                        name="truckType"
                        as="select"
                        className="form-control"
                      >
                        <option value="">Select Truck List</option>
                        {truck.map((type) => (
                          <option key={type.id} value={type.id}>
                            {type.truck_no}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="truckType"
                        component="div"
                        className="text-danger"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <strong>Quote:</strong>
                    </Col>
                    <Col lg={8}>
                      <Field
                        name="quote"
                        type="number"
                        className="form-control"
                        onChange={(e) =>
                          handleFieldChange(
                            setFieldValue,
                            "quote",
                            e.target.value,
                            values
                          )
                        }
                      />
                      <ErrorMessage
                        name="quote"
                        component="div"
                        className="text-danger"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <strong>No of Trips:</strong>
                    </Col>
                    <Col lg={8}>
                      <Field
                        name="numOfTrips"
                        type="number"
                        className="form-control"
                        onChange={(e) =>
                          handleFieldChange(
                            setFieldValue,
                            "numOfTrips",
                            e.target.value,
                            values
                          )
                        }
                      />
                      <ErrorMessage
                        name="numOfTrips"
                        component="div"
                        className="text-danger"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <strong>Total Cost:</strong>
                    </Col>
                    <Col lg={8}> {totalCost} </Col>
                  </Row>
                  <Row className="justify-content-around mt-3">
                    <Button type="reset" variant="secondary">
                      Reset
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={isSubmitting}
                    >
                      Raise Quote
                    </Button>
                  </Row>
                </>
              )}
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default QuoteModal;
