import ContentHeader from "../../components/content-header/ContentHeader";
import "../../styles/Customer.css";
import React, { useState, useRef, useEffect } from "react";
import MaterialTable, { Column } from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import PageLoader from "../../components/PageLoader";
import {
  getAllSupport,
  updateSupportdetails,
  deleteSupport,
  getSupportdetails,
} from "../../services/data/masters/support";
import { hasPermission } from "../../utils/permissions";
import { Link } from "react-router-dom";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";
import { toast } from "react-toastify";
import { Tooltip as ReactTooltip } from "react-tooltip";


const Support = () => {
  const [data, setData] = useState([]);
  console.log('support',data)
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [deleteSupportId, setDeleteSupportId] = useState();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [activeStatus, SetActiveStatus] =  useState('');


  function onConfirm() {    
    // toast.warn("Not Implemented!");
    deleteSupport(deleteSupportId).then((response) => {
      toast.success("Support Deleted");
      setShowDeleteConfirm(false);
    });
  }
  
  function onClose() {
    setShowDeleteConfirm(false);
  }

  const changeStatus = (isActive, id) => {
    const payload = {
      is_active: isActive,
    };
    updateSupportdetails(id, payload).then((response) => {
      if(isActive){
        toast.success("FAQ Activated!");
      }else{
        toast.info("FAQ Deactivated!");
      }      
      getSupportdetails(id).then((response) => {
        setData(response.data);
        SetActiveStatus(isActive)
      });
    });
  };

  const columns = [
    {      
      title: "Name",
      render: (rowData)=>{
      return(
          <div>
              <p>
                <b>{rowData.name}</b>
              </p>
              {/* <p>
                  <Link
                      className="btn ml-0 pl-0 mr-0 pr-0"
                      to={`/managesupport/${rowData.id}`}
                  >                      
                      <i className="fas fa-pencil-alt text-info"></i>
                  </Link>
                  {hasPermission("users.delete") && (
                      <button
                      className="btn  mr-0 pr-0"
                      onClick={(e) => {
                          setDeleteSupportId(rowData.id);
                          setShowDeleteConfirm(true);
                      }}
                      >
                      <i className="fas fa-times text-danger"></i>                     
                      </button>
                  )}                     
              </p> */}
          </div>
        )
      }
  }, 
    { title: "Email", 
      render :(rowData)=>{
        return(
          <>
          <p>{rowData.email ?? '-'}</p>
          </>
        )
      }      
    },
    { title: "Contact No", 
      render :(rowData)=>{
        return(
          <>
          <p>{rowData.contact_no ?? '-'}</p>
          </>
        )
      }      
    },
    {
      title: "Subject",
      render: (rowData) => {         
        const subject = rowData.subject ?? '-';
        return (
          <>
            {
              subject && subject.length > 15 ?
              (                
                <>
                  <p data-tooltip-id={`subject-tooltip-${rowData.id}`}>
                   {subject.substring(0,16)}...
                  </p>
                  <ReactTooltip
                    id={`subject-tooltip-${rowData.id}`}
                    place="top"
                    variant="info"
                    content={subject}
                  />
                </>
              ):(
                <p>{subject}</p>
              )
            }            
          </>
        );
      }
    },
    {
      title: "Message",
      render: (rowData) => {         
        const message = rowData.message ?? '-';
        return (
          <>
            {
              message && message.length > 15 ?
              (                
                <>
                  <p data-tooltip-id={`message-tooltip-${rowData.id}`}>
                   {message.substring(0,15)}...
                  </p>
                  <ReactTooltip
                    id={`message-tooltip-${rowData.id}`}
                    place="top"
                    variant="info"
                    content={message}
                  />
                </>
              ):(
                <p>{message}</p>
              )
            }            
          </>
        );
      }
    },    
    // { 
    //   title: "Status", 
    //   render : (rowData)=>{
    //     return(                       
    //       <h3>
    //           {rowData.status === "IN Active" && (
    //           <button
    //               type="button"
    //               className="btn btn-warning"
    //               onClick={(e) => changeStatus(true, rowData.id)}
    //           >
    //               In Active
    //           </button>
    //           )}
    //           {rowData.status === "active" && (
    //           <button
    //               type="button"
    //               className="btn btn-success"
    //               onClick={(e) => changeStatus(false, rowData.id)}
    //           >
    //               Active
    //           </button>
    //           )} 
    //      </h3>
    //     )
    //   }         
    // }, 
  ]
  useEffect(() => {
    setIsLoading(true);
    getAllSupport().then((response) => {
      setData(response.data);
      setIsPageLoading(false);
      setIsLoading(false);
    });
  }, [showDeleteConfirm, activeStatus]);
  return (
    <div>
      <ContentHeader title="Support" />
      {
        isPageLoading?
        (PageLoader):(
          <section className="content">
            <div className="container-fluid">
              <div className="card">
                <div className="card-header">
                  <h2 
                  className="card-title"
                  style={{textDecoration:"underline"}}
                  >
                    <b>Support Queries</b>
                </h2>
                  {/* <div className="float-right">
                    <Link
                      to="/managesupport"
                      className="btn btn-info"
                    >
                      <i className="fas fa-plus" /> Add Support
                    </Link>
                  </div> */}
                </div>
                <div className="card-body p-0">
                  <MaterialTable
                    // id="pdfDatas"
                    style={{ backgroundColor: "white" }}
                    columns={columns}
                    isLoading={isLoading}
                    data={data}
                    title=""
                    options={{
                      exportAllData: true,
                      exportMenu: [
                        {
                          label: "Export CSV",
                          exportFunc: (cols, datas) =>
                            ExportCsv(cols, datas, "trux"),
                        },
                      ],
                    }}
                  />
                </div>
                {/* <div className="card-footer">Footer</div> */}
              </div>
            </div>
            {showDeleteConfirm && (
            <DeleteConfirmModal
              show={showDeleteConfirm}
              onClose={onClose}
              onConfirm={onConfirm}
            />
            )} 
          </section>
        )
      }
     
    </div>
  );
};

export default Support;
