import ContentHeader from "../../components/content-header/ContentHeader";
import "../../styles/Drivers.css";
import { Link } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import MaterialTable, { Column } from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import "jspdf-autotable";
import { useSelector } from "react-redux";
import { deleteDriverApi, 
         getAllDriversApi, 
         updateDriverApi,
         getDriverApi} from "../../services/data/drivers";
import { hasPermission } from "../../utils/permissions";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";


const Drivers = () => {
  const authentication = useSelector((state) => state.auth.authentication);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteDriverId, setDeleteDriverId] = useState();
  const [activeStatus, SetActiveStatus] =  useState('');

  console.log('Reterival All Drivers Details',data)

  const columns = [
    // { title: "id", field: "id" },
    {
      title:"Driver Name",
      render :(rowData)=>{
        return(
          <>
            <p className="mb-0 pb-0">
              <Link to={`/driver-profile/${rowData.id}`} className="nav-link">
                <i className="fas fa-user nav-icon"></i> <b>{rowData.name ?? '-'}</b>
              </Link>
            </p>
            <p>
              <Link
                className="btn mr-0 pr-0"
                to={`/manage-driver/${rowData.id}`}
              >                             
                <i className="fas fa-pencil-alt text-info"></i>
                {/* <i className="fas fa-edit text-info" /> */}                  
              </Link>{" "}
              {hasPermission("drivers.delete") && (
                <button
                  className="btn  mr-0 pr-0"
                  onClick={(e) => {
                    setDeleteDriverId(rowData.id);
                    setShowDeleteConfirm(true);
                  }}
                >
                  {/* <i className="fas fa-trash text-danger" /> */}
                  <i className="fas fa-times text-danger"></i>
                </button>
              )}{" "}
              <Link
                className="btn mr-0 pr-0"
                to={`/driver-profile/${rowData.id}`}
              >
                <i class="fas fa-eye text-success"></i>
              </Link>
            </p>
          </>
        )
      }
    },
    // { title: "Transporter", field: "transporter" },
    {
      title:"Truck Owner", 
      render: (rowData)=>{
        return(
          <>
            {rowData.transporter.contact_person?? '-'}
          </>
        )
      }
    },
    { title: "Email", field: "email" },
    { 
      title: "Contact No", 
      render: (rowData) => {
        return `+${rowData.country_code}-${rowData.contact_no}`;
      }
    },
    { title: "No Of Trips", field: "no_of_trips" },
    { 
      title: "Rating",
      render:(rowData)=>{
        return(
          <>
          {rowData.rating??"-"}
          </>
        )
      }
   },
   { 
    title: "Status", 
    render : (rowData)=>{
      return(
        <h3>
        {rowData.status === "In Active" && (
          <button
            type="button"
            className="btn btn-warning"
            onClick={(e) => changeStatus(true, rowData.id)}
          >
            In Active
          </button>
        )}
        {rowData.status === "Active" && (
          <button
            type="button"
            className="btn btn-success"
            onClick={(e) => changeStatus(false, rowData.id)}
          >
            Active
          </button>
        )} 
       </h3>
      )
    }         
  },
    // {
    //   title: "Action",
    //   render: (rowData) => {
    //     return (
    //       <div className="btn-group btn-group-sm">
    //         <Link className="btn btn-info" to={`/manage-driver/${rowData.id}`}>
    //           <i className="fas fa-edit" />
    //         </Link>
    //         {hasPermission("drivers.delete") && (
    //           <button
    //             className="btn btn-danger"
    //             onClick={(e) => {
    //               setDeleteDriverId(rowData.id);
    //               setShowDeleteConfirm(true);
    //             }}
    //           >
    //             <i className="fas fa-trash" />
    //           </button>
    //         )}
    //       </div>
    //     );
    //   },
    // },
  ];

  useEffect(() => {
    getAllDriversApi().then((response) => {
      setData(response.data);
      setIsLoading(false);
      setIsPageLoading(false);
    });
  }, [showDeleteConfirm,activeStatus]);

  function onClose() {
    setShowDeleteConfirm(false);
  }

  function onConfirm() {    
    deleteDriverApi(deleteDriverId).then((response) => {
      toast.success("Driver Deleted");
      setShowDeleteConfirm(false)
    });
  }

  const changeStatus = (isActive, id) => {
    const payload = {
      is_active: isActive,
    };
    updateDriverApi(id, payload).then((response) => {
      if(isActive){
        toast.success("User Activated!");
      }else{
        toast.info("User Deactivated!");
      }
      
      getDriverApi(id).then((response) => {
        setData(response.data);
        SetActiveStatus(isActive)
      });
    });
  };

  return (
    <>
      <div>
        <ContentHeader title="Drivers" />
        {isPageLoading ? (
          <section className="content">
            <Spinner animation="grow" className="bg-info" />
            <Spinner animation="grow" className="bg-primary" />
            <Spinner animation="grow" className="bg-success" />
          </section>
        ) : (
          <section className="content">
            <div className="container-fluid">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Driver Details</h3>
                  <div className="card-tools">
                    {hasPermission("drivers.create") && (
                      <Link
                        type="button"
                        className="btn btn-tool bg-info"
                        data-card-widget="collapse"
                        title="Collapse"
                        to="/new-driver"
                      >
                        <i className="fas fa-plus" /> New Driver
                      </Link>
                    )}
                  </div>
                </div>
                {/* <div className="card-body"> */}
                <MaterialTable
                  style={{ backgroundColor: "white" }}
                  columns={columns}
                  data={data}
                  title=""
                  options={{
                    exportAllData: true,
                    exportMenu: [
                      {
                        label: "Export CSV",
                        exportFunc: (cols, datas) =>
                          ExportCsv(cols, datas, "trux"),
                      },
                    ],
                  }}
                />
                {/* </div>
              <div className="card-footer">Footer</div> */}
              </div>
            </div>
            { showDeleteConfirm && 
              <DeleteConfirmModal
                show={showDeleteConfirm}
                onClose={onClose}
                onConfirm={onConfirm}
              />
            }
          </section>
        )}
      </div>
    </>
  );
};

export default Drivers;
