import ContentHeader from "../../components/content-header/ContentHeader";
import "../../styles/Drivers.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "../../services/common/globalvars";
import { getAllTransportersApi } from "../../services/data/transporters";
import Select from "react-select";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import {
  addDrivers,
  getDriverApi,
  updateDriverApi,
  getdriverLicenseDoc,
  getdriverUploadPhoto,
  getDriverResetPassword, 
} from "../../services/data/drivers";
import { getMasterCounteryCode } from "../../services/data/masters";


const ManageDriver = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [transporterOptions, setTransporterOptions] = useState([]);
  const [countryCode, setCountryCode]= useState()
  const [truckOwners, setTruckOwners] = useState([]);


  const [userdata, setUserData] = useState({
    transporter: "",
    name: "",
    country_code: "",
    contact_no: "",
    email: "",
    password: "",
    address: "",
    license_no: "",
    license_doc: [],
    license_validity: "",
    driver_photo: [],
  });  

  

  const {
    transporter,
    name,
    country_code,
    contact_no,
    email,
    password,
    address,
    license_no,
    license_doc,
    license_validity,
    driver_photo,
  } = userdata;
  
  

  const setDate = (date)=>{     
    setUserData(
      { ...userdata, 
        license_validity :`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      });
  }

  useEffect(() => {
    getMasterCounteryCode().then((response) => {
      setCountryCode(response.data);
    });
    getAllTransportersApi().then((response) => {      
      let optionData = [];
      response.data.map((item) =>
        optionData.push({ value: item.id, label: item.contact_person })
      );
      setTruckOwners(optionData);
      setIsPageLoading(false);
    });
    if (id) {
      getDriverApi(id).then((response) => {
        console.log('Reterival Single Edit Driver ', response.data)
        setUserData({
          transporter: response.data.transporter.id,
          name: response.data.name,
          country_code: response.data.country_code,
          contact_no: response.data.contact_no,
          email: response.data.email,
          password: response.data.password,
          address: response.data.address,
          license_no: response.data.license_no,
          license_doc: response.data.license_doc,
          license_validity: response.data.license_validity,
          driver_photo: response.data.driver_photo,
        });
      });
    }
    else {
      handleReset();
      setIsPageLoading(false);
    }
  }, [id]);

  console.log(country_code,'console.log')


  
  
  const dataHandle = (e) => {
    console.log(e);
    setUserData({ ...userdata, [e.target.name]: e.target.value });
  };

  const fileHandle = (e) => {     
    if (e.target.name.trim() === "license_doc"){
      const files = Array.from(e.target.files);      
      // const imageArray = files.map((file)=>URL.createObjectURL(file))          
      setUserData({
        ...userdata,
        [e.target.name]: [...files],
      });    
    }
    else{
      setUserData({
        ...userdata,
        [e.target.name]:  Array.from(e.target.files),
      });
    }
    
  };
 
  
  const handleReset = () => {
    setUserData({
      transporter: "",
      name: "",
      country_code: "",
      contact_no: "",
      email: "",
      password: "",
      address: "",
      license_no: "",
      license_doc: [],
      license_validity: "",
      driver_photo: [],
    });
  };

  const selectOwnerHandle = (e) => {
    console.log(e);
    setUserData({ ...userdata, transporter: e.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsPageLoading(true);
    console.log(userdata);
    if(
      !transporter        || 
      !name               || 
      !country_code       || 
      !contact_no         || 
      !email              ||
      !license_no         || 
      !license_doc.length || 
      !license_validity        
    ){
      toast.error("Please fill in all required fields.");
      setIsLoading(false);
      return
    }

    if (!id && (!password)) {
      toast.error("Please fill the Password");
      setIsLoading(false);
      return;
    }
    
    if (contact_no.length > 10) {
      toast.error("Mobile number too long. Maximum 10 digits allowed.");
      setIsLoading(false);
      return;
    }

    if (id) {
      console.log('update Driver Detail', userdata)
      updateDriverApi(id, userdata).then((response) => {
        if (response.status >= 200) {
          toast.success(response.data.message);
          navigate("/drivers");
        } else {
          toast.error(response.data.message);
          setIsLoading(false);
        }
      });
    } else {
      console.log('post Driver Details', userdata)
      addDrivers(userdata).then((response) => {
        if (response.status === 201) {
          toast.success(response.data.message);
          navigate("/drivers");
        } else {
          toast.error(response.data.message);
          setIsLoading(false);
        }
      });
    }
  };

  // useEffect(()=>{
  //   if (!id) {
  //     setIsPageLoading(true)
  //     getMasterCounteryCode()
  //     .then((response)=>{
  //       setCountryCode(response.data)
  //       setIsPageLoading(false)
  //     })
  //   }
  // },[])

  function ResetPassword(userId){
    getDriverResetPassword(userId).then((response)=>{
      console.log(response.data)
      toast.success(response.data.message);
    })
  }

  return (
    <>
      <div>
        <ContentHeader title={id ? "Edit Driver" :"Drivers"} />
        <section className="content">
          <div className="container-fluid">
            <div className="card">
              {/* <div className="card-header">
                <h3 className="card-title">Driver Form</h3>                
              </div> */}
              <div className="card-body">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="truckowner">Truck Owner</label>
                          <select
                            name="transporter"
                            value={transporter}
                            onChange={dataHandle}
                            className="form-control"
                            defaultValue={transporter}
                          >
                            <option value=''>Select..</option>
                            {truckOwners.map((item) => {
                              return (
                                <option value={item.value}>{item.label}</option>
                              );
                            })}
                          </select>
                        
                        {/* <Select
                          options={transporterOptions}
                          value={transporterOptions[transporter]}
                          
                          name="truck_owner"
                          onChange={selectOwnerHandle}
                        /> */}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="customerName">Driver Name</label>
                        <input
                          type="text"
                          name="name"
                          value={name}
                          placeholder="Driver Name"
                          onChange={dataHandle}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="customerName">Address</label>
                        <input
                          type="text"
                          name="address"
                          value={address}
                          placeholder="Address"
                          onChange={dataHandle}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <div className="form-group">                      
                        <div className="row">
                          <div className="col-12 col-md-4">
                          <label htmlFor="countryCode">Country Code</label>                            
                            <select
                              id="countryCode"
                              name="country_code"
                              value={country_code}
                              defaultValue={country_code}
                              onChange={dataHandle}
                              className="form-control"                             
                            >
                              <option value="">Select..</option>
                              {
                                 countryCode && 
                                 countryCode.map((item)=>{
                                  return(
                                    <option value={item.country_code}>
                                      +{item.country_code+" "+item.country}
                                    </option>
                                  )
                                 })
                                }  
                            </select>
                          </div>
                          <div className="col-12 col-md-8">
                          <label htmlFor="contactNo">Contact No</label>
                            <input
                              type="number"
                              name="contact_no"
                              value={contact_no}
                              placeholder="Contact No"
                              onChange={dataHandle}
                              className="form-control"
                            />
                            <b className="text-danger">{
                              (contact_no.length > 10) ?
                              "Mobile number too long. Maximum 10 digits allowed."
                              : ''
                              }
                            </b>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="email">Email Id</label>
                   
                          <input
                            type="email"
                            name="email"
                            value={email}
                            placeholder="Email Id"
                            onChange={dataHandle}
                            className="form-control"
                          />
                      
                      </div>
                    </div>
                    {!id ? (
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input
                          type="password"
                          name="password"
                          value={password}
                          placeholder="Password"
                          onChange={dataHandle}
                          className="form-control"
                        />
                      </div>
                    </div>
                    ):(
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="password">Password</label>
                          <a
                            onClick={()=>ResetPassword(id)}
                            className="btn btn-link form-control"
                            style={{
                              textDecoration: "underline",
                              textAlign: "left",
                              padding: 0,
                            }}
                          >
                            Click here to reset password
                          </a>
                        </div>
                      </div>
                    )
                    }

                    

                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="customerName">License No</label>
                        <input
                          type="text"
                          name="license_no"
                          value={license_no}
                          placeholder="License No"
                          onChange={dataHandle}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="exampleInputFile">
                          License
                          {id && license_doc &&
                            license_doc.map(
                              (item) => {
                                return (
                                  <a
                                    className="btn btn-link p-0"
                                    onClick={(e) =>
                                      getdriverLicenseDoc(
                                        id,
                                        item.id,
                                        item.file_ext
                                      )
                                    }
                                  >
                                    <i className="far fa-fw fa-file-pdf" />
                                    {`trade-license-${item.id}.${item.file_ext}`}
                                  </a>
                                );
                              }
                          )}
                        </label>
                        <div className="input-group">
                          <div className="custom-file">
                            <input
                              type="file"
                              className="custom-file-input"
                              id="exampleInputFile"
                              name="license_doc"
                              multiple accept="image/*,.pdf"
                              onChange={fileHandle}
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="exampleInputFile"
                            >
                              {license_doc && license_doc.map((file)=> file.name)}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="tradeLicenseValidityDate">
                          License Validity
                        </label>
                        <input
                          type="date"
                          name="license_validity"
                          value={
                            license_validity
                              ? new Date(
                                  license_validity
                                ).toLocaleDateString("en-CA")
                              : ""
                          }
                          placeholder="Trade License Validity Date"
                          onChange={dataHandle}
                          className="form-control"
                        />
                      </div>
                    </div> */}

                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label htmlFor="tradeLicenseValidity">
                            License Validity
                        </label>
                        <div className="input-group">
                          <DatePicker
                            name="license_validity" 
                            value = {
                              license_validity ?
                              new Date(license_validity).toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              })
                              : license_validity }                                 
                            onChange={(date) => setDate(date, "insurane_validity")}
                            showYearDropdown 
                            scrollableYearDropdown 
                            dateFormat="dd/MM/yyyy"
                            placeholderText="dd/MM/yyyy"
                            minDate={new Date()}
                            className="form-control" 
                            popperPlacement="top"                                 
                          />
                          <div className="input-group-append position-relative">
                            <span
                              className="input-group-text position-absolute d-flex align-items-center"
                              style={{ height: "100%" }}
                            >
                              <i className="fa fa-calendar"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="driverInputFile">
                        Upload Driver Photo  (Optional)

                        {id && driver_photo &&
                            driver_photo.map(
                              (item) => {
                                return (
                                  <a
                                    className="btn btn-link p-0"
                                    onClick={(e) =>
                                      getdriverUploadPhoto(
                                        id,
                                        item.id,
                                        item.file_ext
                                      )
                                    }
                                  >
                                    <i className="far fa-fw fa-file-pdf" />
                                    {`trade-license-${item.id}.${item.file_ext}`}
                                  </a>
                                );
                              }
                          )}
                        </label>
                        <div className="input-group">
                          <div className="custom-file">
                            <input
                              type="file"
                              className="custom-file-input"
                              id="driverInputFile"
                              name="driver_photo"
                              multiple accept="image/*,.pdf"
                              onChange={fileHandle}
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="driverInputFile"
                            >
                              {driver_photo && driver_photo.map((file)=> file.name)}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              <div className=" text-right">
                <button
                  type="reset"
                  className="btn btn-warning mr-3"
                  onClick={handleReset}
                >
                  Reset
                </button>
                <button
                  type="button"
                  className="btn btn-info"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
              </div>

            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ManageDriver;
