import ContentHeader from "../../components/content-header/ProfileContentHeader";
import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useParams } from "react-router-dom";
import {
  getCustomerDetails,
  getCustomerDueDiligenceDoc,
  getCustomerLicenseDoc,
  updateCustomersApi,
  updateCustomersDocVerifyApi,
  getCustomerResetPassword,
} from "../../services/data/customers";
import MaterialTable, { Column } from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import PageLoader from "../../components/PageLoader";
import AssignSpocModal from "./AssingSpocModal";
import DuedeligenceModal from "./DuedeligenceModal";
import Box from "@mui/material/Box";
import { toast } from "react-toastify";

import Slider from "@mui/material/Slider";
import ApprovalConfirmModal from "../../components/ApprovalConfirmModal";
import { getDueDiligenceReport } from "../../services/data/spoc";
import Accordion from "react-bootstrap/Accordion";
import {Link} from "react-router-dom"


const CustomerProfile = () => {
  const { id } = useParams();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [showAssignSpocModal, setShowAssignSpocModal] = useState(false);
  const [showDueDeligenceModal, setShowDueDeligenceModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [userId, setuserId] = useState(null);
  const [userData, setUserData] = useState();
  console.log('customerProfile',userData)
  
  useEffect(() => {
    if (showAssignSpocModal || showDueDeligenceModal) {
      return;
    }
    getCustomerDetails(id).then((response) => {
      console.log(response.data, 'customer profile')
      setIsPageLoading(false);
      setUserData(response.data);
    });
  }, [id, showAssignSpocModal, showDueDeligenceModal]);

  const data = [];

  const columns = [
    {
      title: "Booking No",
      field: "booking_no",
    },
    {
      title: "Origin",
      field: "origin",
    },
    {
      title: "Destination",
      field: "destination",
    },
    {
      title: "Booked On",
      field: "booked_on",
    },
    {
      title: "ETA",
      field: "eta",
    },
    {
      title: "Status",
      field: "status",
    },
    {
      title: "Payment Status",
      field: "payment_status",
    },
  ];

  const handleAssignSpoc = () => {
    setShowAssignSpocModal(true);
  };

  const onClose = () => {
    setShowAssignSpocModal(false);
    setShowApproveModal(false);
  };

  const handleDueDeligence = (id) => {
    // setuserId(id)
    setShowDueDeligenceModal(true);
  };

  const onCloseDueDeligence = () => {
    setShowDueDeligenceModal(false);
  };

  const changeStatus = (isActive, id) => {
    const payload = {
      is_active: isActive,
    };
    updateCustomersApi(id, payload).then((response) => {
      if (isActive) {
        toast.success("User Activated!");
      } else {
        toast.info("User Deactivated!");
      }

      getCustomerDetails(id).then((response) => {
        setUserData(response.data);
      });
    });
  };

  function onConfirm() {
    setShowApproveModal(false);
    // toast.warn("Not Implemented!");
    updateCustomersDocVerifyApi(id).then((response) => {
      toast.success("Doc Verified!");
      getCustomerDetails(id).then((response) => {
        setUserData(response.data);
      });
    });
  }

  function ResetPassword(userId){
    getCustomerResetPassword(userId).then((response)=>{
      console.log(response.data)
      toast.success(response.data.message);
    })
  }

  return (
    <>
      <div>
        <ContentHeader title="Customers" />
        {isPageLoading ? (
          <PageLoader />
        ) : (
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="d-flex justify-content-between">
                    <h5 style={{ textDecoration: "underLine" }}>
                      Trips and Business Info
                    </h5>
                    {/* <Box sx={{ width: 300 }}>
                      <h6>Profile Completeness</h6>
                      <Slider
                        value={50}
                        aria-label="Default"
                        valueLabelDisplay="auto"
                      />
                    </Box> */}
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Total Trips Raised</th>
                              <th>Total Trips Confrmed</th>
                              <th>Business Given</th>
                              <th>Paid to Trux</th>
                              <th>Current Due to Trux</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{userData.total_trips ?? "0"}</td>
                              <td>{userData.total_trips_confirmed ?? "0"}</td>
                              <td>
                                <b>{userData.business_given ?? "0"} OMR</b>
                              </td>
                              <td className="text-success">
                                <b>{userData.paid_to_trux ?? "0"} OMR </b>
                              </td>
                              <td className="text-danger">
                                <b>{userData.current_due_to_trux ?? "0"} OMR</b>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="card card-primary">
                    <div className="card-body box-profile">
                      <div className="text-center">
                        <img
                          className="img-fluid"
                          src="/img/avatar.png"
                          alt="User profile picture"
                        />
                      </div>
                      <h3 className="profile-username text-center">
                        {userData.name}
                      </h3>
                      <h3 className="profile-username text-center">
                        {userData.status === "In Active" && (
                          <button
                            type="button"
                            className="btn btn-warning"
                            onClick={(e) => changeStatus(true, id)}
                          >
                            In Active
                          </button>
                        )}
                        {userData.status === "Active" && (
                          <button
                            type="button"
                            className="btn btn-success"
                            onClick={(e) => changeStatus(false, id)}
                          >
                            Active
                          </button>
                        )}
                      </h3>
                    </div>
                  </div>
                </div>

                <div className="col-md-9">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <ul className="list-group">
                            <li className="list-group-item border-0">
                              <div className="row">
                                <div className="col-md-6 col-6">
                                  <b>Customer Name</b>
                                </div>
                                <div className="col-md-6 col-6">
                                  <h6>{userData.name}</h6>
                                </div>
                              </div>
                            </li>

                            <li className="list-group-item border-0">
                              <div className="row">
                                <div className="col-md-6 col-6">
                                  <b>Customer Type</b>
                                </div>
                                <div className="col-md-6 col-6">
                                  <h6>{userData.type.toUpperCase()}</h6>
                                </div>
                              </div>
                            </li>

                            <li className="list-group-item border-0">
                              <div className="row">
                                <div className="col-md-6 col-6">
                                  <b>Password</b>
                                </div>
                                <div className="col-md-6 col-6">
                                  <a
                                    onClick={()=>ResetPassword(id)}
                                    className="btn btn-link p-0"
                                    style={{ textDecoration: "underline" }}
                                  >
                                    Reset Password
                                  </a>
                                </div>
                              </div>
                            </li>

                            {userData.type !== "individual" && (
                              <>
                                <li className="list-group-item border-0">
                                  <div className="row">
                                    <div className="col-md-6 col-6">
                                      <b>Trade/Commercial License</b>
                                    </div>
                                    <div className="col-md-6 col-6">
                                      <h6>
                                      <Accordion defaultActiveKey="0">
                                        <Accordion.Toggle as={Link} variant="link" eventKey="1">
                                          {/* <i className="fas fa-plus"></i> */}
                                          <a 
                                           className="btn btn-link p-0"
                                           style={{ textDecoration: "underline" }}
                                           >
                                            View documents
                                          </a>
                                        </Accordion.Toggle>
                                        {userData.trade_license_doc &&
                                          userData.trade_license_doc.map(
                                            (item) => {
                                              return (
                                                <Accordion.Collapse eventKey="1">
                                                  <a
                                                    className="btn btn-link p-0"
                                                    onClick={(e) =>
                                                      getCustomerLicenseDoc(
                                                        id,
                                                        item.id,
                                                        item.file_ext
                                                      )
                                                    }
                                                  >
                                                    <i className="far fa-fw fa-file-pdf" />
                                                    {`trade-license-${item.id}.${item.file_ext}`}
                                                  </a>
                                                </Accordion.Collapse>
                                              );
                                            }
                                          )}
                                      </Accordion>
                                      </h6>
                                      {userData.trade_license_doc &&
                                        userData.is_trade_license_doc_verified ? (
                                        <div className="custom-control custom-checkbox">
                                          <input
                                            className="custom-control-input custom-control-input-success custom-control-input-outline"
                                            type="checkbox"
                                            id="customCheckbox5"
                                            checked
                                            disabled
                                          />
                                          <label
                                            htmlFor="customCheckbox5"
                                            className="custom-control-label text-success"
                                          >
                                            Admin Verified
                                          </label>
                                        </div>
                                      ) : ( 
                                          userData.trade_license_doc && userData.trade_license_doc.length > 0 ? (
                                          <a
                                            className="btn btn-primary"
                                            onClick={(e) => {
                                              setShowApproveModal(true);
                                            }}
                                          >
                                            Verify
                                          </a>
                                        ) : (
                                          
                                          ' '
                                        )                                                                  
                                      )}
                                    </div>
                                  </div>
                                </li>
                                <li className="list-group-item border-0">
                                  <div className="row">
                                    <div className="col-md-6 col-6">
                                      <b>Company Address</b>
                                    </div>
                                    <div className="col-md-6 col-6">
                                      <h6>{userData.company_address ?? "-"}</h6>
                                    </div>
                                  </div>
                                </li>
                              </>
                            )}
                          </ul>
                        </div>

                        <div className="col-md-6">
                          <ul className="list-group">
                            <li className="list-group-item border-0">
                              <div className="row">
                                <div className="col-md-6 col-6">
                                  <b>Email Address</b>
                                </div>
                                <div className="col-md-6 col-6">
                                  <h6>{userData.email ?? "-"}</h6>
                                </div>
                              </div>
                            </li>
                            <li className="list-group-item border-0">
                              <div className="row">
                                <div className="col-md-6 col-6">
                                  <b>Contact No</b>
                                </div>
                                <div className="col-md-6 col-6">
                                  <h6>
                                    +{userData.country_code}-
                                    {userData.contact_no}
                                  </h6>
                                </div>
                              </div>
                            </li>
                            {userData.type !== "individual" && (
                              <>
                                <li className="list-group-item border-0">
                                  <div className="row">
                                    <div className="col-md-6 col-6">
                                      <b>Trade/Commercial License No</b>
                                    </div>
                                    <div className="col-md-6 col-6">
                                      <h6>
                                        {userData.trade_license_no ?? "-"}
                                      </h6>
                                    </div>
                                  </div>
                                </li>

                                <li className="list-group-item border-0">
                                  <div className="row">
                                    <div className="col-md-6 col-6">
                                      <b>Validity</b>
                                    </div>
                                    <div className="col-md-6 col-6">
                                      {userData.type !== "individual" ? (
                                        <h6>
                                          {userData.trade_license_validity
                                            ? new Date(
                                                userData.trade_license_validity
                                              ).toLocaleDateString("en-GB", {
                                                day: "2-digit",
                                                month: "2-digit",
                                                year: "numeric",
                                              })
                                            : "-"}
                                        </h6>
                                      ) : (
                                        <h6>NA</h6>
                                      )}
                                    </div>
                                  </div>
                                </li>
                              </>
                            )}
                            <li className="list-group-item border-0">
                              <div className="row">
                                <div className="col-md-6 col-6">
                                  <b>Total Trips</b>
                                </div>
                                <div className="col-md-6 col-6">
                                  <h6>{userData.total_trips ?? 0}</h6>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {userData.type !== "individual" && (
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12">
                        <h5>
                          <span style={{ textDecoration: "underLine" }}>
                            Due Deligence
                          </span>{" "}
                          &nbsp;
                          <button
                            className="btn btn-info"
                            onClick={handleAssignSpoc}
                          >
                            Assign SPOC
                          </button>
                        </h5>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-body p-0">
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Assigned SPOC</th>
                                <th>Status</th>
                                <th>Due Deligence Report</th>
                                <th>Credit Duration</th>
                                <th>Authorised Signatory</th>
                                <th>Designation</th>
                              </tr>
                            </thead>
                            <tbody>
                              {userData.due_diligence && (
                                <tr>
                                  <td>
                                    {userData.due_diligence.spoc
                                      ? userData.due_diligence.spoc.name ?? "-"
                                      : "-"}
                                  </td>
                                  <td>
                                    {userData.due_diligence?.spoc?.name ? (
                                      <>
                                        {userData.due_diligence.report
                                          ?.length &&
                                        userData.due_diligence
                                          .credit_duration ? (
                                          <>Completed</>
                                        ) : (
                                          <a
                                            href="#"
                                            onClick={() => handleDueDeligence()}
                                          >
                                            In Progress
                                          </a>
                                        )}
                                      </>
                                    ) : (
                                      "-"
                                    )}
                                  </td>
                                  <td>
                                    {userData.due_diligence.spoc
                                      ? userData.due_diligence.report &&
                                        userData.due_diligence.report.map(
                                          (item, i) => {
                                            return (
                                              <>
                                                <a
                                                  className="btn btn-link p-0"
                                                  onClick={(e) =>
                                                    getDueDiligenceReport(
                                                      userData.due_diligence.id,
                                                      item.id
                                                    )
                                                  }
                                                >
                                                  <i className="far fa-fw fa-file-pdf" />
                                                  {`report-${item.id}.${item.file_ext}`}
                                                </a>
                                                <br />
                                              </>
                                            );
                                          }
                                        )
                                      : "-"}
                                  </td>
                                  <td>
                                    {userData.due_diligence.spoc
                                      ? userData.due_diligence
                                          .credit_duration ?? "-"
                                      : "-"}
                                  </td>
                                  <td>
                                    {userData.due_diligence.spoc
                                      ? userData.due_diligence
                                          .authorised_signatory ?? "-"
                                      : "-"}
                                  </td>
                                  <td>
                                    {userData.due_diligence.spoc
                                      ? userData.due_diligence.designation ??
                                        "-"
                                      : "-"}
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-12">
                  <h5 style={{ textDecoration: "underLine" }}>Trips History</h5>
                  <div className="card">
                    {/* <div className="card-header">
                      <h3 className="card-title">Trip History</h3>
                    </div> */}
                    {/* <div className="card-body">
                      
                    </div> */}
                    <MaterialTable
                      style={{ backgroundColor: "white" }}
                      columns={columns}
                      data={data}
                      title=""
                      options={{
                        exportAllData: true,
                        exportMenu: [
                          {
                            label: "Export CSV",
                            exportFunc: (cols, datas) =>
                              ExportCsv(cols, datas, "trux"),
                          },
                        ],
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {showAssignSpocModal && (
              <AssignSpocModal
                onClose={onClose}
                show={showAssignSpocModal}
                id={id}
              />
            )}
            {showDueDeligenceModal && (
              <DuedeligenceModal
                show={showDueDeligenceModal}
                handleClose={onCloseDueDeligence}
                userId={id}
                dueDiligence={userData.due_diligence}
                existTradeDoc={userData.trade_license_doc}
              />
            )}
            {showApproveModal && (
              <ApprovalConfirmModal
                show={showApproveModal}
                onClose={onClose}
                onConfirm={onConfirm}
              />
            )}
          </section>
        )}
      </div>
    </>
  );
};

export default CustomerProfile;
