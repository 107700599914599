import http from "../common/http-common";

export async function getDriversOptionApi() {
  return await http.get("/api/v1/drivers/basic");
}

export async function getAllDriversApi() {
  return await http.get("/api/v1/drivers",{
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export async function getDriverApi(id) {
  return await http.get(`/api/v1/drivers/${id}`,{
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export async function addDrivers(data) {
  return await http.post("/api/v1/drivers", data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-type": "multipart/form-data",
    },
  });
}

export async function updateDriverApi(id, data) {
  return await http.put(`/api/v1/drivers/${id}`, data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-type": "multipart/form-data",
    },
  });
}

export async function deleteDriverApi(id) {
  return await http.delete(`/api/v1/drivers/${id}`,{
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export async function getdriverLicenseDoc(id, i, ext) {
  await http
    .get(`/api/v1/drivers/${id}/license_doc/${i}`, {
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then((response) => {
      const fileType = response.headers["content-type"];
      const blob = new Blob([response.data], { type: fileType });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
      window.URL.revokeObjectURL(url);
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function getdriverUploadPhoto(id, i, ext) {
  await http
    .get(`/api/v1/drivers/${id}/driver_photo/${i}`, {
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then((response) => {
      const fileType = response.headers["content-type"];
      const blob = new Blob([response.data], { type: fileType });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
      window.URL.revokeObjectURL(url);
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function getDriverResetPassword(id) {
  return await http.get(`/api/v1/drivers/${id}/reset-password`,{
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}
