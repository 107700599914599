import ContentHeader from "../../components/content-header/ContentHeader";
import "../../styles/Transporters.css";
import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import MaterialTable, { Column } from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { useSelector } from "react-redux";
import { hasPermission } from "../../utils/permissions";
import {
  deleteTransporterApi,
  getAllTransportersApi,
  updateTransportersApi,
  getTransportersDetails,
} from "../../services/data/transporters";
import { MdDelete } from "react-icons/md";
import { GrFormView } from "react-icons/gr";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";
import { toast } from "react-toastify";
import PageLoader from "../../components/PageLoader";
import DuedeligenceModal from "../customers/DuedeligenceModal";
import AssignSpocModal from "./AssingSpocModal";
import Accordion from "react-bootstrap/Accordion";
import { getDueDiligenceReport } from "../../services/data/spoc";

const Transporters = () => {
  // Table Data give the place
  const authentication = useSelector((state) => state.auth.authentication);
  const [data, setData] = useState([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [deleteTransporterId, setDeleteTransporterId] = useState();

  const [showDueDeligenceModal, setShowDueDeligenceModal] = useState(false);
  const [id, setId] = useState("");
  const [tradeLicenseDoc, setTradeLicenseDoc] = useState([]);
  const [dueDeligence, setDueDeligence] = useState([]);
  const [showAssignSpocModal, setShowAssignSpocModal] = useState(false);
  const [activeStatus, SetActiveStatus] = useState("");

  console.log("all Transporter Details", data);
  const columns = [
    // { title: "id", field: "id" },
    {
      title: "Company Name",
      field: "company_name",
      render: (rowData) => {
        return (
          <div className="mt-1">
            <p className="mb-0 pb-0">
              <Link
                to={`/transporter-details/${rowData.id}`}
                className="nav-link"
              >
                <i className="fas fa-building nav-icon"></i>&nbsp;
                <b>{rowData.company_name}</b>
              </Link>
            </p>
            <p>
              {hasPermission("transporters.edit") && (
                <Link
                  className="btn mr-0 pr-0"
                  to={`/manage-transporter/${rowData.id}`}
                >
                  <i className="fas fa-pencil-alt text-info"></i>
                  {/* <i className="fas fa-edit text-info" /> */}
                </Link>
              )}
              {hasPermission("transporters.delete") && (
                <button
                  className="btn mr-0 pr-0"
                  onClick={(e) => {
                    setDeleteTransporterId(rowData.id);
                    setShowDeleteConfirm(true);
                  }}
                >
                  {/* <i className="fas fa-trash text-danger" /> */}
                  <i className="fas fa-times text-danger"></i>
                </button>
              )}{" "}
              <Link
                className="btn mr-0 pr-0"
                to={`/transporter-details/${rowData.id}`}
              >
                <i class="fas fa-eye text-success"></i>
              </Link>
            </p>
          </div>
        );
      },
    },
    // { title: "Contact Person", field: "contact_person" },
    {
      title: "Contact Details",
      field: "email_id",
      render: (rowData) => {
        return (
          <>
            <p>
              <b>Name:</b>
              {rowData.contact_person}
            </p>
            <p>
              <b>Email:</b>
              {rowData.email ?? "-"}
            </p>
            <p>
              <b>Mobile No:</b>+{rowData.country_code}-{rowData.contact_no}
            </p>
          </>
        );
      },
    },
    // { title: "Contact No", field: "contact_no" },
    {
      title: "User Type",
      field: "type",
      render: (rowData) => {
        return (
          <>
            {rowData.type.toUpperCase().trim() == "MULTIUSER"
              ? "MULTI USER"
              : "SINGLE USER"}
          </>
        );
      },
    },
    {
      title: "Total Trips",
      field: "total_trips",
      render: (rowData) => {
        return <>{rowData.total_trips ?? "0"}</>;
      },
    },
    {
      title: "Rating",
      field: "rating",
      render: (rowData) => {
        return <>{rowData.rating ?? "-"}</>;
      },
    },
    {
      title: "Due Diligence",
      field: "due_diligence",
      render: (rowData) => {
        return (
          <>
            <p>
              <b>Trux Admin:</b>
              {rowData.due_diligence.spoc &&
                  rowData.is_trade_license_doc_verified
                    ? "Completed"
                    : "In Progress"}
            </p>
            <p>
              <b>Sales SPOC:</b> <br />
              {rowData.due_diligence?.spoc?.name ? (
                rowData.due_diligence.spoc.name
              ) : (
                <a href="#" onClick={() => handleAssignSpoc(rowData.id)}>
                  <span>Select User</span>
                </a>
              )}
            </p>
            {rowData.due_diligence?.spoc && (
              <p>
                <b>Verification:</b>
                {rowData.due_diligence?.is_report_verified ? (
                  "Completed"
                ) : (
                  <a
                    href="#"
                    onClick={() =>
                      handleDueDeligence(
                        rowData.id,
                        rowData.trade_license_doc,
                        rowData.due_diligence
                      )
                    }
                  >
                    In Progress
                  </a>
                )}
              </p>
            )}
            <p>
              {rowData.due_diligence.report?.length > 0 && (
                <Accordion defaultActiveKey="0">
                  <Accordion.Toggle as={Link} variant="link" eventKey="1">
                    <i className="fas fa-plus"></i> Due Deligence Reports
                  </Accordion.Toggle>
                  {rowData.due_diligence.report.map((item) => {
                    return (
                      <Accordion.Collapse eventKey="1">
                        <a
                          className="btn btn-link p-0"
                          onClick={(e) =>
                            getDueDiligenceReport(
                              rowData.due_diligence.id,
                              item.id
                            )
                          }
                        >
                          <i class="fas fa-download"></i>
                          {` report-${item.id}.${item.file_ext}`}
                        </a>
                      </Accordion.Collapse>
                    );
                  })}
                </Accordion>
              )}
            </p>
          </>
        );
      },
    },
    {
      title: "Status",
      render: (rowData) => {
        return (
          <p>
            {hasPermission("transporters.edit") ? (
              <>
                {rowData.status === "In Active" && (
                  <button
                    type="button"
                    className="btn btn-warning"
                    onClick={(e) => changeStatus(true, rowData.id)}
                  >
                    In Active
                  </button>
                )}
                {rowData.status === "Active" && (
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={(e) => changeStatus(false, rowData.id)}
                  >
                    Active
                  </button>
                )}
              </>
            ) : (
              <>{rowData.status}</>
            )}
          </p>
        );
      },
    }, // {
    //   title: "Action",
    //   render: (rowData) => {
    //     return (
    //       <div className="btn-group btn-group-sm">
    //         <Link
    //           className="btn btn-info"
    //           to={`/manage-transporter/${rowData.id}`}
    //         >
    //           <i className="fas fa-edit" />
    //         </Link>
    //         {hasPermission("transporters.delete") && (
    //           <button
    //             className="btn btn-danger"
    //             onClick={(e) => {
    //               setDeleteTransporterId(rowData.id);
    //               setShowDeleteConfirm(true);
    //             }}
    //           >
    //             <i className="fas fa-trash" />
    //           </button>
    //         )}
    //       </div>
    //     );
    //   },
    // },
  ];

  const handleAssignSpoc = (id) => {
    setId(id);
    setShowAssignSpocModal(true);
  };

  const onSpocClose = () => {
    setShowAssignSpocModal(false);
  };

  const handleDueDeligence = (id, document, dueDeligence) => {
    setId(id);
    setTradeLicenseDoc(document);
    setDueDeligence(dueDeligence);
    setShowDueDeligenceModal(true);
  };

  function onClose() {
    setShowDeleteConfirm(false);
  }

  function onConfirm() {
    setShowDeleteConfirm(false);
    // toast.warn("Not Implemented!");
    deleteTransporterApi(deleteTransporterId).then((response) => {
      toast.success("Transporter Deleted!");
    });
  }

  const onCloseDueDeligence = () => {
    setShowDueDeligenceModal(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getAllTransportersApi().then((response) => {
      setData(response.data);
      setIsLoading(false);
      setIsPageLoading(false);
    });
  }, [
    showDeleteConfirm,
    showAssignSpocModal,
    showDueDeligenceModal,
    activeStatus,
  ]);

  const changeStatus = (isActive, id) => {
    const payload = {
      is_active: isActive,
    };
    updateTransportersApi(id, payload).then((response) => {
      if (isActive) {
        toast.success("User Activated!");
      } else {
        toast.info("User Deactivated!");
      }

      getTransportersDetails(id).then((response) => {
        setData(response.data);
        SetActiveStatus(isActive);
      });
    });
  };
  return (
    <>
      <ContentHeader title="Transporters" />
      {isPageLoading ? (
        <PageLoader />
      ) : (
        <section className="content">
          <div className="container-fluid">
            <div className="card bg-transparent border-0">
              <div className="card-header">
                <h3 className="card-title">Transporter Details</h3>
                <div className="card-tools">
                  {hasPermission("transporters.create") && (
                    <Link
                      type="button"
                      className="btn btn-tool bg-info"
                      data-card-widget="collapse"
                      title="Collapse"
                      to="/new-transporter"
                    >
                      <i className="fas fa-plus" /> New Transporter
                    </Link>
                  )}
                </div>
              </div>
              {/* <div className="card-body"> */}
              <MaterialTable
                style={{ backgroundColor: "#f4f6f9" }}
                columns={columns}
                isLoading={isLoading}
                data={data}
                title=""
                options={{
                  rowStyle:(rowData) => ({
                    borderRadius: '10% !important', // Rounded corners
                    backgroundColor: "#FFF",
                    borderTop: '15px solid #f4f6f9', // Transparent top border
                    borderBottom: '15px solid #f4f6f9' // Transparent bottom border
                  }),
                  exportAllData: true,
                  exportMenu: [
                    {
                      label: "Export CSV",
                      exportFunc: (cols, datas) =>
                        ExportCsv(cols, datas, "trux"),
                    },
                  ],
                }}
              />
              {/* </div>    */}
              {/* <div className="card-footer">Footer</div> */}
            </div>
          </div>
          <DeleteConfirmModal
            show={showDeleteConfirm}
            onClose={onClose}
            onConfirm={onConfirm}
          />
          {showAssignSpocModal && (
            <AssignSpocModal
              onClose={onSpocClose}
              show={showAssignSpocModal}
              id={id}
            />
          )}
          {showDueDeligenceModal && (
            <DuedeligenceModal
              show={showDueDeligenceModal}
              handleClose={onCloseDueDeligence}
              userId={id}
              dueDiligence={dueDeligence}
              existTradeDoc={tradeLicenseDoc}
            />
          )}
        </section>
      )}
    </>
  );
};

export default Transporters;
