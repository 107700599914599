import React, { useEffect, useState } from "react";
import { getDriverName } from  "../../services/data/transporters";
import PageLoader from "../../components/PageLoader";
import { Link } from 'react-router-dom'

const DriverModal = ({show,handleClose,userId}) => {   
    const [isLoading, setIsLoading] = useState(true)
    const [driverName, setDriverName] = useState() 

    console.log('driver Modal',driverName)

    useEffect(()=>{
      getDriverName(userId).then((response) => {               
      setDriverName(response.data);
         setIsLoading(false)      
      });      
    },[])
   
  return (
    <>
    { isLoading ? 
    (
     <PageLoader/>
    ):
    (<div
        className={`modal fade ${show ? "show" : "hide"}`}
        style={{ display: show ? "block" : "none" }}
        id="modal-md"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <form id="driverModal">
              <div className="modal-header">
                <h6 ><b>Driver Details</b></h6>                
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true" onClick={() => handleClose()}>
                    ×
                  </span>
                </button>
              </div>
              <div className="modal-body p-0">
                <div className="row">
                  <div className="col-12">
                  <ul className="list-group">
                    <li className="list-group-item border-0">
                        <div className="row">                        
                            <div className="col-12">
                              <div className="table-responsive">
                                <table className="table border">
                                  <thead>
                                    <tr>
                                      <th>Driver Name</th>
                                      <th>Email Id</th>
                                      <th>Contact No</th>                                    
                                    </tr>
                                  </thead>
                                  <tbody>                                  
                                      {
                                        driverName.length > 0 ? (driverName.map((item, index)=>{
                                        return(
                                          <tr>
                                            <td>
                                              <Link className="btn btn-link p-0" 
                                                key={item.id}
                                                to={`/driver-profile/${item.id}`}                                        
                                              >
                                                <b>{item.name.toUpperCase()}</b>                                          
                                              </Link>
                                            </td>
                                            <td>{item.email ?? '-'}</td>
                                            <td>{item.contact_no ?? '-'}</td>
                                          </tr>
                                        )
                                        })):( 
                                          <tr>
                                            <h6 className="text-center pt-3">No Records</h6>
                                          </tr>                                                                              
                                        )
                                      }                                                                  
                                  </tbody>
                                </table>
                              </div>                                                            
                            </div>
                        </div>
                    </li>
                  </ul>                                                                                                                                                          
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-center">                
              </div>
            </form>
          </div>
          {/* /.modal-content */}
        </div>
        {/* /.modal-dialog */}
      </div>
      )
    }
      
    </>
  )
}

export default DriverModal
