import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import MenuItem from "../../../components/menu-item/MenuItem";
import { hasPermission, hasRole } from "../../../utils/permissions";

export const MENU = [
  {
    name: "Dashboard",
    icon: "fas fa-tachometer-alt nav-icon",
    path: "/",
  },
  {
    name: "Customers",
    icon: "fas fa-users nav-icon",
    path: "/customers",
    action: "customers.view",
    children: [
      {
        name: "New Customers",
        icon: "fas fa-user nav-icon",
        path: "/manage-customer/new",
        action: "customers.create",
      },
      {
        name: "View Customers",
        icon: "fas fa-users nav-icon",
        path: "/customers",
        action: "customers.view",
      },
    ],
  },
  {
    name: "Transporters",
    icon: "fas fa-user-friends nav-icon",
    action: "transporters.view",
    children: [
      {
        name: "New Transporter",
        icon: "fas fa-user nav-icon",
        path: "/new-transporter",
        action: "transporters.create",
      },
      {
        name: "View Transporters",
        icon: "fas fa-user nav-icon",
        path: "/transporters",
      },
    ],
  },
  // {
  //   name: "Trips",
  //   icon: "fas fa-user-friends nav-icon",   
  //   children: [
  //     {
  //       name: "New Trips",
  //       icon: "fas fa-user nav-icon",
  //       path: "/new-trip",
  //       restrict_role: ["driver"],               
  //     },
  //     {
  //       name: "View Trips",
  //       icon: "fas fa-user nav-icon",
  //       path: "/trips",
  //     },
  //   ],
  // },
  {
    name: "Trucks",
    icon: "fas fa-truck nav-icon",
    // path: "/trucks",
    action: "trucks.view",
    children: [
      {
        name: "New Trucks",
        icon: "fas fa-truck nav-icon",
        path: "/new-truck",
        action: "trucks.create",
      },
      {
        name: "View Trucks",
        icon: "fas fa-truck nav-icon",
        path: "/trucks",
        action: "trucks.view",
      },
    ],
  }, 
  {
    name: "Drivers",
    icon: "fas fa-cogs nav-icon",
    action: "drivers.view",
    children: [
      {
        name: "New Driver",
        icon: "fas fa-user nav-icon",
        path: "/new-driver",
        action: "drivers.create",       
      },
      {
        name: "View Drivers",
        icon: "fas fa-users nav-icon",
        path: "/drivers",
        action: "drivers.view",
      },
    ],
  },
  {
    name: "Spoc",
    icon: "fas fa-users nav-icon",
    path: "/spoc",
    action: "spoc.view",
    children: [
      {
        name: "New Spoc",
        icon: "fas fa-user nav-icon",
        path: "/manage-spoc",
        action: "spoc.create",
      },
      {
        name: "View Spoc",
        icon: "fas fa-users nav-icon",
        path: "/spoc",
        action: "spoc.view",
      },
    ],
  },
  {
    name: "Masters",
    icon: "fas fa-file-invoice nav-icon",
    action: "master.view",
    children: [
      {
        name:'Truck Type',
        icon: "fas fa-truck nav-icon",
        path: "/truck-type",
      },
      {
        name:'Cargo Type',
        icon: "fa fa-ship nav-icon",
        path: "/cargo-type",
      },
      {
        name:'Package Type',
        icon: "fas fa-box nav-icon",
        path: "/packagetype",
      },      
    ]       
  },
  {
    name: "Trips",
    icon: "fas fa-user-friends nav-icon",
    path: "/trips",           
  },
  {
    name: "Push Notification",
    icon: "fas fa-bell nav-icon",
    path: "/push-notification",    
  },
  {
    name: "Bookings",
    icon: "fas fa-list nav-icon",
    path: "/bookings",
    action: "bookings.view",
    restrict_role: ["transporter"],
  },
  {
    name: "Invoices",
    icon: "fas fa-file-invoice nav-icon",
    path: "/invoices",
  },
  {
    name: "Commissions",
    icon: "fas fa-receipt nav-icon",
    path: "/commissions",
  },
  {
    name: "Coupon",
    icon: "fas fa-bahai nav-icon",
    path: "/coupon",
  },
  {
    name: "Terms & Conditions",
    icon: "fas fa-file nav-icon",
    path: "/terms-and-conditions",
  },
  {
    name: "Privacy Policy",
    icon: "fas fa-sticky-note nav-icon",
    path: "/privacypolicy",
  },
  {
    name: "FAQ",
    icon: "fas fa-question-circle nav-icon",
    path: "/faq",
  },
  {
    name:'Support',
    icon: "fas fa-comments nav-icon",
    path: "/support",
  },  
  {
    name:'Google Map',
    icon: "fas fa-map nav-icon",
    path: "/google-map",
  },  
];

const MenuSidebar = () => {
  // const authentication = useSelector((state) => state.auth.authentication);
  const authentication = JSON.parse(localStorage.getItem('authentication'));
  const menuItemFlat = useSelector((state) => state.ui.menuItemFlat);
  const menuChildIndent = useSelector((state) => state.ui.menuChildIndent);

  return (
    <aside
      className={`main-sidebar sidebar-light-info elevation-4 sidebar-light-primary`}
    >
      <Link to="/" className="brand-link bg-info">
        <img
          className="brand-image elevation-3"
          src="/img/logo.png"
          alt="AdminLTE Logo"
          // width={33}
          // height={33}
          // rounded
        />
        <span className="brand-text font-weight-light">&nbsp;</span>
      </Link>
      <div className="sidebar">
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
            <img
              className="img-circle elevation-2"
              src="/img/avatar.png"
              alt="User"
              // width={34}
              // height={34}
            />
          </div>
          <div className="info">
            <Link to="/profile" className="d-block">
              {authentication.email}
            </Link>
          </div>
        </div>

        <nav className="mt-2" style={{ overflowY: "hidden" }}>
          <ul
            className={`nav nav-pills nav-sidebar flex-column${
              menuItemFlat ? " nav-flat" : ""
            }${menuChildIndent ? " nav-child-indent" : ""}`}
            role="menu"
          >
            {MENU.map((menuItem) => {
                  
              // Check if the menuItem has a restrict_role property             
              if (menuItem.restrict_role) {
                // Check if the current user's role is in the restrict_role array
                const userRole = JSON.parse(
                  localStorage.getItem("authentication")
                  ).role;
                if (menuItem.restrict_role.includes(userRole)) {
                  return null;
                }
              }

              // Existing permission check
              if (menuItem.action && !hasPermission(menuItem.action)) {
                return null;
              }

              return (
                <MenuItem
                  key={menuItem.name + (menuItem.path || "")}
                  menuItem={menuItem}
                />
              );
            })}
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default MenuSidebar;
