// import ContentHeader from "../../components/content-header/ContentHeader";
// import React, { useState, useRef, useEffect } from "react";
// import { Link, useNavigate, useParams } from "react-router-dom";
// import { hasPermission } from "../../utils/permissions";
// import {
//     addMasterPrivacyPolicyApi,
//     getPrivacyPolicydetails,
//     updatePrivacyPolicydetails,
//   } from "../../services/data/masters/privacypolicy";
// import PageLoader from "../../components/PageLoader";
// import { toast } from "react-toastify";
// import MaterialTable, { Column } from "@material-table/core";


// const ManagePrivacyPolicy = () => {

//   const [userdata, setUserData] = useState({message: "",});
//   const{message} = userdata;
//   const [isPageLoading, setIsPageLoading] = useState(true);
//   const { id } = useParams();
//   const navigate = useNavigate();

//   const handleReset = () => {
//     setUserData({
//       message: "",     
//     });
//   };

//   const dataHandle = (e) => {
//     console.log(e.target.value);
//     setUserData({ ...userdata, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = async (e) => {
//     // e.preventDefault();
//     // console.log(userdata);
//     // setIsLoading(true);
//     if (id) {
//       await updatePrivacyPolicydetails(id, userdata).then((response) => {
//         console.log(userdata)
//         if (response.status >= 200) {
//           toast.success(response.data.message);
//           navigate("/privacypolicy");
//         } else {
//           toast.error(response.data.message);
//           setIsPageLoading(false);
//         }
//       });
//     } else {
//       await addMasterPrivacyPolicyApi(userdata).then((response) => {
//         if (response.status === 200) {
//           toast.success(response.data.message);         
//           navigate("/privacypolicy");          
//         } else {
//           toast.error(response.data.message);
//           setIsPageLoading(false);
//         }
//       });
//     }
//   };

//   useEffect(() => {    
//     if (id) {
//       getPrivacyPolicydetails(id).then((response) => {        
//         setUserData({
//           message: response.data.message          
//         });        
//         setIsPageLoading(false);
//       });
//     } else {
//       handleReset();
//       setIsPageLoading(false);
//     }
//   }, []);
//   return (
//     <div>
//       <ContentHeader title={id ? `Edit Privacy Policy` : `Privacy Policy` } />
//       {isPageLoading ? 
//       (<PageLoader/>
//       ):(
//         <section className="content">
//         <div className="container-fluid">
//           <div className="card">
//             <div className="card-header">
//               <h3 className="card-title float-right">
//                 <Link 
//                   className="btn btn-primary"
//                   to="/privacypolicy"
//                 >
//                   Back
//                 </Link>
//               </h3>
              
//             </div>
//             <div className="card-body">
//               <div className="container-fluid">
//                 <div className="row">
//                   <div className="col-12">
//                     <div className="form-group">
//                       <label htmlFor="Message">Privacy Policy</label>
//                       <textarea
//                         type="text"
//                         name="message"
//                         rows = "5"
//                         cols = "12"
//                         value={message}
//                         placeholder="Privacy Policy"
//                         onChange={dataHandle}
//                         className="form-control"
//                       />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="card-footer text-center">
//               <button
//                 type="reset"
//                 className="btn btn-warning mr-3"
//                 onClick={handleReset}
//               >
//                 Reset
//               </button>
//               {/* {hasPermission("customers.create") && (
//                 <button
//                   type="reset"
//                   className="btn btn-warning mr-3"
//                   // onClick={handleReset}
//                 >
//                   Reset
//                 </button>
//               )} */}
//                <button
//                 type="button"
//                 className="btn btn-info mr-3"
//                 onClick={handleSubmit}
//                >
//                 Submit
//               </button>
//               {/* {hasPermission("customers.edit") &&
//                 hasPermission("customers.create") && (
//                   <button
//                     type="button"
//                     className="btn btn-info mr-3"
//                     // onClick={handleSubmit}
//                   >
//                     Submit
//                   </button>
//                 )} */}
//             </div>
//           </div>
//         </div>
//         </section>
//       )
//        }
      
//     </div>
//   );
// };

// export default ManagePrivacyPolicy;




import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ContentHeader from "../../components/content-header/ContentHeader";
import PageLoader from '../../components/PageLoader';
import {
  addMasterPrivacyPolicyApi,
  getAllPrivacyPolicy,
} from "../../services/data/masters/privacypolicy";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";

const ManagePrivacyPolicy = () => {
  const [userdata, setUserData] = useState({message: "",});
  const {message} = userdata;
  const [isloading,setIsLoading] = useState(false)  
  const navigate = useNavigate();
  console.log(' post t&c data', userdata)
  const handleChange = (newValue) => {
    setUserData({...userdata, message: newValue});
  };
 
  useEffect(()=>{
    getAllPrivacyPolicy().then((res)=>{
      setUserData({ message: res.data.message});
      setIsLoading(false)
      }) 
  },[])
 
  const handleSubmit = async (e) => { 
      if (message){
        await addMasterPrivacyPolicyApi(userdata).then((response) => {       
          if (response.status === 200) {
            toast.success(response.data.message);
            navigate('/privacypolicy')                      
          } else {
            toast.error(response.data.message);
            setIsLoading(false);
          }
      });   
      } 
      else{
        alert("No Data")
      }              
  };

  return (
    <>
    <ContentHeader title={`Privacy Policy` } />
    {
      isloading ? (<PageLoader/>)
      :
      (
        <section className="content">
        <div className='card'>
          <div className='card-body'>
          <div className="float-right">
            <Link
              to="/privacypolicy"
              className="btn btn-primary"
            >
              Back
            </Link>
          </div>
            <ReactQuill
            theme= 'snow'
            value={userdata.message}
            onChange={handleChange}
            modules={{
              toolbar: [
                [{ header: [1, 2, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [
                  { list: 'ordered' },
                  { list: 'bullet' },
                  { indent: '-1' },
                  { indent: '+1' },
                ],
                ['link', 'image'],
                [{ color: ['#FF0000', '#00FF00', '#0000FF', '#FFA500', '#800080', 'black'] }, { background: ['#FFFF00', '#00FFFF', '#FF00FF', '#FFC0CB', '#D3D3D3'] }],
                ['clean'],
              ],
            }}
            formats={[
              'header',
              'bold',
              'italic',
              'underline',
              'strike',
              'blockquote',
              'list',
              'bullet',
              'indent',
              'link',
              'image',
              'color', 
              'background', 
            ]}
          />
          </div>
          <div className='card-footer text-center'>
          <button
            type="button"
            className="btn btn-info mr-3"
            onClick={handleSubmit}
            >
            Submit
          </button>
          </div>       
        </div>
        </section>
      )
    }   
    </>
    
  );
};

export default ManagePrivacyPolicy;
