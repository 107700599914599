import ContentHeader from "../../components/content-header/ContentHeader";
import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { hasPermission } from "../../utils/permissions";
import {
    addMasterPackageTypeApi,
    getPackageTypedetails,
    updatePackageTypedetails,
    getAllCargoType,
    getCargoTypedetails
  } from "../../services/data/masters/packagetype";
import PageLoader from "../../components/PageLoader";
import { toast } from "react-toastify";
import MaterialTable, { Column } from "@material-table/core";
import Select from 'react-select';


const ManagePackageType = () => {

  const [userdata, setUserData] = useState(
    {
        package_type: "",
        nature_of_cargo : "" ,
    });

  const[cargos, setCargos] = useState([]) 
  console.log(cargos) 
  const {
    package_type,
    nature_of_cargo,
   } = userdata;

  const [isPageLoading, setIsPageLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();

  const handleReset = () => {
    setUserData({
        package_type: "",
        nature_of_cargo : ""     
    });
  };

  const dataHandle = (e) => {
    console.log(e.target.value);
    setUserData({ ...userdata, [e.target.name]: e.target.value });
  };

  const handlecargo = (e) => {
    setUserData({ ...userdata, nature_of_cargo: e.value });
  };

 
  const handleSubmit = async (e) => {
    // e.preventDefault();
    // console.log(userdata);
    setIsPageLoading(true);
    if( 
      !package_type||
      !nature_of_cargo
     ){
      toast.error("Please fill in all required fields.");
      setIsPageLoading(false);
      return;
    }
    if (id) {
      await updatePackageTypedetails(id, userdata).then((response) => {
        console.log(userdata)
        if (response.status >= 200) {
          toast.success(response.data.message);
          navigate("/packagetype");
        } else {
          toast.error(response.data.message);
          setIsPageLoading(false);
        }
      });      
    } else {
      await addMasterPackageTypeApi(userdata).then((response) => {
        if (response.status === 200) {
          toast.success(response.data.message);         
          navigate("/packagetype");          
        } else {
          toast.error(response.data.message);
          setIsPageLoading(false);
        }
      });
    }
  };

  useEffect(() => { 
    getAllCargoType().then((response)=>{
     let options = []
     response.data.map((item)=>
       options.push({value: item.id, label:item.name})
     )
     setCargos(options)
    })

    if (id) {
        getPackageTypedetails(id).then((response) => {                      
        setUserData({
          package_type: response.data.name,
          nature_of_cargo: response.data.nature_of_cargo.id,          
        });

        setIsPageLoading(false);
      });
    } else {
      handleReset();
      setIsPageLoading(false);
    }
  }, []);
  return (
    <div>
      <ContentHeader title={id ? `Edit Package Type` : `Package Type` } />
      {isPageLoading ? 
      (<PageLoader/>
      ):(
        <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title float-right">
                <Link 
                  className="btn btn-primary"
                  to="/packagetype"
                >
                  Back
                </Link>
              </h3>
              
            </div>
            <div className="card-body">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="Message">Package Name</label>
                      <input
                        type="text"
                        name="package_type"                       
                        value={package_type}
                        placeholder="Package Name"
                        onChange={dataHandle}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="natureofcargo">Nature of Cargo</label>
                      <Select
                        id="natureofcargo"                       
                        options={cargos}
                        name="nature_of_cargo"                                             
                        onChange={handlecargo}
                        defaultValue={                          
                          cargos[
                            cargos.findIndex(
                              (obj) => obj.value === nature_of_cargo
                            )
                          ] 
                        }                                                                                           
                      />                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer text-center">
              <button
                type="reset"
                className="btn btn-warning mr-3"
                onClick={handleReset}
              >
                Reset
              </button>
              {/* {hasPermission("customers.create") && (
                <button
                  type="reset"
                  className="btn btn-warning mr-3"
                  // onClick={handleReset}
                >
                  Reset
                </button>
              )} */}
               <button
                type="button"
                className="btn btn-info mr-3"
                onClick={handleSubmit}
               >
                Submit
              </button>
              {/* {hasPermission("customers.edit") &&
                hasPermission("customers.create") && (
                  <button
                    type="button"
                    className="btn btn-info mr-3"
                    // onClick={handleSubmit}
                  >
                    Submit
                  </button>
                )} */}
            </div>
          </div>
        </div>
        </section>
      )
       }
      
    </div>
  );
};

export default ManagePackageType;
