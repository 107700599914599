import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { getAllTrucksApi } from "../../services/data/trucks";
import { Formik, Form, Field, ErrorMessage } from "formik";
import PageLoader from "../../components/PageLoader";
import { assignTripApi } from "../../services/data/trip";
import { toast } from "react-toastify";

const AssignTruckModal = ({ show, handleClose, selectedTrip }) => {
  const [trucks, setTrucks] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getAllTrucksApi()       
      .then(response => {             
        setTrucks(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error("Error fetching trucks:", error);
        setLoading(false);
      });
  }, []);

  const handleSaveChanges = async (values, { setSubmitting }) => { 
    console.log(values)           
    await assignTripApi(selectedTrip.id,values).then((response)=>{
      if (response.status === 201 || response.data.status === 'success'){
          toast.success(response.data.message)
          handleClose()
      }else{
        toast.error(response.data.message)
        setSubmitting(false)
      }
    })
    .catch((err)=>{
      toast.error(err.response.data.message)
      setSubmitting(false)
    }) 
  };

  return (
    <Modal show={show} onHide={handleClose}>
        { loading ? <PageLoader loading="Loading..."/>:(
            <>
                <Modal.Header closeButton>
                    <Modal.Title>Assign Truck</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Assign a truck for trip: {selectedTrip && selectedTrip.booking.booking_no}</p>
                    <Formik
                    initialValues={{ truck_id: "" }}
                    validate={values => {
                        const errors = {};
                        if (!values.truck_id) {
                        errors.truck_id = "Required";
                        }
                        return errors;
                    }}
                    onSubmit={handleSaveChanges}
                    >
                    {({ isSubmitting }) => (
                        <Form>
                        <div className="form-group">
                            <label htmlFor="truck">Select Truck</label>
                            <Field as="select" className="form-control" id="truck_id" name="truck_id">
                            <option value="">Select a truck</option>
                            {trucks.map(truck => (
                                <option key={truck.id} value={truck.id}>{truck.truck_no}</option>
                            ))}
                            </Field>
                            <ErrorMessage name="truck_id" component="div" className="text-danger" />
                        </div>
                        <div className="d-flex justify-content-end" >
                        <Button variant="secondary" className="mr-2" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                            Save changes
                        </Button>
                        </div>
                        </Form>
                    )}
                    </Formik>
                </Modal.Body>
            </>
        )}
    </Modal>
  );
};

export default AssignTruckModal;