import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ContentHeader from "../../components/content-header/ProfileContentHeader";
import MaterialTable, { Column } from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { getTruckApi, 
         updateTrucksApi,
         getTrucksInsurance,
         getTrucksRegistrationCard, 
         getTrucksCargoInsurance,
       } from "../../services/data/trucks";
import PageLoader from "../../components/PageLoader";
import { toast } from "react-toastify";
import Accordion from "react-bootstrap/Accordion";
import {Link} from "react-router-dom"

const TruckProfile = () => {
  const { id } = useParams();
  const [truckInfo, setTruckInfo] = useState([]);
  const [complianceData, setcomplianceData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  // JSON.parse(stringArray.replace(/\\/g, ''));
  // replace(/['/"/\/[\]]/g, '').split(',')
 console.log('truckprofile', truckInfo)
  useEffect(() => {
    getTruckApi(id).then((response) => {
      setTruckInfo(response.data);        
      setIsLoading(false)
    });
  }, []);

  const data = [];

  const columns = [
    {
      title: "Booking No",
      field: "booking_no",
    },
    {
      title: "Origin",
      field: "origin",
    },
    {
      title: "Destination",
      field: "destination",
    },
    {
      title: "Booked On",
      field: "booked_on",
    },
    {
      title: "ETA",
      field: "eta",
    },
    {
      title: "Status",
      field: "status",
    },
  ];

  const changeStatus = (isActive, id) => {
    const payload = {
      is_active: isActive,
    };
    updateTrucksApi(id, payload).then((response) => {
      if(isActive){
        toast.success("User Activated!");
      }else{
        toast.info("User Deactivated!");
      }      
      getTruckApi(id).then((response) => {
        setTruckInfo(response.data);
      });
    });
  };

  return (
    <>
      {
       isLoading ? (<PageLoader />)
       :(
          <div>
          <ContentHeader title="Trucks" />
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-3">
                  <div className="card card-primary">
                    <div className="card-body box-profile">
                      <div className="text-center">
                        <img
                          className="img-fluid"
                          src="/img/truck.png"
                          alt="Truck picture"
                        />
                      </div>
                      <h3 className="profile-username text-center">
                        {truckInfo.status === "In Active" && (
                          <button
                            type="button"
                            className="btn btn-warning"
                            onClick={(e) => changeStatus(true, id)}
                          >
                            In Active
                          </button>
                        )}
                        {truckInfo.status === "Active" && (
                          <button
                            type="button"
                            className="btn btn-success"
                            onClick={(e) => changeStatus(false, id)}
                          >
                            Active
                          </button>
                        )}
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <ul className="list-group">
                            <li className="list-group-item border-0">
                              <div className="row">
                                <div className="col-md-6 col-6">
                                  <b>Truck Type</b>
                                </div>
                                <div className="col-md-6 col-6">
                                  <h6>{ truckInfo.truck_type.truck_type ?  truckInfo.truck_type.truck_type : '-' }</h6>
                                </div>
                              </div>
                            </li>

                            <li className="list-group-item border-0">
                              <div className="row">
                                <div className="col-md-6 col-6">
                                  <b>Registration Card</b>
                                </div>
                                <div className="col-md-6 col-6">
                                  <h6>
                                    <Accordion defaultActiveKey="0">
                                      <Accordion.Toggle as={Link} variant="link" eventKey="1">
                                        {/* <i className="fas fa-plus"></i> */}
                                        <a 
                                          className="btn btn-link p-0"
                                          style={{ textDecoration: "underline" }}
                                          >
                                          View documents
                                        </a>
                                      </Accordion.Toggle>
                                      {truckInfo.reg_card &&
                                      truckInfo.reg_card.map((item)=>{
                                        return(
                                          <Accordion.Collapse eventKey="1">
                                            <a
                                              className="btn btn-link p-0"
                                              onClick={(e) =>
                                                getTrucksRegistrationCard(id, item.id, item.file_ext)
                                              }
                                            >
                                              <i className="far fa-fw fa-file-pdf" />
                                              {`Registration card-${item.id}.${item.file_ext}`}
                                            </a>
                                          </Accordion.Collapse>
                                        )
                                      }) 
                                      }
                                    </Accordion>
                                  </h6>
                                </div>
                              </div>
                            </li>
                            <li className="list-group-item border-0">
                              <div className="row">
                                <div className="col-md-6 col-6">
                                  <b>Insurance</b>
                                </div>
                                <div className="col-md-6 col-6">
                                  {truckInfo.insurance ? (
                                    <h6>
                                      <Accordion defaultActiveKey="0">
                                        <Accordion.Toggle as={Link} variant="link" eventKey="1">
                                          <a className="btn btn-link p-0" style={{ textDecoration: "underline" }}>
                                            View documents
                                          </a>
                                        </Accordion.Toggle>
                                        {truckInfo.insurance &&
                                          truckInfo.insurance.map((item) => {
                                            return (
                                              <Accordion.Collapse eventKey="1">
                                                <a
                                                  className="btn btn-link p-0"
                                                  onClick={(e) => getTrucksInsurance(id, item.id, item.file_ext)}
                                                >
                                                  <i className="far fa-fw fa-file-pdf" />
                                                  {`Registration card-${item.id}.${item.file_ext}`}
                                                </a>
                                              </Accordion.Collapse>
                                            );
                                          })}
                                      </Accordion>
                                    </h6>
                                  ) : (
                                    '-'
                                  )}
                                </div>
                              </div>
                            </li>
                            <li className="list-group-item border-0">
                              <div className="row">
                                <div className="col-md-6 col-6">
                                  <b>Cargo Insurance</b>
                                </div>
                                <div className="col-md-6 col-6">
                                  <h6>
                                  <Accordion defaultActiveKey="0">
                                    <Accordion.Toggle as={Link} variant="link" eventKey="1">
                                      {/* <i className="fas fa-plus"></i> */}
                                      <a 
                                        className="btn btn-link p-0"
                                        style={{ textDecoration: "underline" }}
                                        >
                                        View documents
                                      </a>
                                    </Accordion.Toggle>
                                    {truckInfo.cargo_insurance &&
                                    truckInfo.cargo_insurance.map((item)=>{
                                    return(
                                      <Accordion.Collapse eventKey="1">
                                        <a
                                        className="btn btn-link p-0"
                                        onClick={(e) =>
                                          getTrucksCargoInsurance(id, item.id, item.file_ext)
                                        }
                                        >
                                        <i className="far fa-fw fa-file-pdf" />
                                        {`Registration card-${item.id}.${item.file_ext}`}
                                       </a>
                                      </Accordion.Collapse>
                                    )
                                   })
                                   }
                                   </Accordion>
                                  </h6>
                                </div>
                              </div>
                            </li>
                            <li className="list-group-item border-0">
                              <div className="row">
                                <div className="col-md-6 col-6">
                                  <b>Compliances</b>
                                </div>
                                <div className="col-md-6 col-6">
                                 {truckInfo.compilances.length ? 
                                  (<h6>{
                                      (truckInfo.compilances) 
                                      ? 
                                      (truckInfo.compilances.map((data,i)=>{
                                        return(
                                            <div className="custom-control custom-checkbox">
                                            <input
                                              className="custom-control-input"
                                              type="checkbox"
                                              checked
                                              id={i}
                                              defaultValue={data}                                         
                                            />
                                            <label
                                              htmlFor={i}
                                              className="custom-control-label"
                                            > 
                                              {data}                                           
                                            </label>
                                        </div> 
                                        )})                                     
                                      ): '-'}</h6>)
                                  :(<h6>-</h6>)
                                  }
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <div className="col-md-6">
                          <ul className="list-group">
                            <li className="list-group-item border-0">
                              <div className="row">
                                <div className="col-md-6 col-6">
                                  <b>Transporter</b>
                                </div>
                                <div className="col-md-6 col-6">
                                  <h6>{truckInfo.transporter.company_name &&
                                      truckInfo.transporter.company_name}</h6>
                                </div>
                              </div>
                            </li>
                            <li className="list-group-item border-0">
                              <div className="row">
                                <div className="col-md-6 col-6">
                                  <b>Truck No</b>
                                </div>
                                <div className="col-md-6 col-6">
                                  <h6>
                                    <h6>{truckInfo.truck_no ?? "-"}</h6>
                                  </h6>
                                </div>
                              </div>
                            </li>

                            <li className="list-group-item border-0">
                              <div className="row">
                                <div className="col-md-6 col-6">
                                  <b>Registration Validity</b>
                                </div>
                                <div className="col-md-6 col-6">
                                  <h6>
                                    {truckInfo.reg_expire_date
                                      ? new Date(
                                          truckInfo.reg_expire_date
                                        ).toLocaleDateString("en-GB", 
                                        { day: "2-digit", month: "2-digit", year: "numeric" })    
                                      : "-"}
                                  </h6>
                                </div>
                              </div>
                            </li>
                            <li className="list-group-item border-0">
                              <div className="row">
                                <div className="col-md-6 col-6">
                                  <b>Insurance Validity</b>
                                </div>
                                <div className="col-md-6 col-6">
                                  <h6>
                                    {truckInfo.insurance_validity
                                      ? new Date(
                                          truckInfo.insurance_validity
                                        ).toLocaleDateString("en-GB", 
                                        { day: "2-digit", month: "2-digit", year: "numeric" })    
                                      : "-"}
                                  </h6>
                                </div>
                              </div>
                            </li>
                            <li className="list-group-item border-0">
                              <div className="row">
                                <div className="col-md-6 col-6">
                                  <b>Enrolled On</b>
                                </div>
                                <div className="col-md-6 col-6">
                                  <h6>
                                    {
                                     truckInfo.enrolled_on ? 
                                     new Date(
                                       truckInfo.enrolled_on
                                      ).toLocaleDateString("en-GB", {
                                      day: "2-digit",
                                      month: "2-digit",
                                      year: "numeric",
                                    })
                                     : '-'
                                    }
                                   </h6>
                                </div>
                              </div>
                            </li>
                            <li className="list-group-item border-0">
                              <div className="row">
                                <div className="col-md-6 col-6">
                                  <b>Total Trips</b>
                                </div>
                                <div className="col-md-6 col-6">
                                  <h6>{truckInfo.trips ?? 0}</h6>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <h5 style={{ textDecoration: "underLine" }}>
                    Total Trips Made
                  </h5>
                  <div className="card">
                    <MaterialTable
                      style={{ backgroundColor: "white" }}
                      columns={columns}
                      data={data}
                      title=""
                      options={{
                        exportAllData: true,
                        exportMenu: [
                          {
                            label: "Export CSV",
                            exportFunc: (cols, datas) =>
                              ExportCsv(cols, datas, "trux"),
                          },
                        ],
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          </div>
       )
      }
    
    </>
  );
};

export default TruckProfile;
