import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { toggleSidebarMenu } from "../../../store/reducers/ui";
// import UserDropdown from "./user-dropdown/UserDropdown";
import { Dropdown, NavDropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { getAuth } from "../../../utils/auth";
import { IoSettings } from "react-icons/io5";
import { BiLogoMastercard } from "react-icons/bi";
import { hasPermission } from "../../../utils/permissions";
const UserDropDown = ({ name }) => {
  return (
    <>
      {/* <i className="fa fa-search mr-3" style={{ fontSize: "20px" }}></i> */}
      {/* <i className="fa fa-bell mr-3" style={{ fontSize: "20px" }}></i> */}
      {/* <i className="fas fa-user mr-3" style={{ fontSize: "20px" }} /> */}
      <b className="text-dark">Welcome {name} </b>
    </>
  );
};

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = getAuth();

  const handleToggleMenuSidebar = () => {
    dispatch(toggleSidebarMenu());
  };

  const logOut = async (event) => {
    event.preventDefault();
    console.log("Logging out");

    localStorage.removeItem("token");
    localStorage.removeItem("authentication");
    navigate("/login");
  };

  const getContainerClasses = useCallback(() => {
    let classes = `main-header navbar navbar-expand navbar-light`;
    return classes;
  }, []);

  return (
    <nav style={{ backgroundColor: "#f4f6f9" }} className={getContainerClasses()}>
      <ul className="navbar-nav">
        <li className="nav-item">
          <button
            onClick={handleToggleMenuSidebar}
            type="button"
            className="nav-link"
          >
            <i className="fas fa-bars" />
          </button>
        </li>
      </ul>
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <i className="fa fa-search mt-2 mr-3" style={{ fontSize: "20px" }}></i>
        </li>
        <li className="nav-item">
          <Link to="/bell-notification">
          <i className="fa fa-bell mt-2 mr-3" style={{ fontSize: "20px" }}></i>
          </Link>
        </li>
        <li className="nav-item">
          <NavDropdown
            title={<UserDropDown name={auth.name} />}
            id="basic-nav-dropdown"
            expand="lg"
          >
            <span className="dropdown-item dropdown-header">{auth.email}</span>
            <NavDropdown.Divider />
            <Link to="/profile" className="dropdown-item">
              <i className="far fa-address-card mr-2" /> Profile
            </Link>
            {hasPermission("master.edit") && (
              <Link to="/general-settings" className="dropdown-item">
                <IoSettings /> General Settings
              </Link>
            )}
            {hasPermission("users.edit") && (
              <>
                <Link to="/users" className="dropdown-item">
                  <i class="fas fa-user-cog"></i> User Management
                </Link>
                <Link to="/user-roles" className="dropdown-item">
                  <i class="fas fa-user-cog"></i> User Role Management
                </Link>
              </>
            )}
            {hasPermission("master.edit") && (
              <>
                <Link to="/country-master" className="dropdown-item">
                  <BiLogoMastercard /> Country Master
                </Link>
                <Link to="/document-master" className="dropdown-item">
                  <BiLogoMastercard /> Document Master
                </Link>
              </>
            )}
            <NavDropdown.Divider />
            <button className="dropdown-item" onClick={(e) => logOut(e)}>
              <i className="fas fa-sign-out-alt mr-2" /> Logout
            </button>
          </NavDropdown>
        </li>

        {/* <UserDropdown /> */}
      </ul>
    </nav>
  );
};

export default Header;
