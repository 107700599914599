import React, { useEffect, useState } from "react";
import ContentHeader from "../components/content-header/ContentHeader";
import PageLoader from "../components/PageLoader";
import {
  addBranches,
  addGeneralSetting,
  getGeneralSettingApi,
  getGeneralSettingDoc,
} from "../services/data/masters";
import { toast } from "react-toastify";

const Generalsettings = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataReload, setdataReload] = useState(true);
  const [nextId, setNextId] = useState(0);
  const [branchData, setBranchData] = useState([]);

  const addField = () => {
    setBranchData([
      ...branchData,
      {
        aliasId: nextId + 1,
        city: "",
        address: "",
        contact_person: "",
        contact_no: "",
        designation: "",
        email_id: "",
        is_head_quqrters: false,
      },
    ]);
    setNextId(nextId + 1);
  };

  const deleteField = (id) => {
    let newBranchData = branchData.filter((branch) => branch.aliasId !== id);
    setBranchData(newBranchData);
  };

  const handleInputChange = (e, id) => {
    const { name, value } = e.target;
    setBranchData((prevData) =>
      prevData.map((branch) =>
        branch.aliasId === id ? { ...branch, [name]: value } : branch
      )
    );
  };

  const handleCheckboxChange = (e, id) => {
    const { checked } = e.target;
    setBranchData((prevData) =>
      prevData.map((branch) =>
        branch.aliasId === id
          ? { ...branch, is_head_quqrters: checked }
          : { ...branch, is_head_quqrters: false }
      )
    );
  };

  const [userdata, setUserData] = useState({
    company_name: "",
    company_logo: "",
    website: "",
    vat: 0,
    commission: 7,
    city: "",
    address: "",
    contact_person: "",
    contact_no: "",
    designation: "",
    email_id: "",
    name: "",
    benefcary_name: "",
    account_no: "",
    branch: "",
    swift_code: "",
    bank: {},
  });  

  useEffect(() => {
    if (dataReload) {
      setIsLoading(true);
      let nextId = 0;
      let tbran = [];
      getGeneralSettingApi().then((res) => {        
        setUserData(res.data);
        console.log('Get',res.data);
        if (res.data.branches) {
          res.data.branches.map((branch) => {
            tbran.push({
              aliasId: nextId,
              city: branch.city,
              address: branch.address,
              contact_person: branch.contact_person,
              contact_no: branch.contact_no,
              designation: branch.designation,
              email_id: branch.email_id,
              is_head_quqrters: branch.is_head_quqrters,
            });
            nextId = nextId + 1;
          });
          setNextId(nextId);
          console.log(tbran);
          setBranchData(tbran);
          setIsLoading(false);
        }
      });
      setdataReload(false);
    }
  }, [dataReload]);

  console.log(userdata);
  const {
    company_name,
    company_logo,
    website,
    vat,
    commission,
    city,
    address,
    contact_person,
    contact_no,
    designation,
    email_id,
    bank,
  } = userdata;

  const dataHandle = (e) => {
    console.log(e);
    setUserData({ ...userdata, [e.target.name]: e.target.value });
  };

  const handleReset = () => {
    setUserData({
      company_name: "",
      company_logo: "",
      website: "",
      vat: 0,
      commission: 7,
      city: "",
      address: "",
      contact_person: "",
      contact_no: "",
      designation: "",
      email_id: "",
      bank: {},
    });
  };

  const fileHandle = (e) => {
    console.log(e.target.files[0])
    // const files = Array.from(e.target.files);
    setUserData({
      ...userdata,
      // [e.target.name]: [...files],
      [e.target.name]: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('post-1',userdata);
    console.log('post-2',branchData);

    addGeneralSetting(userdata).then((res) => {
      addBranches(branchData).then((res) => {
        setdataReload(true);
        toast.success("Added!");
      });
    });
   
  };

  return (
    <>
      <div>
        <ContentHeader title="General Settings" />
        {isLoading ? (
          <div>
            <PageLoader />
            <p>
              <b>Loading...</b>
            </p>
          </div>
        ) : (
          <section className="content">
            <div className="container-fluid">
              <div className="card">
                {/* <div className="card-header">
                    <h3 className="card-title">Title</h3>                
                  </div> */}
                <div className="card-body">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="companyName">Company Name</label>
                          <input
                            type="text"
                            name="company_name"
                            value={company_name}
                            placeholder="Company Name"
                            onChange={dataHandle}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="exampleInputFile">Company Logo</label>                                                    
                          { 
                            company_name &&                           
                            <a
                             className="btn btn-link p-0" 
                             onClick={(e)=>getGeneralSettingDoc()}
                            >
                              Company Logo
                            </a>
                          }
                          <div className="input-group">
                            <div className="custom-file">
                              <input
                                type="file"
                                className="custom-file-input"
                                id="exampleInputFile"
                                name="company_logo"
                                accept="image/*,.pdf"
                                // multiple
                                onChange={fileHandle}
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="exampleInputFile"
                              >
                                {company_logo && company_logo.name}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="website">Website</label>
                          <input
                            type="text"
                            name="website"
                            value={website}
                            placeholder="Website"
                            onChange={dataHandle}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="website">VAT</label>
                          <input
                            type="text"
                            name="vat"
                            defaultValue={vat}
                            placeholder="vat"
                            onChange={dataHandle}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12"></div>
                      <div className="col-md-4 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="commission">Commission</label>
                          <div className="input-group mb-3">
                            <input
                              type="text"
                              name="commission"
                              value={commission}
                              placeholder="commission"
                              onChange={dataHandle}
                              className="form-control"
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">%</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12  pt-3 pb-3">
                        <h6>
                          <b style={{ textDecoration: "underline" }}>
                            Branches
                          </b>
                        </h6>
                      </div>

                      {branchData.map((branch) => {
                        return (
                          <div className="row" key={branch.aliasId}>
                            <div className="col-md-6 col-sm-12">
                              <div className="form-group">
                                <label htmlFor="companyName">City</label>
                                <input
                                  type="text"
                                  name="city"
                                  value={branch.city}
                                  placeholder="City"
                                  onChange={(e) =>
                                    handleInputChange(e, branch.aliasId)
                                  }
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                              <div className="form-group">
                                <label htmlFor="companyName">
                                  Branch Address
                                </label>
                                <input
                                  type="text"
                                  name="address"
                                  value={branch.address}
                                  placeholder="Branch Address"
                                  onChange={(e) =>
                                    handleInputChange(e, branch.aliasId)
                                  }
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                              <div className="form-group">
                                <label htmlFor="companyName">
                                  Contact Person
                                </label>
                                <input
                                  type="text"
                                  name="contact_person"
                                  value={branch.contact_person}
                                  placeholder="Contact Person"
                                  onChange={(e) =>
                                    handleInputChange(e, branch.aliasId)
                                  }
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                              <div className="form-group">
                                <label htmlFor="companyName">Contact No</label>
                                <input
                                  type="text"
                                  name="contact_no"
                                  value={branch.contact_no}
                                  placeholder="Contact No"
                                  onChange={(e) =>
                                    handleInputChange(e, branch.aliasId)
                                  }
                                  className="form-control"
                                />
                                <b className="text-danger">
                                  {branch.contact_no.length > 10
                                    ? "Mobile number too long. Maximum 10 digits allowed."
                                    : ""}
                                </b>
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                              <div className="form-group">
                                <label htmlFor="companyName">
                                  Designation (Optional)
                                </label>
                                <input
                                  type="text"
                                  name="designation"
                                  value={branch.designation}
                                  placeholder="Designation (Optional)"
                                  onChange={(e) =>
                                    handleInputChange(e, branch.aliasId)
                                  }
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                              <div className="form-group">
                                <label htmlFor="companyName">
                                  Official Email Address
                                </label>
                                <input
                                  type="email"
                                  name="email_id"
                                  value={branch.email_id}
                                  placeholder="Official Email Address"
                                  onChange={(e) =>
                                    handleInputChange(e, branch.aliasId)
                                  }
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-12 mb-5">
                              <div className="custom-control custom-checkbox">
                                <input
                                  className="custom-control-input"
                                  type="checkbox"
                                  id={`regular-${branch.aliasId}`}
                                  checked={branch.is_head_quqrters}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, branch.aliasId)
                                  }
                                />
                                <label
                                  htmlFor={`regular-${branch.aliasId}`}
                                  className="custom-control-label"
                                >
                                  Mark As HeadQuarters
                                </label>
                              </div>
                            </div>
                            {!branch.is_head_quqrters && (
                              <div className="col-md-6 col-sm-12 mb-5">
                                <div className="float-right">
                                  <button
                                    className="btn btn-danger"
                                    onClick={(e) => deleteField(branch.aliasId)}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}

                      <div className="col-12">
                        <div className="float-right">
                          <button className="btn btn-info" onClick={addField}>
                            Add More Branches
                          </button>
                        </div>
                      </div>
                      <div className="col-12 pt-3 pb-3">
                        <h6>
                          <b style={{ textDecoration: "underline" }}>
                            Bank Details
                          </b>
                        </h6>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="companyName">Bank Name</label>
                          <input
                            type="text"
                            name="name"
                            defaultValue={bank.name}
                            placeholder="Bank Name"
                            onChange={dataHandle}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="companyName">Beneficary Name</label>
                          <input
                            type="text"
                            name="benefcary_name"
                            defaultValue={bank.benefcary_name}
                            placeholder="Beneficary Name"
                            onChange={dataHandle}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="companyName">Account No</label>
                          <input
                            type="number"
                            name="account_no"
                            defaultValue={bank.account_no}
                            placeholder="Account No"
                            onChange={dataHandle}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="companyName">Bank Branch</label>
                          <input
                            type="text"
                            name="branch"
                            defaultValue={bank.branch}
                            placeholder="Bank Branch"
                            onChange={dataHandle}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="companyName">Swift Code</label>
                          <input
                            type="text"
                            name="swift_code"
                            defaultValue={bank.swift_code}
                            placeholder="Swift Code"
                            onChange={dataHandle}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer text-center">
                  <button
                    type="reset"
                    className="btn btn-warning mr-3"
                    onClick={handleReset}
                  >
                    Reset
                  </button>
                  <button
                    type="button"
                    className="btn btn-info mr-3"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    </>
  );
};

export default Generalsettings;
