import ContentHeader from "../../components/content-header/ContentHeader";
// import '../styles/Users.css';
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable, { Column } from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { deleteUserApi, getAllUserManagementApi } from "../../services/data/usermanagement";
import { hasPermission } from "../../utils/permissions";
import { toast } from "react-toastify";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";

const Users = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [delteUserId, setDelteUserId] = useState();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const columns = [
    // { title: "id", field: "id" },
    { title: "First Name", field: "firstname" },
    { title: "Last Name", field: "lastname" },
    { title: "Designation", field: "designation" },
    { title: "Gender", field: "gender" },
    { title: "Email Id", field: "email_id" },
    { title: "Contact No", field: "contact_no" },
    { title: "User Role", field: "user_role" },
    { title: "Joining Date", field: "joining_date" },
    { title: "Address", field: "address" },
    { title: "Status", field: "status" },
    {
      title: "Action",
      render: (rowData) => {
        return (
          <div className="btn-group btn-group-sm">
            <Link className="btn btn-info" to={`/edit-users/${rowData.id}`}>
              <i className="fas fa-edit" />
            </Link>
            {hasPermission("users.delete") && (
              <button
                className="btn btn-danger"
                onClick={(e) => {
                  setDelteUserId(rowData.id);
                  setShowDeleteConfirm(true);
                }}
              >
                <i className="fas fa-trash" />
              </button>
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getAllUserManagementApi().then((response) => {
      setData(response.data);
      setIsLoading(false);
    });
  }, [showDeleteConfirm]);

  function onClose() {
    setShowDeleteConfirm(false);
  }

  function onConfirm() {
    setShowDeleteConfirm(false);
    deleteUserApi(delteUserId).then((response) => {
      toast.success("User Deleted");
    });
  }

  return (
    <>
      <div>
        <ContentHeader title="User Management" />
        {isPageLoading ? (
          <section className="content preloader justify-content-center align-items-center">
            <Spinner animation="grow" className="bg-info" />
            <Spinner animation="grow" className="bg-primary" />
            <Spinner animation="grow" className="bg-success" />
          </section>
        ) : (
          <section className="content">
            <div className="container-fluid">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">User Management Details</h3>
                  <div className="text-right">
                    <Link to="/users-roles" className="btn btn-info">
                      <i className="fas fa-plus" /> Add New User
                    </Link>
                  </div>
                </div>
                {/* <div className="card-body"> */}
                <MaterialTable
                  style={{ backgroundColor: "white" }}
                  columns={columns}
                  isLoading={isLoading}
                  data={data}
                  title=""
                  options={{
                    exportAllData: true,
                    exportMenu: [
                      {
                        label: "Export CSV",
                        exportFunc: (cols, datas) =>
                          ExportCsv(cols, datas, "trux"),
                      },
                    ],
                  }}
                />
                {/* </div>
                <div className="card-footer">Footer</div> */}
              </div>
            </div>
            <DeleteConfirmModal
              show={showDeleteConfirm}
              onClose={onClose}
              onConfirm={onConfirm}
            />
          </section>
        )}
      </div>
    </>
  );
};

export default Users;
