import ContentHeader from "../../components/content-header/ContentHeader";
// import "../styles/UserRoleManagement.css";
import React, { useState, useEffect, useRef } from "react";
import { Link, Navigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable, { Column } from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { getAllUserRoleManagementApi } from "../../services/data/userrolemanagement";

const UserRoles = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [edit, setEdit] = useState(false);

  const [roleId, setRoleId] = useState();
  const [rolePermissions, setRolePermissions] = useState([]);
  const [roleManagement, setRoleManagement] = useState({});

  const handleEdit = (id, name, permissions) => {
    setRoleManagement({
      id: id,
      name: name,
      permissions: permissions,
    });
    // setRoleId(roleId)
    // setRolePermissions(permissions)
    setEdit(true);
  };

  const columns = [
    // { title: "id", field: "id" },
    { title: "Name", field: "name" },
    // {
    //   title: "Permissions",
    //   field: "permissions",
    // },
    { title: "Status", field: "status" },
    {
      title: "Edit",
      render: (rowData) => {
        return (
          <div className="btn-group btn-group-sm">
            <button
              className="btn btn-info"
              onClick={(e) =>
                handleEdit(rowData.id, rowData.name, rowData.permissions)
              }
            >
              <i className="fas fa-edit" />
            </button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getAllUserRoleManagementApi().then((response) => {
      setData(response.data);
      setIsLoading(false);
    });
  }, []);

  return (
    <>
      <div>
        <ContentHeader title="User Role Management" />
        {isPageLoading ? (
          <section className="content preloader justify-content-center align-items-center">
            <Spinner animation="grow" className="bg-info" />
            <Spinner animation="grow" className="bg-primary" />
            <Spinner animation="grow" className="bg-success" />
          </section>
        ) : (
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">
                        User Role Management Details
                      </h3>
                      <div className="float-right">
                        <Link
                          to="/user-role-management"
                          className="btn btn-info"
                        >
                          <i className="fas fa-plus" /> New Role
                        </Link>
                      </div>
                    </div>
                    {/* <div className="card-body"> */}
                    <MaterialTable
                      style={{
                        backgroundColor: "white",
                        paddingLeft: "25px",
                        paddingRight: "25px",
                      }}
                      columns={columns}
                      isLoading={isLoading}
                      data={data}
                      title=""
                      options={{
                        exportAllData: true,
                        exportMenu: [
                          {
                            label: "Export CSV",
                            exportFunc: (cols, datas) =>
                              ExportCsv(cols, datas, "trux"),
                          },
                        ],
                      }}
                    />
                    {/* </div> */}
                    {/* <div className="card-footer">Footer</div> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
      {edit && (
        <Navigate
          to="/user-role-management"
          state={{ roleManagement }}
          // replace={true}
        />
      )}
    </>
  );
};

export default UserRoles;
