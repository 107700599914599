import { useState, useEffect, useCallback } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebarMenu } from "../../store/reducers/ui";
import { addWindowClass, removeWindowClass } from "../../utils/helpers";
import Header from "./header/Header";
import MenuSidebar from "./menu-sidebar/MenuSidebar";
import Footer from "./footer/Footer";
import { toast } from "react-toastify";

import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "../../firebase/firebaseConfig";

const Message = ({ notification }) => {
  return (
    <>
      <div id="notificationHeader">
        {/* image is optional */}
        {notification.image && (
          <div id="imageContainer">
            <img src={notification.image} width={100} />
          </div>
        )}
        <span>{notification.title}</span>
      </div>
      <div id="notificationBody">{notification.body}</div>
    </>
  );
};


const Main = () => {
  const dispatch = useDispatch();
  const menuSidebarCollapsed = useSelector(
    (state) => state.ui.menuSidebarCollapsed
  );
  const screenSize = useSelector((state) => state.ui.screenSize);
  // const authentication = useSelector((state) => state.auth.authentication);
  const authentication = localStorage.getItem("token");
  const [isAppLoaded, setIsAppLoaded] = useState(true);

  const handleToggleMenuSidebar = () => {
    dispatch(toggleSidebarMenu());
  };

  async function requestPermission() {
    //requesting permission using Notification API
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey:
          "BB1ZJhxnlPuGl9i_s9A2MsZRKwN4Im8c5JHlP48ivpz8svvH3td8DoVy6pzRFZ9sz55lJ7wQhiYYKu4yqOOIT5E",
      });

      //We can send token to server
      console.log("Token generated : ", token);
    } else if (permission === "denied") {
      //notifications are blocked
      alert("You denied for the notification");
    }
  }

  onMessage(messaging, (payload) => {
    console.log("incoming msg");
    console.log(payload)
    toast.info(<Message notification={payload.notification} />);
  });
  useEffect(() => {
    requestPermission();
  }, []);
  useEffect(() => {
    addWindowClass("text-sm");
    setIsAppLoaded(Boolean(authentication));
  }, [authentication]);

  useEffect(() => {
    removeWindowClass("register-page");
    removeWindowClass("login-page");
    removeWindowClass("hold-transition");

    addWindowClass("sidebar-mini");

    // fetchProfile();
    return () => {
      removeWindowClass("sidebar-mini");
    };
  }, []);

  useEffect(() => {
    removeWindowClass("sidebar-closed");
    removeWindowClass("sidebar-collapse");
    removeWindowClass("sidebar-open");
    if (menuSidebarCollapsed && screenSize === "lg") {
      addWindowClass("sidebar-collapse");
    } else if (menuSidebarCollapsed && screenSize === "xs") {
      addWindowClass("sidebar-open");
    } else if (!menuSidebarCollapsed && screenSize !== "lg") {
      addWindowClass("sidebar-closed");
      addWindowClass("sidebar-collapse");
    }
  }, [screenSize, menuSidebarCollapsed]);

  const getAppTemplate = useCallback(() => {
    if (!isAppLoaded) {
      return (
        <div className="preloader flex-column justify-content-center align-items-center">
          <i className="fas fa-truck"></i>
          Loading
          {/* <Image
            className="animation__shake"
            src="/img/logo.png"
            alt="AdminLTELogo"
            height={60}
            width={60}
          /> */}
        </div>
      );
    }
    return (
      <>
        <Header />
        <MenuSidebar />
        <div className="content-wrapper">
          <div className="pt-3" />
          <section className="content">
            <Outlet />
          </section>
        </div>
        <Footer />
        <div
          id="sidebar-overlay"
          role="presentation"
          onClick={handleToggleMenuSidebar}
          onKeyDown={() => {}}
        />
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAppLoaded]);

  return <div className="wrapper">{getAppTemplate()}</div>;
};

export default Main;
