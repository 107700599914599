import React, { useEffect, useState } from "react";
import { Accordion, Card, Button, Row, Col } from "react-bootstrap";
import ContentHeader from "../../components/content-header/ContentHeader";
import QuoteModal from "./QuoteModal";
import { toast } from "react-toastify";
import { getAllBookingNotificationApi } from "../../services/data/notification";
import { jwtDecode } from "jwt-decode";

const BellNotification = () => {
  const token = localStorage.getItem("token");
  // Initialize state to store the role
  const [role, setRole] = useState(null);

  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode(token);
      setRole(decodedToken.role);
    }
  }, [token]);
  const [notifications, setNotifications] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentNotification, setCurrentNotification] = useState(null);
  useEffect(() => {
    getAllBookingNotificationApi()
      .then((response) => {
        // console.log("response.data:", response.data);
        setNotifications(response.data); // Set the notifications state with the response data
      })
      .catch((error) => console.error("Error fetching notifications:", error));
  }, []);
  // console.log("notifications:", notifications);

  const handleShowModal = (notification) => {
    setCurrentNotification(notification);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentNotification(null);
  };

  return (
    <div>
      <ContentHeader title="Notifications" />
      <section className="content">
        <div
          className="container-fluid"
          style={{
            maxHeight: "550px",
            position: "relative",
            overflowY: "auto",
            overflowX: "auto",
          }}
        >
          <Accordion defaultActiveKey={["0"]}>
            {notifications.map((notification, index) => (
              <Card key={notification.bookingNo} className="mb-3">
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey={String(index)}
                  style={{ cursor: "pointer" }}
                >
                  <strong>Booking No:</strong> {notification.bookingNo}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={String(index)}>
                  <Card.Body>
                    <Row>
                      <Col lg={2}>
                        <strong>Date and Time:</strong>
                      </Col>
                      <Col lg={10}>
                        {notification.dateTime
                          ? notification.dateTime
                          : " Null"}
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={2}>
                        <strong>Origin:</strong>
                      </Col>
                      <Col lg={10}>{notification.origin}</Col>
                    </Row>
                    <Row>
                      <Col lg={2}>
                        <strong>Destination:</strong>
                      </Col>
                      <Col lg={10}>{notification.destination.destination1}</Col>
                    </Row>
                    <Row>
                      <Col lg={2}>
                        <strong>Truck Type:</strong>
                      </Col>
                      <Col lg={10}>
                        {notification.truck_type?.truck_type || "null"}
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={2}>
                        <strong>Similar Trips:</strong>
                      </Col>
                      <Col lg={10}>{notification.no_of_trips}</Col>
                    </Row>
                    <Row>
                      <Col lg={2}>
                        <strong>Cargo Name:</strong>
                      </Col>
                      <Col lg={10}>{notification.cargoName}</Col>
                    </Row>
                    <Row>
                      <Col lg={2}>
                        <strong>Description:</strong>
                      </Col>
                      <Col lg={10}>{notification.description}</Col>
                    </Row>
                    <Row>
                      <Col lg={2}>
                        <strong>No of Packages:</strong>
                      </Col>
                      <Col lg={10}>{notification.noOfPackages}</Col>
                    </Row>
                    <Row>
                      <Col lg={2}>
                        <strong>Weight:</strong>
                      </Col>
                      <Col lg={10}>{notification.weight?.weight || "null"}</Col>
                    </Row>
                    <Row>
                      <Col lg={2}>
                        <strong>Status:</strong>
                      </Col>
                      <Col lg={10}>{notification.status?.status || "null"}</Col>
                    </Row>
                    {role === "transporter" && (
                      <div className="text-center">
                        <Button
                          variant="primary"
                          className="mt-3"
                          onClick={() => handleShowModal(notification)}
                        >
                          Quote For The Order
                        </Button>
                      </div>
                    )}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ))}
          </Accordion>
        </div>
      </section>
     { showModal && (
        <QuoteModal
        show={showModal}
        handleClose={handleCloseModal}
        notification={currentNotification}
      />
      )}      
    </div>
  );
};

export default BellNotification;
