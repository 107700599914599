import ContentHeader from "../../components/content-header/ContentHeader";import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import PageLoader from "../../components/PageLoader";
import {     
    getUsersDetail,
    addPushNotificationsApi,   
} from "../../services/data/pushnotifications" 
import { toast } from "react-toastify";

const ManagePushNotification = () => {
    const { id } = useParams(); 
    const navigate = useNavigate()
    const [individaulUser, setIndividaulUser]  = useState([])
    const [isLoading, setIsLoading] = useState(false);      
    const [valuedata, setValuedata] = useState(false);
    const [userdata, setUserData] = useState({
        title:"",
        message: "",
        type: [],
        user:[],        
        dateandtime: "",                
    }); 
     
    const options = [
        { label: "Customer Individual", value: "customer individual,"},
        { label: "Customer Entity", value: "customer entity"},
        { label: "Transporter Single User", value: "transporter single"},
        { label: "Transporter Multi User", value: "transporter multiple"},
        { label: "Drivers", value: "driver" },
        { label: "Sales Spoc", value: "spoc" },        
        // { label: "Transporter Single User", value: "transporter_single_users", disabled: true },
    ];  

   const 
   {
    title,
    message,
    type,
    user,
    dateandtime,
   } = userdata


   if (valuedata) {
    if (userdata.type.length === 0 ){
      setIndividaulUser([])
      setUserData({...userdata,user:[]})
      setValuedata(false)
    }
 }
         
   const handleMultiSelectChange = (name, selectedOptions) => {
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: selectedOptions.map((option) => option.value),
    }));

    if (name === "type"){
        setValuedata(true)
    }
  };

   const currentDate = new Date();
   const option = {
       timeZone: 'Asia/Kolkata', // Setting the time zone to Indian Standard Time
       hour12: true, // Whether to use 12-hour clock
       hour: 'numeric', // Displaying hours
       minute: 'numeric', // Displaying minutes
       second: 'numeric' // Displaying seconds
   };
   const indianTime = currentDate.toLocaleTimeString('en-IN', option);

   const setDate = (date)=>{   
    if (date) {
        setUserData(
            { ...userdata, 
                dateandtime :`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${indianTime}`
            }
        );
    }    
    }

    const dataHandle = (e) => {
        console.log(e.target.value);
        setUserData({ ...userdata, [e.target.name]: e.target.value });
    };

    const handleReset = () => {
        setUserData({
            title:"",
            message: "",
            type: [],
            user:[],
            dateandtime: "",
        });
    };

    function getUser(){ 
        if (userdata.type.length === 0){
             toast.error("Please Select the Group")
        }
        else{
            getUsersDetail(userdata.type).then((response)=>{
                let option=[]
                response.data.map((item)=>(
                    option.push({label:item.name, value:item.id})
                ))
                setIndividaulUser(option)
                toast.success("User Name retrieved successfully.")
            })
        }                   
    }

    const handleSubmit = async (e) => {
        e.preventDefault();    
        // setIsLoading(true);
        const payload = {
            title:userdata.title,
            message:userdata.message,
            users:userdata.user,
            publish_time :userdata.dateandtime
        }
        await addPushNotificationsApi(payload).then((response)=>{
           if (response.status === 201){
               toast.success(response.data.message);
               navigate("/push-notification");
               setIsLoading(false);
           }
        })
        
      };
    
  return (
    <div>
      <ContentHeader title="Push Notification" />
      {
        isLoading ? 
        (
          <PageLoader />
        )
        :
        (
            <section className="content">
            <div className="container-fluid">
              <div className="card">
                <div className="card-header">
                  <div className="card-tools">
                     <Link 
                       className="btn btn-primary"
                       to="/push-notification"
                     >
                        Back
                     </Link>
                  </div>         
                </div>
                <div className="card-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div  className="col-md-6 col-12">
                                <div className="form-group">
                                    <label htmlFor="notification">
                                        Select Group (Customers, Transporters, Drivers, Sales Spoc)
                                         &nbsp; <span 
                                                 className="btn btn-success"
                                                 onClick={getUser}
                                                >
                                                  GO
                                                </span>
                                    </label>                               
                                    <MultiSelect
                                        options={options}
                                        name="type"
                                        value={options.filter(option => userdata.type.includes(option.value))}                                    
                                        onChange={(selectedOptions) => handleMultiSelectChange('type', selectedOptions)}                                        
                                        labelledBy="Select"                                                                                                                                                                                                                                                                                                                
                                    />
                                </div>                            
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="form-group">
                                    <label htmlFor="notification">
                                        Select users
                                        &nbsp; <span 
                                                 className="btn btn-light text-white"                                                 
                                                >
                                                 f
                                                </span>
                                    </label>                               
                                    <MultiSelect
                                        options={individaulUser}
                                        name="user"
                                        value={                                                                                        
                                            individaulUser.filter(option => 
                                                userdata.user.includes(option.value))                                                                                     
                                        }                                    
                                        onChange={(selectedOptions) => handleMultiSelectChange('user', selectedOptions)}                                        
                                        labelledBy="Select"                                                                                                                        
                                    />
                                </div> 
                            </div>
                            <div className="col-md-12 col-12">
                                <div className="form-group">
                                <label htmlFor="password">Title</label>
                                <textarea
                                    type="text"
                                    name="title"
                                    value={title}
                                    placeholder={"Title(Limited to 30 words)"}
                                    onChange={dataHandle}
                                    className="form-control"
                                    rows = "1"
                                    cols = "12"
                                />
                                </div>
                            </div>
                            <div className="col-md-12 col-12">
                                <div className="form-group">
                                    <label htmlFor="Message">Push Message (Limited to 100 words)</label>
                                    <textarea
                                        type="text"
                                        name="message"
                                        rows = "2"
                                        cols = "12"
                                        value={message}
                                        placeholder="Push Message (Limited to 100 words)"
                                        onChange={dataHandle}
                                        className="form-control"
                                    />
                                </div>
                            </div>                           
                            <div className="col-md-6 col-12">
                                <div className="form-group">
                                    <label htmlFor="tradeLicenseValidity">
                                        Publish Date and Time
                                    </label>
                                    <div className="input-group">
                                        <DatePicker
                                        name="dateandtime" 
                                        selected={
                                            userdata.dateandtime 
                                            && userdata.dateandtime ? 
                                            new Date(userdata.dateandtime) : null
                                            }
                                        onChange={setDate}
                                        dateFormat="dd-MM-yyyy  h:mm:ss aa" // 12-hour time format with AM/PM
                                        showYearDropdown 
                                        scrollableYearDropdown                                  
                                        placeholderText="Publish Date and Time"
                                        className="form-control"
                                        minDate={new Date()}
                                        popperPlacement="top"                                                                                                                                     
                                        />                                 
                                        <div className="input-group-append position-relative">
                                        <span
                                            className="input-group-text position-absolute d-flex align-items-center"
                                            style={{ height: "100%" }}
                                        >
                                            <i className="fa fa-calendar"></i>
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            </div>                       
                        </div>
                    </div>              
                </div>
                <div className="card-footer text-center">
                    <button
                        type="reset"
                        className="btn btn-warning mr-3"
                        onClick={handleReset}
                    >
                        Reset
                    </button>
                    <button
                        type="button"
                        className="btn btn-info mr-3"
                        onClick={handleSubmit}
                        >
                        Submit
                    </button>
                </div>
              </div>
            </div>
          </section>
        )
      }
      
    </div>
  );
};

export default ManagePushNotification;