import http from "../common/http-common";

export async function getAllSpoc() {
  return await http.get("/api/v1/spoc");
}

export async function addNewSpocApi(data) {
  return await http.post("/api/v1/spoc", data);
}

export async function getSpocDetails(id) {
  return await http.get(`/api/v1/spoc/${id}`);
}

export async function updateSpocApi(id, data) {
  return await http.put(`/api/v1/spoc/${id}`, data, {   
  });
}

export async function deletespocApi(id) {
  return await http.delete(`/api/v1/spoc/${id}`);
}

export async function getDueDiligenceReport(id, i) {
  await http
    .get(`/api/v1/users/spoc/due-diligences/${id}/report/${i}`, {
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then((response) => {
      const fileType = response.headers["content-type"];
      const blob = new Blob([response.data], { type: fileType });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
      window.URL.revokeObjectURL(url);
    })
    .catch((err) => {
      console.log(err);
      // toast.error("Download Error!!");
    });
}

export async function getSpocResetPassword(id) {
  return await http.get(`/api/v1/spoc/${id}/reset-password`);
}