import Spinner from "react-bootstrap/Spinner";

export default function PageLoader(props) {
  return (
    <section className="content">
      <Spinner animation="grow" className="bg-info" />
      <Spinner animation="grow" className="bg-primary" />
      <Spinner animation="grow" className="bg-success" />
       <p><b>{props.loading}</b></p>
    </section>
  );
}
