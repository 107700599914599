import React, { useEffect, useState } from "react";

export default function ApprovalConfirmModal(props) {

  const [isLoading, setIsLoading] = useState(false);


  return (
    <div
      className={`modal fade ${props.show ? "show" : "hide"}`}
      style={{ display: props.show ? "block" : "none" }}
      id="modal"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-sm">
        <div className="modal-content bg-success">
          {isLoading && (
            <div className="overlay">
              <i className="fas fa-2x fa-sync fa-spin"></i>
            </div>
          )}
          <div className="modal-header">
            <h4 className="modal-title">Confirm ?</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" onClick={() => props.onClose()}>
                ×
              </span>
            </button>
          </div>
          {/* <div className="modal-body">
            <div className="col-sm-6">
              
            </div>
          </div> */}
          <div className="modal-footer justify-content-between">
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
              onClick={() => props.onClose()}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => props.onConfirm()}
            >
              Confirm
            </button>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
}