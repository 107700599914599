import http from "../common/http-common";

export async function getAllCustomersApi() {
  return await http.get("/api/v1/customers",{
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export async function addCustomersApi(data) {
  return await http.post("/api/v1/customers", data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-type": "multipart/form-data",
    },
  });
}

export async function updateCustomersApi(id, data) { 
 
  return await http.put(`/api/v1/customers/${id}`, data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-type": "multipart/form-data",
    },
  });
}


export async function updateCustomersDocVerifyApi(id) {
  return await http.put(`/api/v1/customers/${id}/trade_license_doc/approve`, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-type": "multipart/form-data",
    },
  });
}

export async function getCustomerLicenseDoc(id, i, ext) {
  await http
    .get(`/api/v1/customers/${id}/trade_license_doc/${i}`, {
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then((response) => {
      const fileType = response.headers["content-type"];
      const blob = new Blob([response.data], { type: fileType });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
      window.URL.revokeObjectURL(url);
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function getCustomerDueDiligenceDoc(id, i, ext) {
  await http
    .get(`/api/v1/customers/${id}/report/${i}`, {
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then((response) => {
      const fileType = response.headers["content-type"];
      const blob = new Blob([response.data], { type: fileType });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
      window.URL.revokeObjectURL(url);
    })
    .catch((err) => {
      console.log(err);
      // toast.error("Download Error!!");
    });
}

export async function getCustomerDetails(id) {
  return await http.get(`/api/v1/customers/${id}`,{
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export async function deleteCustomer(id) {
  return await http.delete(`/api/v1/customers/${id}`,{
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export async function assingSpoc(id, data) {
  return await http.post(`/api/v1/customers/${id}/due_diligence`, data,{
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export async function assingDuediligence(id, data) {
  return await http.put(`/api/v1/customers/${id}/due_diligence`, data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-type": "multipart/form-data",
    },
  });
}

export async function getCustomerResetPassword(id) {
  return await http.get(`/api/v1/customers/${id}/reset-password`,{
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

