import http from "../common/http-common";

export async function getMasterCounteryCode() {
  return await http.get(`/api/v1/master/country-codes`,{
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export async function getGeneralSettingApi(){
  return await http.get("/api/v1/master/general-settings",{
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
}

export async function addGeneralSetting(data) {
  return await http.put("/api/v1/master/general-settings", data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-type": "multipart/form-data",
    },
  });
}

export async function addBranches(data) {
  return await http.put("/api/v1/master/general-settings/branches", data,{
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}



export async function getGeneralSettingDoc() {
  await http
    .get(`/api/v1/master/general-settings/logo`, {
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then((response) => {
      const fileType = response.headers["content-type"];
      const blob = new Blob([response.data], { type: fileType });      
      const url = window.URL.createObjectURL(blob);
      window.open(url);
      window.URL.revokeObjectURL(url);
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function getGeneralCommisionApi(){
  return await http.get("/api/v1/master/general-settings/commission",{
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
}

