import React, { useEffect, useState } from "react";
import { getAllUserManagementApi } from "../../services/data/usermanagement";
import Select from "react-select";
import { assingSpoc } from "../../services/data/customers";
import { toast } from "react-toastify";
import { getAllSpoc } from "../../services/data/spoc";

export default function AssignSpocModal(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [spoc, setSpoc] = useState();
  const [spocOptions, setSpocOptions] = useState([]);

  useEffect(() => {
    getAllSpoc().then((response) => { 
      console.log('spocAssignName',response.data)          
      let optionData = [];
      response.data.map((item) =>
        optionData.push({ value: item.id, label: `${item.name}${' '}${item.email}` })
      );
      setSpocOptions(optionData);
    });
  }, []);

  const handleSpocChange = (e) => {
    setSpoc(e.value);
  };

  function onSave() {
    const payload = {
      spoc_id: spoc,
    };
    assingSpoc(props.id, payload).then((response) => {
      toast.success("SPOC Assigned!");
      props.onClose();
    });
  }
  return (
    <div
      className={`modal fade ${props.show ? "show" : "hide"}`}
      style={{ display: props.show ? "block" : "none" }}
      id="modal"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          {isLoading && (
            <div className="overlay">
              <i className="fas fa-2x fa-sync fa-spin"></i>
            </div>
          )}
          <div className="modal-header">
            <h4 className="modal-title">Choose Spoc</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" onClick={() => props.onClose()}>
                ×
              </span>
            </button>
          </div>
          <div className="modal-body">
            <div className="col-sm-12">
              <Select
                options={spocOptions}
                name="role_id"
                onChange={handleSpocChange}
              />
            </div>
          </div>
          <div className="modal-footer justify-content-between">
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
              onClick={() => props.onClose()}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => onSave()}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
