import React, { useEffect, useState } from "react";
import { getTruckName } from  "../../services/data/transporters";
import PageLoader from "../../components/PageLoader";
import { Link } from 'react-router-dom'

const TruckModal = ({show,handleClose,userId}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [truckName, setTruckName] = useState()
  console.log(truckName)   
  useEffect(()=>{
       getTruckName(userId).then((response) => {               
       setTruckName(response.data);
       setIsLoading(false)
      });
  },[])
return (
  <>
  { isLoading ? 
  (
   <PageLoader/>
  ):
  (<div
      className={`modal fade ${show ? "show" : "hide"}`}
      style={{ display: show ? "block" : "none" }}
      id="modal-md"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <form id="driverModal">
            <div className="modal-header">
              <h6 ><b>Truck Name</b></h6>                
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" onClick={() => handleClose()}>
                  ×
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                <ul className="list-group">
                  <li className="list-group-item border-0">
                      <div className="row">                        
                          <div className="col-12">
                          <div className="table-responsive">
                                <table className="table border">
                                  <thead>
                                    <tr>
                                      <th>Truck No</th>
                                      <th>Truck Type</th>
                                      <th>Compilances</th>                                    
                                    </tr>
                                  </thead>
                                  <tbody>                                  
                                      {
                                        truckName.length > 0 ? (truckName.map((item, index)=>{
                                        return(
                                          <tr>
                                            <td>
                                              <Link className="btn btn-link p-0" 
                                                key={item.id}
                                                to={`/truck-profile/${item.id}`}                                        
                                              >
                                                <b>{item.truck_no}</b>                                          
                                              </Link>
                                            </td>
                                            <td>{item.truck_type ?? '-'}</td>
                                            <td>{Array.isArray(item.compilances) && item.compilances.map((item) => (
                                              <>
                                                <span className="badge badge-info">{item}</span> &nbsp;
                                              </>
                                              ))}
                                            </td>
                                          </tr>
                                        )
                                        })):( 
                                          <tr>
                                            <h6 className="text-center pt-3">No Records</h6>
                                          </tr>                                                                              
                                        )
                                      }                                                                  
                                  </tbody>
                                </table>
                              </div>                               
                          </div>
                      </div>
                  </li>
                </ul>                                                                                                                                                          
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-center">                
            </div>
          </form>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
    )
  }
    
  </>
)
}

export default TruckModal
