import http from "../common/http-common";

export async function getAllTruckTypeApi() {
    return await http.get(`/api/v1/master/truck-types`);
  }

export async function addMasterTruckTypeApi(data){
    return await http.post(`/api/v1/master/truck-types`, data, {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            'Content-Type': 'application/json'
        },
    } )
} 

export async function getTruckTypeApi(id) {
  return await http.get(`/api/v1/master/truck-types/${id}`);
}


export async function deleteTruckType(id) {
    return await http.delete(`/api/v1/master/truck-types/${id}`);
  }

export async function getAllPackageApi() {
    return await http.get("/api/v1/master/package-types");
  }

  export async function updateTruckTypeApi(id, data) {
    return await http.put(`/api/v1/master/truck-types/${id}`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        'Content-Type': 'application/json',
      },
    });
  }
