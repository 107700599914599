import http from "../../common/http-common";

// export async function addMastertermsandconditionsApi(data){
//     return await http.post(`/api/v1/master/terms-and-conditions`, data, {
//         headers: {
//             Authorization: "Bearer " + localStorage.getItem("token"),
//             'Content-Type': 'application/json'
//         },
//     } )
// } 

export async function addMastertermsandconditionsApi(data){
  return await http.put(`/api/v1/master/terms-and-conditions`, data, {
      headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          'Content-Type': 'application/json'
      },
  } )
} 

export async function getAllTermsandConditions() {
    return await http.get(`/api/v1/master/terms-and-conditions`);
  }

// export async function getTermsandConditionsdetails(id) {
//     return await http.get(`/api/v1/master/terms-and-conditions/${id}`);
// }

// export async function updateTermsandConditionsdetails(id, data) {
//     return await http.put(`/api/v1/master/terms-and-conditions/${id}`, data, {
//       headers: {
//         Authorization: "Bearer " + localStorage.getItem("token"),
//         'Content-Type': 'application/json',
//       },
//     });
//   }

//   export async function deleteTermsandConditions(id) {
//     return await http.delete(`/api/v1/master/terms-and-conditions/${id}`);
//   }



 