import http from "../common/http-common";

export async function getAllUserRoleManagementApi() {
  return await http.get("/api/v1/user-roles");
}

export async function addUserRoleManagementApi(data) {
  return await http.post("/api/v1/user-roles", data);
}

export async function updateUserRoleManagementApi(id, data) {
  return await http.put(`/api/v1/user-roles/${id}` , data);
}


export async function getUserRoleManagementDetails(id){
  return await http.get(`/api/v1/user-roles/${id}`)
}