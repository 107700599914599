import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { setAuthentication } from "../../store/reducers/auth";
import { setWindowClass } from "../../utils/helpers";
import * as Yup from "yup";
import { authLogin } from "../../utils/oidc-providers";
import { Form, InputGroup } from "react-bootstrap";
import { doLogin } from "../../services/data/auth";
import { jwtDecode } from "jwt-decode";
import login_img from "../../image/Trux_login_img.png";
import "../../styles/Login.css";
import {generateToken} from '../../firebase/firebaseConfig'
import PageLoader from "../../components/PageLoader";

const Login = () => {
  const [isAuthLoading, setAuthLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [notificationToken, setNotificationToken] = useState('')

  const login = async (email, password, notificationToken) => {
    console.log("Logging in...");
    localStorage.removeItem("token");
    localStorage.removeItem("authentication");
    const payload = {
      username: email,
      password: password,
      fcm_token: notificationToken
    };
    console.log(payload)
    await doLogin(payload)
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        const { email, permissions, role, name } = jwtDecode(response.data.token);
        localStorage.setItem(
          "authentication",
          JSON.stringify({ email, permissions, role, name })
        );
        toast.success("Login is succeed!");
        navigate(0);
      })
      .catch((error) => {
        toast.error("Login failed!");
        setAuthLoading(false);
      });

    // try {
    //   setAuthLoading(true);
    //   await doLogin(email, password).then((response) => {
    //     // dispatch(setAuthentication(response));
    //     if (response) {
    //       toast.success("Login is succeed!");
    //       setAuthLoading(false);
    //       // dispatch(loginUser(token));
    //       navigate("/");
    //     } else {
    //       toast.error("Login failed!");
    //       setAuthLoading(false);
    //       navigate("/");
    //     }
    //   });
    // } catch (error) {
    //   setAuthLoading(false);
    //   toast.error(error.message || "Failed");
    // }
  };

  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      // email: Yup.string().email("Invalid email address").required("Required"),
      // password: Yup.string()
      //   .min(5, "Must be 5 characters or more")
      //   .max(30, "Must be 30 characters or less")
      //   .required("Required"),
    }),
    onSubmit: (values) => {
      login(values.email, values.password, notificationToken);
    },
  });

  setWindowClass("hold-transition login-page");
  
  useEffect(()=>{
    setLoading(true);
    async function getToken(){
      try {
         const value = await generateToken();
         console.log('token',value)
         setNotificationToken(value)         
      } catch (error) {
        console.error('Error generating token:', error);
      } finally {
        setLoading(false);
      }
    }    
    getToken()
  },[])

  return (
    <>
      { loading ? <PageLoader loading="Loading Please Wait few Seconds..."/> : (
        <div className="container">
          <div className="row">
            <div className="col-6 d-none d-md-block">
              <img src={login_img} className="userLogin-img" />
            </div>
            <div className="col-12 col-md-6 d-flex flex-column justify-content-center ">
              <div className="login-box m-auto">
                <div className="card card-outline card-primary">
                  <div className="card-header text-center">
                    <Link to="/" className="h1">
                      <b>Trux</b>
                    </Link>
                  </div>
                  <div className="card-body">
                    <p className="login-box-msg">Sign In</p>
                    <form onSubmit={handleSubmit} className="pb-3">
                      <div className="mb-3">
                        <InputGroup className="mb-3">
                          <Form.Control
                            id="email"
                            name="email"
                            type="email"
                            placeholder="Email"
                            onChange={handleChange}
                            value={values.email}
                            isValid={touched.email && !errors.email}
                            isInvalid={touched.email && !!errors.email}
                          />
                          {touched.email && errors.email ? (
                            <Form.Control.Feedback type="invalid">
                              {errors.email}
                            </Form.Control.Feedback>
                          ) : (
                            <InputGroup.Append>
                              <InputGroup.Text>
                                <i className="fas fa-envelope" />
                              </InputGroup.Text>
                            </InputGroup.Append>
                          )}
                        </InputGroup>
                      </div>
                      <div className="mb-3">
                        <InputGroup className="mb-3">
                          <Form.Control
                            id="password"
                            name="password"
                            type="password"
                            placeholder="Password"
                            onChange={handleChange}
                            value={values.password}
                            isValid={touched.password && !errors.password}
                            isInvalid={touched.password && !!errors.password}
                          />
                          {touched.password && errors.password ? (
                            <Form.Control.Feedback type="invalid">
                              {errors.password}
                            </Form.Control.Feedback>
                          ) : (
                            <InputGroup.Append>
                              <InputGroup.Text>
                                <i className="fas fa-lock" />
                              </InputGroup.Text>
                            </InputGroup.Append>
                          )}
                        </InputGroup>
                      </div>                    
                      <div className="row">
                        <div className="col-12">
                          <button
                            loading={isAuthLoading}
                            className="btn btn-block btn-info btn-sm"
                            onClick={handleSubmit}
                            type="submit"
                          >
                            Sign In
                          </button>
                        </div> 
                        <div className="col-12 text-right">
                        <Link 
                          className="btn btn-link"
                          to="/account-deletion"
                        >
                          Account Deletion
                        </Link>
                      </div>                     
                      </div>                                       
                    </form>

                    {/* <p className="mb-1">
                      <Link to="/forgot-password">Forget Password</Link>
                    </p>
                    <p className="mb-0">
                      <Link to="/register" className="text-center">
                        Register Now
                      </Link>
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
