import ContentHeader from "../../components/content-header/ContentHeader";
import { hasPermission } from "../../utils/permissions";
import { getMasterCounteryCode } from "../../services/data/masters";
import { useEffect, useState } from "react";
import PageLoader from "../../components/PageLoader";
import { 
  addMasterTruckTypeApi,
  getAllPackageApi,
  getTruckTypeApi,
  updateTruckTypeApi,
 } from "../../services/data/trucktype";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";


const ManageTruckType = () => {
  const [isloding, setIsLoading] = useState(true)
  const [packageType, setPackageType] = useState([]);
  const navigate = useNavigate()
  const { id } = useParams();
  
  const [userdata, setUserData] = useState({
    truck_type: "",
    package_type_id: "",
    weight: "",
    height: "",
    length: "",
    width: "",
    temperature: "",
    volume: "",    
  });

   
  const{
    truck_type,
    package_type_id,
    weight,
    height,
    length,
    width,
    temperature,
    volume,
  } = userdata

 

  const dataHandle = (e) => {   
    setUserData({ ...userdata, [e.target.name]: e.target.value });
  };

  const handleReset = () => {
    setUserData({
      truck_type: "",
      package_type_id: "",
      weight: "",
      height: "",
      length: "",
      width: "",
      temperature: "",
      volume: "", 
    });
  };

 
  useEffect(() => {
    getAllPackageApi().then((response)=>{           
      setPackageType(response.data)
      setIsLoading(false)      
    })
    if (id) {
      setIsLoading(true);
      getTruckTypeApi(id).then((response) => {
        console.log(response.data ,'getsingleuser') 
        setUserData({
          truck_type: response.data.truck_type,
          package_type_id: response.data.package_type.id,
          weight: response.data.weight,
          height: response.data.height,
          length: response.data.length,
          width: response.data.width,
          temperature: response.data.temperature,
          volume: response.data.volume,
        });
        setIsLoading(false);
      });
    } else {
      handleReset();
      setIsLoading(false);
    }
  }, [id]);

  const handleSubmit = async (e) => {
      e.preventDefault()
      setIsLoading(true);
    if(
      !truck_type||
      !package_type_id||
      !weight||
      !height||
      !length||
      !width||
      !temperature||
      !volume
    ){
      toast.error("Please fill in all required fields.");
      setIsLoading(false);
      return;
    } 

    if (id) {
      await updateTruckTypeApi(id, userdata).then((response) => {
        if (response.status >= 200) {
          toast.success(response.data.message);
          navigate("/truck-type");
        } else {
          toast.error(response.data.message);
          setIsLoading(false);
        }
      });
    } 
    else{      
      await addMasterTruckTypeApi(userdata).then((response)=>{       
        if (response.status === 200) {
          toast.success(response.data.message);
          navigate("/truck-type");        
        } else{
          toast.error(response.data.message)
          setIsLoading(false);
        }               
      })  
    }         
  }

  return (
    <div>
      <ContentHeader title={id ? "Edit Truck Types" :"Truck Types"} />
      {
        isloding ? 
        (
          <PageLoader loading="Loading..."/>           
        ):(
          <section className="content">
            <div className="container-fluid">
              <div className="card">
                <div className="card-header">
                  <Link to ="/truck-type" className="btn btn-primary float-right">Back</Link>
                </div>           
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6 col-sm-12">

                      <div className="form-group">
                        <label htmlFor="name">Truck Type</label>
                        <input
                          type="text"
                          name="truck_type"
                          value={truck_type}
                          placeholder="Truck Type"
                          onChange={dataHandle}
                          className="form-control"
                        />
                      </div>

                      <div className="form-group">
                          <label htmlFor="creditduration">Weight</label>
                          <div class="input-group">
                            <input
                              className="form-control"                              
                              type="number"
                              name="weight"
                              placeholder="Weight"
                              value={weight}                         
                              onChange={dataHandle}                          
                            />
                            <div class="input-group-append">
                              <span class="input-group-text">in Tons</span>
                            </div>
                          </div>                     
                      </div>

                      <div className="form-group">
                          <label htmlFor="creditduration">Length</label>
                          <div class="input-group">
                            <input
                              className="form-control"                              
                              type="number"
                              name="length"
                              placeholder="Length"
                              value={length}                         
                              onChange={dataHandle}                          
                            />
                            <div class="input-group-append">
                              <span class="input-group-text">in meters</span>
                            </div>
                          </div>                     
                      </div>

                      <div className="form-group">
                          <label htmlFor="creditduration">Temperature</label>
                          <div class="input-group">
                            <input
                              className="form-control"                             
                              type="text"
                              name="temperature"
                              placeholder="Temperature (Comes only when frozen is choosen)"
                              value={temperature}                         
                              onChange={dataHandle}                          
                            />
                            <div class="input-group-append">
                              <span class="input-group-text">in celesius</span>
                            </div>
                          </div>                     
                      </div>

                    </div>

                    <div className="col-md-6 col-sm-12">

                      <div className="form-group">
                        <label htmlFor="countryCode">Package Type</label>
                        <select                        
                          name="package_type_id"
                          value = {package_type_id}
                          defaultValue= {package_type_id}
                          onChange={dataHandle}
                          className="form-control"
                        >
                          <option value=''>Select..</option>
                          {packageType &&
                            packageType.map((item) => {
                              return (
                                <option value={item.id}>
                                  {item.name}
                                </option>
                              );
                          })}
                        </select>
                      </div>

                      <div className="form-group">
                          <label htmlFor="creditduration">Height</label>
                          <div class="input-group">
                            <input
                              className="form-control"                            
                              type="number"
                              name="height"
                              placeholder="Height"
                              value={height}                         
                              onChange={dataHandle}                          
                            />
                            <div class="input-group-append">
                              <span class="input-group-text">in meters</span>
                            </div>
                          </div>                     
                      </div>

                      <div className="form-group">
                          <label htmlFor="creditduration">Width</label>
                          <div class="input-group">
                            <input
                              className="form-control"                              
                              type="number"
                              name="width"
                              placeholder="width"
                              value={width}                         
                              onChange={dataHandle}                          
                            />
                            <div class="input-group-append">
                              <span class="input-group-text">in meters</span>
                            </div>
                          </div>                     
                      </div>

                      <div className="form-group">
                          <label htmlFor="creditduration">Volume(CBM)</label>
                          <div class="input-group">
                            <input
                              className="form-control"                             
                              type="text"
                              name="volume"
                              placeholder="Volume(CBM)"
                              value={volume}                         
                              onChange={dataHandle}                          
                            />                        
                          </div>                     
                      </div>

                    </div>
                  </div>
                  {(hasPermission("users.create") || hasPermission("users.edit")) && (
                    <div className=" text-right">
                      <button
                        type="reset"
                        className="btn btn-warning mr-3"
                        onClick={handleReset}
                      >
                        Reset
                      </button>
                      <button
                        type="button"
                        className="btn btn-info mr-3"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  )}
                  </div>

              </div>
            </div>
          </section>
        )
      }
      
    </div>
  );
};

export default ManageTruckType;
