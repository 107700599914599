import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { setAuthentication } from "../../store/reducers/auth";
import { setWindowClass } from "../../utils/helpers";
import * as Yup from "yup";

import { authLogin } from "../../utils/oidc-providers";
import { Form, InputGroup } from "react-bootstrap";
import login_img from "../../image/Trux_login_img.png";
import "../../styles/Login.css";

const AccountDeletion = () => {
  const [isAuthLoading, setAuthLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const login = async (email, password) => {
          
  };

  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: {
      email: "",
      phone: "",
      reason: "",
    },
    validationSchema: Yup.object({
      // email: Yup.string().email("Invalid email address").required("Required"),
      // password: Yup.string()
      //   .min(5, "Must be 5 characters or more")
      //   .max(30, "Must be 30 characters or less")
      //   .required("Required"),
    }),
    onSubmit: (values) => {
      login(
        values.email, 
        values.phone,
        values.reason,
    );
    },
  });

  setWindowClass("hold-transition login-page");

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-6 d-none d-md-block">
            <img src={login_img} className="userLogin-img" />
          </div>
          <div className="col-12 col-md-6 d-flex flex-column justify-content-center ">
            <div className="login-box m-auto">
              <div className="card card-outline card-primary">
                <div className="card-header text-center">
                  <Link to="/" className="h1">
                    <b>Trux</b>
                  </Link>
                </div>
                <div className="card-body">
                  <p className="login-box-msg">Sign In</p>
                  <form onSubmit={handleSubmit} className="pb-3">
                    <div className="mb-3">
                      <InputGroup className="mb-3">
                        <Form.Control
                          id="email"
                          name="email"
                          type="email"
                          placeholder="Email"
                          onChange={handleChange}
                          value={values.email}
                          isValid={touched.email && !errors.email}
                          isInvalid={touched.email && !!errors.email}
                        />
                        {touched.email && errors.email ? (
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        ) : (
                          <InputGroup.Append>
                            <InputGroup.Text>
                              <i className="fas fa-envelope" />
                            </InputGroup.Text>
                          </InputGroup.Append>
                        )}
                      </InputGroup>
                    </div>
                    <div className="mb-3">
                      <InputGroup className="mb-3">
                        <Form.Control
                          id="phone"
                          name="phone"
                          type="number"
                          placeholder="Phone No"
                          onChange={handleChange}
                          value={values.phone}
                          isValid={touched.phone && !errors.phone}
                          isInvalid={touched.phone && !!errors.phone}
                        />
                        {touched.phone && errors.phone ? (
                          <Form.Control.Feedback type="invalid">
                            {errors.phone}
                          </Form.Control.Feedback>
                        ) : (
                          <InputGroup.Append>
                            <InputGroup.Text>
                              <i className="fas fa-phone" />
                            </InputGroup.Text>
                          </InputGroup.Append>
                        )}
                      </InputGroup>
                    </div>
                    <div className="mb-3">
                      <InputGroup className="mb-3">
                        <Form.Control
                          id="reason"
                          name="reason"
                          type="text"
                          placeholder="Reason"
                          onChange={handleChange}
                          value={values.reason}
                          isValid={touched.reason && !errors.reason}
                          isInvalid={touched.reason && !!errors.reason}
                        />
                        {touched.phone && errors.phone ? (
                          <Form.Control.Feedback type="invalid">
                            {errors.reason}
                          </Form.Control.Feedback>
                        ) : (
                          <InputGroup.Append>
                            <InputGroup.Text>
                              <i className="fas fa-question-circle" />
                            </InputGroup.Text>
                          </InputGroup.Append>
                        )}
                      </InputGroup>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <button
                          loading={isAuthLoading}
                          type="submit"
                          className="btn btn-block btn-info btn-sm"
                          onClick={handleSubmit}
                        >
                          Submit
                        </button>
                      </div>
                      <div className="col-12 text-right">
                       <Link 
                        className="btn btn-link"
                        to="/login"
                       >
                         Log In
                       </Link>
                    </div>
                    </div>
                  </form>

                  {/* <p className="mb-1">
                    <Link to="/forgot-password">Forget Password</Link>
                  </p>
                  <p className="mb-0">
                    <Link to="/register" className="text-center">
                      Register Now
                    </Link>
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountDeletion;
