import React, { useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { addTripApi } from "../../services/data/trip";
import { toast } from "react-toastify";

const AddressConfirmationModal = ({ show, handleClose, Quote, quotedata, bookingData }) => {
  console.log("Quote :",Quote)
  console.log("quotedata :",quotedata)
  console.log("bookingData :",bookingData)

  
  const [initialValues, setInitialValues] = useState({
    booking_id: quotedata[0]?.booking_id ?? null,
    quotation_id:quotedata[0]?.id ?? null,
    origin_name: "",
    origin_contact: "",
    origin_address: "",
    origin_landmark: "",
    consignee_name: "",
    consignee_contact: "",
    consignee_address: "",
    consignee_landmark: "",
  });

  const validationSchema = Yup.object().shape({
    origin_name: Yup.string().required("Required"),
    origin_contact: Yup.string().required("Required"),
    origin_address: Yup.string().required("Required"),
    origin_landmark: Yup.string().required("Required"),
    consignee_name: Yup.string().required("Required"),
    consignee_contact: Yup.string().required("Required"),
    consignee_address: Yup.string().required("Required"),
    consignee_landmark: Yup.string().required("Required"),
  });

  const handleSubmit = (values) => {
    console.log("Form data", values);
    addTripApi(values).then((res)=>{
      if (res.data.status === 'success'){
        toast.success(res.data.message)
        handleClose();
      }else{
        toast.error(res.data.message)
      }
    })
    
  };

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Addresses</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col lg={4}>
            <strong>Date and Time:</strong>
          </Col>
          <Col lg={8}>{bookingData?.date_and_time_of_shipment}</Col>
        </Row>
        <Row>
          <Col lg={4}>
            <strong>Origin:</strong>
          </Col>
          <Col lg={8}>{bookingData?.origin}</Col>
        </Row>
        <Row>
          <Col lg={4}>
            <strong>Destination:</strong>
          </Col>
          <Col lg={8}>{bookingData?.destination?.destination1}</Col>
        </Row>
        <Row>
          <Col lg={4}>
            <strong>Truck Type:</strong>
          </Col>
          <Col lg={8}>{bookingData?.truck_type ?? '-' }</Col>
        </Row>
        <Row >
          <Col lg={4}>
            <strong>Similar Trips:</strong>
          </Col>
          <Col lg={8}>{bookingData?.no_of_trips ?? '-' }</Col>
        </Row>
        <Row className="mb-2">
          <Col lg={4}>
            <strong>Status:</strong>
          </Col>
          <Col lg={8}>To Start</Col>
        </Row>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ touched, errors }) => (
            <Form>
              <Row>
                <Col lg={6}>
                  <h5>Add Origin Address</h5>
                  <div className="form-group">
                    <label>Name of the Person</label>
                    <Field
                      name="origin_name"
                      className={`form-control ${
                        touched.origin_name && errors.origin_name
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="origin_name"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group">
                    <label>Contact No</label>
                    <Field
                      name="origin_contact"
                      className={`form-control ${
                        touched.origin_contact && errors.origin_contact
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="origin_contact"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group">
                    <label>Address</label>
                    <Field
                      name="origin_address"
                      className={`form-control ${
                        touched.origin_address && errors.origin_address
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="origin_address"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group">
                    <label>Landmark</label>
                    <Field
                      name="origin_landmark"
                      className={`form-control ${
                        touched.origin_landmark && errors.origin_landmark
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="origin_landmark"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </Col>
                <Col lg={6} >
                  <h5>Add Consignee Address</h5>
                  <div className="form-group">
                    <label>Name of the Person</label>
                    <Field
                      name="consignee_name"
                      className={`form-control ${
                        touched.consignee_name && errors.consignee_name
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="consignee_name"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group">
                    <label>Contact No</label>
                    <Field
                      name="consignee_contact"
                      className={`form-control ${
                        touched.consignee_contact && errors.consignee_contact
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="consignee_contact"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group">
                    <label>Address</label>
                    <Field
                      name="consignee_address"
                      className={`form-control ${
                        touched.consignee_address && errors.consignee_address
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="consignee_address"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group">
                    <label>Landmark</label>
                    <Field
                      name="consignee_landmark"
                      className={`form-control ${
                        touched.consignee_landmark && errors.consignee_landmark
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="consignee_landmark"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </Col>
              </Row>
              <Button type="submit" variant="primary">
                Confirm Address
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default AddressConfirmationModal;
