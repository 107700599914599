import React, { useState, useEffect } from "react";
import ContentHeader from "../../components/content-header/ContentHeader";
import MaterialTable from "@material-table/core";
import { Link } from "react-router-dom";
import { ExportCsv } from "@material-table/exporters";
import { toast } from "react-toastify";
import AssignDriverModal from "./AssignDriverModal";
import AssignTruckModal from "./AssignTruckModal";
import {
  getAllTripsApi
} from "../../services/data/trip";
import PageLoader from "../../components/PageLoader";
import { useAuth } from "../../services/common/utils";
import EndTripModal from "./EndTripModal";
import StartTripModal from "./StartTripModal";

const Trips = () => {
  const { email , name, role, userid, permissions } = useAuth();      
  const [data, setData] = useState([])
  const [showModal, setShowModal] = useState(false);
  const [showModalTruck, setShowModalTruck] = useState(false);
  const [selectedTrip, setSelectedTrip] = useState(null);
  const [startTripModal, setStartTripModal] = useState(false);
  const [endTripModal, setEndTripModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tripId, setTripid] = useState('');
  const [tripActive, setTripActive] = useState('')
  console.log('akkk',data)

  const handleStartTrip = (id) => {         
    setStartTripModal(true);   
    setTripid(id)
    setTripActive('start')
  };

  const handleEndTrip = (id) => {         
    setEndTripModal(true);
    setTripid(id)
    setTripActive('end')
  };

  const handleAssignDriver = (trip) => {    
    console.log('trip log on driver submission',trip) 
    setSelectedTrip(trip);
    setShowModal(true);
  };
   
  const handleAssignTruck = (trip) => {     
    setSelectedTrip(trip);
    setShowModalTruck(true);
  };

  const handleModalClose = () => {
    setStartTripModal(false);
    setEndTripModal(false) 
    setShowModal(false);
    setShowModalTruck(false)
    setTripActive('finshed')
    setSelectedTrip(null);
  };
  
  useEffect(()=>{
    getAllTripsApi().then((res)=>{        
      setData(res.data)
      setLoading(false)
    })
  },[tripActive])

  const basecolumns = [
    { title: "Booking No", 
      field: "booking.booking_no",
      render:(rowData)=>{
        return(
          <>           
            <Link to={`/booking-profile/${rowData.booking_id}`} className="nav-link p-0">                      
              {rowData.booking.booking_no}
            </Link>
          </>
        )
      } 
    },
    { title: "Date and Time", field: "booking.booking_date" },
    { title: "Origin", field: "booking.origin" },
    { title: "Destination", field: "booking.destination.destination1" },
    {
      title:"Trips",
      render: (rowData) => {
        return(
          <>
            {
              rowData.is_started === false && rowData.is_ended === false  ? (
                <>
                  <button 
                    className="btn btn-info"
                    onClick={()=>handleStartTrip(rowData.id)}
                  >
                      Start Trip
                  </button>
                </>
              ): rowData.is_started === true  && rowData.is_ended === false ? (
                  <button 
                    className="btn btn-info"
                    onClick={()=>handleEndTrip(rowData.id)}
                  >
                    End Tip
                  </button>
              ): rowData.is_started === true && rowData.is_ended === true && (
                  <p className="text-success">Trip Completed</p>
              )
            }                       
             
          </>
        )
      } 
    },    
    {
      title: "Action",
      render: (rowData) => {
      return(
        <>
          <p className="d-flex flex-row">
            <button
              type="button"
              className="btn btn-info"
              onClick={() => handleAssignDriver(rowData)}
            >
              Assign Driver
            </button> 
          </p>
          <p>            
            <button
              type="button"
              className="btn btn-info"
              onClick={() => handleAssignTruck(rowData)}
            >
              Assign Truck
            </button>
          </p>
       </>   
      )            
     },
    },
  ];   
  
  function transporterShowColumn(){
    basecolumns.splice(4,1)
    return basecolumns
  }

  const columns = role === 'transporter' ? transporterShowColumn() : basecolumns.splice(0,(basecolumns.length-1))
  return (
    <div>
      <ContentHeader title="Trips" />
      { loading ? <PageLoader loading="Loading..."/> : (
        <>
          <section className="content">
            <div className="container-fluid">
              <div className="card bg-transparent border-0">
                <div className="card-header">
                  <h3 className="card-title">Trips List</h3>
                  <Link to="/new-trip" className="float-right btn btn-info">
                    New Trips
                  </Link>                  
                </div>
                <div className="card-body p-0 m-0">
                  <MaterialTable
                    style={{ backgroundColor: "#f4f6f9" }}
                    title=""
                    data={data}
                    columns={columns}
                    options={{
                      exportAllData: true,
                      exportMenu: [
                        {
                          label: "Export CSV",
                          exportFunc: (cols, datas) =>
                            ExportCsv(cols, datas, "Trips List"),
                        },
                      ],
                      rowStyle: (rowData) => ({
                        borderRadius: "10% !important",
                        backgroundColor: "#FFF",
                        borderTop: "15px solid #f4f6f9",
                        borderBottom: "15px solid #f4f6f9",
                      }),
                    }}
                  />
                </div>
              </div>
            </div>
            {showModal &&
              <AssignDriverModal
                show={showModal}
                handleClose={handleModalClose}
                selectedTrip={selectedTrip}
              />
            }
            {showModalTruck &&
              <AssignTruckModal
                show={showModalTruck}
                handleClose={handleModalClose}
                selectedTrip={selectedTrip}
              />
            }
            { endTripModal && 
              <EndTripModal
              tripid={tripId}
              show={endTripModal}
              handleClose={handleModalClose}
              /> 
            }
            { startTripModal && 
              <StartTripModal
              tripid={tripId}
              show={startTripModal}
              handleClose={handleModalClose}
              /> 
            }
          </section>
        </>
      )}                 
    </div>
  );
};

export default Trips;