import ContentHeader from "../components/content-header/ContentHeader";

const Profile = () => {
  return (
    <div>
      <ContentHeader title="Profile" />
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <form className="form-horizontal">
              <div className="row">
                <div className="col-6">
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Email address</label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Enter email"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="contactNo">Contact No</label>
                      <input
                        type="text"
                        className="form-control"
                        id="contactNo"
                        placeholder="Contact No"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Password</label>
                      <input
                        type="password"
                        className="form-control"
                        id="exampleInputPassword1"
                        placeholder="Password"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">
                        Confirm Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="exampleInputPassword1"
                        placeholder="Password"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-footer">
                <button type="submit" className="btn btn-info float-right">
                  Sign in
                </button>
                <button type="submit" className="btn btn-default">
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Profile;
