import ContentHeader from "../../components/content-header/ContentHeader";
import "../../styles/Customer.css";
import React, { useState, useRef, useEffect } from "react";
import MaterialTable, { Column } from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import "jspdf-autotable";
import { useSelector } from "react-redux";
import { hasPermission } from "../../utils/permissions";
import {
  deleteCustomer,
  getAllCustomersApi,
  getCustomerDetails,
  updateCustomersApi,
  getCustomerDueDiligenceDoc,
} from "../../services/data/customers";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import { Save } from "@mui/icons-material";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";
import { toast } from "react-toastify";
import PageLoader from "../../components/PageLoader";
import AssignSpocModal from "./AssingSpocModal";
import DuedeligenceModal from "./DuedeligenceModal";
import Accordion from "react-bootstrap/Accordion";
import { getDueDiligenceReport } from "../../services/data/spoc";

const Customers = () => {
  const authentication = useSelector((state) => state.auth.authentication);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [delteCustomerId, setDelteCustomerId] = useState();
  const [showAssignSpocModal, setShowAssignSpocModal] = useState(false);
  const [showDueDeligenceModal, setShowDueDeligenceModal] = useState(false);
  const [id, setId] = useState("");
  const [tradeLicenseDoc, setTradeLicenseDoc] = useState([]);
  const [dueDeligence, setDueDeligence] = useState([]);
  const [activeStatus, SetActiveStatus] = useState("");

  console.log("Reterival All customer Details", data);

  const handleAssignSpoc = (id) => {
    setId(id);
    setShowAssignSpocModal(true);
  };

  const onSpocClose = () => {
    setShowAssignSpocModal(false);
  };

  const handleDueDeligence = (id, document, dueDeligence) => {
    setId(id);
    setTradeLicenseDoc(document);
    setDueDeligence(dueDeligence);
    setShowDueDeligenceModal(true);
  };

  const onCloseDueDeligence = () => {
    setShowDueDeligenceModal(false);
  };

  const changeStatus = (isActive, id) => {
    const payload = {
      is_active: isActive,
    };
    updateCustomersApi(id, payload).then((response) => {
      if (isActive) {
        toast.success("User Activated!");
      } else {
        toast.info("User Deactivated!");
      }

      getCustomerDetails(id).then((response) => {
        setData(response.data);
        SetActiveStatus(isActive);
      });
    });
  };

  const columns = [
    // { title: "id", field: "id" },
    {
      title: "Customer Name",
      field: "name",
      render: (rowData) => {
        return (
          <div className="mt-1">
            {/* <p className="mb-0 pb-0">
              <Link to={`/customer-profile/${rowData.id}`} className="nav-link">
                <i className="fas fa-user nav-icon"></i> 
                <b>{rowData.name}</b>
              </Link>
            </p> */}
            <b>{rowData.name}</b>
          </div>
        );
      },
    },
    {
      title: "Contact Details",
      field: "email",
      render: (rowData) => {
        return (
          <>
            <p>
              <b>Mobile No: </b> + {rowData.country_code} - {rowData.contact_no}
              </p><p>
              <b>Email:</b>
              {rowData.email ?? "-"}

            </p>
          </>
        );
      },
    },
    {
      title: "Contact No",
      headerStyle: { "display":"none"},
      field: "contact_no",
      cellStyle : { "display":"none" },
      render: (rowData) => {
        return (
          <>
          + {rowData.country_code}-{rowData.contact_no}
          </>
        );
      },
    },
    {
      title: "User Type",
      field: "type",
      render: (rowData) => {
        return <>{rowData.type.toUpperCase()}</>;
      },
    },

    {
      title: "Due Diligence",
      field: "due_deligence",
      render: (rowData) => {
        return (
          <>
            {rowData.type !== "individual" ? (
              <>
                <p>
                  <b>Trux Admin:</b>
                  {rowData.due_diligence.spoc &&
                    rowData.is_trade_license_doc_verified
                    ? "Completed"
                    : "In Progress"}
                </p>
                <p>
                  <b>Sales SPOC:</b> <br />
                  {rowData.due_diligence?.spoc?.name ? (
                    rowData.due_diligence.spoc.name
                  ) : (
                    <a href="#" onClick={() => handleAssignSpoc(rowData.id)}>
                      <span>Select User</span>
                    </a>
                  )}
                </p>
                {rowData.due_diligence?.spoc && (
                  <p>
                    <b>Verification:</b>
                    {rowData.due_diligence?.is_report_verified ? (
                      "Completed"
                    ) : (
                      <a
                        href="#"
                        onClick={() =>
                          handleDueDeligence(
                            rowData.id,
                            rowData.trade_license_doc,
                            rowData.due_diligence
                          )
                        }
                      >
                        In Progress
                      </a>
                    )}
                  </p>
                )}
                <p>
                  {rowData.due_diligence.report?.length > 0 && (
                    <Accordion defaultActiveKey="0">
                      <Accordion.Toggle as={Link} variant="link" eventKey="1">
                        <i className="fas fa-plus"></i> Due Deligence Reports
                      </Accordion.Toggle>
                      {rowData.due_diligence.report.map((item) => {
                        return (
                          <Accordion.Collapse eventKey="1">
                            <a
                              className="btn btn-link p-0"
                              onClick={(e) =>
                                getDueDiligenceReport(
                                  rowData.due_diligence.id,
                                  item.id
                                )
                              }
                            >
                              <i class="fas fa-download"></i>
                              {` report-${item.id}.${item.file_ext}`}
                            </a>
                          </Accordion.Collapse>
                        );
                      })}
                    </Accordion>
                  )}
                </p>
              </>
            ) : (
              "NA"
            )}
          </>
        );
      },
    },
    {
      title: "Total Trips",
      field: "total_trips",
      render: (rowData) => {
        return <>{rowData.total_trips ?? "0"}</>;
      },
    },
    // {
    //   title: "Payment Details",
    //   field: "payment_details",
    //   render: (rowData) => {
    //     return <>{rowData.payment_details ?? "-"}</>;
    //   },
    // },
    {
      title: "Status",
      render: (rowData) => {
        return (
          <p>
            {hasPermission("customers.edit") ? (
              <>
                {rowData.status === "In Active" && (
                  <button
                    type="button"
                    className="btn "
                    style={{ backgroundColor: "#8D9A9C", color: "#FFF" }}
                    onClick={(e) => changeStatus(true, rowData.id)}
                  >
                    In Active
                  </button>
                )}
                {rowData.status === "Active" && (
                  <button
                    type="button"
                    className="btn "
                    style={{ backgroundColor: "#004751", color: "#FFF" }}
                    onClick={(e) => changeStatus(false, rowData.id)}
                  >
                    Active
                  </button>
                )}
              </>
            ) : (
              <>{rowData.status}</>
            )}
          </p>
        );
      },
    },
    {
      title: "Action",

      render: (rowData) => {
        return (
          <>
            <Link
              className="btn mr-0 pr-0"
              to={`/customer-profile/${rowData.id}`}
            >
              <i className="fas fa-eye" style={{ color: "#004751" }}></i>
            </Link>
            {hasPermission("customers.edit") && (
              <Link
                className="btn mr-0 pr-0"
                to={`/manage-customer/${rowData.id}`}
              >
                <i className="fas fa-edit " style={{ color: "#004751" }} />

                {/* <i className="fas fa-pencil-alt text-info"></i> */}
              </Link>
            )}
            {hasPermission("customers.delete") && (
              <button
                className="btn  mr-0 pr-0"
                onClick={(e) => {
                  setDelteCustomerId(rowData.id);
                  setShowDeleteConfirm(true);
                }}
              >
                {/* <i className="fas fa-times text-danger"></i> */}
                <i className="fas fa-trash-alt" style={{ color: "#D46B6B" }}></i>
              </button>
            )}{" "}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    getAllCustomersApi().then((response) => {
      setData(response.data);
      setIsPageLoading(false);
      setIsLoading(false);
    });
  }, [
    showDeleteConfirm,
    showAssignSpocModal,
    showDueDeligenceModal,
    activeStatus,
  ]);

  function onClose() {
    setShowDeleteConfirm(false);
  }

  function onConfirm() {
    // toast.warn("Not Implemented!");
    deleteCustomer(delteCustomerId).then((response) => {
      toast.success("Customer Deleted");
      setShowDeleteConfirm(false);
    });
  }

  return (
    <>
      <div>
        <ContentHeader title="Customers" />
        {isPageLoading ? (
          <PageLoader />
        ) : (
          <section className="content">
            <div className="container-fluid">
              <div className="card bg-transparent border-0">
                <div className="card-header">
                  <h3 className="card-title">Customer Details</h3>
                  <div className="card-tools">
                    {hasPermission("customers.create") && (
                      <Link
                        type="button"
                        className="btn btn-tool bg-info"
                        data-card-widget="collapse"
                        title="Collapse"
                        to="/manage-customer/new"
                      >
                        <i className="fas fa-plus" /> New Customer
                      </Link>
                    )}
                  </div>
                </div>
                {/* <div> */}
                <MaterialTable
                  // id="pdfDatas"
                  style={{ backgroundColor: "#f4f6f9" }}
                  columns={columns}
                  isLoading={isLoading}
                  data={data}
                  title=""
                  options={{
                    rowStyle: (rowData) => ({
                      borderRadius: '10% !important', // Rounded corners
                      backgroundColor: "#FFF",
                      borderTop: '15px solid #f4f6f9', // Transparent top border
                      borderBottom: '15px solid #f4f6f9' // Transparent bottom border
                    }),
                    exportAllData: true,
                    exportMenu: [
                      {
                        label: "Export CSV",
                        exportFunc: (cols, datas) =>
                          ExportCsv(cols, datas, "trux"),
                      },
                    ],
                  }}
                />
                {/* </div> */}
                {/* <div className="card-footer">Footer</div> */}
              </div>
            </div>
            {showDeleteConfirm && (
              <DeleteConfirmModal
                show={showDeleteConfirm}
                onClose={onClose}
                onConfirm={onConfirm}
              />
            )}
            {showAssignSpocModal && (
              <AssignSpocModal
                onClose={onSpocClose}
                show={showAssignSpocModal}
                id={id}
              />
            )}
            {showDueDeligenceModal && (
              <DuedeligenceModal
                show={showDueDeligenceModal}
                handleClose={onCloseDueDeligence}
                userId={id}
                dueDiligence={dueDeligence}
                existTradeDoc={tradeLicenseDoc}
              />
            )}
          </section>
        )}
      </div>
    </>
  );
};

export default Customers;
