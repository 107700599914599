// import http from "../common/http-common";
// import { jwtDecode } from "jwt-decode";

import http from "../common/http-common";

export async function doLogin(data) {
  return http.post("/api/v1/login", data, {
    headers: {
      "Content-type": "application/json",
    },
  });
}

// export function doLogin(email, password) {
//   return new Promise(async (res, rej) => {
//     const payload = {
//       username: email,
//       password: password,
//     };
//     await http.post("/api/v1/login", payload).then((response) => {
//       localStorage.setItem("token", response.data.token);
//       const { user, permission } = jwtDecode(response.data.token);
//       localStorage.setItem(
//         "authentication",
//         JSON.stringify({ profile: { email: user }, permissions: permission })
//       );
//       return res({ profile: { email: user }, permissions: permission }), 201;
//     })
//   });
// }
