import ContentHeader from "../../components/content-header/ProfileContentHeader";
import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useParams } from "react-router-dom";
import MaterialTable, { Column } from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import PageLoader from "../../components/PageLoader";
import AssignSpocModal from "./AssingSpocModal";
import {
  getTransporterLicenseDoc,
  getTransportersDetails,
  updateTransportersApi,
  updateTransportessDocVerifyApi,
  getTransporterResetPassword,
} from "../../services/data/transporters";
import DuedeligenceModal from "./DuedeligenceModal";
import { toast } from "react-toastify";
import ApprovalConfirmModal from "../../components/ApprovalConfirmModal";
import { getDueDiligenceReport } from "../../services/data/spoc";
import Accordion from "react-bootstrap/Accordion";
import {Link} from "react-router-dom"
import DriverModal from "./DriverModal";
import TruckModal from "./TruckModal";


const TransporterDetails = () => {
  const { id } = useParams();
  const [isPageLoagin, setIsPageLoading] = useState(true);
  const [showAssignSpocModal, setShowAssignSpocModal] = useState(false);
  const [userData, setUserData] = useState();
  const [userId, setuserId] = useState(null);
  const [showDueDeligenceModal, setShowDueDeligenceModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [driverModal, setDriverModal] = useState(false);
  const [truckModal, setTruckModal] = useState(false);

  console.log('Transporter Profile', userData)
  

  useEffect(() => {
    if (showAssignSpocModal || showDueDeligenceModal) {
      return;
    }
    getTransportersDetails(id).then((response) => {
      setIsPageLoading(false);
      console.log(response.data, 'Transporter Details')
      setUserData(response.data);
    });
  }, [id, showAssignSpocModal, showDueDeligenceModal]);

  const data = [];

  const columns = [
    {
      title: "Booking No",
      field: "booking_no",
    },
    {
      title: "Origin",
      field: "origin",
    },
    {
      title: "Destination",
      field: "destination",
    },
    {
      title: "Booked On",
      field: "booked_on",
    },
    {
      title: "ETA",
      field: "eta",
    },
    {
      title: "Status",
      field: "status",
    },
    {
      title: "Payment Status",
      field: "payment_status",
    },
  ];

  const driverModalDetails = () => {
    setDriverModal(true);
  };
  
  const truckModalDetails = () => {
    setTruckModal(true);
  };

  const handleAssignSpoc = () => {
    setShowAssignSpocModal(true);
  };

  const onClose = () => {
    setShowAssignSpocModal(false);
    setShowApproveModal(false);
    setDriverModal(false);
    setTruckModal(false);
  };

  const handleDueDeligence = (id) => {
    // setuserId(id)
    setShowDueDeligenceModal(true);
  };

  const onCloseDueDeligence = () => {
    setShowDueDeligenceModal(false);
  };

  const changeStatus = (isActive, id) => {
    const payload = {
      is_active: isActive,
    };
    updateTransportersApi(id, payload).then((response) => {
      if (isActive) {
        toast.success("User Activated!");
      } else {
        toast.info("User Deactivated!");
      }

      getTransportersDetails(id).then((response) => {
        setUserData(response.data);
      });
    });
  };

  const handleDocVeriyChecked = (e) => {
    const { value, checked } = e.target;
    // const { permissions } = userdata;

    console.log(`${value} is ${checked}`);
    if (checked) {
      const payload = {
        is_trade_license_doc_verified: true,
      };
      updateTransportersApi(id, payload).then((response) => {
        toast.success("Doc Verified!");
        getTransportersDetails(id).then((response) => {
          setUserData(response.data);
        });
      });
    }
  };

  function onConfirm() {
    setShowApproveModal(false);
    // toast.warn("Not Implemented!");
    updateTransportessDocVerifyApi(id).then((response) => {
      toast.success("Doc Verified!");
      getTransportersDetails(id).then((response) => {
        setUserData(response.data);
      });
    });
  }

  function ResetPassword(userId){
    getTransporterResetPassword(userId).then((response)=>{
      console.log(response.data)
      toast.success(response.data.message);
    })
  }
  return (
    <>
      <div>
        <ContentHeader title="Transporters" />
        {isPageLoagin ? (
          <PageLoader />
        ) : (
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <h5 style={{ textDecoration: "underLine" }}>
                    Trips and Business Info
                  </h5>
                  <div className="card">
                    <div className="card-body p-0">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Total Trips Quoted</th>
                            <th>Total Trips Confrmed</th>
                            <th>Business Done</th>
                            <th>Amount Paid</th>
                            <th>Due to Transporters</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{userData.total_trips ?? "0"}</td>
                            <td>{userData.total_trips_confirmed ?? "0"}</td>
                            <td>
                              <b>{userData.business_given ?? "0"} OMR</b>
                            </td>
                            <td className="text-success">
                              <b>{userData.paid_to_trux ?? "0"} OMR</b>
                            </td>
                            <td className="text-danger">
                              <b>{userData.current_due_to_trux ?? "0"} OMR</b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="card card-primary">
                    <div className="card-body box-profile">
                      <div className="text-center">
                        <img
                          className="img-fluid"
                          src="/img/avatar.png"
                          alt="User profile picture"
                        />
                      </div>
                      <h3 className="profile-username text-center">
                        {userData.contact_person}
                      </h3>
                      <h3 className="profile-username text-center">
                        {userData.status === "In Active" && (
                          <button
                            type="button"
                            className="btn btn-warning"
                            onClick={(e) => changeStatus(true, id)}
                          >
                            In Active
                          </button>
                        )}
                        {userData.status === "Active" && (
                          <button
                            type="button"
                            className="btn btn-success"
                            onClick={(e) => changeStatus(false, id)}
                          >
                            Active
                          </button>
                        )}
                      </h3>
                    </div>
                  </div>
                </div>

                <div className="col-md-9">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <ul className="list-group">
                            <li className="list-group-item border-0">
                              <div className="row">
                                <div className="col-md-6 col-6">
                                  <b>Company Name</b>
                                </div>
                                <div className="col-md-6 col-6">
                                  <h6>{userData.company_name ?? "-"}</h6>
                                </div>
                              </div>
                            </li>

                            <li className="list-group-item border-0">
                              <div className="row">
                                <div className="col-md-6 col-6">
                                  <b>Contact Person</b>
                                </div>
                                <div className="col-md-6 col-6">
                                  <h6>{userData.contact_person ?? "-"}</h6>
                                </div>
                              </div>
                            </li>

                            <li className="list-group-item border-0">
                              <div className="row">
                                <div className="col-md-6 col-6">
                                  <b>Email Address</b>
                                </div>
                                <div className="col-md-6 col-6">
                                  <h6>{userData.email ?? "-"}</h6>
                                </div>
                              </div>
                            </li>

                            <li className="list-group-item border-0">
                              <div className="row">
                                <div className="col-md-6 col-6">
                                  <b>Trade/Commercial License</b>
                                </div>
                                <div className="col-md-6 col-6">
                                  <h6>
                                  <Accordion defaultActiveKey="0">
                                    <Accordion.Toggle as={Link} variant="link" eventKey="1">
                                      {/* <i className="fas fa-plus"></i> */}
                                      <a 
                                      className="btn btn-link p-0"
                                      style={{ textDecoration: "underline" }}
                                      >
                                        View documents
                                      </a>
                                    </Accordion.Toggle>
                                      {userData.trade_license_doc &&
                                        userData.trade_license_doc.map(
                                        (item, i) => {
                                          return (
                                            <Accordion.Collapse eventKey="1">
                                              
                                              <a
                                                className="btn btn-link p-0"
                                                onClick={(e) =>
                                                  getTransporterLicenseDoc(
                                                    id,
                                                    item.id,
                                                    item.file_ext
                                                  )
                                                }
                                              >
                                                <i className="far fa-fw fa-file-pdf" />
                                                {`trade-license-${item.id}.${item.file_ext}`}
                                              </a>
                                            </Accordion.Collapse>
                                          );
                                        }
                                      )}
                                  </Accordion>
                                  </h6>
                                  {userData.trade_license_doc &&
                                  userData.is_trade_license_doc_verified ? (
                                    <div className="custom-control custom-checkbox">
                                      <input
                                        className="custom-control-input custom-control-input-success custom-control-input-outline"
                                        type="checkbox"
                                        id="customCheckbox5"
                                        checked
                                        disabled
                                      />
                                      <label
                                        htmlFor="customCheckbox5"
                                        className="custom-control-label text-success"
                                      >
                                        Admin Verified
                                      </label>
                                    </div>
                                  ) : ( 
                                    userData.trade_license_doc && userData.trade_license_doc.length > 0 ? (
                                      <a
                                        className="btn btn-primary"
                                        onClick={(e) => {
                                          setShowApproveModal(true);
                                        }}
                                      >
                                        Verify
                                      </a>
                                    ) : (
                                      
                                      ' '
                                    )                                                                                                    
                                    // <div className="custom-control custom-checkbox">
                                    //   <input
                                    //     className="custom-control-input custom-control-input-info custom-control-input-outline"
                                    //     type="checkbox"
                                    //     id="customCheckbox5"
                                    //     onChange={handleDocVeriyChecked}
                                    //   />
                                    //   <label
                                    //     htmlFor="customCheckbox5"
                                    //     className="custom-control-label"
                                    //   >
                                    //     Admin Verified
                                    //   </label>
                                    // </div>
                                  )}
                                </div>
                              </div>
                            </li>

                            <li className="list-group-item border-0">
                              <div className="row">
                                <div className="col-md-6 col-6">
                                  <b>Enrolled On</b>
                                </div>
                                <div className="col-md-6 col-6">                                
                                  <h6>
                                    {userData.enrolled_on ?
                                     (
                                        new Date(
                                        userData.enrolled_on
                                        ).toLocaleDateString("en-GB", 
                                        { day: "2-digit", month: "2-digit", year: "numeric" }) 
                                      ) : "-"
                                    }
                                  </h6>
                                </div>
                              </div>
                            </li>

                            <li className="list-group-item border-0">
                              <div className="row">
                                <div className="col-md-6 col-6">
                                  <b>Total Trucks</b>
                                </div>
                                <div className="col-md-6 col-6 p-0 m-0">
                                  <h6
                                    className="btn btn-link"
                                    onClick={truckModalDetails}
                                   >
                                    {userData.total_trucks ?? "0"}
                                  </h6>
                                </div>
                              </div>
                            </li>
                          </ul>

                          {/* <strong>Company Name</strong>
                          <p>
                            <h6>{userData.company_name}</h6>
                          </p>
                          <strong>Company Type</strong>
                          <p>
                            <h6>{userData.type}</h6>
                          </p>
                          <strong>Email Address</strong>
                          <p>
                            <h6>{userData.email_id}</h6>
                          </p>
                          <strong>Contact No</strong>
                          <p>
                            <h6>
                              {userData.country_code}-{userData.contact_no}
                            </h6>
                          </p> */}
                        </div>

                        <div className="col-md-6">
                          <ul className="list-group">
                            <li className="list-group-item border-0">
                              <div className="row">
                                <div className="col-md-6 col-6">
                                  <b>Transporter</b>
                                </div>
                                <div className="col-md-6 col-6">
                                  <h6>{userData.company_name ?? "-"}</h6>
                                </div>
                              </div>
                            </li>

                            <li className="list-group-item border-0">
                              <div className="row">
                                <div className="col-md-6 col-6">
                                  <b>Contact No</b>
                                </div>
                                <div className="col-md-6 col-6">
                                  <h6>
                                    +{userData.country_code}-
                                    {userData.contact_no}
                                  </h6>
                                </div>
                              </div>
                            </li>

                            <li className="list-group-item border-0">
                              <div className="row">
                                <div className="col-md-6 col-6">
                                  <b>Password</b>
                                </div>
                                <div className="col-md-6 col-6">
                                  <a
                                    onClick={()=>ResetPassword(id)}
                                    className="btn btn-link p-0"
                                    style={{ textDecoration: "underline" }}
                                  >
                                    Reset Password
                                  </a>
                                </div>
                              </div>
                            </li>

                            <li className="list-group-item border-0">
                              <div className="row">
                                <div className="col-md-6 col-6">
                                  <b>Trade/Commercial License No</b>
                                </div>
                                <div className="col-md-6 col-6">
                                  <h6>{userData.trade_license_no}</h6>
                                </div>
                              </div>
                            </li>

                            <li className="list-group-item border-0">
                              <div className="row">
                                <div className="col-md-6 col-6">
                                  <b>Total Trips</b>
                                </div>
                                <div className="col-md-6 col-6">
                                  <h6>{userData.total_trips ?? "0"}</h6>
                                </div>
                              </div>
                            </li>

                            <li className="list-group-item border-0">
                              <div className="row">
                                <div className="col-md-6 col-6">
                                  <b>Total Drivers</b>
                                </div>
                                <div className="col-md-6 col-6">
                                  <h6
                                    className="btn btn-link p-0 m-0"
                                    onClick={driverModalDetails} 
                                   >
                                    {userData.total_drivers ?? "0"}
                                  </h6>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      <h5>
                        <span style={{ textDecoration: "underLine" }}>
                          Due Deligence
                        </span>{" "}
                        &nbsp;
                        <button
                          className="btn btn-info"
                          onClick={handleAssignSpoc}
                        >
                          Assign SPOC
                        </button>
                      </h5>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body p-0">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Assigned SPOC</th>
                            <th>Status</th>
                            <th>Due Deligence Report</th>
                            {/* <th>Credit Duration</th> */}
                            <th>Authorised Signatory</th>
                            <th>Designation</th>
                          </tr>
                        </thead>
                        <tbody>
                          {userData.due_diligence && (
                            <tr>
                              <td>
                                {userData.due_diligence.spoc
                                  ? userData.due_diligence.spoc.name ?? "-"
                                  : "-"}
                              </td>
                              <td>
                                {userData.due_diligence.spoc?.name ? (
                                  <>
                                    {userData.due_diligence.report
                                      ?.length &&
                                    userData.due_diligence.report?.length > 0
                                       ? (
                                      <>Completed</>
                                    ) : (
                                      <a
                                        href="#"
                                        onClick={() => handleDueDeligence()}
                                      >
                                        In Progress
                                      </a>
                                    )}
                                  </>
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td>
                                {userData.due_diligence.spoc
                                  ? userData.due_diligence.report &&
                                    userData.due_diligence.report.map(
                                      (item, i) => {
                                        return (
                                          <>
                                          <a
                                            className="btn btn-link p-0"
                                            onClick={(e) =>
                                              getDueDiligenceReport(
                                                userData.due_diligence.id,
                                                item.id
                                              )
                                            }
                                          >
                                            <i className="far fa-fw fa-file-pdf" />
                                            {`report-${item.id}.${item.file_ext}`}
                                          </a>
                                          <br />
                                        </>
                                        );
                                      }
                                    )
                                  : "-"}
                              </td>
                              {/* <td>
                                {userData.due_diligence.spoc
                                  ? userData.due_diligence.credit_duration ??
                                    "-"
                                  : "-"}
                              </td> */}
                              <td>
                                {userData.due_diligence.spoc
                                  ? userData.due_diligence
                                      .authorised_signatory ?? "-"
                                  : "-"}
                              </td>
                              <td>
                                {userData.due_diligence.spoc
                                  ? userData.due_diligence.designation ?? "-"
                                  : "-"}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h5 style={{ textDecoration: "underLine" }}>Trips History</h5>
                  <div className="card">
                    {/* <div className="card-header">
                      <h3 className="card-title">Trip History</h3>
                    </div> */}
                    {/* <div className="card-body">
                      
                    </div> */}
                    <MaterialTable
                      style={{ backgroundColor: "white" }}
                      columns={columns}
                      data={data}
                      title=""
                      options={{
                        exportAllData: true,
                        exportMenu: [
                          {
                            label: "Export CSV",
                            exportFunc: (cols, datas) =>
                              ExportCsv(cols, datas, "trux"),
                          },
                        ],
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {showAssignSpocModal && (
              <AssignSpocModal
                onClose={onClose}
                show={showAssignSpocModal}
                id={id}
              />
            )}
            {showDueDeligenceModal && (
              <DuedeligenceModal
                show={showDueDeligenceModal}
                handleClose={onCloseDueDeligence}
                userId={id}
                dueDiligence={userData.due_diligence}
                existTradeDoc={userData.trade_license_doc}
              />
            )}
            {showApproveModal && (
              <ApprovalConfirmModal
                show={showApproveModal}
                onClose={onClose}
                onConfirm={onConfirm}
              />
            )}
            {driverModal && (
               <DriverModal
                 show ={driverModal}
                 handleClose={onClose}
                 userId={id}
               />
            )}
            {truckModal && (
               <TruckModal
                 show ={truckModal}
                 handleClose={onClose}
                 userId={id}
               />
            )}                        
          </section>
        )}
      </div>
    </>
  );
};

export default TransporterDetails;
