import ContentHeader from "../../components/content-header/ContentHeader";
import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { hasPermission } from "../../utils/permissions";
import {
    addMasterSupportApi,
    getSupportdetails,
    updateSupportdetails,
  } from "../../services/data/masters/support";
import PageLoader from "../../components/PageLoader";
import { toast } from "react-toastify";
import MaterialTable, { Column } from "@material-table/core";


const ManageSupport = () => {

  const [userdata, setUserData] = useState(
    {
     name: "",
     email:"",
     subject:"",
     message:"",
     contact_no:""
    });

  const{
    name,
    email,
    subject,
    message,
    contact_no,
  } = userdata;

  const [isPageLoading, setIsPageLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();

  const handleReset = () => {
    setUserData({
        name: "",
        email:"",
        subject:"",
        message:"",
        contact_no:""    
    });
  };

  const dataHandle = (e) => {
    console.log(e.target.value);
    setUserData({ ...userdata, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();
    // console.log(userdata);
    // setIsLoading(true);
    if (id) {
      await updateSupportdetails(id, userdata).then((response) => {
        console.log(userdata)
        if (response.status >= 200) {
          toast.success(response.data.message);
          navigate("/faq");
        } else {
          toast.error(response.data.message);
          setIsPageLoading(false);
        }
      });
    } else {
      await addMasterSupportApi(userdata).then((response) => {
        if (response.status === 201) {
          toast.success(response.data.message);         
          navigate("/support");          
        } else {
          toast.error(response.data.message);
          setIsPageLoading(false);
        }
      });
    }
  };

  useEffect(() => {    
    if (id) {
        getSupportdetails(id).then((response) => {                
        setUserData({
            name:response.data.name,
            email:response.data.email,
            subject:response.data.subject,
            message:response.data.message,
            contact_no:response.data.contact_no         
        });        
        setIsPageLoading(false);
      });
    } else {
      handleReset();
      setIsPageLoading(false);
    }
  }, []);
  return (
    <div>
      <ContentHeader title={id ? `Edit Support` : `Support` } />
      {isPageLoading ? 
      (<PageLoader/>
      ):(
        <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title float-right">
                <Link 
                  className="btn btn-primary"
                  to="/support"
                >
                  Back
                </Link>
              </h3>
              
            </div>
            <div className="card-body">
              <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 col-12">
                        <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            name="name"                       
                            value={name}
                            placeholder="Name"
                            onChange={dataHandle}
                            className="form-control"
                        />
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            name="email"                       
                            value={email}
                            placeholder="Email"
                            onChange={dataHandle}
                            className="form-control"
                        />
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="form-group">
                        <label htmlFor="contact_no">Contact No</label>
                        <input
                            type="number"
                            name="contact_no"                       
                            value={contact_no}
                            placeholder="Contact No"
                            onChange={dataHandle}
                            className="form-control"
                        />
                        <b className="text-danger">
                        {contact_no.length > 10
                            ? "Mobile number too long. Maximum 10 digits allowed."
                            : ""}
                        </b>
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="form-group">
                        <label htmlFor="Message">Subject</label>
                        <input
                            type="text"
                            name="subject"                       
                            value={subject}
                            placeholder="Subject"
                            onChange={dataHandle}
                            className="form-control"
                        />
                        </div>
                    </div>
                    <div className="col-12">                                                                            
                        <div className="form-group">
                        <label htmlFor="Message">Message</label>
                        <textarea
                            type="text"
                            name="message"
                            rows = "5"
                            cols = "12"
                            value={message}
                            placeholder="Message"
                            onChange={dataHandle}
                            className="form-control"
                        />
                        </div>
                    </div>
                </div>
              </div>
            <div className=" text-right">
              <button
                type="reset"
                className="btn btn-warning mr-3"
                onClick={handleReset}
              >
                Reset
              </button>
              {/* {hasPermission("customers.create") && (
                <button
                  type="reset"
                  className="btn btn-warning mr-3"
                  // onClick={handleReset}
                >
                  Reset
                </button>
              )} */}
               <button
                type="button"
                className="btn btn-info mr-3"
                onClick={handleSubmit}
               >
                Submit
              </button>
              {/* {hasPermission("customers.edit") &&
                hasPermission("customers.create") && (
                  <button
                    type="button"
                    className="btn btn-info mr-3"
                    // onClick={handleSubmit}
                  >
                    Submit
                  </button>
                )} */}
            </div>
            </div>

          </div>
        </div>
        </section>
      )
       }
      
    </div>
  );
};

export default ManageSupport;
