// import { useSelector } from 'react-redux';

// const authentication = useSelector((state) => state.auth.authentication);
// const permissions = authentication.permissions

// export function hasPermission(action) {
//   const permissions = JSON.parse(
//     localStorage.getItem("authentication")
//   ).permissions; 
//   return permissions.includes(action);
// }

export function hasPermission(action) {
  const authData = localStorage.getItem("authentication");
  // console.log("authData",authData)
  if (authData) {
    const { permissions } = JSON.parse(authData);
    if (permissions && Array.isArray(permissions)) {
      return permissions.includes(action);
    }
  }
  return false
}

export function hasRole(roleOrRoles) {
  const authData = localStorage.getItem("authentication");
  if (authData) {
    const { role: userRole } = JSON.parse(authData);
    return Array.isArray(roleOrRoles) ? roleOrRoles.includes(userRole) : roleOrRoles === userRole;
  }
  return false;
}