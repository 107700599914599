import React from "react";
import ContentHeader from "../../components/content-header/ContentHeader";
import { useParams } from "react-router-dom";

const ManageTrip = () => {
  const { id } = useParams();

  return (
    <div>
      <ContentHeader title={id ? "Edit Trip" : "New Trip"} />
      ManageTrip
    </div>
  );
};

export default ManageTrip;