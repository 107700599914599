import ContentHeader from "../../components/content-header/ContentHeader";
import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { hasPermission } from "../../utils/permissions";
import {
    addMasterFAQApi,
    getFAQdetails,
    updateFAQdetails,
  } from "../../services/data/masters/faq";
import PageLoader from "../../components/PageLoader";
import { toast } from "react-toastify";
import MaterialTable, { Column } from "@material-table/core";


const ManageFAQ = () => {

  const [userdata, setUserData] = useState(
    {
     question: "",
     answer:"",  
    });

  const{
    question,
    answer
  } = userdata;

  const [isPageLoading, setIsPageLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();

  const handleReset = () => {
    setUserData({
      question: "",
      answer:"",     
    });
  };

  const dataHandle = (e) => {
    console.log(e.target.value);
    setUserData({ ...userdata, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();
    // console.log(userdata);
    // setIsLoading(true);
    if (id) {
      await updateFAQdetails(id, userdata).then((response) => {
        console.log(userdata)
        if (response.status >= 200) {
          toast.success(response.data.message);
          navigate("/faq");
        } else {
          toast.error(response.data.message);
          setIsPageLoading(false);
        }
      });
    } else {
      await addMasterFAQApi(userdata).then((response) => {
        if (response.status === 201) {
          toast.success(response.data.message);         
          navigate("/faq");          
        } else {
          toast.error(response.data.message);
          setIsPageLoading(false);
        }
      });
    }
  };

  useEffect(() => {    
    if (id) {
      getFAQdetails(id).then((response) => {                
        setUserData({
          question:response.data.question,
          answer:response.data.answer,           
        });        
        setIsPageLoading(false);
      });
    } else {
      handleReset();
      setIsPageLoading(false);
    }
  }, []);
  return (
    <div>
      <ContentHeader title={id ? `Edit FAQ` : `FAQ` } />
      {isPageLoading ? 
      (<PageLoader/>
      ):(
        <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title float-right">
                <Link 
                  className="btn btn-primary"
                  to="/faq"
                >
                  Back
                </Link>
              </h3>
              
            </div>
            <div className="card-body">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="Message">Question</label>
                      <input
                        type="text"
                        name="question"                       
                        value={question}
                        placeholder="Question"
                        onChange={dataHandle}
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="Message">Answer</label>
                      <textarea
                        type="text"
                        name="answer"
                        rows = "5"
                        cols = "12"
                        value={answer}
                        placeholder="Answer"
                        onChange={dataHandle}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer text-center">
              <button
                type="reset"
                className="btn btn-warning mr-3"
                onClick={handleReset}
              >
                Reset
              </button>
              {/* {hasPermission("customers.create") && (
                <button
                  type="reset"
                  className="btn btn-warning mr-3"
                  // onClick={handleReset}
                >
                  Reset
                </button>
              )} */}
               <button
                type="button"
                className="btn btn-info mr-3"
                onClick={handleSubmit}
               >
                Submit
              </button>
              {/* {hasPermission("customers.edit") &&
                hasPermission("customers.create") && (
                  <button
                    type="button"
                    className="btn btn-info mr-3"
                    // onClick={handleSubmit}
                  >
                    Submit
                  </button>
                )} */}
            </div>
          </div>
        </div>
        </section>
      )
       }
      
    </div>
  );
};

export default ManageFAQ;
