import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ContentHeader from "../../components/content-header/ContentHeader";
import PageLoader from '../../components/PageLoader';
import {
  addMastertermsandconditionsApi,
  getAllTermsandConditions
} from "../../services/data/masters/termsandconditions";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import ContentDisplay from '../../components/content-header/ContentDisplay';

const EditTermsAndConditions = () => {
  const [userdata, setUserData] = useState({message: "",});
  const {message} = userdata;
  const [isloading, setIsLoading] = useState(true)  
  const navigate = useNavigate();
   
  const handleChange = (newValue) => {
    setUserData({...userdata, message: newValue});
  };
 
  useEffect(() => {
    getAllTermsandConditions().then((res) => {      
      setUserData({ message: res.data.message });
      setIsLoading(false);
    });
  }, []);
 
  const handleSubmit = async (e) => { 
      if (message){
        await addMastertermsandconditionsApi(userdata).then((response) => {       
          if (response.status === 200) {
            toast.success(response.data.message);
            navigate('/terms-and-conditions')                      
          } else {
            toast.error(response.data.message);
            setIsLoading(false);
          }
      });   
      } 
      else{
        alert("No Data")
      }              
  };

  return (
    <>
    <ContentHeader title={` Edit Terms & Conditions` } />
    {
      isloading ? (<PageLoader/>)
      :
      (
        <section className="content">
        <div className='card'>
          <div className='card-body'>
          <div className="float-right">
            <Link
              to="/terms-and-conditions"
              className="btn btn-primary"
            >
              Back
            </Link>
          </div>
            <ReactQuill
            theme= 'snow'
            value={userdata.message}
            onChange={handleChange}
            modules={{
              toolbar: [
                [{ header: [1, 2, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [
                  { list: 'ordered' },
                  { list: 'bullet' },
                  { indent: '-1' },
                  { indent: '+1' },
                ],
                ['link', 'image'],
                [{ color: ['#FF0000', '#00FF00', '#0000FF', '#FFA500', '#800080', 'black'] }, { background: ['#FFFF00', '#00FFFF', '#FF00FF', '#FFC0CB', '#D3D3D3'] }],
                ['clean'],
              ],
            }}
            formats={[
              'header',
              'bold',
              'italic',
              'underline',
              'strike',
              'blockquote',
              'list',
              'bullet',
              'indent',
              'link',
              'image',
              'color', 
              'background', 
            ]}           
          />           
          </div>
          <div className='card-footer text-center'>
          <button
            type="button"
            className="btn btn-info mr-3"
            onClick={handleSubmit}
            >
            Submit
          </button>
          </div>       
        </div>
        </section>
      )
    }   
    </>
    
  );
};

export default EditTermsAndConditions;