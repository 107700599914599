import ContentHeader from "../../components/content-header/ContentHeader";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "../../services/common/globalvars";
import { getAllUserRoleManagementApi } from "../../services/data/userrolemanagement";
import Select from "react-select";
import {
  addUserManagementApi,
  getUserManagementDetails,
  updateUserApi,
} from "../../services/data/usermanagement";

const NewUserManagement = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [roleOptions, setRoleOptions] = useState([]);

  useEffect(() => {
    getAllUserRoleManagementApi().then((response) => {
      let optionData = [];
      response.data.map((item) =>
        optionData.push({ value: item.id, label: item.name })
      );
      setRoleOptions(optionData);
    });
  }, []);

  // User Management Form data
  const [userdata, setUserData] = useState({
    first_name: "",
    last_name: "",
    designation: "",
    gender: "",
    email: "",
    contact_no: "",
    password: "",
    role_id: "",
    joining_date: "",
    address: "",
  });

  useEffect(() => {
    getUserManagementDetails(id).then((response) => {
      setUserData({
        first_name: response.data.firstname,
        last_name: response.data.lastname,
        designation: response.data.designation,
        gender: response.data.gender,
        email: response.data.email_id,
        contact_no: response.data.contact_no,
        role_id: response.data.role_id,
        joining_date: new Date(response.data.joining_date).toLocaleDateString(
          "en-CA"
        ),
        address: response.data.address,
      });
    });
  }, [id]);

  const {
    first_name,
    last_name,
    designation,
    gender,
    email,
    contact_no,
    password,
    role_id,
    joining_date,
    address,
  } = userdata;

  // const fileHandle = (e) => {
  //     console.log(e);
  //     setUserData({
  //       ...userdata,
  //       [e.target.name]: e.target.files[0],
  //       trade_license_doc_name: e.target.files[0].name,
  //     });
  //   };

  const dataHandle = (e) => {
    console.log(e);
    setUserData({ ...userdata, [e.target.name]: e.target.value });
  };

  const selectRoleHandle = (e) => {
    console.log(e);
    setUserData({ ...userdata, role_id: e.value });
  };

  const handleReset = () => {
    setUserData({
      first_name: "",
      last_name: "",
      designation: "",
      gender: "",
      email: "",
      contact_no: "",
      password: "",
      role_id: "",
      joining_date: "",
      address: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(userdata);
    setIsLoading(true);
    if (id) {
      updateUserApi(id, userdata).then((response) => {
        if (response.status >= 200) {
          toast.success(response.data.message);
          navigate("/users");
        } else {
          toast.error(response.data.message);
          setIsLoading(false);
        }
      });
    } else {
      addUserManagementApi(userdata).then((response) => {
        if (response.status === 201) {
          toast.success(response.data.message);
          navigate("/users");
        } else {
          toast.error(response.data.message);
          setIsLoading(false);
        }
      });
    }
  };

  return (
    <>
      <div>
        <ContentHeader title="User Management" />
        <section className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">User Management Form</h3>
              </div>
              <div className="card-body">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="first_name">First Name</label>
                        <input
                          type="text"
                          name="first_name"
                          value={first_name}
                          placeholder="First Name"
                          onChange={dataHandle}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="last_name">Last Name</label>
                        <input
                          type="text"
                          name="last_name"
                          value={last_name}
                          placeholder="Last Name"
                          onChange={dataHandle}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="designation">
                          Designation (Optional)
                        </label>
                        <input
                          type="text"
                          name="designation"
                          value={designation}
                          placeholder="Designation"
                          onChange={dataHandle}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="gender">Select Gender</label>
                        <select
                          name="gender"
                          value={gender}
                          onChange={dataHandle}
                          className="form-control"
                        >
                          <option value="">Select Gender</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          <option value="others">others</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="email">Email Id</label>
                        <input
                          type="text"
                          name="email"
                          value={email}
                          placeholder="Email Id"
                          onChange={dataHandle}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input
                          type="password"
                          name="password"
                          value={password}
                          placeholder="Login Password"
                          onChange={dataHandle}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="contact_no">Contact No</label>
                        <input
                          type="tel"
                          name="contact_no"
                          value={contact_no}
                          placeholder="Contact No"
                          onChange={dataHandle}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="role_id">User Role</label>
                        <Select
                          options={roleOptions}
                          name="role_id"
                          onChange={selectRoleHandle}
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="joining_date">Joining Date</label>
                        <input
                          type="date"
                          name="joining_date"
                          value={joining_date}
                          placeholder="Joining Date"
                          onChange={dataHandle}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="address">Address</label>
                        <input
                          type="text"
                          name="address"
                          value={address}
                          placeholder="Address"
                          onChange={dataHandle}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-footer">
                <button
                  type="reset"
                  className="btn btn-default"
                  onClick={handleReset}
                >
                  Reset
                </button>
                <button
                  type="button"
                  className="btn btn-primary float-right"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default NewUserManagement;
