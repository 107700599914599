import ContentHeader from "../../components/content-header/ContentHeader";
import "../../styles/Customer.css";
import React, { useState, useRef, useEffect } from "react";
import MaterialTable, { Column } from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import PageLoader from "../../components/PageLoader";
import {
    getAllPackageType,  
    deletePackageType,
    updatePackageTypedetails,
    getPackageTypedetails,
} from "../../services/data/masters/packagetype";
import { hasPermission } from "../../utils/permissions";
import { Link } from "react-router-dom";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";
import { toast } from "react-toastify";


const PackageType = () => {
  const [data, setData] = useState([]); 
  const [isLoading, setIsLoading] = useState(true);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [deletePackageTypeId, setDeletePackageTypeId] = useState();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [activeStatus, SetActiveStatus] =  useState('');

  console.log('packagetype',data)
  function onConfirm() {    
    // toast.warn("Not Implemented!");
    deletePackageType(deletePackageTypeId).then((response) => {
      toast.success("Package Type Deleted");
      setShowDeleteConfirm(false);
    });
  }
  
  function onClose() {
    setShowDeleteConfirm(false);    
  }

  const changeStatus = (isActive, id) => {
    const payload = {
      is_active: isActive,
    };
    updatePackageTypedetails(id, payload).then((response) => {
      if(isActive){
        toast.success("Package Type Activated!");
      }else{
        toast.info("Package Type Deactivated!");
      }
      
      getAllPackageType().then((response) => {
        setData(response.data);
        SetActiveStatus(isActive)
      });
    });
  };

  const columns = [
    {      
        title: "Package Type",
        render: (rowData)=>{
        return(
            <div>
                <p>
                    <b>{rowData.name}</b>
                </p>
                <p>
                    <Link
                        className="btn ml-0 pl-0 mr-0 pr-0"
                        to={`/managepackagetype/${rowData.id}`}
                    >
                        {/* <i className="fas fa-edit text-info" /> */}
                        <i className="fas fa-pencil-alt text-info"></i>
                    </Link>{" "}
                    {/* {hasPermission("users.delete") && (
                        <button
                        className="btn  mr-0 pr-0"
                        onClick={(e) => {
                            setDeletePackageTypeId(rowData.id);
                            setShowDeleteConfirm(true);
                        }}
                        >
                        <i className="fas fa-times text-danger"></i>                        
                        </button>
                    )}{" "}                         */}
                </p>
            </div>
          )
        }
    }, 
    {
        title: "Nature of Cargo",
        render:(rowData)=>{
            return(
                <>
                  {rowData.nature_of_cargo?.name ?? '-'}
                </>
            )
        }
    },
   
    { 
        title: "Status", 
        render : (rowData)=>{
          return(
            // <h3>
            //     {rowData.status.trim() === "In Active" && "In Active"}
            // </h3>
                
            <h3>
                {rowData.status === "In Active" && (
                <button
                    type="button"
                    className="btn btn-warning"
                    onClick={(e) => changeStatus(true, rowData.id)}
                >
                    In Active
                </button>
                )}
                {rowData.status === "Active" && (
                <button
                    type="button"
                    className="btn btn-success"
                    onClick={(e) => changeStatus(false, rowData.id)}
                >
                    Active
                </button>
                )} 
           </h3>
          )
        }         
    }, 
  ]
  useEffect(() => {
    setIsLoading(true);
    getAllPackageType().then((response) => {
      setData(response.data);
      setIsPageLoading(false);
      setIsLoading(false);
    });
  }, [showDeleteConfirm,activeStatus]);
  return (
    <div>
      <ContentHeader title="Package Type" />
      {
        isPageLoading?
        (PageLoader):(
          <section className="content">
            <div className="container-fluid">
              <div className="card">
                <div className="card-header">
                  {/* <h3 className="card-title">Title</h3> */}
                  <div className="float-right">
                    <Link
                      to="/managepackagetype"
                      className="btn btn-info"
                    >
                      <i className="fas fa-plus" /> Add Package Type
                    </Link>
                  </div>
                </div>
                <div className="card-body p-0">
                  <MaterialTable
                    // id="pdfDatas"
                    style={{ backgroundColor: "white" }}
                    columns={columns}
                    isLoading={isLoading}
                    data={data}
                    title=""
                    options={{
                      exportAllData: true,
                      exportMenu: [
                        {
                          label: "Export CSV",
                          exportFunc: (cols, datas) =>
                            ExportCsv(cols, datas, "trux"),
                        },
                      ],
                    }}
                  />
                </div>
                {/* <div className="card-footer">Footer</div> */}
              </div>
            </div>
            {showDeleteConfirm && (
            <DeleteConfirmModal
              show={showDeleteConfirm}
              onClose={onClose}
              onConfirm={onConfirm}
            />
            )} 
          </section>
        )
      }
     
    </div>
  );
};

export default PackageType;
