import http from "../../common/http-common";

export async function addMasterFAQApi(data){
    return await http.post(`/api/v1/faq`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-type": "multipart/form-data",
      },
    } )
} 

export async function getAllFAQ() {
    return await http.get(`/api/v1/faq`);
  }

export async function getFAQdetails(id) {
    return await http.get(`/api/v1/faq/${id}`);
}

export async function updateFAQdetails(id, data) {
    return await http.put(`/api/v1/faq/${id}`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-type": "multipart/form-data",
      },
    });
  }

  export async function deleteFAQ(id) {
    return await http.delete(`/api/v1/faq/${id}`);
  }



 