import ContentHeader from "../../components/content-header/ContentHeader";
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "../../styles/Transporters.css";
import axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "../../services/common/globalvars";
import {
  addTransportersApi,
  getTransporterLicenseDoc,
  getTransportersDetails,
  updateTransportersApi,
  getTransporterResetPassword,
} from "../../services/data/transporters";
import Spinner from "react-bootstrap/Spinner";
import PageLoader from "../../components/PageLoader";
import { 
   getMasterCounteryCode,
   getGeneralCommisionApi
  } from "../../services/data/masters";

const ManageTransporter = () => {
  // User Transporter Form data
  const navigate = useNavigate();
  const { id } = useParams();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [countryCode, setCountryCode] = useState();
  const [generalCommsion, setGeneralCommsion] = useState()

  const [userdata, setUserData] = useState({
    company_name: "",
    company_address: "",
    contact_person: "",
    type: "",
    email: "",
    password: "",
    cpassword: "",
    country_code: "",
    contact_no: "",
    trade_license_no: "",
    // Optional
    trade_license_validity: "",
    trade_license_doc: [],
    commission: 7,
    website: "",
  });

  const {
    company_name,
    company_address,
    contact_person,
    type,
    email,
    password,
    cpassword,
    country_code,
    contact_no,
    trade_license_no,
    trade_license_doc,
    trade_license_validity,
    commission,
    website,
  } = userdata;

  const dataHandle = (e) => {
    console.log(e);
    setUserData({ ...userdata, [e.target.name]: e.target.value });
  };

  const fileHandle = (e) => {
    const files = Array.from(e.target.files);
    setUserData({
      ...userdata,
      [e.target.name]: [...files],
    });

    // const files = Array.from(e.target.files)
    // console.log(files ,'ak')
    // setUserData({
    //   ...userdata,
    //   [e.target.name]: e.target.files[0],
    //   trade_license_doc_name: e.target.files[0].name,
    // });
  };
  const handleReset = () => {
    setUserData({
      company_name: "",
      company_address: "",
      contact_person: "",
      type: "singleuser",
      email: "",
      password: "",
      cpassword: "",
      country_code: "",
      contact_no: "",
      trade_license_no: "",
      // Optional
      trade_license_validity: "",
      trade_license_doc: [],
      commission: userdata.commission,
      website: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(userdata);
    setIsLoading(true);

    if (!company_address || !country_code || !email || !contact_no || !company_name || !trade_license_no || !trade_license_doc.length) {
      toast.error("Please fill in all required fields.");
      setIsLoading(false);
      return;
    }
    
    if (!id && (!password || !cpassword)) {
      toast.error("Please fill the Password");
      setIsLoading(false);
      return;
    }

    if (!id && (password !== cpassword)) {
      toast.error("Passwords do not match.");
      setIsLoading(false);
      return;
    }

    if (contact_no.length > 10) {
      toast.error("Mobile number too long. Maximum 10 digits allowed.");
      setIsLoading(false);
      return;
    }

    if (id) {
      updateTransportersApi(id, userdata).then((response) => {
        console.log('update transporter details', response.data)
        if (response.status >= 200) {
          toast.success(response.data.message);
          navigate("/transporters");
        } else {
          toast.error(response.data.message);
          setIsLoading(false);
        }
      });
    } else {
      addTransportersApi(userdata).then((response) => {
        console.log('post Tranporter', userdata)
        if (response.status === 201) {
          toast.success(response.data.message);
          navigate("/transporters");
        } else {
          toast.error(response.data.message);
          setIsLoading(false);
        }
      });
    }
  };

  useEffect(() => {
    getMasterCounteryCode().then((response) => {
      setCountryCode(response.data);
    });
    if (id) {
      getTransportersDetails(id).then((response) => {
        console.log("retrival Single Edit Transporter", response.data)
        setUserData({
          company_name: response.data.company_name,
          company_address: response.data.company_address,
          contact_person: response.data.contact_person,
          type: response.data.type,
          email: response.data.email,
          country_code: response.data.country_code,
          contact_no: response.data.contact_no,
          trade_license_no: response.data.trade_license_no,
          // Optional
          trade_license_validity: response.data.trade_license_validity,
          trade_license_doc: response.data.trade_license_doc,
          website: response.data.website,
          commission: response.data.commission,
        });
        setIsPageLoading(false);
      });
    } else {
      // handleReset();
      getGeneralCommisionApi().then((response)=>{
        setUserData((prevUserData) => ({
          ...prevUserData,
          commission: response.data,
        }));
      })
      setIsPageLoading(false);
    }
  }, [id]);

  function ResetPassword(userId){
    getTransporterResetPassword(userId).then((response)=>{
      console.log(response.data)
      toast.success(response.data.message);
    })
  }

  return (
    <>
      <div>
        <ContentHeader title={id ? "Edit Transporter" : "Transporter"} />
        {isPageLoading ? (
          <PageLoader />
        ) : (
          <section className="content">
            <div className="container-fluid">
              <div className="card">
                {/* <div className="card-header">
                  <h3 className="card-title">Transporter Form</h3>
                </div> */}
                <div className="card-body">
                  <div className="container-fluid">
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        {/* <label htmlFor="companyName">Transporter Type</label> */}
                        <div className="row">
                          <div className="col-md-6">
                            <div className="custom-control custom-radio">
                              <input
                                className="custom-control-input"
                                type="radio"
                                id="singleuser"
                                name="type"
                                value="singleuser"
                                checked={type === "singleuser"}
                                onChange={dataHandle}
                              />
                              <label
                                htmlFor="singleuser"
                                className="custom-control-label"
                              >
                                Single User
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="custom-control custom-radio">
                              <input
                                className="custom-control-input"
                                type="radio"
                                id="multiuser"
                                value={"multiuser"}
                                name="type"
                                checked={type === "multiuser"}
                                onChange={dataHandle}
                              />
                              <label
                                htmlFor="multiuser"
                                className="custom-control-label"
                              >
                                Multi User
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="companyName">Company Name</label>
                          <input
                            type="text"
                            name="company_name"
                            value={company_name}
                            placeholder="Company Name"
                            onChange={dataHandle}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="companyName">Company Address</label>
                          <input
                            type="text"
                            name="company_address"
                            value={company_address}
                            placeholder="Company Address"
                            onChange={dataHandle}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="contactPerson">Contact Person</label>
                          <input
                            type="text"
                            name="contact_person"
                            value={contact_person}
                            placeholder="Contact Person"
                            onChange={dataHandle}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="email">Email Address</label>
                        
                            <input
                              type="email"
                              name="email"
                              value={email}
                              placeholder="Email Address"
                              onChange={dataHandle}
                              className="form-control"
                            />
                          
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <div className="row">
                            <div className="col-12 col-md-4">
                              <label htmlFor="contactCode">Country Code</label>
                              <select
                                name="country_code"
                                value={country_code}
                                onChange={dataHandle}
                                className="form-control"
                              >
                                <option value="">Select..</option>
                                {countryCode &&
                                  countryCode.map((item) => {
                                    return (
                                      <option value={item.country_code}>
                                        +{item.country_code + " " + item.country}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                            <div className="col-12 col-md-8">
                              <label htmlFor="contactNo">Contact No</label>
                              <input
                                type="number"
                                name="contact_no"
                                value={contact_no}
                                placeholder="Contact No"
                                onChange={dataHandle}
                                className="form-control"
                              />
                              <b className="text-danger">
                                {contact_no.length > 10
                                  ? "Mobile number too long. Maximum 10 digits allowed."
                                  : ""}
                              </b>
                            </div>
                          </div>
                        </div>
                      </div>
                      {!id ? (
                        <>
                          <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                              <label htmlFor="password">Password</label>
                              <input
                                type="password"
                                name="password"
                                value={password}
                                placeholder="Password"
                                onChange={dataHandle}
                                className="form-control"
                              />
                            </div>
                          </div>

                          <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                              <label htmlFor="password">Conform Password</label>
                              <input
                                type="password"
                                name="cpassword"
                                value={cpassword}
                                placeholder="Conform Password"
                                onChange={dataHandle}
                                className="form-control"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="col-md-6 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <a
                              onClick={()=>ResetPassword(id)}
                              className="btn btn-link form-control"
                              style={{
                                textDecoration: "underline",
                                textAlign: "left",
                                padding: 0,
                              }}
                            >
                              Click here to reset password
                            </a>
                          </div>
                        </div>
                      )}

                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="website">Website (optional)</label>
                          <input
                            type="text"
                            name="website"
                            value={website}
                            placeholder="Website (optional)"
                            onChange={dataHandle}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="tradeLicenseNo">
                            Trade License No
                          </label>
                          <input
                            type="text"
                            name="trade_license_no"
                            value={trade_license_no}
                            placeholder="Trade License No"
                            onChange={dataHandle}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="exampleInputFile">
                            Trade License Document
                            {id && trade_license_doc?.map((item) => {
                                return (
                                  <a
                                    className="btn btn-link p-0"
                                    onClick={(e) =>
                                      getTransporterLicenseDoc(
                                        id,
                                        item.id,
                                        item.file_ext
                                      )
                                    }
                                  >
                                    <i className="far fa-fw fa-file-pdf" />
                                    {`doc-${item.id}.${item.file_ext}`}
                                  </a>
                                );
                              })}
                          </label>
                          <div className="input-group">
                            <div className="custom-file">
                              <input
                                type="file"
                                className="custom-file-input"
                                id="exampleInputFile"
                                name="trade_license_doc"
                                accept="image/*,.pdf"
                                multiple
                                onChange={fileHandle}
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="exampleInputFile"
                              >
                                {trade_license_doc &&
                                  trade_license_doc.map((file) => file.name)}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="tradeLicenseValidityDate">
                            Trade License Validity Date
                          </label>
                          <input
                            type="date"
                            name="trade_license_validity"
                            value={
                              trade_license_validity
                                ? new Date(
                                    trade_license_validity
                                  ).toLocaleDateString("en-CA")
                                : ""
                            }
                            placeholder="dd/mm/yyyy"
                            onChange={dataHandle}
                            className="form-control"
                          />
                        </div>
                      </div> */}

                      <div className="col-md-2 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="commission">Commission</label>
                          <div className="input-group mb-3">
                            <input
                              type="text"
                              name="commission"
                              value={commission}
                              placeholder="commission"
                              onChange={dataHandle}
                              className="form-control"
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                <div className=" text-right">
                  <button
                    type="reset"
                    className="btn btn-warning mr-3"
                    onClick={handleReset}
                  >
                    Reset
                  </button>
                  <button
                    type="button"
                    className="btn btn-info mr-3"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
                </div>

              </div>
            </div>
          </section>
        )}
      </div>
    </>
  );
};

export default ManageTransporter;
