import http from "../common/http-common";

export async function addBookingApi(data) {
  return await http.post("/api/v1/booking", data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
  });
}
export async function updateBookingApi(id, data) {
  return await http.put(`/api/v1/booking?booking_id=${id}`, data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
  });
}
export async function getAllBookingsApi() {
  return await http.get("/api/v1/booking",{
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export async function getBookingApi(id) {
  return await http.get(`/api/v1/booking?booking_id=${id}`,{
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}

export async function deleteBookingApi(id) {
    return await http.delete(`/api/v1/booking?booking_id=${id}`,{
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  }


