import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { startEndTripApi } from "../../services/data/trip";
import { Formik, Form, Field, ErrorMessage } from "formik";
import PageLoader from "../../components/PageLoader";
import { assignTripApi } from "../../services/data/trip";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";

const tripSchema = Yup.object().shape({
    end_image: Yup.array().min(1, "At least one File is required"),  
    end_otp: Yup.string().required("Please Enter the end_otp"),    
  });

const EndTripModal = ({ show, handleClose, tripid }) => {
const [loading, setLoading] = useState(false)
  const initialValues = {
    end_otp:"",
    end_image:[],   
  };
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,        
    errors,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: tripSchema,
    onSubmit: (values) => {       
        startEndTripApi(tripid, 'end', values).then((res)=>{
         if (res.data.status === 'success'){
            toast.success(res.data.message)
            handleClose() 
         }else{
            toast.error(res.data.message)
         }        
        })
        .catch((err)=>{
            toast.error(err.response.data.message)
        })    
    },
  });

  return (
    <Modal show={show} onHide={handleClose}>
        { loading ? <PageLoader loading="Loading..."/>:(
            <>
                <Modal.Header closeButton>
                    <Modal.Title>End Trip</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   <form onSubmit={handleSubmit}>                   
                        <div className="container">
                            <div className="row">                                                        
                                <div className="col-12">
                                    <div className="form-group">
                                            <label htmlFor="end_otp">End otp</label>
                                            <div className="input-group">
                                                <input
                                                className="form-control"
                                                id="end_otp"
                                                type="number"
                                                name="end_otp"
                                                placeholder="Credit Duration"
                                                value={values.end_otp}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                style={{ fontSize: "14px" }}
                                                />                            
                                            </div>
                                            {errors.end_otp && (
                                                <b className="text-danger">{errors.end_otp}</b>
                                            )}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="image">
                                            Image
                                        </label>                                        
                                        <div className="input-group">
                                            <div className="custom-file">
                                                <input
                                                    type="file"
                                                    className="custom-file-input"
                                                    id="image"
                                                    name="end_image"
                                                    multiple
                                                    accept="image/*"
                                                    onBlur={handleBlur}
                                                    onChange={(event) => {
                                                    setFieldValue(
                                                        "end_image",
                                                        Array.from(event.currentTarget.files)
                                                    );
                                                    }}                                               
                                                />
                                                <label
                                                    className="custom-file-label"
                                                    htmlFor="image"
                                                >
                                                    {values.end_image &&
                                                    values.end_image.map(
                                                        (file) => file.name
                                                    )}
                                                </label>
                                            </div>                                            
                                        </div>
                                        {errors.end_image && (
                                                <b className="text-danger">{errors.end_image}</b>
                                            )}
                                    </div>                                
                                </div>                                
                            </div>
                        </div>
                        <Modal.Footer>
                            <button type="submit" className="btn btn-info">
                                Submit
                            </button>
                        </Modal.Footer> 
                    </form>
                </Modal.Body>
            </>
        )}
    </Modal>
  );
};

export default EndTripModal;