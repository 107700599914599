import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ContentHeader from "../../components/content-header/ContentHeader";
import MaterialTable, { Column } from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import {
  getSpocDetails,
  updateSpocApi,
} from "../../services/data/spoc";
import { toast } from "react-toastify";
import Accordion from "react-bootstrap/Accordion";
import {Link} from "react-router-dom"
import PageLoader from "../../components/PageLoader";

const SpocProfile = () => {
  const { id } = useParams(); 
  const [spoc, setSpoc ] = useState();
  const [isLoading, setIsLoading] = useState(true)

  
  console.log('spocprofile',spoc)
  
  useEffect(()=>{
    getSpocDetails(id).then((response)=>{
      console.log(response.data)
      setSpoc(response.data)
      setIsLoading(false)
    })
  },[])
  
  const changeStatus = (isActive, id) => {
    const payload = {
      is_active: isActive,
    };
    updateSpocApi(id, payload).then((response) => {
      if(isActive){
        toast.success("User Activated!");
      }else{
        toast.info("User Deactivated!");
      }
      
      getSpocDetails(id).then((response) => {
        setSpoc(response.data);
      });
    });
  };

  return (
    <>
      <div>
        <ContentHeader title="Spoc" />
        {
          isLoading ? 
          (
           <PageLoader />
          ):(
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-3">
                    <div className="card card-primary">
                      <div className="card-body box-profile">
                        <div className="text-center">
                          <img
                            className="img-fluid"
                            src="/img/avatar.png"
                            alt="Truck picture"
                          />
                        </div>
                        <h3 className="profile-username text-center">
                          {spoc.name}
                         </h3>
                        <h3 className="profile-username text-center">
                          {spoc.status === "In Active" && (
                            <button
                              type="button"
                              className="btn btn-warning"
                              onClick={(e) => changeStatus(true, id)}
                            >
                              In Active
                            </button>
                          )}
                          {spoc.status === "Active" && (
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={(e) => changeStatus(false, id)}
                            >
                              Active
                            </button>
                          )}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12">
                            <ul className="list-group">                              
                              <li className="list-group-item border-0">
                                <div className="row">
                                  <div className="col-md-6 col-6">
                                    <b>Spoc Name</b>
                                  </div>
                                  <div className="col-md-6 col-6">
                                    <h6>{spoc.name ?? '-'}</h6>
                                  </div>
                                </div>
                              </li>
                              <li className="list-group-item border-0">
                                <div className="row">
                                  <div className="col-md-6 col-6">
                                    <b>Email ID</b>
                                  </div>
                                  <div className="col-md-6 col-6">
                                    <h6>{spoc.email ?? '-'}</h6>
                                  </div>
                                </div>
                              </li>
                              <li className="list-group-item border-0">
                                <div className="row">
                                  <div className="col-md-6 col-6">
                                    <b>Contact No</b>
                                  </div>
                                  <div className="col-md-6 col-6">
                                    <h6>+{spoc.country_code}-{spoc.contact_no}</h6>
                                  </div>                                  
                                </div>
                              </li>
                              <li className="list-group-item border-0">
                                <div className="row">
                                  <div className="col-md-6 col-6">
                                    <b>User Role</b>
                                  </div>
                                  <div className="col-md-6 col-6">
                                    <h6>{spoc.user_role ??'-'}</h6>
                                  </div>
                                </div>
                              </li>
                              {/* <li className="list-group-item border-0">
                                <div className="row">
                                  <div className="col-md-6 col-6">
                                    <b>Address</b>
                                  </div>
                                  <div className="col-md-6 col-6">
                                    <h6>{spoc.address ?? '-'}</h6>
                                  </div>                                  
                                </div>
                              </li>                                                                                          */}
                            </ul>
                          </div>

                          {/* <div className="col-md-6">
                            <ul className="list-group">
                              <li className="list-group-item border-0">
                                <div className="row">
                                  <div className="col-md-6 col-6">
                                    <b>User Role</b>
                                  </div>
                                  <div className="col-md-6 col-6">
                                    <h6>{spoc.user_role ??'-'}</h6>
                                  </div>
                                </div>
                              </li>
                              <li className="list-group-item border-0">
                                <div className="row">
                                  <div className="col-md-6 col-6">
                                    <b>Gender</b>
                                  </div>
                                  <div className="col-md-6 col-6">
                                    <h6>{spoc.gender ??'-'}</h6>
                                  </div>
                                </div>
                              </li>
                              <li className="list-group-item border-0">
                                <div className="row">
                                  <div className="col-md-6 col-6">
                                    <b>Joining Date</b>
                                  </div>
                                  <div className="col-md-6 col-6">
                                    <h6>{spoc.joining_date??'-'}</h6>
                                  </div>
                                </div>
                              </li>
                              <li className="list-group-item border-0">
                                <div className="row">
                                  <div className="col-md-6 col-6">
                                    <b>Designation</b>
                                  </div>
                                  <div className="col-md-6 col-6">
                                    <h6>{spoc.designation??'-'}</h6>
                                  </div>
                                </div>
                              </li>                 
                            </ul>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="row">
                  <div className="col-12">
                    <h5 style={{ textDecoration: "underLine" }}>
                      Trips History
                    </h5>
                    <div className="card">
                      <MaterialTable
                        style={{ 
                          backgroundColor: "white",                      
                      }}
                        columns={columns}
                        data={data}
                        title=""
                        options={{
                          exportAllData: true,
                          exportMenu: [
                            {
                              label: "Export CSV",
                              exportFunc: (cols, datas) =>
                                ExportCsv(cols, datas, "trux"),
                            },
                          ],
                          pageSize: 3,
                        }}
                      />
                    </div>
                  </div>
                </div> */}
              </div>
            </section>
          )
        }
       
      </div>
    </>
  );
};

export default SpocProfile;
