import React, {useState, useEffect} from "react";
import ContentHeader from "../../components/content-header/ContentHeader";
import MaterialTable, { Column } from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import PageLoader from "../../components/PageLoader";
import { Link } from "react-router-dom";
import {getAllUsersDetailNotifications} from "../../services/data/pushnotifications"
import { onMessage } from "firebase/messaging";
import { messaging } from '../../firebase/firebaseConfig'

const PushNotification = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const [receivedNotifications, setReceivedNotifications] = useState([]);
    const columns = [
    //   { title: "id", field: "id" },
      { title: "Title", field: "title" },
      { title: "Notification", field: "message" },
      { 
        title: "To", 
        field: "users", 
        render :(rowData)=>{
          return(
            <>
            {
              rowData.users && rowData.users.length
            }
            </>
          )
        }
      },
      { title: "Publish Date and Time", field: "publish_time" },
    ]
       
    useEffect(()=>{
      async function fetchData(){
        await getAllUsersDetailNotifications().then((response)=>{
          setData(response.data)
          setIsLoading(false)
        }) 
        .catch((err)=>{
          console.log(err)
          setIsLoading(false)          
        })
              
      const unsubscribe = onMessage(messaging, (response) => {
        console.log("Message received. ", response);        
        setReceivedNotifications((prevNotifications) => [...prevNotifications, {title:response.notification.title, message:response.notification.body}]);
      });
      // Clean up the listener when the component unmounts
      return () => unsubscribe();     
      }
      fetchData()            
    },[])

    const tableData = [...data];
    tableData.concat(receivedNotifications)

  return (
    <div>
      <ContentHeader title="Push Notification" />
      {
        isLoading ? (<PageLoader />)
        :
        (
        <section className="content">
            <div className="container-fluid">
            <div className="card">
                <div className="card-header">
                    <h3 
                    className="card-title"
                    style={{textDecoration:'underline'}}
                    >
                    <b>Notifications List</b>
                    </h3>
                    <div className="card-tools">                   
                      <Link
                        type="button"
                        className="btn btn-tool bg-info"
                        data-card-widget="collapse"
                        title="Collapse"
                        to="/managepushnotification"
                      >
                        <i className="fas fa-plus" /> Add Push Notification
                      </Link>                    
                  </div>                
                </div>
                <div className="card-body p-0">
                    <MaterialTable
                        // id="pdfDatas"
                        style={{ backgroundColor: "white" }}
                        columns={columns}
                        isLoading={isLoading}
                        data={tableData}
                        title=""
                        options={{
                        exportAllData: true,
                        exportMenu: [
                            {
                            label: "Export CSV",
                            exportFunc: (cols, datas) =>
                                ExportCsv(cols, datas, "trux"),
                            },
                        ],
                        }}
                    />
                </div>
                {/* <div className="card-footer">Footer</div> */}
            </div>
            </div>
        </section>
        )
      }
      
    </div>
  );
};

export default PushNotification;
