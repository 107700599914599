import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Main from "./modules/main/Main";
import { useWindowSize } from "./hooks/useWindowSize";
import { calculateWindowSize } from "./utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { setWindowSize } from "./store/reducers/ui";

import Blank from './pages/Blank';
import Customers from './pages/customers/Customers';
import Transporters from './pages/transporters/Transporters';
import Trucks from './pages/trucks/Trucks';
import Drivers from './pages/drivers/Drivers';
import Invoices from './pages/Invoices';
import Commissions from './pages/Commissions';
import Coupon from './pages/Coupon';
import ManageTermsAndConditions from './pages/termsandconditions/ManageTermsAndConditions';
import TermsConditions from './pages/termsandconditions/TermsConditions';
import PrivacyPolicy from './pages/privacypolicy/PrivacyPolicy';
import ManagePrivacyPolicy from './pages/privacypolicy/ManagePrivacyPolicy';
import FAQ from './pages/faq/FAQ';
import ManageFAQ from './pages/faq/ManageFAQ';
import Support from "./pages/support/Support";
import ManageSupport from "./pages/support/ManageSupport";

import Dashboard from './pages/Dashboard';

import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';
import Login from './modules/login/Login';
import { setAuthentication } from './store/reducers/auth';
import {
  getAuthStatus,
} from './utils/oidc-providers';
import Users from './pages/users-roles/Users';
import Profile from './pages/Profile';
import ManageCustomer from './pages/customers/ManageCustomer';
import ManageTransporter from './pages/transporters/ManageTransporters';
import ManageTruck from './pages/trucks/ManageTruck';
import ManageDriver from './pages/drivers/ManageDriver';
import CustomerProfile from './pages/customers/CustomerProfile';
import UserManagement from './pages/users-roles/UserManagement';
import UserRoles from './pages/users-roles/UserRoles';
import UserRoleManagement from './pages/users-roles/UserRoleManagement';
import TransporterDetails from './pages/transporters/TransporterDetails';
import Spoc from './pages/spoc/Spoc';
import ManageSpoc from './pages/spoc/ManageSpoc';
import Bookings from './pages/booking/Bookings';
import BookingProfile from './pages/booking/BookingProfile';
import Generalsettings from './pages/Generalsettings';
import Countrymaster from './pages/Countrymaster';
import Documentmaster from './pages/Documentmaster';
import TruckProfile from './pages/trucks/TruckProfile';
import DriverProfile from './pages/drivers/DriverProfile';
import TruckTypes from './pages/trucksTypes/TruckTypes';
import ManageTruckType from './pages/trucksTypes/ManageTruckType';
import PackageType from './pages/packagetype/PackageType';
import ManagePackageType from './pages/packagetype/ManagePackageType';
import SpocProfile from "./pages/spoc/SpocProfile";
import PushNotification from "./pages/pushnotification/PushNotification";
import ManagePushNotification from "./pages/pushnotification/ManagePushNotification";
import EditTermsAndConditions from "./pages/termsandconditions/EditTermsAndConditions";
import CargoType from "./pages/cargoType/CargoType";
import ManageCargoType from "./pages/cargoType/ManageCargoType";
import AccountDeletion from "./modules/login/AccountDeletion";
import BookingForm from "./pages/booking/BookingForm";
import BellNotification from "./pages/bellnotification/BellNotification";
import DriverOrderDetails from "./pages/Driverview/DriverOrderDetails";
import Trips from "./pages/trips/Trips";
import ManageTrip from "./pages/trips/ManageTrip";
import GoogleMap from "./pages/map/GoogleMap";

const App = () => {
  const windowSize = useWindowSize();
  const screenSize = useSelector((state) => state.ui.screenSize);
  const dispatch = useDispatch();

  const [isAppLoading, setIsAppLoading] = useState(false);    
  const checkSession = async () => {
    try {
      let responses = await Promise.all([getAuthStatus()]);
      responses = responses.filter((r) => Boolean(r));
      if (responses && responses.length > 0) {
        dispatch(setAuthentication(responses[0]));
      }
    } catch (error) {
      console.log("error", error);
    }
    setIsAppLoading(false);
  };

  useEffect(() => {    
    checkSession();    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // generateToken()
  }, []);

  

  useEffect(() => {
    const size = calculateWindowSize(windowSize.width);
    if (screenSize !== size) {
      dispatch(setWindowSize(size));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize]);

  if (isAppLoading) {
    return <p>Loading</p>;
  }

  return (
    <>
      <Routes>
        <Route path="/login" element={<PublicRoute />}>
          <Route path="/login" element={<Login />} />          
        </Route>
 
        <Route path="/account-deletion" element={<AccountDeletion />} />   

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<Main />}>            
            <Route path="/customers" element={<Customers />} />
            <Route path="/manage-customer/new" element={<ManageCustomer />} />
            <Route path="/customer-profile/:id" element={<CustomerProfile />} />
            <Route path="/manage-customer/:id" element={<ManageCustomer />} />

            <Route path="/transporters" element={<Transporters />} />
            <Route path="/new-transporter" element={<ManageTransporter />} />
            <Route
              path="/manage-transporter/:id"
              element={<ManageTransporter />}
            />
            <Route
              path="/transporter-details/:id"
              element={<TransporterDetails />}
            />

            <Route path="/trucks" element={<Trucks />} />
            <Route path="/new-truck" element={<ManageTruck />} />
            <Route path="/manage-trucks/:id" element={<ManageTruck />} />
            <Route path="/truck-profile/:id" element={<TruckProfile />} />

            <Route path="/truck-type" element={<TruckTypes />} />
            <Route path="/New-truck-type" element={<ManageTruckType />} />
            <Route path="/manage-trucktype/:id" element={<ManageTruckType />} />

            <Route path="/cargo-type" element={<CargoType />} />
            <Route path="/New-cargo-type" element={<ManageCargoType />} />
            <Route path="/cargo-types/:id" element={<ManageCargoType />} />


            <Route path="/drivers" element={<Drivers />} />
            <Route path="/new-driver" element={<ManageDriver />} />
            <Route path="/manage-driver/:id" element={<ManageDriver />} />
            <Route path="/driver-profile/:id" element={<DriverProfile />} />

            <Route path="/spoc" element={<Spoc />} />
            <Route path="/manage-spoc" element={<ManageSpoc />} />
            <Route path="/manage-spoc/:id" element={<ManageSpoc />} />
            <Route path="/spocprofile/:id" element={<SpocProfile />} />

            
            <Route path="/bookings" element={<Bookings />} />
            <Route path="/booking-form" element={<BookingForm />} />
            <Route path="/booking-form/:id" element={<BookingForm />} />
            <Route path="/booking-profile" element={<BookingProfile />} />
            <Route path="/booking-profile/:id" element={<BookingProfile />} />

            <Route path="/manage-terms-and-conditions" element={<ManageTermsAndConditions />} />
            <Route path="/manage-terms-and-conditions/:id" element={<ManageTermsAndConditions />} />
            <Route path="/terms-and-conditions" element={<TermsConditions />} />
            <Route path="/edit-terms-and-conditions" element={<EditTermsAndConditions />} />            

            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/manageprivacypolicy" element={<ManagePrivacyPolicy />} />
            <Route path="/manageprivacypolicy/:id" element={<ManagePrivacyPolicy />} />
            
            <Route path="/faq" element={<FAQ />} />
            <Route path="/managefaq" element={<ManageFAQ />} />
            <Route path="/managefaq/:id" element={<ManageFAQ />} />
            
            <Route path="/packagetype" element={<PackageType />} />
            <Route path="/managepackagetype" element={<ManagePackageType />} />
            <Route path="/managepackagetype/:id" element={<ManagePackageType />} />
            
            <Route path="/support" element={<Support />} />
            <Route path="/managesupport" element={<ManageSupport />} />
            <Route path="/managesupport/:id" element={<ManageSupport />} />

            <Route path="/push-notification" element={<PushNotification />} />
            <Route path="/managepushnotification" element={<ManagePushNotification />} />

            <Route path="/bell-notification" element={<BellNotification />} />
            <Route path="/driver-order-details" element={<DriverOrderDetails />} />

            <Route path="/invoices" element={<Invoices />} />
            <Route path="/commissions" element={<Commissions />} />
            <Route path="/coupon" element={<Coupon />} />
            
            <Route path="/profile" element={<Profile />} />
            <Route path="/users" element={<Users />} />
            <Route path="/user-roles" element={<UserRoles />} />
            <Route path="/users-roles" element={<UserManagement />} />
            <Route path="/edit-users/:id" element={<UserManagement />} />
            <Route
              path="/user-role-management"
              element={<UserRoleManagement />}
            />

            <Route path="/general-settings" element={<Generalsettings />} />
            <Route path="/country-master" element={<Countrymaster />} />
            <Route path="/document-master" element={<Documentmaster />} />

            <Route path="/trips" element={<Trips />} />
            <Route path="/new-trip" element={<ManageTrip />} />
            <Route path="/edit-trip/:id" element={<ManageTrip />} />

            <Route path="/google-map" element={<GoogleMap />} />


            <Route path="/" element={<Dashboard />} />
          </Route>
        </Route>
      </Routes>
      <ToastContainer
        autoClose={3000}
        draggable={false}
        position="top-right"
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnHover
      />
    </>
  );
};

export default App;
