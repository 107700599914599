import ContentHeader from "../../components/content-header/ContentHeader";
import React, { useEffect, useState } from "react";
import { FaRegFilePdf } from "react-icons/fa6";
import { Link, useParams } from "react-router-dom";
import { getAllBookingsApi, getBookingApi } from "../../services/data/bookings";
import { FaStar } from "react-icons/fa";
import { FaRegStar } from "react-icons/fa";
import { Dropdown, Spinner } from "react-bootstrap";
import MaterialTable from "@material-table/core";
import AddressConfirmationModal from "./AddressConfirmationModal";
import { getQuotationApi, getAllQuotationsApi } from "../../services/data/notification";
import PageLoader from "../../components/PageLoader";

const BookingProfile = () => {
  const [BookingData, setBookingData] = useState([]);
  const [currentQuote, setCurrentQuote] = useState(null);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [Quotedata, setQuotedata] = useState([]);
  // console.log('Quotedata',Quotedata)
  // console.log('BookingData',BookingData)

  const { id } = useParams();


  const columns = [
    {
      title: "Vendor Code",
      field: "rowData",
      render:(rowData)=>{
        return(
          <>
          {rowData?.rowData ?? '-'}
          </>
        )
      },
      sorting: true,
    },
    {
      title: "Truck Type",
      field: "truck_type.truck_type",
    },
    {
      title: "Cost Per Trip",
      field: "quotation_for_trip",
    },
    {
      title: "No of Similar Trips",
      field: "number_of_trips",
    },
    {
      title: "Total Cost of Trips",
      field: "quotation_amount",
    },
  ];


  useEffect(() => {    
    const fetchData = async () => {
      setIsPageLoading(true);
      try {        
        if (id) {        
          const response = await getBookingApi(id);         
          const bookingData = response.data;
          console.log("bookingData :", bookingData);          
          setBookingData(bookingData);
          setIsPageLoading(false);
        }
      } catch (error) {        
        console.error("Error fetching booking details:", error);      
      }
    };    
    fetchData();
  }, [id,modalShow])

  useEffect(() => {
    const fetchData = async () => {
      setIsPageLoading(true);
      try {
        if (id) {
          const response = await getQuotationApi(id);
          setQuotedata(response.data);
          setIsPageLoading(false);
        }
      } catch (error) {
        console.error("Error fetching booking details:", error);
      }
    };

    fetchData();
  }, [id,modalShow]); 

  const renderStars = () => {
    const maxStars = 5;
    const filledStars = Math.min(
      Math.max(0, Math.round(parseFloat(BookingData.review_star))),
      maxStars
    );
    const emptyStars = maxStars - filledStars;
    const filledStarElements = Array.from(
      { length: filledStars },
      (_, index) => <FaStar key={index} />
    );
    const emptyStarElements = Array.from({ length: emptyStars }, (_, index) => (
      <FaRegStar key={index} />
    ));
    return [...filledStarElements, ...emptyStarElements];
  };

  const renderDestination = (destination) => {
    let destinationString = "No destination provided";
    if (destination) {
      destinationString = `${destination.destination1}, ${destination.destination2}`;
    }
    return destinationString;
  };

  const handleSelectionChange = (rowData) => {   
    setCurrentQuote(rowData);
    setModalShow(true);    
  };

  return (
    <>     
        <div>
        <ContentHeader title="Booking Profile" />
        {isPageLoading ? (<PageLoader loading="Loading.."/>)
          :
          (
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h2 className="card-title">
                        <b>Booking Information</b>
                      </h2>                    
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-end">
                <div className="col-md-6">
                  <div className="card p-3">
                    <div className="row">
                      <div className="col">
                        <Link>
                          <FaRegFilePdf /> Download WayBill-Trip-1{" "}
                        </Link>
                      </div>
                      <div className="col">
                        <Link>
                          <FaRegFilePdf /> Download Invoice-Trip-1{" "}
                        </Link>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <Link>
                          <FaRegFilePdf /> Download WayBill-Trip-2{" "}
                        </Link>
                      </div>
                      <div className="col">
                        <Link>
                          <FaRegFilePdf /> Download Invoice-Trip-2{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-5">
                  <div className="card">
                    <div className="card-body box-profile">
                      <ul className="list-group">
                        <li className="list-group-item">
                          <b>Booking No</b>
                          <a className="float-right">
                            {BookingData.booking_no
                              ? BookingData.booking_no
                              : "No booking_no provided"}
                          </a>
                        </li>
                        <li className="list-group-item">
                          <b>Date and Time</b>
                          <a className="float-right">
                            {BookingData.booking_date
                              ? new Date(
                                  BookingData.booking_date
                                ).toLocaleString()
                              : "No booking_date provided"}
                          </a>
                        </li>
                        <li className="list-group-item">
                          <b>Origin</b>
                          <a className="float-right">
                            {BookingData.origin
                              ? BookingData.origin
                              : "No origin provided"}
                          </a>
                        </li>
                        <li className="list-group-item">
                          <b>Destination</b>
                          <a className="float-right">
                            {renderDestination(BookingData.destination)}
                          </a>
                        </li>
                        <li className="list-group-item">
                          <b>Truck Type</b>
                          <a className="float-right">
                            {BookingData.truck_type
                              ? BookingData.truck_type.truck_type
                              : "No truck_type provided"}
                          </a>
                        </li>
                        <li className="list-group-item">
                          <b>Similar Trips</b>
                          <a className="float-right">
                            {" "}
                            {BookingData.no_of_trips
                              ? BookingData.no_of_trips
                              : "No Similar Trips provided"}
                          </a>
                        </li>
                        <li className="list-group-item">
                          <b>Cargo Name</b>
                          <a className="float-right">
                            {" "}
                            {BookingData.cargo_name
                              ? BookingData.cargo_name
                              : "No cargo_name provided"}
                          </a>
                        </li>
                        <li className="list-group-item">
                          <b>Description</b>
                          <a className="float-right">
                            {BookingData.description
                              ? BookingData.description
                              : "No description provided"}
                          </a>
                        </li>
                        <li className="list-group-item">
                          <b>No of Packages</b>
                          <a className="float-right">
                            {" "}
                            {BookingData.no_of_packages
                              ? BookingData.no_of_packages
                              : "No no_of_packages provided"}
                          </a>
                        </li>
                        <li className="list-group-item">
                          <b>Weight</b>
                          <a className="float-right">
                            {BookingData.weight
                              ? BookingData.weight
                              : "No weight provided"}
                          </a>
                        </li>
                        <li className="list-group-item">
                          <b>Status</b>
                          <a className="float-right">
                            {BookingData.status
                              ? BookingData.status
                              : "No status provided"}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              
              { 
                !BookingData?.transporter?.address && !BookingData?.shipper_address?.address && !BookingData?.consignee_address?.address &&
                (
                  <div className="col-md-7 mb-2">
                  <div className="card  p-0 m-0">
                    <div className="card-header bg-info">
                      <div className="d-flex justify-content-between align-items-center">
                        <h3 className="card-title">
                          Transporter and Shipment Details
                        </h3>
                        { Quotedata.length > 0 && (
                          <>
                            <button
                                className="btn btn-success"
                                onClick={() => setModalShow(true)}
                                type="button"
                                // disabled={Quotedata.length > 0 ? false : true}
                              >
                                Accept Quote
                            </button>
                          </>
                        )                      
                        }
                      </div>
                    </div>                  
                      <div className="card-body p-0">
                        {Quotedata.length > 0 ? (
                          <MaterialTable
                            title=""
                            columns={columns}
                            data={Quotedata}
                            isLoading={isPageLoading}
                            options={{
                              selection: true,
                            }}
                            // onRowClick={(Quotedata)=>handleRowClick(Quotedata)}
                            onSelectionChange={(rows) => handleSelectionChange(rows)}
                          />
                        ) : (
                          <p>No Data ...!</p>
                        )}
                      </div>                 
                  </div>
                </div>
                )              
              }
            
  {/* ---------------------------------------transporter Details --------------------------- */}
              { 
                BookingData?.transporter?.address && BookingData?.shipper_address?.address && BookingData?.consignee_address?.address &&
                (
                  <>
                    <div className="col-md-7 mb-2">
                      <div className="card bg-info p-0 m-0">
                        <div className="card-header">
                          <div className="d-flex justify-content-between">
                            <div className="text-start">
                              <h3 className="card-title">
                                Transporter Details
                              </h3>
                            </div>
                            {/* <div className="d-inline-flex">
                              <button type="button" className="btn btn-success mr-2">
                                Assign Driver
                              </button>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="success"
                                  id="dropdown-basic"
                                >
                                  Pay
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item href="#/action-2">
                                    Pay OMR{" "}
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#/action-1">
                                    Avail 30 Days Credit
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div calssName="card p-0 m-0">
                        <div className="card-body p-0">
                          <ul className="list-group">
                            <li className="list-group-item">
                              <b>Vendor Code</b>
                              <a className="float-right">
                                {BookingData?.transporter?.code ?? '-'}
                              </a>
                            </li>
                            <li className="list-group-item">
                              <b>Name</b>
                              <a className="float-right">
                                {BookingData?.transporter?.name ?? '-'}
                              </a>
                            </li>
                            <li className="list-group-item">
                              <b>Address</b>
                              <a className="float-right">
                              {BookingData?.transporter?.address ?? '-'}
                              </a>
                            </li>                                            
                            <li className="list-group-item">
                              <b>City</b>
                              <a className="float-right">
                              {BookingData?.transporter?.city ?? '-'}
                              </a>
                            </li>
                            <li className="list-group-item">
                              <b>
                                State                           
                              </b>
                              <a className="float-right">
                              {BookingData?.transporter?.state ?? '-'}
                              </a>
                            </li>
                            <li className="list-group-item">
                              <b>Contact No</b>
                              <a className="float-right">
                              {BookingData?.transporter?.contact_person ?? '-'}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="card p-0 m-0 bg-info">
                            <div className="card-header m-auto">
                              <h3 className="card-title">Shipper Address</h3>
                            </div>
                          </div>
                          <div className="card p-0 m-0">
                            <div className="card-body p-0">
                              <ul className="list-group">
                                <li className="list-group-item">
                                  <b calssName="text-dark">Name</b>
                                  <a className="float-right">
                                    {BookingData?.shipper_address?.name ?? '-'}
                                  </a>
                                </li>
                                <li className="list-group-item">
                                  <b>Address</b>
                                  <a className="float-right">
                                    {BookingData?.shipper_address?.address ?? '-'}
                                  </a>
                                </li>
                                <li className="list-group-item">
                                  <b>Contact No</b>
                                  <a className="float-right">
                                    {BookingData?.shipper_address?.contact ?? '-'}
                                  </a>
                                </li>
                                <li className="list-group-item">
                                  <b>Landmark</b>
                                  <a className="float-right">
                                    {BookingData?.shipper_address?.land_mark ?? '-'}
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="card p-0 m-0 bg-info">
                            <div className="card-header m-auto">
                              <h3 className="card-title">Consignee Address</h3>
                            </div>
                          </div>
                          <div className="card p-0 mt-0">
                            <div className="card-body p-0">
                              <ul className="list-group">
                                <li className="list-group-item">
                                  <b calssName="text-dark">Name</b>
                                  <a className="float-right">
                                    {BookingData?.consignee_address?.name ?? '-'}
                                  </a>
                                </li>
                                <li className="list-group-item">
                                  <b>Address</b>
                                  <a className="float-right">
                                    {BookingData?.consignee_address?.address ?? '-'}
                                  </a>
                                </li>
                                <li className="list-group-item">
                                  <b>Contact No</b>
                                  <a className="float-right">
                                    {BookingData?.consignee_address?.contact ?? '-'}
                                  </a>
                                </li>
                                <li className="list-group-item">
                                  <b>Landmark</b>
                                  <a className="float-right">
                                    {BookingData?.consignee_address?.land_mark ?? '-'}
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )                
                }
              </div>
  {/* ---------------------------------------transporter Details --------------------------- */}


              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">
                        <b>Booking and Delivery Date and Time</b>
                      </h3>
                    </div>
                    <div className="card-body p-0">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Booking Date/Time</th>
                              <th>ETA Given by Driver</th>
                              <th>Actual Arrival Date/Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {BookingData.booking_date
                                  ? new Date(
                                      BookingData.booking_date
                                    ).toLocaleString()
                                  : "No booking_date provided"}
                              </td>
                              <td>
                                {BookingData.eta
                                  ? BookingData.eta
                                  : "No ETA provided"}
                              </td>
                              <td>
                                {BookingData.arrival
                                  ? BookingData.arrival
                                  : "No arrival provided"}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header ">
                      <h2 className="card-title">
                        <b>Driver Information</b>
                      </h2>
                    </div>
                    <div className="card-body p-0">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Driver Name</th>
                              <th>Contact No</th>
                              <th>Address</th>
                              <th>Licence No</th>
                              <th>Licence</th>
                              <th>Licence Validity</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {BookingData.driver && BookingData.driver.name
                                  ? BookingData.driver.name
                                  : "No Driver Name provided"}
                              </td>
                              <td>
                                {BookingData.driver && BookingData.driver.contact
                                  ? BookingData.driver.contact
                                  : "No Contact No provided"}
                              </td>
                              <td>
                                {BookingData.driver && BookingData.driver.address
                                  ? BookingData.driver.address
                                  : "No address provided"}
                              </td>
                              <td>
                                {BookingData.driver && BookingData.driver.license
                                  ? BookingData.driver.license
                                  : "No license provided"}
                              </td>
                              <td>
                                {BookingData.driver &&
                                BookingData.driver.license_doc
                                  ? BookingData.driver.license_doc
                                  : "No provided"}
                              </td>
                              <td>
                                {BookingData.driver &&
                                BookingData.driver.validity_no
                                  ? BookingData.driver.validity_no
                                  : "No validity_no provided"}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="card ">
                    <div className="card-header">
                      <h2 className="card-title">
                        <b>Proof of Delivery</b>
                      </h2>
                    </div>
                    <div className="card-body p-0">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Start OTP</th>
                              <th>Picture of Loading</th>
                              <th>End OTP</th>
                              <th>Picture of Unloading</th>
                              <th>Name of Receiver</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <input type="checkbox" checked readOnly />
                              </td>
                              <td>View Picture</td>
                              <td>
                                <input type="checkbox" checked readOnly />
                              </td>
                              <td>View Picture</td>
                              <td>Ansar</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">
                        <b>Review and Feedback</b>
                      </h3>
                    </div>
                    <div className="card-body p-0">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Review</th>
                              <th>Feedback</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {BookingData.review_star
                                  ? renderStars()
                                  : "No review_star provided"}
                              </td>
                              <td>
                                {BookingData.feedback
                                  ? BookingData.feedback
                                  : "No feedback provided"}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {modalShow && 
            <AddressConfirmationModal
              show={modalShow}
              quotedata={Quotedata}
              bookingData ={[BookingData]}
              handleClose={() => setModalShow(false)}
              Quote={currentQuote}
            />}         
          </section>
          )}
        </div>
      
      
    </>
  );
};

export default BookingProfile;
