import React, { useState, useEffect } from "react";
import {
  assingDuediligence,
  getCustomerLicenseDoc,
} from "../../services/data/customers";
import { toast } from "react-toastify";
import { getCustomerDetails } from "../../services/data/customers";
import { useFormik } from "formik";
import * as Yup from "yup";
import PageLoader from "../../components/PageLoader";

const SignupSchema = Yup.object().shape({
  duedeligencereport: Yup.array().min(1, "At least one File is required"),
  // commercialInsurance : Yup.array()
  // .min(1, 'At least one File is required'),
  creditduration: Yup.string().required("Please Enter the Credit Duration"),
  // authorizedsignatory: Yup.string()
  //   .required('Please Enter the Authorized Signatory'),
  // designation: Yup.string()
  // .required('Please Enter the Designation'),
});

const DuedeligenceModal = ({
  show,
  handleClose,
  userId,
  dueDiligence,
  existTradeDoc,
}) => {
  const initialValues = {
    duedeligencereport: [],
    commercialInsurance: [],
    creditduration: dueDiligence.credit_duration || "",
    authorizedsignatory: dueDiligence.authorised_signatory || "",
    designation: dueDiligence.designation || "",
  };
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: SignupSchema,
    onSubmit: (values) => {
      console.log(values);
      const payload = {
        spoc_id: userId,
        report: values.duedeligencereport,
        trade_license_doc: values.commercialInsurance,
        credit_duration: values.creditduration,
        authorised_signatory: values.authorizedsignatory,
        designation: values.designation,
      };
      assingDuediligence(userId, payload).then((response) => {
        toast.success("DueDeligence Details Added!");
        handleClose();
      });
    },
  });

  return (
    <>
      <div
        className={`modal fade ${show ? "show" : "hide"}`}
        style={{ display: show ? "block" : "none" }}
        id="modal-md"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <form onSubmit={handleSubmit} id="dueDeligenceSubmit">
              <div className="modal-header">
                <h4 className="modal-title">Due Deligence</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true" onClick={() => handleClose()}>
                    ×
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="duedeligencereport">
                        Due Deligence Report
                      </label>

                      {dueDiligence.report?.map((item, i) => {
                        return (
                          <a
                            className="btn btn-link p-0"
                            onClick={(e) =>
                              getCustomerLicenseDoc(
                                userId,
                                item.id,
                                item.file_ext
                              )
                            }
                          >
                            <i className="far fa-fw fa-file-pdf" />
                            {`report-${item.id}.${item.file_ext}`}
                          </a>
                        );
                      })}

                      <div className="input-group">
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="duedeligencereport"
                            name="due_deligence_report"
                            multiple
                            accept="image/*,.pdf"
                            onBlur={handleBlur}
                            onChange={(event) => {
                              setFieldValue(
                                "duedeligencereport",
                                Array.from(event.currentTarget.files)
                              );
                            }}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="duedeligencereport"
                          >
                            {values.duedeligencereport &&
                              values.duedeligencereport.map(
                                (file) => file.name
                              )}
                          </label>
                        </div>
                      </div>
                      {errors.duedeligencereport && (
                        <b className="text-danger">
                          {errors.duedeligencereport}
                        </b>
                      )}
                    </div>

                    {/* <div className="form-group">
                        <label htmlFor="commercialinsurance">
                          Trade/Commercial Insurance
                        </label>

                        <div className="input-group">
                          <div className="custom-file">
                            <input
                              type="file"
                              className="custom-file-input"
                              id="commercialinsurance"
                              name="commercial_insurance"
                              multiple accept="image/*,.pdf"                                    
                              onBlur={handleBlur}
                              onChange={(event) => {
                                setFieldValue('commercialInsurance', Array.from(event.currentTarget.files))}}                                                                    
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="commercialinsurance"
                            >
                              {values.commercialInsurance && values.commercialInsurance.map((file)=> file.name)}                                     
                            </label>
                          </div>
                        </div>
                        {errors.commercialInsurance && <b className='text-danger'>{errors.commercialInsurance}</b>}
                      </div>                                                                                   */}

                    <div className="form-group">
                      <label htmlFor="commercialinsurance">
                        Trade/Commercial Insurance
                      </label>
                      {existTradeDoc?.map((item) => {
                        return (
                          <a
                            className="btn btn-link p-0"
                            onClick={(e) =>
                              getCustomerLicenseDoc(
                                userId,
                                item.id,
                                item.file_ext
                              )
                            }
                          >
                            <i className="far fa-fw fa-file-pdf" />
                            {`doc-${item.id}.${item.file_ext}`}
                          </a>
                        );
                      })}
                      <div className="input-group">
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="commercialinsurance"
                            name="commercial_insurance"
                            multiple
                            accept="image/*,.pdf"
                            onBlur={handleBlur}
                            onChange={(event) => {
                              setFieldValue(
                                "commercialInsurance",
                                Array.from(event.currentTarget.files)
                              );
                            }}
                            // onChange={(e) => fileSelect(e)}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="commercialinsurance"
                          >
                            {values.commercialInsurance &&
                              values.commercialInsurance.map(
                                (file) => file.name
                              )}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="creditduration">Credit Duration</label>
                      <div className="input-group">
                        <input
                          className="form-control"
                          id="creditduration"
                          type="number"
                          name="creditduration"
                          placeholder="Credit Duration"
                          value={values.creditduration}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          style={{ fontSize: "14px" }}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">in days</span>
                        </div>
                      </div>
                      {errors.creditduration && (
                        <b className="text-danger">{errors.creditduration}</b>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="creditduration">
                        Authorized Signatory(optional)
                      </label>
                      <div class="input-group">
                        <input
                          className="form-control"
                          id="creditduration"
                          type="text"
                          placeholder="Authorized Signatory"
                          name="authorizedsignatory"
                          value={values.authorizedsignatory}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          style={{ fontSize: "14px" }}
                        />
                      </div>
                      {errors.authorizedsignatory && (
                        <b className="text-danger">
                          {errors.authorizedsignatory}
                        </b>
                      )}
                    </div>

                    <div className="form-group">
                      <label>Designation(optional)</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Designation"
                        name="designation"
                        value={values.designation}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        style={{ fontSize: "14px" }}
                      />
                      {errors.designation && (
                        <b className="text-danger">{errors.designation}</b>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                {/* <button
                    type="button"
                    className="btn btn-default"
                    data-dismiss="modal"
                    onClick={() => handleClose()}
                  >
                    Close
                  </button> */}
                <button type="submit" className="btn btn-info">
                  Submit
                </button>
              </div>
            </form>
          </div>
          {/* /.modal-content */}
        </div>
        {/* /.modal-dialog */}
      </div>
    </>
  );
};

export default DuedeligenceModal;
