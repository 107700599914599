import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ContentHeader from "../../components/content-header/ContentHeader";
import {
  addMasterCargoTypeApi,
  updateCargoTypedetails,
  getCargoTypedetails,
} from "../../services/data/masters/packagetype";
import { toast } from "react-toastify";

const ManageCargoType = () => {
  const [initialValue, setInitialValue] = useState({
    cargo: "",
    isActive: false,
  });

  const navigate = useNavigate();
  const { id } = useParams();

  // Define validation schema using Yup
  const validationSchema = Yup.object().shape({
    cargo: Yup.string().required("*Nature of Cargo is required"),
  });

  // Fetch cargo type data by ID if in edit mode
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id) {
          const response = await getCargoTypedetails(id);
          const cargoTypeData = response.data;
          setInitialValue({
            cargo: cargoTypeData.name,
            isActive: cargoTypeData.status === "Active",
          });
        }
      } catch (error) {
        console.error("Error fetching cargo type details:", error);
        // Handle error as needed
      }
    };

    fetchData();
  }, [id]);

  const handleSubmit = async (values, { setSubmitting }) => {
    console.log("values :", values);
    try {
      setSubmitting(true);
      if (id) {
        await updateCargoTypedetails(id, values);
        toast.success("Cargo type updated successfully!");
      } else {
        await addMasterCargoTypeApi(values);
        toast.success("Cargo type added successfully!");
      }
      setSubmitting(false);
      navigate("/cargo-type");
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error submitting form. Please try again later.");
      setSubmitting(false);
    }
  };

  return (
    <div>
      <ContentHeader title={id ? "Edit Cargo Type" : "Add Cargo Type"} />
      <section className="content">
        <div className="container-fluid">
          <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title float-right">
                      <Link className="btn btn-primary" to="/cargo-type">
                        Back
                      </Link>
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="row justify-content-center">
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label htmlFor="cargo">
                            Nature of Cargo <i className="text-danger">*</i>{" "}
                          </label>
                          <Field
                            type="text"
                            name="cargo"
                            placeholder="Nature of Cargo"                            
                            className="form-control"
                          />
                          <ErrorMessage
                            name="cargo"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-footer text-center">
                    <button type="reset" className="btn btn-warning mr-3">
                      Reset
                    </button>
                    <button
                      type="submit"
                      className="btn btn-info mr-3"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Submitting" : "Submit"}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </section>
    </div>
  );
};

export default ManageCargoType;
