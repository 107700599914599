import ContentHeader from "../../components/content-header/ContentHeader";
// import "../../styles/Trux.css";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import MaterialTable from "@material-table/core";
import { ExportCsv } from "@material-table/exporters";
import { useSelector } from "react-redux";
import { hasPermission } from "../../utils/permissions";
import { 
  deleteTruckApi, 
  getAllTrucksApi,
  updateTrucksApi,
  getTruckApi,
 } from "../../services/data/trucks";
import "jspdf-autotable";
import { toast } from "react-toastify";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";
import PageLoader from "../../components/PageLoader";


const Trucks = () => {
  // Table Data give the place
  const authentication = useSelector((state) => state.auth.authentication);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteTruckId, setDeleteTruckId] = useState();
  const [activeStatus, SetActiveStatus] =  useState('');
   
  const columns = [
    // { title: "id", field: "id" },
    {      
      title: "Truck No",
      field: "truck_no",
      render: (rowData) => {
        return (
          <>
           <p className="mb-0 pb-0">
              <Link  to={`/truck-profile/${rowData.id}`} className="nav-link">
                {/* <i className="fas fa-truck nav-icon"></i>  */}
                <b>{rowData.truck_no ?? '-'}</b>
              </Link>
            </p>
            <p>
              <Link
                className="btn mr-0 pr-0"
                to={`/manage-trucks/${rowData.id}`}
              > 
                <i className="fas fa-pencil-alt text-info"></i>               
                {/* <i className="fas fa-edit text-info" /> */}
              </Link>{" "}
              {hasPermission("customers.delete") && (
                <button
                  className="btn  mr-0 pr-0"
                  onClick={(e) => {
                    setDeleteTruckId(rowData.id);
                    setShowDeleteConfirm(true);
                  }}
                >
                  {/* <i className="fas fa-trash text-danger" /> */}
                  <i className="fas fa-times text-danger"></i>
                </button>
              )}{" "}
              <Link
                className="btn mr-0 pr-0"
                to={`/truck-profile/${rowData.id}`}
              >
                <i className="fas fa-eye text-success"></i>
              </Link>
            </p>  
          </>                         
        );
      },
    },
    {
      title: "Truck Type",
      render:(rowData)=>{
        return(
          <>
            { rowData.truck_type ? 
              rowData.truck_type?.truck_type
              : '-'
            }
          </>
        )
      },
    },
    {
      title: "Truck Owner",
      render: (rowData)=>{
        return(
          <>
            {rowData.transporter.contact_person ?? '-'}                                   
          </>
        )
      }
    },
        
    // {
    //   title: "Transporter",
    //   render: (rowData) => {
    //     return <>{rowData.transporter.company_name}</>;
    //   },
    // },   
    {
      title: "Compilances",
      field: "compilances",
      render: (rowData) => {
        return (
          <>  
            {Array.isArray(rowData.compilances) && rowData.compilances.map((item) => (
              <>
                <span className="badge badge-info">{item}</span> &nbsp;
              </>
            ))}
          </>
        );
      },
    },
    { 
      title: "Trips",
      render: (rowData)=>{
        return(
          <>
          {rowData.trips ?? '-'}
          </>
        )
      }
    },
    { 
      title: "Status", 
      render : (rowData)=>{
        return(
          <h3>
            {rowData.status === "In Active" && (
              <button
                type="button"
                className="btn btn-warning"
                onClick={(e) => changeStatus(true, rowData.id)}
              >
                In Active
              </button>
            )}
            {rowData.status === "Active" && (
              <button
                type="button"
                className="btn btn-success"
                onClick={(e) => changeStatus(false, rowData.id)}
              >
                Active
              </button>
            )} 
          </h3>
        )
      }         
    },
    // {
    //   title: "Action",
    //   render: (rowData) => {
    //     return (
    //       <div className="btn-group btn-group-sm">
    //         <Link className="btn btn-info" to={`/manage-trucks/${rowData.id}`}>
    //           <i className="fas fa-edit" />
    //         </Link>
    //         {hasPermission("trucks.delete") && (
    //           <button
    //             className="btn btn-danger"
    //             onClick={(e) => {
    //               setDeleteTruckId(rowData.id);
    //               setShowDeleteConfirm(true);
    //             }}
    //           >
    //             <i className="fas fa-trash" />
    //           </button>
    //         )}
    //       </div>
    //     );
    //   },
    // },
  ];
  useEffect(() => {
    getAllTrucksApi().then((response) => {
      console.log(response.data)
      setData(response.data);
      setIsLoading(false);
      setIsPageLoading(false);
    });
  }, [showDeleteConfirm, activeStatus]);

  function onClose() {
    setShowDeleteConfirm(false);
  }

  function onConfirm() {
    setShowDeleteConfirm(false);
    // toast.warn("Not Implemented!");
    deleteTruckApi(deleteTruckId).then((response) => {
      toast.success("Truck Deleted!");
      getAllTrucksApi().then((response) => {
        setData(response.data);        
      });
    });
  }

  const changeStatus = (isActive, id) => {
    const payload = {
      is_active: isActive,
    };
    updateTrucksApi(id, payload).then((response) => {
      if(isActive){
        toast.success("User Activated!");
      }else{
        toast.info("User Deactivated!");
      }      
      getTruckApi(id).then((response) => {
        setData(response.data);
        SetActiveStatus(isActive)
      });
    });
  };

  return (
    <>
      <ContentHeader title="Trucks" />
      {isPageLoading ? (
        <PageLoader />
      ) : (
        <section className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Truck Details</h3>
                <div className="card-tools">
                  {hasPermission("trucks.create") && (
                    <Link
                      type="button"
                      className="btn btn-tool bg-info"
                      data-card-widget="collapse"
                      title="Collapse"
                      to="/new-truck"
                    >
                      <i className="fas fa-plus" /> New Truck
                    </Link>
                  )}
                </div>
              </div>
              {/* <div className="card-body"> */}
              <MaterialTable
                // id="pdfDatas"
                style={{ backgroundColor: "white" }}
                columns={columns}
                data={data}
                title=""
                options={{
                  exportAllData: true,
                  exportMenu: [
                    {
                      label: "Export CSV",
                      exportFunc: (cols, datas) =>
                        ExportCsv(cols, datas, "trux"),
                    },
                  ],
                }}
              />

              {/* </div> */}
              {/* <div className="card-footer">Footer</div> */}
            </div>
          </div>
          <DeleteConfirmModal
            show={showDeleteConfirm}
            onClose={onClose}
            onConfirm={onConfirm}
          />
        </section>
      )}
    </>
  );
};

export default Trucks;
