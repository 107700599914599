import http from "../../common/http-common";

// export async function addMasterPrivacyPolicyApi(data){
//     return await http.post(`/api/v1/master/privacy-policy`, data, {
//         headers: {
//             Authorization: "Bearer " + localStorage.getItem("token"),
//             'Content-Type': 'application/json'
//         },
//     } )
// } 

export async function addMasterPrivacyPolicyApi(data){
  return await http.put(`/api/v1/master/privacy-policy`, data, {
      headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          'Content-Type': 'application/json'
      },
  } )
} 

export async function getAllPrivacyPolicy() {
    return await http.get(`/api/v1/master/privacy-policy`);
  }

// export async function getPrivacyPolicydetails(id) {
//     return await http.get(`/api/v1/master/privacy-policy/${id}`);
// }

// export async function updatePrivacyPolicydetails(id, data) {
//     return await http.put(`/api/v1/master/privacy-policy/${id}`, data, {
//       headers: {
//         Authorization: "Bearer " + localStorage.getItem("token"),
//         'Content-Type': 'application/json',
//       },
//     });
//   }

//   export async function deletePrivacyPolicy(id) {
//     return await http.delete(`/api/v1/master/privacy-policy/${id}`);
//   }



 