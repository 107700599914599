import http from "../common/http-common";

export async function getAllTransportersApi() {
  return await http.get("/api/v1/transporters");
}

export async function getTransportersDetails(id) {
  return await http.get(`/api/v1/transporters/${id}`);
}

export async function getDriverName(id) {
  return await http.get(`/api/v1/transporters/${id}/drivers`);
}

export async function getTruckName(id) {
  return await http.get(`/api/v1/transporters/${id}/trucks`);
}

export async function addTransportersApi(data) {
  return await http.post("/api/v1/transporters", data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-type": "multipart/form-data",
    },
  });
}

export async function updateTransportersApi(id, data) {
  return await http.put(`/api/v1/transporters/${id}`, data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-type": "multipart/form-data",
    },
  });
}

export async function updateTransportessDocVerifyApi(id) {
  return await http.put(
    `/api/v1/transporters/${id}/trade_license_doc/approve`,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-type": "multipart/form-data",
      },
    }
  );
}

// export async function getTransporterLicenseDoc(id, i, ext) {
//   await http
//     .get(`/api/v1/transporters/${id}/trade_license_doc/${i}`, {
//       responseType: "blob",
//       headers: {
//         Authorization: "Bearer " + localStorage.getItem("token"),
//       },
//     })
//     .then((response) => {
//       const url = window.URL.createObjectURL(new Blob([response.data]));
//       const tempLink = document.createElement("a");
//       tempLink.href = url;
//       tempLink.setAttribute("download", `trade-license-${i}.${ext}`);
//       document.body.appendChild(tempLink);
//       tempLink.click();
//       document.body.removeChild(tempLink);
//       window.URL.revokeObjectURL(url);
//     })
//     .catch((err) => {
//       console.log(err);
//       // toast.error("Download Error!!");
//     });
// }

export async function getTransporterLicenseDoc(id, i, ext) {
  await http
    .get(`/api/v1/transporters/${id}/trade_license_doc/${i}`, {
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then((response) => {
      const fileType = response.headers["content-type"];
      const blob = new Blob([response.data], { type: fileType });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
      window.URL.revokeObjectURL(url);
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function deleteTransporterApi(id) {
  await http.delete(`/api/v1/transporters/${id}`);
}

export async function assingTransporterSpoc(id, data) {
  return await http.post(`/api/v1/transporters/${id}/due_diligence`, data);
}

export async function assingTransporterDuediligence(id, data) {
  return await http.put(`/api/v1/transporters/${id}/due_diligence`, data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-type": "multipart/form-data",
    },
  });
}

export async function getMasterTruckTypes() {
  return await http.get(`/api/v1/master/truck-types`, );
}

export async function getTransporterResetPassword(id) {
  return await http.get(`/api/v1/transporters/${id}/reset-password`);
}
