import ContentHeader from "../../components/content-header/ContentHeader";
import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "../../styles/Customer.css";
import {
  addCustomersApi,
  getCustomerDetails,
  getCustomerLicenseDoc,
  updateCustomersApi,
  getCustomerResetPassword,
} from "../../services/data/customers";
import axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "../../services/common/globalvars";
import { sleep } from "../../utils/helpers";
import Spinner from "react-bootstrap/Spinner";
import { hasPermission } from "../../utils/permissions";
import PageLoader from "../../components/PageLoader";
import { getMasterCounteryCode } from "../../services/data/masters";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ManageCustomer = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const date = new Date();
  // User Customer Form data
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [countryCode, setCountryCode] = useState();

  const [userdata, setUserData] = useState({
    name: "",
    type: "individual",
    email: "",
    password: "",
    cpassword: "",
    country_code: "",
    contact_no: "",
    company_name: "",
    company_address: "",
    trade_license_no: "",
    trade_license_doc: [],
    trade_license_validity: "",
    contact_person: "",
  });

  const {
    name,
    type,
    email,
    password,
    cpassword,
    country_code,
    contact_no,
    company_name,
    company_address,
    trade_license_no,
    trade_license_doc,
    trade_license_validity,
    contact_person,
  } = userdata;

  const [typeCopy, setTypeCopy] = useState();
  if (id && typeCopy && typeCopy.trim() === "individual" && type === "entity") {
    userdata.is_trade_license_doc_verified = true;
    userdata.status = "In Active";
  }

  const setDate = (date) => {
    if (date) {
      setUserData({
        ...userdata,
        trade_license_validity: `${date.getFullYear()}-${
          date.getMonth() + 1
        }-${date.getDate()}`,
      });
    }
  };

  const dataHandle = (e) => {
    console.log(e.target.value);
    setUserData({ ...userdata, [e.target.name]: e.target.value });
  };
  const fileHandle = (e) => {
    const files = Array.from(e.target.files);
    // const imageArray = files.map((file)=>URL.createObjectURL(file))
    setUserData({
      ...userdata,
      [e.target.name]: [...files],
    });

    //   console.log(e);
    //   setUserData({
    //     ...userdata,
    //     [e.target.name]: e.target.files[0],
    //     trade_license_doc_name: e.target.files[0].name,
    //   });
  };
  const handleReset = () => {
    setUserData({
      name: "",
      type: "individual",
      email: "",
      password: "",
      cpassword: "",
      country_code: "",
      contact_no: "",
      company_name: "",
      company_address: "",
      trade_license_no: "",
      trade_license_doc: [],
      trade_license_validity: "",
      contact_person: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (
      !country_code ||
      !name ||
      !email ||
      !contact_no ||
      (type === "entity" &&
        (!company_name || !trade_license_no || !trade_license_doc.length))
    ) {
      toast.error("Please fill in all required fields.");
      setIsLoading(false);
      return;
    }

    if (!id && (!password || !cpassword)) {
      toast.error("Please fill the Password");
      setIsLoading(false);
      return;
    }

    if (!id && password !== cpassword) {
      toast.error("Passwords do not match.");
      setIsLoading(false);
      return;
    }

    if (contact_no.length > 10) {
      toast.error("Mobile number too long. Maximum 10 digits allowed.");
      setIsLoading(false);
      return;
    }

    if (id) {
      await updateCustomersApi(id, userdata).then((response) => {
        console.log("submit", userdata);
        if (response.status >= 200) {
          toast.success(response.data.message);
          navigate("/customers");
        } else {
          toast.error(response.data.message);
          setIsLoading(false);
        }
      });
    } else {
      await addCustomersApi(userdata).then((response) => {
        console.log("post customer Detail", userdata);
        if (response.status === 201) {
          toast.success(response.data.message);
          navigate("/customers");
        } else {
          toast.error(response.data.message);
          setIsLoading(false);
        }
      });
    }
  };

  // useEffect(()=>{
  //   if (!id) {
  //     setIsPageLoading(true)
  //     getMasterCounteryCode()
  //     .then((response)=>{
  //       setCountryCode(response.data)
  //       setIsPageLoading(false)
  //     })
  //   }
  // },[])

  useEffect(() => {
    getMasterCounteryCode().then((response) => {
      setCountryCode(response.data);
    });
    if (id) {
      getCustomerDetails(id).then((response) => {
        console.log("Edit retrival single Customer", response.data);
        setUserData({
          name: response.data.name,
          type: response.data.type,
          email: response.data.email,
          contact_no: response.data.contact_no,
          country_code: response.data.country_code,
          company_name: response.data.company_name,
          company_address: response.data.company_address,
          trade_license_no: response.data.trade_license_no,
          trade_license_doc: response.data.trade_license_doc,
          trade_license_validity: response.data.trade_license_validity,
          contact_person: response.data.contact_person,
        });
        setTypeCopy(response.data.type);
        setIsPageLoading(false);
      });
    } else {
      handleReset();
      setIsPageLoading(false);
    }
  }, [id]);

  function customerResetPassword(userId) {
    getCustomerResetPassword(userId).then((response) => {
      toast.success(response.data.message);
    });
  }

  return (
    <>
      <div>
        <ContentHeader title={id ? "Edit Customer" : "Customer"} />
        {isPageLoading ? (
          <PageLoader />
        ) : (
          <section className="content">
            <div className="container-fluid">
              <div className="card">
                {isLoading && (
                  <div className="overlay">
                    <i className="fas fa-2x fa-sync fa-spin" />
                  </div>
                )}
                {/* <div className="card-header">
                  <h3 className="card-title">Customer User Form</h3>
                </div> */}
                <div className="card-body">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="custom-control custom-radio">
                                <input
                                  className="custom-control-input"
                                  type="radio"
                                  id="individual"
                                  name="type"
                                  value="individual"
                                  checked={type === "individual"}
                                  onChange={dataHandle}
                                />
                                <label
                                  htmlFor="individual"
                                  className="custom-control-label"
                                >
                                  Individual
                                </label>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="custom-control custom-radio">
                                <input
                                  className="custom-control-input"
                                  type="radio"
                                  id="entity"
                                  value={"entity"}
                                  name="type"
                                  checked={type === "entity"}
                                  onChange={dataHandle}
                                />
                                <label
                                  htmlFor="entity"
                                  className="custom-control-label"
                                >
                                  Entity
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="customerName">Customer Name</label>
                          <input
                            type="text"
                            name="name"
                            value={name}
                            placeholder="Customer Name"
                            onChange={dataHandle}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="email">Email Address</label>
                        
                            <input
                              type="email"
                              name="email"
                              value={email}
                              placeholder="Email Address"
                              onChange={dataHandle}
                              className="form-control"
                            />
                         
                        </div>
                      </div>
                      {!id ? (
                        <>
                          <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                              <label htmlFor="password">Password</label>
                              <input
                                type="password"
                                name="password"
                                value={password}
                                placeholder={"Password"}
                                onChange={dataHandle}
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                              <label htmlFor="password">Confirm Password</label>
                              <input
                                type="password"
                                name="cpassword"
                                value={cpassword}
                                placeholder={"Confirm Password"}
                                onChange={dataHandle}
                                className="form-control"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="col-md-6 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <a
                              onClick={() => customerResetPassword(id)}
                              className="btn btn-link form-control"
                              style={{
                                textDecoration: "underline",
                                textAlign: "left",
                                padding: 0,
                              }}
                            >
                              Click here to reset password
                            </a>
                          </div>
                        </div>
                      )}

                      <div className="col-md-6 col-sm-12">
                        <div className="row">
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label htmlFor="countryCode">Country Code</label>
                              <select
                                id="countryCode"
                                name="country_code"
                                value={country_code}
                                defaultValue={country_code}
                                onChange={dataHandle}
                                className="form-control"
                              >
                                <option value="">Select..</option>
                                {countryCode &&
                                  countryCode.map((item) => {
                                    return (
                                      <option value={item.country_code}>
                                        +{item.country_code} {item.country}
                                      </option>
                                    );
                                  })}
                              </select>
                              {/* <b className="text-danger">
                                {country_code.length === 0
                                  ? "Select the country Code"
                                  : ""}
                              </b> */}
                            </div>
                          </div>
                          <div className="col-md-8 col-sm-12">
                            <div className="form-group">
                              <label htmlFor="contactNo">Contact No</label>
                              <input
                                type="number"
                                name="contact_no"
                                value={contact_no}
                                placeholder="Contact No"
                                onChange={dataHandle}
                                className="form-control"
                              />
                              <b className="text-danger">
                                {contact_no.length > 10
                                  ? "Mobile number too long. Maximum 10 digits allowed."
                                  : ""}
                              </b>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="contactNo">Contact No</label>
                          <div className="row">
                            <div className="col-4">
                              <select
                                name="country_code"
                                value={country_code}
                                onChange={dataHandle}
                                className="form-control"
                              >
                                <option value="+968">+968</option>
                                <option value="+967">+967</option>
                                <option value="+966">+966</option>
                              </select>
                            </div>
                            <div className="col-8">
                              <input
                                type="tel"
                                name="contact_no"
                                value={contact_no}
                                placeholder="Contact No"
                                onChange={dataHandle}
                                className="form-control"
                              />
                            </div>
                          </div>
                        </div>
                      </div> */}

                      {userdata.type === "entity" && (
                        <>
                          <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                              <label htmlFor="tradeLicenseNo">
                                Contact Person
                              </label>
                              <input
                                type="text"
                                name="contact_person"
                                value={contact_person}
                                placeholder="Contact Person"
                                onChange={dataHandle}
                                className="form-control"
                              />
                            </div>
                          </div>

                          <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                              <label htmlFor="companyName">Company Name</label>
                              <input
                                type="text"
                                name="company_name"
                                value={company_name}
                                placeholder="Company Name"
                                onChange={dataHandle}
                                className="form-control"
                              />
                            </div>
                          </div>

                          <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                              <label htmlFor="companyAddress">
                                Company Address
                              </label>
                              <input
                                type="text"
                                name="company_address"
                                value={company_address}
                                placeholder="Company Address"
                                onChange={dataHandle}
                                className="form-control"
                              />
                            </div>
                          </div>

                          <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                              <label htmlFor="tradeLicenseNo">
                                Trade/Commercial License No
                              </label>
                              <input
                                type="text"
                                name="trade_license_no"
                                value={trade_license_no}
                                placeholder="Trade/Commercial License No"
                                onChange={dataHandle}
                                className="form-control"
                              />
                            </div>
                          </div>

                          {/* <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                              <label htmlFor="tradeLicenseValidity">
                                Trade/Commercial License Validity
                              </label>
                              <input
                                type="date"
                                name="trade_license_validity"
                                value={
                                  trade_license_validity
                                    ? new Date(
                                        trade_license_validity
                                      ).toLocaleDateString("en-CA")
                                    : ""
                                }
                                placeholder="dd-mm-yyyy"
                                onChange={dataHandle}
                                className="form-control"
                              />
                            </div>
                          </div> */}

                          <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                              <label htmlFor="exampleInputFile">
                                Trade/Commercial License Document
                                {id &&
                                  trade_license_doc?.map((item) => {
                                    return (
                                      <a
                                        className="btn btn-link p-0"
                                        onClick={(e) =>
                                          getCustomerLicenseDoc(
                                            id,
                                            item.id,
                                            item.file_ext
                                          )
                                        }
                                      >
                                        <i className="far fa-fw fa-file" />
                                        {`doc-${item.id}.${item.file_ext}`}
                                      </a>
                                    );
                                  })}
                              </label>

                              <div className="input-group">
                                <div className="custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                    id="exampleInputFile"
                                    name="trade_license_doc"
                                    multiple
                                    accept="image/*,.pdf"
                                    onChange={fileHandle}
                                  />
                                  <label
                                    className="custom-file-label"
                                    htmlFor="exampleInputFile"
                                  >
                                    {trade_license_doc &&
                                      trade_license_doc.map(
                                        (file) => file.name
                                      )}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                              <label htmlFor="tradeLicenseValidity">
                                Trade/Commercial License Validity
                              </label>
                              <div className="input-group">
                                <DatePicker
                                  name="trade_license_validity"
                                  // selected={
                                  //   userdata.trade_license_validity
                                  //   && userdata.trade_license_validity ?
                                  //   new Date(userdata.trade_license_validity) : null
                                  //   }
                                  value={
                                    trade_license_validity
                                      ? new Date(
                                          trade_license_validity
                                        ).toLocaleDateString("en-GB", {
                                          day: "2-digit",
                                          month: "2-digit",
                                          year: "numeric",
                                        })
                                      : trade_license_validity
                                  }
                                  dateFormat="dd/MM/yyyy"
                                  onChange={setDate}
                                  showYearDropdown
                                  scrollableYearDropdown
                                  placeholderText="dd/MM/yyyy"
                                  className="form-control"
                                  minDate={new Date()}
                                  popperPlacement="top"
                                />
                                <div className="input-group-append position-relative">
                                  <span
                                    className="input-group-text position-absolute d-flex align-items-center"
                                    style={{ height: "100%" }}
                                  >
                                    <i className="fa fa-calendar"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                              <label htmlFor="tradeLicenseValidityDate">
                                Trade License Validity Date
                              </label>
                              <input
                                type="date"
                                name="trade_license_validity"
                                value={
                                  trade_license_validity
                                    ? new Date(
                                        trade_license_validity
                                      ).toLocaleDateString("en-CA")
                                    : ""
                                }
                                placeholder="dd-mm-yyyy"
                                onChange={dataHandle}
                                className="form-control"
                              />
                            </div>
                          </div> */}
                        </>
                      )}

                      {/* Row End */}
                    </div>
                  </div>
                  <div className="text-right">
                    {hasPermission("customers.create") && (
                      <button
                        type="reset"
                        className="btn btn-warning mr-3"
                        onClick={handleReset}
                      >
                        Reset
                      </button>
                    )}
                    {hasPermission("customers.edit") &&
                      hasPermission("customers.create") && (
                        <button
                          type="button"
                          className="btn btn-info mr-3"
                          onClick={handleSubmit}
                        >
                          Submit
                        </button>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    </>
  );
};

export default ManageCustomer;
