import React, { useState, useEffect } from "react";
import ContentHeader from "../../components/content-header/ContentHeader";
import { useNavigate, useParams } from "react-router-dom";
import PageLoader from "../../components/PageLoader";
import { toast } from "react-toastify";
import { Container, Card, Row, Col } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import {
  addBookingApi,
  getBookingApi,
  updateBookingApi,
} from "../../services/data/bookings";
import { getAllTruckTypeApi } from "../../services/data/trucktype";

const BookingForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [truckTypes, setTruckTypes] = useState([]);

  useEffect(() => {
    const fetchTruckTypes = async () => {
      try {
        const response = await getAllTruckTypeApi();
        console.log("API Response :", response);
        setTruckTypes(response.data);
      } catch (error) {
        console.error("Error fetching truck types:", error);
      }
    };
    fetchTruckTypes();
  }, []);
  // console.log("truckTypes :", truckTypes);

  const [initialValues, setInitialValues] = useState({
    origin: "",
    destination1: "",
    destination2: "",
    multipleDestination: false,
    shipmentDate: "",
    hazardous: "",
    compliance: "",
    truckCargo: "",
    generalBulk: "",
    weightPerTrip: "",
    weight: "",
    weightPerCbm: "",
    truckList: "",
    temperature: "",
    description: "",
    numberOfPackages: "",
    cargoName: "",
    numberOfTrips: "",
    typeOfPackage: "",
    length: "",
    width: "",
    height: "",
  });

  const validationSchema = Yup.object().shape({
    origin: Yup.string().required("Origin is required"),
    destination1: Yup.string().required("Destination is required"),
    destination2: Yup.string().nullable(),
    shipmentDate: Yup.date().required("Date and time of shipment is required"),
    hazardous: Yup.string().required("Please select the nature of goods"),
    compliance: Yup.string().required("Compliance is required"),
    truckCargo: Yup.string().required("Please select Truck or Cargo"),
    weightPerTrip: Yup.number().nullable(),
    weight: Yup.number().nullable(),
    weightPerCbm: Yup.number().nullable(),
    truckList: Yup.string().nullable(),
    temperature: Yup.number().nullable(),
    typeOfPackage: Yup.string().nullable(),
    length: Yup.number().nullable(),
    width: Yup.number().nullable(),
    height: Yup.number().nullable(),
    description: Yup.string().required("Description of goods is required"),
    numberOfPackages:Yup.number().nullable(),
    cargoName: Yup.string().required("Cargo name is required"),
    numberOfTrips: Yup.number().nullable(),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      const formattedValues = {
        origin: values.origin,
        destination: {
          destination1: values.destination1,
          destination2: values.destination2,
        },
        date_and_time_of_shipment: values.shipmentDate,
        truck_type_id: values.truckList,
        compliances: {
          hazardous: values.hazardous,
          compliance: values.compliance,
        },
        description: values.description,
        no_of_packages: values.numberOfPackages,
        cargo_name: values.cargoName,
        
        truck:
          values.truckCargo === "truck"
            ? {
                weight_per_trip: values.weightPerTrip,
                no_of_similar_trips: values.numberOfTrips,
                temperature: values.temperature,
              }
            : null,
        cargo_general:
          values.truckCargo === "cargo" && values.generalBulk === "general"
            ? {
                length: values.length,
                width: values.width,
                height: values.height,
                package_type: values.typeOfPackage,
                weight: values.weight,
                no_of_similar_trips: values.numberOfTrips,
              }
            : null,
        cargo_bulk:
          values.truckCargo === "cargo" && values.generalBulk === "bulk"
            ? {
                weight: values.weight,
                weight_per_cbm: values.weightPerCbm,
              }
            : null,
      };

      console.log("formattedValues :", formattedValues);

      if (id) {
        await updateBookingApi(id, formattedValues);
        toast.success("Booking updated successfully!");
      } else {
        await addBookingApi(formattedValues);
        toast.success("Booking added successfully!");
      }
      setSubmitting(false);
      navigate("/bookings");
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error submitting form. Please try again later.");
      setSubmitting(false);
    }
  };

  const fetchBookingData = async (id) => {
    try {
      const response = await getBookingApi(id);
      const data = response.data;
      const shipmentDate = data.date_and_time_of_shipment.substring(0, 19);
      const { hazardous, compliance } = data.compliances;
      // console.log("data.truck_type :",data.truck_type);
      setInitialValues({
        origin: data.origin,
        destination1: data.destination.destination1,
        destination2: data.destination.destination2 || "",
        multipleDestination: Boolean(data.destination.destination2),
        shipmentDate: shipmentDate,
        hazardous: hazardous === "hazardous" ? "hazardous" : "non-hazardous",
        compliance: compliance,
        truckCargo: data.booking_type,
        generalBulk: data.weight_per_cbm ? "bulk" : "general",
        weightPerTrip: data.weight_per_trip,
        weight: data.weight,
        weightPerCbm: data.weight_per_cbm,
        truckList: data.truck_type ? data.truck_type.id.toString() : "", // null check here
        temperature: data.temperature,
        description: data.description,
        numberOfPackages: data.no_of_packages,
        cargoName: data.cargo_name,
        numberOfTrips: data.no_of_trips,
        typeOfPackage: data.package_type || "",
        length: data.length,
        width: data.width,
        height: data.height,
      });
    } catch (error) {
      console.error("Error fetching booking data:", error);
    }
  };

  useEffect(() => {
    setIsPageLoading(false);
  }, []);

  useEffect(() => {
    if (id === null || id === undefined) {
      setIsPageLoading(false);
      return;
    }

    fetchBookingData(id);
    setIsPageLoading(false);
  }, [id]);

  const getComplianceOptions = (hazardous) => {
    if (hazardous === "hazardous") {
      return [
        { value: "", label: "Select compliance" },
        { value: "regular", label: "Regular" },
        { value: "oil-and-gas", label: "Oil and Gas" },
      ];
    } else if (hazardous === "non-hazardous") {
      return [
        { value: "", label: "Select compliance" },
        { value: "regular", label: "Regular" },
        { value: "oil-and-gas", label: "Oil and Gas" },
        { value: "food-grade", label: "Food Grade" },
      ];
    } else {
      return [{ value: "", label: "Select compliance" }];
    }
  };

  const MultipleDestinationField = () => {
    const { values } = useFormikContext();
    return values.multipleDestination ? (
      <Col md={6}>
        <div className="form-group">
          <label>Destination 2</label>
          <Field
            type="text"
            name="destination2"
            placeholder="Destination 2"
            className="form-control"
          />
          <ErrorMessage
            name="destination2"
            component="div"
            className="text-danger"
          />
        </div>
      </Col>
    ) : null;
  };

  return (
    <>
      <ContentHeader title={id ? "Edit Booking" : "New Booking"} />
      {isPageLoading ? (
        <PageLoader />
      ) : (
        <section className="content">
          <Container fluid>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, values }) => (
                <Form>
                  <Card>
                    <Card.Body>
                      <Row>
                        {/* Common fields for all booking types */}
                        <Col md={6}>
                          <div className="form-group">
                            <label>
                              Origin <i className="text-danger">*</i>
                            </label>
                            <Field
                              type="text"
                              name="origin"
                              placeholder="Origin"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="origin"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="form-group">
                            <label>
                              Destination <i className="text-danger">*</i>
                              <Field
                                type="checkbox"
                                name="multipleDestination"
                                className="ml-2"
                              />
                              <span className="ml-1">
                                Add Multiple Destination
                              </span>
                            </label>
                            <Field
                              type="text"
                              name="destination1"
                              placeholder="Destination"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="destination1"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Col>
                        <MultipleDestinationField />
                        <Col md={6}>
                          <div className="form-group">
                            <label>
                              Date and Time Of Shipment{" "}
                              <i className="text-danger">*</i>
                            </label>
                            <Field
                              type="datetime-local"
                              name="shipmentDate"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="shipmentDate"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="form-group">
                            <label>
                              Nature of Goods <i className="text-danger">*</i>
                            </label>
                            <div>
                              <label className="mr-3">
                                <Field
                                  type="radio"
                                  name="hazardous"
                                  value="hazardous"
                                />
                                Hazardous
                              </label>
                              <label>
                                <Field
                                  type="radio"
                                  name="hazardous"
                                  value="non-hazardous"
                                />
                                Non-Hazardous
                              </label>
                            </div>
                            <ErrorMessage
                              name="hazardous"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="form-group">
                            <label>
                              Compliance <i className="text-danger">*</i>
                            </label>
                            <Field
                              as="select"
                              name="compliance"
                              className="form-control"
                            >
                              {getComplianceOptions(values.hazardous).map(
                                (option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                )
                              )}
                            </Field>
                            <ErrorMessage
                              name="compliance"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="form-group">
                            <label>
                              Choose Truck or Cargo{" "}
                              <i className="text-danger">*</i>
                            </label>
                            <div>
                              <label className="btn btn-success mr-3">
                                <Field
                                  type="radio"
                                  name="truckCargo"
                                  value="truck"
                                  hidden
                                  // disabled = {values.truckCargo === "cargo" ?  false : true }
                                />
                                Truck
                              </label>
                              <label className="btn btn-info mr-3">
                                <Field
                                  type="radio"
                                  name="truckCargo"
                                  value="cargo"
                                  hidden
                                  // disabled = {values.truckCargo === "truck" ?  false : false }
                                />
                                Cargo
                              </label>
                            </div>
                            <ErrorMessage
                              name="truckCargo"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Col>

                        {/* Fields for truck booking type */}
                        {values.truckCargo === "truck" && (
                          <>
                            <Col md={6}>
                              <div className="form-group">
                                <label>Weight Per Trip (in Tons)</label>
                                <Field
                                  type="number"
                                  name="weightPerTrip"
                                  className="form-control"                                  
                                />
                                <ErrorMessage
                                  name="weightPerTrip"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="form-group">
                                <label>Choose Truck List</label>
                                <Field
                                  as="select"
                                  name="truckList"
                                  className="form-control"
                                >
                                  <option value="">Select Truck List</option>
                                  {truckTypes.map((type) => (
                                    <option
                                      key={type.id}
                                      value={type.id.toString()}
                                    >
                                      {type.truck_type}
                                    </option>
                                  ))}
                                </Field>
                                <ErrorMessage
                                  name="truckList"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="form-group">
                                <label>Temperature (in Celsius)</label>
                                <Field
                                  type="number"
                                  name="temperature"
                                  className="form-control"
                                />
                                <ErrorMessage
                                  name="temperature"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </Col>
                          </>
                        )}
                        {values.truckCargo === "cargo" && (
                          <Col md={6}>
                            <div className="form-group">
                              <label>
                                Choose General or Bulk{" "}
                                <i className="text-danger">*</i>
                              </label>
                              <div>
                                <label className="btn btn-success mr-3">
                                  <Field
                                    type="radio"
                                    name="generalBulk"
                                    value="general"
                                    hidden
                                  />
                                  <img
                                    src="/img/pickup_truck.png"
                                    alt="pickup_truck"
                                    width={40}
                                  />
                                  <br />
                                  General
                                </label>
                                <label className="btn btn-info mr-3">
                                  <Field
                                    type="radio"
                                    name="generalBulk"
                                    value="bulk"
                                    hidden
                                  />
                                  <img
                                    src="/img/pickup_truck.png"
                                    alt="pickup_truck"
                                    width={40}
                                  />
                                  <br />
                                  Bulk
                                </label>
                              </div>
                              <ErrorMessage
                                name="generalBulk"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </Col>
                        )}

                        {/* Fields for cargo general booking type */}
                        {values.truckCargo === "cargo" &&
                          values.generalBulk === "general" && (
                            <>
                              <Col md={6}>
                                <div className="form-group">
                                  <label>Choose Type of Package</label>
                                  <Field
                                    as="select"
                                    name="typeOfPackage"
                                    className="form-control"
                                  >
                                    <option value="">
                                      Select Type of Package
                                    </option>
                                    <option value="palletised">
                                      Palletised
                                    </option>
                                    <option value="non-palletised">
                                      Non-Palletised
                                    </option>
                                    <option value="container-dry-van">
                                      Container Dry Van
                                    </option>
                                    <option value="container-reefer">
                                      Container - Reefer
                                    </option>
                                    <option value="container-duel-20-dry-van">
                                      Container - Duel 20 Dry Van
                                    </option>
                                    <option value="palletised-non-palletised">
                                      Palletised/Non-Palletised
                                    </option>
                                    <option value="equipment">Equipment</option>
                                  </Field>
                                  <ErrorMessage
                                    name="typeOfPackage"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="form-group">
                                  <label>Weight (in Tons)</label>
                                  <Field
                                    type="number"
                                    name="weight"
                                    className="form-control"
                                  />
                                  <ErrorMessage
                                    name="weight"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="form-group">
                                  <label>Length (in Meters)</label>
                                  <Field
                                    type="number"
                                    name="length"
                                    className="form-control"
                                  />
                                  <ErrorMessage
                                    name="length"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="form-group">
                                  <label>Width (in Meters)</label>
                                  <Field
                                    type="number"
                                    name="width"
                                    className="form-control"
                                  />
                                  <ErrorMessage
                                    name="width"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="form-group">
                                  <label>Height (in Meters)</label>
                                  <Field
                                    type="number"
                                    name="height"
                                    className="form-control"
                                  />
                                  <ErrorMessage
                                    name="height"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                              </Col>
                            </>
                          )}
                        {/* Fields for cargo bulk booking type */}
                        {values.truckCargo === "cargo" &&
                          values.generalBulk === "bulk" && (
                            <>
                              <Col md={6}>
                                <div className="form-group">
                                  <label>Weight (in Tons)</label>
                                  <Field
                                    type="number"
                                    name="weight"
                                    className="form-control"
                                  />
                                  <ErrorMessage
                                    name="weight"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="form-group">
                                  <label>Weight Per CBM (in Tons)</label>
                                  <Field
                                    type="number"
                                    name="weightPerCbm"
                                    className="form-control"
                                  />
                                  <ErrorMessage
                                    name="weightPerCbm"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                              </Col>
                            </>
                          )}
                        {/* Common fields for all booking types */}
                        <Col md={6}>
                          <div className="form-group">
                            <label>
                              Description of Goods{" "}
                              <i className="text-danger">*</i>
                            </label>
                            <Field
                              type="text"
                              name="description"
                              placeholder="Description of Goods"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="description"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="form-group">
                            <label>
                              Cargo Name <i className="text-danger">*</i>
                            </label>
                            <Field
                              type="text"
                              name="cargoName"
                              placeholder="Cargo Name"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="cargoName"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Col>
                        {(values.generalBulk === "general" ||
                          values.truckCargo === "truck") && (
                          <Col md={6}>
                            <div className="form-group">
                              <label>
                                Number of Packages{" "}
                                <i className="text-danger">*</i>
                              </label>
                              <Field
                                type="number"
                                name="numberOfPackages"
                                placeholder="Number of Packages"
                                className="form-control"
                              />
                              <ErrorMessage
                                name="numberOfPackages"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </Col>
                        )}
                        {(values.generalBulk === "general" ||
                          values.truckCargo === "truck") && (
                          <Col md={6}>
                            <div className="form-group">
                              <label>
                                Number of Similar Trips{" "}
                                <i className="text-danger">*</i>
                              </label>
                              <Field
                                type="number"
                                name="numberOfTrips"
                                placeholder="Number of Similar Trips"
                                className="form-control"
                              />
                              <ErrorMessage
                                name="numberOfTrips"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </Col>
                        )}
                      </Row>
                    </Card.Body>
                    <Card.Footer className="text-center">
                      <button type="reset" className="btn btn-warning mr-3">
                        Reset
                      </button>
                      <button
                        type="submit"
                        className="btn btn-info mr-3"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Submitting" : "Submit"}
                      </button>
                    </Card.Footer>
                  </Card>
                </Form>
              )}
            </Formik>
          </Container>
        </section>
      )}
    </>
  );
};

export default BookingForm;
