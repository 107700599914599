import React, { useState, useEffect } from "react";
import ContentHeader from "../../components/content-header/ContentHeader";
import { Link } from "react-router-dom";
import MaterialTable from "@material-table/core";
import { ExportCsv } from "@material-table/exporters";
import {
  getAllCargoType,
  deleteCargoType,
} from "../../services/data/masters/packagetype";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";
import { toast } from "react-toastify";
import {
  getCargoTypedetails,
  updateCargoTypedetails,
} from "../../services/data/masters/packagetype";

const CargoType = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteCargoTypeId, setDeleteCargoTypeId] = useState();

  useEffect(() => {
    // Fetch cargo types from the backend when the component mounts
    fetchCargoTypes();
  }, []);

  const fetchCargoTypes = async () => {
    try {
      const response = await getAllCargoType();
      setData(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching cargo types:", error);
      // Handle error as needed
    }
  };

  const handleDelete = async (id) => {
    try {
      // Delete cargo type from the backend
      await deleteCargoType(id);
      // Update the data state to reflect the deletion
      setData(data.filter((item) => item.id !== id));
      toast.success("Cargo type deleted successfully!");
      setShowDeleteConfirm(false);
    } catch (error) {
      console.error("Error deleting cargo type:", error);
      // Handle error as needed
    }
  };

  const handleStatusChange = async (id, isActive) => {
    try {
      // Update status in the backend
      const response = await updateCargoTypedetails(id, { isActive });
      // Update the data state to reflect the status change
      setData(
        data.map((item) => (item.id === id ? { ...item, isActive } : item))
      );
      const statusMessage = isActive ? "activated" : "deactivated";
      toast.success(`Cargo type ${statusMessage} successfully!`);
    } catch (error) {
      console.error("Error updating cargo type status:", error);
      // Handle error as needed
    }
  };

  const columns = [
    {
      title: "Name",
      field: "name",
      render: (rowData) => (
        <div>
          <b>{rowData.name}</b>
        </div>
      ),
    },
    {
      title: "Active",
      field: "isActive",
      render: (rowData) => (
        <button
          className={`btn btn-${rowData.isActive ? "success" : "warning"}`}
          onClick={() => handleStatusChange(rowData.id, !rowData.isActive)}
        >
          {rowData.isActive ? "Active" : "Inactive"}
        </button>
      ),
    },
    {
      title: "Action",
      render: (rowData) => (
        <>
          <Link
            to={`/cargo-types/${rowData.id}`}
            className="btn ml-0 pl-0 mr-2"
          >
            <i className="fas fa-edit " style={{ color: "#004751" }} />
          </Link>
          <button
            className="btn "
            onClick={() => {
              setDeleteCargoTypeId(rowData.id);
              setShowDeleteConfirm(true);
            }}
          >
            <i className="fas fa-trash-alt" style={{ color: "#D46B6B" }}></i>
          </button>
        </>
      ),
    },
  ];

  const onConfirmDelete = () => {
    handleDelete(deleteCargoTypeId);
  };

  return (
    <div>
      <ContentHeader title="Cargo Types" />
      <section className="content">
        <div className="container-fluid">
          <div className="card bg-transparent border-0">
            <div className="card-header">
              <h3 className="card-title">Cargo Type List</h3>
              <Link to="/new-cargo-type" className="float-right btn btn-info">
                New Cargo
              </Link>
            </div>
            <div className="card-body p-0 m-0">
              <MaterialTable
                style={{ backgroundColor: "#f4f6f9" }}
                title=""
                data={data}
                columns={columns}
                options={{
                  exportAllData: true,
                  exportMenu: [
                    {
                      label: "Export CSV",
                      exportFunc: (cols, datas) =>
                        ExportCsv(cols, datas, "Cargo Type List"),
                    },
                  ],
                  rowStyle:(rowData) => ({
                    borderRadius: '10% !important', // Rounded corners
                    backgroundColor: "#FFF",
                    borderTop: '15px solid #f4f6f9', // Transparent top border
                    borderBottom: '15px solid #f4f6f9' // Transparent bottom border
                  }),
                }}
              />
            </div>
          </div>
        </div>
      </section>
      <DeleteConfirmModal
        show={showDeleteConfirm}
        onClose={() => setShowDeleteConfirm(false)}
        onConfirm={onConfirmDelete}
      />
    </div>
  );
};

export default CargoType;
