import http from "../../common/http-common";

export async function addMasterSupportApi(data){
    return await http.post(`/api/v1/support`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-type": "multipart/form-data",
      },
    } )
} 

export async function getAllSupport() {
    return await http.get(`/api/v1/support`);
  }

export async function getSupportdetails(id) {
    return await http.get(`/api/v1/support${id}`);
}

export async function updateSupportdetails(id, data) {
    return await http.put(`/api/v1/support${id}`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-type": "multipart/form-data",
      },
    });
  }

  export async function deleteSupport(id) {
    return await http.delete(`/api/v1/support${id}`);
  }



 