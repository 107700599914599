import http from "../common/http-common";

export async function getUsersDetail(data) {
  return await http.get(`/api/v1/users/filter?role=${data}`)
}

export async function addPushNotificationsApi(data){
  return await http.post(`/api/v1/notifications`, data, {
      headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          'Content-Type': 'application/json'
      },
  } )
} 

export async function getAllUsersDetailNotifications() {
  return await http.get(`/api/v1/notifications`)
}


// export async function getAllUsersApi(data) {
//   return await http.get(`/api/v1/users/filter?role=${data}`);
// }


// export async function addUsersApi(data, roles) {
//   const queryParams = new URLSearchParams({ role: roles });
//   const url = `/api/v1/users/filter?${queryParams.toString()}`;

//   return await http.post(url, data, {
//     headers: {
//       Authorization: "Bearer " + localStorage.getItem("token"),
//       "Content-type": "multipart/form-data",
//     },
//   });
// }

// export async function addUsersApi(data) {
//   return await http.post(`/api/v1/users/filter?role=${data}`
//   );
// }

