import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ContentHeader from "../../components/content-header/ContentHeader";
import MaterialTable, { Column } from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { getDriverApi,
         updateDriverApi,
         getdriverLicenseDoc } from "../../services/data/drivers";
import { toast } from "react-toastify";
import Accordion from "react-bootstrap/Accordion";
import {Link} from "react-router-dom"
import PageLoader from "../../components/PageLoader";



const DriverProfile = () => {
  const { id } = useParams();
  const [driverInfo, setDruckInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(true)

  console.log('driver Profile', driverInfo)

  useEffect(() => {
    getDriverApi(id).then((response) => {
      setDruckInfo(response.data);
      setIsLoading(false)
    });
  }, []);
  
  const data = [];

  const columns = [
    {
      title: "Booking No",
      field: "booking_no",
    },
    {
      title: "Origin",
      field: "origin",
    },
    {
      title: "Destination",
      field: "destination",
    },
    {
      title: "Booked On",
      field: "booked_on",
    },
    {
      title: "ETA",
      field: "eta",
    },
    {
      title: "Rating",
      field: "rating",
    },
    {
      title: "Status",
      field: "status",
    },
  ];

  const changeStatus = (isActive, id) => {
    const payload = {
      is_active: isActive,
    };
    updateDriverApi(id, payload).then((response) => {
      if(isActive){
        toast.success("User Activated!");
      }else{
        toast.info("User Deactivated!");
      }
      
      getDriverApi(id).then((response) => {
        setDruckInfo(response.data);
      });
    });
  };

  return (
    <>
      <div>
        <ContentHeader title="Drivers" />
        {
          isLoading ? 
          (
           <PageLoader />
          ):(
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-3">
                    <div className="card card-primary">
                      <div className="card-body box-profile">
                        <div className="text-center">
                          <img
                            className="img-fluid"
                            src="/img/avatar.png"
                            alt="Truck picture"
                          />
                        </div>
                        <h3 className="profile-username text-center">
                          {driverInfo.name}
                         </h3>
                        <h3 className="profile-username text-center">
                          {driverInfo.status === "In Active" && (
                            <button
                              type="button"
                              className="btn btn-warning"
                              onClick={(e) => changeStatus(true, id)}
                            >
                              In Active
                            </button>
                          )}
                          {driverInfo.status === "Active" && (
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={(e) => changeStatus(false, id)}
                            >
                              Active
                            </button>
                          )}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6">
                            <ul className="list-group">
                              <li className="list-group-item border-0">
                                <div className="row">
                                  <div className="col-md-6 col-6">
                                    <b>Transporter</b>
                                  </div>
                                  <div className="col-md-6 col-6">
                                    <h6>{
                                      driverInfo.transporter?.company_name && 
                                      driverInfo.transporter.company_name }
                                    </h6>
                                  </div>
                                </div>
                              </li>

                              <li className="list-group-item border-0">
                                <div className="row">
                                  <div className="col-md-6 col-6">
                                    <b>Driver Name</b>
                                  </div>
                                  <div className="col-md-6 col-6">
                                    <h6>{driverInfo.name ?? '-'}</h6>
                                  </div>
                                </div>
                              </li>
                              <li className="list-group-item border-0">
                                <div className="row">
                                  <div className="col-md-6 col-6">
                                    <b>Contact No</b>
                                  </div>
                                  <div className="col-md-6 col-6">
                                    <h6>+{
                                      driverInfo.country_code+'-'+driverInfo.contact_no
                                    }</h6>
                                  </div>
                                </div>
                              </li>
                              <li className="list-group-item border-0">
                                <div className="row">
                                  <div className="col-md-6 col-6">
                                    <b>Email Id</b>
                                  </div>
                                  <div className="col-md-6 col-6">
                                    <h6>{driverInfo.email ?? '-'}</h6>
                                  </div>
                                </div>
                              </li>
                              <li className="list-group-item border-0">
                                <div className="row">
                                  <div className="col-md-6 col-6">
                                    <b>Address</b>
                                  </div>
                                  <div className="col-md-6 col-6">
                                    <h6>{driverInfo.address??'-'}</h6>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>

                          <div className="col-md-6">
                            <ul className="list-group">
                              <li className="list-group-item border-0">
                                <div className="row">
                                  <div className="col-md-6 col-6">
                                    <b>License No</b>
                                  </div>
                                  <div className="col-md-6 col-6">
                                    <h6>{driverInfo.license_no ??'-'}</h6>
                                  </div>
                                </div>
                              </li>
                              <li className="list-group-item border-0">
                                <div className="row">
                                  <div className="col-md-6 col-6">
                                    <b>License</b>
                                  </div>
                                  <div className="col-md-6 col-6">
                                  <h6>
                                  <Accordion defaultActiveKey="0">
                                    <Accordion.Toggle as={Link} variant="link" eventKey="1">
                                      {/* <i className="fas fa-plus"></i> */}
                                      <a 
                                        className="btn btn-link p-0"
                                        style={{ textDecoration: "underline" }}
                                        >
                                        View documents
                                      </a>
                                    </Accordion.Toggle>
                                    {driverInfo.license_doc &&
                                      driverInfo.license_doc.map(
                                        (item) => {
                                          return (
                                            <Accordion.Collapse eventKey="1">
                                              <a
                                                className="btn btn-link p-0"
                                                onClick={(e) =>
                                                  getdriverLicenseDoc(
                                                    id,
                                                    item.id,
                                                    item.file_ext
                                                  )
                                                }
                                              >
                                                <i className="far fa-fw fa-file-pdf" />
                                                {`trade-license-${item.id}.${item.file_ext}`}
                                              </a>
                                            </Accordion.Collapse>
                                          );
                                        }
                                      )}
                                  </Accordion>
                                  </h6>
                                  </div>
                                </div>
                              </li>

                              <li className="list-group-item border-0">
                                <div className="row">
                                  <div className="col-md-6 col-6">
                                    <b>License Validity</b>
                                  </div>
                                  <div className="col-md-6 col-6">
                                    <h6>
                                      {driverInfo.license_validity
                                        ? new Date(
                                            driverInfo.license_validity
                                          ).toLocaleDateString("en-GB", 
                                          { day: "2-digit", month: "2-digit", year: "numeric" }) 
                                        : "-"}
                                    </h6>
                                  </div>
                                </div>
                              </li>

                              <li className="list-group-item border-0">
                                <div className="row">
                                  <div className="col-md-6 col-6">
                                    <b>Enrolled</b>
                                  </div>
                                  <div className="col-md-6 col-6">
                                  <h6>
                                    {driverInfo.enrolled_on ?
                                     (
                                        new Date(
                                          driverInfo.enrolled_on
                                        ).toLocaleDateString("en-GB", 
                                        { day: "2-digit", month: "2-digit", year: "numeric" }) 
                                      ) : "-"
                                    }
                                  </h6>
                                  </div>
                                </div>
                              </li>
                                                      
                              <li className="list-group-item border-0">
                                <div className="row">
                                  <div className="col-md-6 col-6">
                                    <b>Total Trips</b>
                                  </div>
                                  <div className="col-md-6 col-6">
                                    <h6>{driverInfo.total_trips ?? "0"}</h6>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <h5 style={{ textDecoration: "underLine" }}>
                      Trips History
                    </h5>
                    <div className="card">
                      <MaterialTable
                        style={{ 
                          backgroundColor: "white",                      
                      }}
                        columns={columns}
                        data={data}
                        title=""
                        options={{
                          exportAllData: true,
                          exportMenu: [
                            {
                              label: "Export CSV",
                              exportFunc: (cols, datas) =>
                                ExportCsv(cols, datas, "trux"),
                            },
                          ],
                          pageSize: 3,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )
        }
       
      </div>
    </>
  );
};

export default DriverProfile;
