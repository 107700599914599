import ContentHeader from "../../components/content-header/ContentHeader";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../styles/Customer.css";
import { toast } from "react-toastify";
import PageLoader from "../../components/PageLoader";
import { 
  addNewSpocApi,
  getSpocDetails,
  updateSpocApi,
  getSpocResetPassword,
 } from "../../services/data/spoc";
import { getMasterCounteryCode } from "../../services/data/masters";


const ManageSpoc = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const date = new Date();
  // User Customer Form data
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [countryCode, setCountryCode] = useState();

  const [userdata, setUserData] = useState({
    name: "",
    email: "",
    password: "",
    country_code: "",
    contact_no: "",
  });

  const { name, email, password, country_code, contact_no } = userdata;

  console.log(country_code, 'code')
  const dataHandle = (e) => {
    console.log(e.target.value);
    setUserData({ ...userdata, [e.target.name]: e.target.value });
  };

  const handleReset = () => {
    setUserData({
      name: "",
      email: "",
      password: "",
      country_code: "",
      contact_no: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();    
    setIsLoading(true);
    if(
      !name ||
      !email ||
      !country_code ||
      !contact_no 
    ){
      toast.error("Please fill in all required fields.");
      setIsLoading(false);
      return;
    }
    
    if (contact_no.length > 10) {
      toast.error("Mobile number too long. Maximum 10 digits allowed.");
      setIsLoading(false);
      return;
    }

    if (id) {
      await updateSpocApi(id, userdata).then((response) => {
        if (response.status >= 200) {
          toast.success(response.data.message);
          navigate("/spoc");
        } else {
          toast.error(response.data.message);
          setIsLoading(false);
        }
      });
    } else {
      await addNewSpocApi(userdata).then((response) => {
        console.log('post Spoc',userdata)
        if (response.status >= 200) {
          toast.success(response.data.message);
          navigate("/spoc");
        } else {
          toast.error(response.data.message);
          setIsLoading(false);
        }
      });
    }
  };

  useEffect(() => {
    getMasterCounteryCode().then((response) => {
      setCountryCode(response.data);
    });    
    if (id) {
      getSpocDetails(id).then((response) => {
        console.log('Edit Single Spoc',response.data)
        setUserData({
          name: response.data.name,          
          email: response.data.email,
          contact_no: response.data.contact_no,
          country_code: response.data.country_code,                    
        });
        setIsPageLoading(false);
      });
    } else {
      handleReset();
      setIsPageLoading(false);
    }
  }, [id]);

  function ResetPassword(userId){
    getSpocResetPassword(userId).then((response)=>{      
      toast.success(response.data.message);
    })
  }  

  return (
    <>
      <div>
        <ContentHeader title={id ? "Edit Spoc" : "New Spoc"} />
        {isPageLoading ? (
          <PageLoader />
        ) : (
          <section className="content">
            <div className="container-fluid">
              <div className="card">
                {isLoading && (
                  <div className="overlay">
                    <i className="fas fa-2x fa-sync fa-spin" />
                  </div>
                )}
                <div className="card-header">
                  <h3 className="card-title">Spoc User Form</h3>
                </div>
                <div className="card-body">
                  <div className="container-fluid">                    
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="spocName">Name</label>
                          <input
                            type="text"
                            name="name"
                            value={name}
                            placeholder="Spoc Name"
                            onChange={dataHandle}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="email">Email</label>
                     
                            <input
                              type="email"
                              name="email"
                              value={email}
                              placeholder="Email Address"
                              onChange={dataHandle}
                              className="form-control"
                            />
                       
                        </div>
                      </div>
                      {!id ?
                      // (
                      // <div className="col-md-6 col-sm-12">
                      //   <div className="form-group">
                      //     <label htmlFor="password">Password</label>
                      //     <input
                      //       type="password"
                      //       name="password"
                      //       value={password}
                      //       placeholder={
                      //         id ? "Reset Password" : "Password"
                      //       }
                      //       onChange={dataHandle}
                      //       className="form-control"
                      //     />
                      //   </div>
                      // </div>
                      // )
                      (
                        ''
                      )
                      :(
                        <div className="col-md-6 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <a
                              onClick={()=>ResetPassword(id)}
                              className="btn btn-link form-control"
                              style={{
                                textDecoration: "underline",
                                textAlign: "left",
                                padding: 0,
                              }}
                            >
                              Click here to reset password
                            </a>
                          </div>
                        </div>
                      )}
                      <div className="col-md-6 col-sm-12">
                        <div className="row">
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label htmlFor="countryCode">Country Code</label>
                              <select
                                id="countryCode"
                                name="country_code"
                                value={country_code}
                                defaultValue={country_code}
                                onChange={dataHandle}
                                className="form-control"
                              >
                                <option value=''>Select..</option>
                                {countryCode &&
                                  countryCode.map((item) => {
                                    return (
                                      <option value={item.country_code}>
                                        +{item.country_code + " " + item.country}
                                      </option>
                                    );
                                  })}
                              </select>
                              {/* <b className="text-danger">
                                {country_code.length === 0
                                  ? "Select the country Code"
                                  : ""}
                              </b> */}
                            </div>
                          </div>
                          <div className="col-md-8 col-sm-12">
                            <div className="form-group">
                              <label htmlFor="contactNo">Contact No</label>
                              <input
                                type="number"
                                name="contact_no"
                                value={contact_no}
                                placeholder="Contact No"
                                onChange={dataHandle}
                                className="form-control"
                              />
                              <b className="text-danger">
                                {contact_no.length > 10
                                  ? "Mobile number too long. Maximum 10 digits allowed."
                                  : ""}
                              </b>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                <div className=" text-right">
                  {/* {hasPermission("spoc.create") && ( */}
                  <button
                    type="reset"
                    className="btn btn-warning mr-3"
                    onClick={handleReset}
                  >
                    Reset
                  </button>
                  {/* )} */}
                  {/* {hasPermission("spoc.edit") && */}
                  {/* hasPermission("spoc.create") && ( */}
                  <button
                    type="button"
                    className="btn btn-info"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                  {/* )} */}
                </div>
                </div>

              </div>
            </div>
          </section>
        )}
      </div>
    </>
  );
};

export default ManageSpoc;
