import http from "../common/http-common";

export async function addTripApi(data) {
    return await http.post("/api/v1/trips", data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
}

export async function getAllTripsApi() {
  return await http.get("/api/v1/trips",{
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),     
    },
  });
}

export async function assignTripApi(id,data) {
  console.log('id',id, data)
  return await http.put(`/api/v1/trips/${id}/assign`, data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
  });
}

export async function startEndTripApi(trip_id, type, data) {
  return await http.put(`/api/v1/trips/${trip_id}/${type}`, data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-type": "multipart/form-data",
    },
  });
}

