import React from 'react'
import ContentHeader from "../components/content-header/ContentHeader";


const Documentmaster = () => {
  return (
    <>
      <div>
        <ContentHeader title="Document Master" />
        <section className="content">
            <div className="container-fluid">
            <div className="card">
                <div className="card-header">
                <h3 className="card-title">Title</h3>
                
                </div>
                <div className="card-body">
                Start creating your amazing application!
                </div>
                <div className="card-footer">Footer</div>
            </div>
            </div>
        </section>
    </div>
    </>
  )
}

export default Documentmaster
