import http from "../../common/http-common";

export async function addMasterPackageTypeApi(data){
    return await http.post(`/api/v1/master/package-types`, data, {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            'Content-Type': 'application/json'
        },
    } )
} 

export async function getAllPackageType() {
    return await http.get(`/api/v1/master/package-types`);
  }

export async function getPackageTypedetails(id) {
    return await http.get(`/api/v1/master/package-types/${id}`);
}

export async function updatePackageTypedetails(id, data) {
    return await http.put(`/api/v1/master/package-types/${id}`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        'Content-Type': 'application/json',
      },
    });
  }

  export async function deletePackageType(id) {
    return await http.delete(`/api/v1/master/package-types/${id}`);
  }

  
export async function addMasterCargoTypeApi(data){
  return await http.post(`/api/v1/master/cargo-types`, data, {
      headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          'Content-Type': 'application/json'
      },
  } )
} 



export async function getAllCargoType() {
    return await http.get(`/api/v1/master/cargo-types`);
  }

export async function getCargoTypedetails(id) {
    return await http.get(`/api/v1/master/cargo-types/${id}`);
}

export async function updateCargoTypedetails(id, data) {
    return await http.put(`/api/v1/master/cargo-types/${id}`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        'Content-Type': 'application/json',
      },
    });
  }

  export async function deleteCargoType(id) {
    return await http.delete(`/api/v1/master/cargo-types/${id}`);
  }





 