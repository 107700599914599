import ContentHeader from "../components/content-header/ContentHeader";
import Spinner from "react-bootstrap/Spinner";
import React, { useState, useEffect,useMemo  } from "react";
import { getDashboardDetails } from "../services/data/dashboard";
import { toast } from "react-toastify";
import { PieChart } from "@mui/x-charts/PieChart";
import { BarChart } from "@mui/x-charts/BarChart";
import styled, { css } from "styled-components";

const ImgIcon = styled.img`
  position: absolute;
  right: 15px;
  top: 15px;
  transform: scale(1);
  transition: transform 0.3s linear;

  ${(props) =>
    props.hoverable &&
    css`
      &:hover {
        transform: scale(1.2);
      }
    `}
`;

const data = [
  { id: 0, value: 10, label: "series A" },
  { id: 1, value: 15, label: "series B" },
  { id: 2, value: 20, label: "series C" },
];

const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
const xLabels = [
  "Page A",
  "Page B",
  "Page C",
  "Page D",
  "Page E",
  "Page F",
  "Page G",
];
const imgStyles = {
  position: "absolute",
  right: "15px",
  top: "15px",
  transition: "transform .3s linear",
  transform: "scale(1)",
};
const Dashboard = () => {
  // const [isPageLoading, setIsPageLoading] = useState(true);
  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsPageLoading(false);
  //   }, 2000);
  // }, []);
  const labelFunction = useMemo(
    () => (datum, index, series) => {
      const total = series.data.reduce((acc, curr) => acc + curr.value, 0);
      const percentage = ((datum.value / total) * 100).toFixed(1);

      // Add a custom data attribute to the label element
      // so that we can use it in CSS to position the label
      const labelElement = (
        <text
          key={`label-${index}-${series.index}`}
          data-index={index} // Add this line
          textAnchor="middle"
          dominantBaseline="central"
        >
          {`${datum.label} - ${percentage}%`}
        </text>
      );

      return labelElement;
    },
    []
  );
  const [dashboardDetails, setDashboardDetails] = useState({});
  useEffect(() => {
    getDashboardDetails().then((response) => {
      setDashboardDetails(response.data);
    });
  }, []);
  return (
    <div>
      <ContentHeader title="Dashboard" />
      {/* {isPageLoading ? (
        <section className="content preloader justify-content-center align-items-center">
          <Spinner animation="grow" className="bg-info" />
          <Spinner animation="grow" className="bg-primary" />
          <Spinner animation="grow" className="bg-success" />
        </section>
      ) : ( */}
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className=" col-8">
              <div className="row">
                <div className=" col-6">
                  <div className="small-box bg-white">
                    <div className="inner">
                      {/* <h3>{dashboardDetails.no_of_customers}</h3> */}
                      <h3>100</h3>
                      <p>Total Trips</p>
                    </div>
                    <div className="icon">
                      <ImgIcon
                        hoverable
                        src="/img/Trux_1.png"
                        width={150}
                        alt="Trux_1.png"
                      />
                    </div>
                  </div>
                </div>
                <div className=" col-6">
                  <div className="small-box bg-white">
                    <div className="inner">
                      {/* <h3>{dashboardDetails.no_of_transporters}</h3> */}
                      <h3>100</h3>

                      <p>Confirmed Trips</p>
                    </div>
                    <div className="icon">
                      <ImgIcon
                        hoverable
                        src="/img/Trux_1.png"
                        width={150}
                        alt="Trux_1.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className=" col-6">
                  <div className="small-box bg-white">
                    <div className="inner">
                      {/* <h3>{dashboardDetails.no_of_trucks}</h3> */}
                      <div className="d-inline-flex">
                        <h3>30000</h3>
                        <h4 className="ml-1 mt-2">OMR</h4>
                      </div>

                      <p>Amount Earned</p>
                    </div>
                    <div className="icon">
                      <ImgIcon
                        hoverable
                        src="/img/money1.png"
                        width={100}
                        alt="money1.png"
                      />
                    </div>
                  </div>
                </div>
                <div className=" col-6">
                  <div className="small-box bg-white">
                    <div className="inner">
                      {/* <h3>{dashboardDetails.no_of_drivers}</h3> */}
                      <div className="d-inline-flex">
                        <h3>207</h3>
                        <h4 className="ml-1 mt-2">OMR</h4>
                      </div>
                      <p>Commissions Earned</p>
                    </div>
                    <div className="icon">
                      <ImgIcon
                        hoverable
                        src="/img/money1.png"
                        width={100}
                        alt="money1.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-4">
              <div className="bg-white rounded mr-1">
                <PieChart
                  series={[
                    {
                      data,
                      highlightScope: { faded: "global", highlighted: "item" },
                      faded: {
                        innerRadius: 30,
                        additionalRadius: -30,
                        color: "gray",
                      },
                      type: "pie",
                      labels: {
                        // position: "outside",
                        // offset: [0, 20],
                        labelFunction,
                      },
                    },
                  ]}
                  height={245}
                />
              </div>
            </div>
          </div>
          <div className="row ">
            <div className=" col-6">
              <div className="bg-white rounded mr-1">
                <BarChart
                  width={500}
                  height={300}
                  series={[
                    { data: pData, label: "pv", id: "pvId" },
                    { data: uData, label: "uv", id: "uvId" },
                  ]}
                  xAxis={[{ data: xLabels, scaleType: "band" }]}
                />
              </div>
            </div>
            <div className=" col-6">
              <div className="bg-white rounded mr-1">
                <BarChart
                  width={500}
                  height={300}
                  series={[
                    { data: pData, label: "pv", id: "pvId" },
                    { data: uData, label: "uv", id: "uvId" },
                  ]}
                  xAxis={[{ data: xLabels, scaleType: "band" }]}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* )} */}
    </div>
  );
};

export default Dashboard;
