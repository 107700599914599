import React, { useState, useEffect } from "react";
import ContentHeader from "../../components/content-header/ContentHeader";
import MaterialTable from "@material-table/core";
import { ExportCsv } from "@material-table/exporters";
import { hasPermission } from "../../utils/permissions";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";
import { Link } from 'react-router-dom'
import { 
    getAllTruckTypeApi,
    deleteTruckType,
    getTruckTypeApi,
    updateTruckTypeApi
    } from "../../services/data/trucktype";
import { toast } from "react-toastify";


const TruckTypes = () => {
    const [data, setData] = useState([])
    const [isloading, setIsLoading] = useState(true)
    const [truckTypeId, setTruckTypeId] = useState();
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [activeStatus, SetActiveStatus] =  useState('');


    
    useEffect(()=>{
        getAllTruckTypeApi().then((response)=>{
            console.log(response.data, 'trucktypedetails')
            setData(response.data)
            setIsLoading(false)
        })
    },[showDeleteConfirm,activeStatus])


    function onClose() {
        setShowDeleteConfirm(false);
    }
    
    
  function onConfirm() {    
    // toast.warn("Not Implemented!");
    deleteTruckType(truckTypeId).then((response) => {
      toast.success("Truck Type Deleted");
      setShowDeleteConfirm(false);
    });
  }

  const changeStatus = (isActive, id) => {
    const payload = {
      is_active: isActive,
    };
    updateTruckTypeApi(id, payload).then((response) => {
      if(isActive){
        toast.success("Truck Type Activated!");
      }else{
        toast.info("Truck Type Deactivated!");
      }
      
      getAllTruckTypeApi().then((response) => {
        setData(response.data);
        SetActiveStatus(isActive)
      });
    });
  };

    const columns = [
        // { title: "id", field: "id" },
        {      
          title: "Truck Type",
          render: (rowData)=>{
            return(
                <div>
                    <p>
                        <b>{rowData.truck_type}</b>
                    </p>
                    <p>
                        <Link
                            className="btn ml-0 pl-0 mr-0 pr-0"
                            to={`/manage-trucktype/${rowData.id}`}
                        >
                            {/* <i className="fas fa-edit text-info" /> */}
                            <i className="fas fa-pencil-alt text-info"></i>
                        </Link>{" "}
                        {hasPermission("users.delete") && (
                            <button
                            className="btn  mr-0 pr-0"
                            onClick={(e) => {
                                setTruckTypeId(rowData.id);
                                setShowDeleteConfirm(true);
                            }}
                            >
                            <i className="fas fa-times text-danger"></i>
                            {/* <i className="fas fa-trash text-danger" /> */}
                            </button>
                        )}{" "}                        
                    </p>
                </div>
            )
          }
        }, 
        {      
            title: "Packages",
            render :(rowData)=>{
                return(
                    rowData.package_type.name
                )
            }
        },
        {      
            title: "Weight",
            field: "weight",
        },  
        {      
            title: "Height",
            field: "height",
        },
        {      
            title: "Length",
            field: "length",
        },
        {      
            title: "Width",
            field: "width",
        }, 
        {      
            title: "Temperature",
            field: "temperature",
        },
        {      
            title: "Volume(cbm)",
            field: "volume",
        }, 
        { 
            title: "Status", 
            render : (rowData)=>{
              return(
                // <h3>
                //     {rowData.status.trim() === "In Active" && "In Active"}
                // </h3>
                    
                <h3>
                    {rowData.status === "IN Active" && (
                    <button
                        type="button"
                        className="btn btn-warning"
                        onClick={(e) => changeStatus(true, rowData.id)}
                    >
                        In Active
                    </button>
                    )}
                    {rowData.status === "Active" && (
                    <button
                        type="button"
                        className="btn btn-success"
                        onClick={(e) => changeStatus(false, rowData.id)}
                    >
                        Active
                    </button>
                    )} 
               </h3>
              )
            }         
        },    
      ];
  return (
    <>
        <div>
            <ContentHeader title="Truck Types" />
            <section className="content">
            <div className="container-fluid">
                <div className="card">
                <div className="card-header">
                    <h3 className="card-title">Truck Type List</h3>
                    <Link to="/New-truck-type" className="float-right btn btn-info">New Truck</Link>                    
                </div>
                <div className="card-body p-0 m-0">                    
                    <MaterialTable
                        // id="pdfDatas"
                        style={{ backgroundColor: "white" }}
                        isLoading = {isloading}
                        columns={columns}
                        data={data}
                        title=""
                        options={{
                        exportAllData: true,
                        exportMenu: [
                            {
                            label: "Export CSV",
                            exportFunc: (cols, datas) =>
                                ExportCsv(cols, datas, "trux"),
                            },
                        ],
                        }}
                    />

                  
                </div>
                {/* <div className="card-footer">Footer</div> */}
                </div>
            </div>
            {showDeleteConfirm && (
            <DeleteConfirmModal
              show={showDeleteConfirm}
              onClose={onClose}
              onConfirm={onConfirm}
            />
            )} 
            </section>
        </div>
    </>
  )
}

export default TruckTypes
