import { createSlice } from '@reduxjs/toolkit';
import {
  addWindowClass,
  calculateWindowSize,
} from '../../utils/helpers';

const initialState = {
  screenSize: calculateWindowSize(window.innerWidth),
  menuSidebarCollapsed: false,
  controlUserDropdown: false,
  menuItemFlat: false,
  menuChildIndent: false,
};

addWindowClass('layout-footer-fixed');

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleSidebarMenu: (state) => {
      state.menuSidebarCollapsed = !state.menuSidebarCollapsed;
    },
    toggleUserDropdown: (state) => {
      state.controlUserDropdown = !state.controlUserDropdown;
    },
    toggleMenuItemFlat: (state) => {
      state.menuItemFlat = !state.menuItemFlat;
    },
    toggleMenuChildIndent: (state) => {
      state.menuChildIndent = !state.menuChildIndent;
    },
    setWindowSize: (state, { payload }) => {
      state.screenSize = payload;
    },
  },
});

export const {
  toggleSidebarMenu,
  setWindowSize,
  toggleUserDropdown,
  toggleMenuItemFlat,
  toggleMenuChildIndent,
} = uiSlice.actions;

export default uiSlice.reducer;
