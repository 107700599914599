import ContentHeader from "../../components/content-header/ContentHeader";
import React, { useState, useEffect, useRef } from "react";
import MaterialTable, { Column } from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { Link, useParams } from "react-router-dom";
import { deleteBookingApi, getAllBookingsApi } from "../../services/data/bookings";
import Spinner from "react-bootstrap/Spinner";
import * as XLSX from "xlsx";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";
import { toast } from "react-toastify";
// import { useAuth } from "../services/common/utils";
import { useAuth } from "../../services/common/utils";

const Bookings = () => {
  const { id } = useParams();
  const { email , name, role, userid, permissions } = useAuth();  
  const [datas, setDatas] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteBookingId, setDeleteBookingId] = useState();

  const basecolumns = [
    {
      title: "Booking Info",
      field: "booking_no",
      render: (rowData) => {
        return (
          <>
            <Link to={`/booking-profile/${rowData.id}`} className="nav-link">
              {rowData.booking_no}
            </Link>
            <p>
              <strong>Booking Date: </strong> {rowData.booking_date}
            </p>
            <p>
              <Link
                to={`/booking-form/${rowData.id}`}
                className="btn ml-0 pl-0 mr-2"
              >
                <i className="fas fa-edit text-info" />
              </Link>
              <button
                className="btn "
                onClick={() => {
                  setDeleteBookingId(rowData.id);
                  setShowDeleteConfirm(true);
                }}
              >
                <i className="fas fa-trash-alt text-danger" />
              </button>
            </p>
          </>
        );
      },
    },
    {
      title: "Customer Info",
      field: "customer_info",
      render: (rowData) => {
        const { shipper_address } = rowData;
        const nameText =
          shipper_address.name !== null ? shipper_address.name : "null";
        const contactText =
          shipper_address.contact !== null ? shipper_address.contact : "null";
        return (
          <div>
            <p>
              <strong>Name:</strong> {nameText}
            </p>
            <p>
              <strong>Contact:</strong> {contactText}
            </p>
          </div>
        );
      },
    },
    {
      title: "Origin / Destination",
      field: "origin_destination",
      render: (rowData) => {
        const { origin, destination } = rowData;
        const originText = origin !== null ? origin : "null";
        let destinationText = "null";

        if (destination) {
          const destination1 = destination.destination1 || "null";
          const destination2 = destination.destination2 || "null";

          // Check if there is only one destination
          if (destination1 !== "null" && destination2 === "null") {
            destinationText = destination1;
          } else if (destination1 === "null" && destination2 !== "null") {
            destinationText = destination2;
          } else {
            destinationText = `${destination1} , ${destination2}`;
          }
        }

        return (
          <div>
            <p>
              <strong>Origin:</strong> {originText}
            </p>
            <p>
              <strong>Destination:</strong> {destinationText}
            </p>
          </div>
        );
      },
    },
    {
      title: "No Of Trips",
      field: "no_of_trips",
      render: (rowData) =>
        rowData.no_of_trips !== null ? rowData.no_of_trips : "null",
    },
    {
      title: "ETA",
      field: "eta",
      render: (rowData) => (rowData.eta !== null ? rowData.eta : "null"),
    },
    {
      title: "Amount",
      field: "amount",
      render: (rowData) => (rowData.amount !== null ? rowData.amount : "null"),
    },
    {
      title: "Status",
      field: "status",
      render: (rowData) => (rowData.status !== null ? rowData.status : "null"),
    },
  ];
 
 function removeBaseCol(){
  basecolumns.splice(1,1)
  return basecolumns
 }

  const columns = role === 'admin' ?  basecolumns : removeBaseCol()

  useEffect(() => {
    getAllBookingsApi().then((response) => {
      setDatas(response.data);
      setIsPageLoading(false);
    });
  }, []);

  // console.log("datas :", datas);

  // Function to export data to Excel (XLSX) format
  const ExportExcel = (cols, datas, filename) => {
    // Convert data to XLSX format
    const worksheet = XLSX.utils.json_to_sheet(datas);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Save the workbook
    XLSX.writeFile(workbook, `${filename}.xlsx`);
  };

  const handleDelete = async (id) => {
    try {
      // Delete cargo type from the backend
      await deleteBookingApi(id);
      // Update the data state to reflect the deletion
      setDatas(datas.filter((item) => item.id !== id));
      toast.success("Booking deleted successfully!");
      setShowDeleteConfirm(false);
    } catch (error) {
      console.error("Error deleting cargo type:", error);
      // Handle error as needed
    }
  };
  const onConfirmDelete = () => {
    handleDelete(deleteBookingId);
  };
  return (
    <>
      <div>
        <ContentHeader title="Bookings" />
        {isPageLoading ? (
          <section className="content preloader justify-content-center align-items-center">
            <p>Loading Wait Few Seconds...</p>
            <Spinner animation="grow" className="bg-info" />
            <Spinner animation="grow" className="bg-primary" />
            <Spinner animation="grow" className="bg-success" />
          </section>
        ) : (
          <section className="content">
            <div className="container-fluid">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Booking Details</h3>
                  <div className="float-right">
                    { role !== 'admin' && (
                      <>
                        <Link to="/booking-form" className="btn btn-info mr-3">
                          <i className="fas fa-plus" /> Booking
                        </Link>
                      </>
                    )}
                  </div>
                </div>
                <div className="card-body">
                  {datas.length > 0 ? (
                    <MaterialTable
                      title=""
                      columns={columns}
                      data={datas}
                      isLoading={isPageLoading}
                      options={{
                        exportAllData: true,
                        exportMenu: [
                          {
                            label: "Export CSV",
                            exportFunc: (cols, datas) =>
                              ExportCsv(cols, datas, "trux"),
                          },
                          {
                            label: "Export Excel",
                            exportFunc: (cols, datas) =>
                              ExportExcel(cols, datas, "trux"),
                          },
                          {
                            label: "Export PDF",
                            exportFunc: (cols, datas) =>
                              ExportPdf(cols, datas, "trux"),
                          },
                        ],
                      }}
                    />
                  ) : (
                    <p>No Data ...!</p>
                  )}
                </div>
                {/* <div className="card-footer">Footer</div> */}
              </div>
            </div>
          </section>
        )}
        <DeleteConfirmModal
          show={showDeleteConfirm}
          onClose={() => setShowDeleteConfirm(false)}
          onConfirm={onConfirmDelete}
        />
      </div>
    </>
  );
};

export default Bookings;
