import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { getDriversOptionApi } from "../../services/data/drivers";
import { Formik, Form, Field, ErrorMessage } from "formik";
import PageLoader from "../../components/PageLoader";
import { assignTripApi } from "../../services/data/trip";
import { toast } from "react-toastify";

const AssignDriverModal = ({ show, handleClose, selectedTrip}) => {
  const [drivers, setDrivers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getDriversOptionApi()
      .then(response => {
        console.log('driver',response.data)
        setDrivers(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error("Error fetching drivers:", error);
        setLoading(false);
      });
  }, []);

  const handleSaveChanges = async(values,{ setSubmitting }) => {       
    await assignTripApi(selectedTrip.id,values).then((response)=>{
      if (response.status === 201 || response.data.status === 'success'){
          toast.success(response.data.message)
          handleClose()
      }else{
        toast.error(response.data.message)
        setSubmitting(false)
      }
    })
    .catch((err)=>{
      toast.error(err.response.data.message)
      setSubmitting(false)
    })    
  };

  return (
    <Modal show={show} onHide={handleClose}>
      {
        loading ? <PageLoader loading="Loading..."/> : (
          <div>
            <Modal.Header closeButton>
              <Modal.Title>Assign Driver</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Assign a driver for trip: {selectedTrip && selectedTrip.booking.booking_no}</p>
              <Formik
                initialValues={{ driver_id: "" }}
                validate={values => {
                  const errors = {};
                  if (!values.driver_id) {
                    errors.driver_id = "Required";
                  }
                  return errors;
                }}
                onSubmit={handleSaveChanges}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="form-group">
                      <label htmlFor="driver">Select Driver</label>
                      <Field as="select" className="form-control" id="driver_id" name="driver_id">
                        <option value="">Select a driver</option>
                        {drivers.map(driver => (
                          <option key={driver.id} value={Number(driver.id)}>{driver.name}</option>
                        ))}
                      </Field>
                      <ErrorMessage name="driver_id" component="div" className="text-danger" />
                    </div>
                    <div className="d-flex justify-content-end" >
                    <Button variant="secondary" className="mr-2" onClick={handleClose}>
                      Close
                    </Button>
                    <Button variant="primary" type="submit" disabled={isSubmitting}>
                      Save changes
                    </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Modal.Body>
         </div>  
        )}         
    </Modal>
  );
};

export default AssignDriverModal;