import ContentHeader from "../../components/content-header/ContentHeader";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  addTrucksApi,
  getTruckApi,
  updateTrucksApi,
  getTrucksInsurance,
  getTrucksRegistrationCard,
  getTrucksCargoInsurance,
  getTrucksPhoto,
} from "../../services/data/trucks";
import Select from "react-select";
import {
  getAllTransportersApi,
  getMasterTruckTypes,
} from "../../services/data/transporters";
import PageLoader from "../../components/PageLoader";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';



const ManageTruck = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [truckOwners, setTruckOwners] = useState([]);
  const [truckTypes, setTruckTypes] = useState([]);

  // console.log(truckOwners, '123')

  const [userdata, setUserData] = useState({
    truck_owner: "",
    truck_type: "",
    truck_no: "",
    reg_card: [],
    reg_expiry_date: "",
    insurance: [],
    insurane_validity: "",
    truck_photo: [],
    cargo_insurance: [],
    compilances: [],
  });

  const {
    truck_owner,
    truck_type,
    truck_no,
    reg_card,
    reg_expiry_date,
    insurance,
    insurane_validity,
    truck_photo,
    cargo_insurance,
    compilances,    

  } = userdata;
     
  const setDate = (date, name)=>{
    if (date){
      if (name == "reg_expiry_date"){
        setUserData(
        { ...userdata, 
          reg_expiry_date : `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
        });
      }
      else if (name == "insurane_validity"){
        setUserData(
        { ...userdata, 
          insurane_validity : `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
        });
      }      
    }      
  }

  useEffect(() => {
    getAllTransportersApi().then((response) => {
      let optionData = [];
      response.data.map((item) =>
        optionData.push({ value: item.id, label: item.contact_person })
      );
      setTruckOwners(optionData);
    });
    getMasterTruckTypes().then((response) => {     
      setTruckTypes(response.data);
    });

    if (id) {
      getTruckApi(id).then((response) => {
        console.log("Retrival Single Edit truck", response.data);
        setUserData({
          truck_owner: response.data.transporter.id,
          truck_type: response.data.truck_type.id,
          truck_no: response.data.truck_no,
          reg_card: response.data.reg_card,
          reg_expiry_date: response.data.reg_expire_date,
          insurance: response.data.insurance,
          insurane_validity: response.data.insurance_validity,
          truck_photo: response.data.truck_photo,
          cargo_insurance: response.data.cargo_insurance,
          compilances: response.data.compilances,
        });
        setIsPageLoading(false);
      });
    } else {
      handleReset();
      setIsPageLoading(false);
    }
  }, [id]);

 
  const userCompliance = (e) => {
    const { value, checked } = e.target;
    const { compilances } = userdata;

    console.log(`${value} is ${checked}`);

    if (checked) {
      setUserData({
        ...userdata,
        compilances: [...compilances, value],
      });
    }

    // Case 2  : The user unchecks the box
    else {
      setUserData({
        ...userdata,
        compilances: compilances.filter((e) => e !== value),
      });
    }
  };
  const selectOwnerHandle = (e) => {
    console.log(e);
    setUserData({ ...userdata, truck_owner: e.value });
  };
  const dataHandle = (e) => {
    console.log(e);
    setUserData({ ...userdata, [e.target.name]: e.target.value });
  };

  const fileHandle = (e) => {
    if (e.target.name.trim() === "reg_card") {
      const files = Array.from(e.target.files);
      // const imageArray = files.map((file)=>URL.createObjectURL(file))
      setUserData({
        ...userdata,
        [e.target.name]: [...files],
      });
    } else if (e.target.name.trim() === "insurance") {
      const files = Array.from(e.target.files);
      // const imageArray = files.map((file)=>URL.createObjectURL(file))
      setUserData({
        ...userdata,
        [e.target.name]: [...files],
      });
    } else if (e.target.name.trim() === "truck_photo") {
      const files = Array.from(e.target.files);
      // const imageArray = files.map((file)=>URL.createObjectURL(file))
      setUserData({
        ...userdata,
        [e.target.name]: [...files],
      });
    } else if (e.target.name.trim() === "cargo_insurance") {
      const files = Array.from(e.target.files);
      // const imageArray = files.map((file)=>URL.createObjectURL(file))
      setUserData({
        ...userdata,
        [e.target.name]: [...files],
      });
    } else {
      setUserData({
        ...userdata,
        [e.target.name]: e.target.files[0],
      });
    }
  };

  const handleReset = () => {
    setUserData({
      truck_owner: "",
      truck_type: "",
      truck_no: "",
      reg_card: [],
      reg_expiry_date: "",
      insurance: [],
      insurane_validity: "",
      truck_photo: [],
      cargo_insurance: [],
      compilances: [],      
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
        
    if (
      !truck_owner ||
      !truck_type ||
      !truck_no ||
      !reg_card.length ||
      !reg_expiry_date ||
      !insurance.length ||
      !insurane_validity ||
      !userdata.compilances.length
    ) {
      toast.error("Please fill in all required fields."); 
      setIsLoading(false);
      return;    
    } 
    
    setUserData({ ...userdata, compilances: JSON.stringify(compilances) });
    
    if (id) {
      console.log('update Truck',userdata)
      updateTrucksApi(id, userdata)      
        .then((response) => {
          console.log(response.data.message)
          if (response.status >= 200) {
            toast.success(response.data.message);
            navigate("/trucks");
          } else {
            toast.error(response.data.message);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          toast.error("Failed to add Truck!");
        });
    } else {
      console.log('Post Truck',userdata)
      addTrucksApi(userdata)        
        .then((response) => {
          if (response.status === 201) {
            toast.success(response.data.message);
            navigate("/trucks");
          } else {
            toast.error(response.data.message);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          toast.error("Failed to add Truck!");
        });
    }
  };
  return (
    <>
      <ContentHeader title={id ? "Edit Truck" : "Trucks"} />
      {isPageLoading ? (
        <PageLoader />
      ) : (
        <section className="content">
          <div className="container-fluid">
            <div className="card">
              {/* <div className="card-header">
                <h3 className="card-title">Truck Details</h3>
              </div> */}
              <div className="card-body">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="truckowner">Truck Owner</label>
                        <select
                          name="truck_owner"
                          defaultValue={truck_owner}
                          value={truck_owner}
                          onChange={dataHandle}
                          className="form-control"
                        >
                          <option value="">Select..</option>
                          {truckOwners.map((item) => {
                            return (
                              <option value={item.value}>{item.label}</option>
                            );
                          })}
                        </select>
                        {/* <Select
                          options={truckOwners}
                          // defaultValue={truckOwners[0]}
                          defaultValue={
                            truckOwners[
                              truckOwners.findIndex(
                                (obj) => obj.value === truck_owner
                              )
                            ]
                          }
                          // value={truck_owner}
                          name="truck_owner"
                          onChange={selectOwnerHandle}
                        /> */}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="trucktype">Truck Type</label>
                        <select
                          name="truck_type"
                          defaultValue={truck_type}
                          value={truck_type}
                          onChange={dataHandle}
                          className="form-control select2"
                        >
                          <option value="">Select..</option>
                          {truckTypes.map((item) => {
                            return (
                              <option value={item.id}>{item.truck_type}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputFile">
                          Registration Card
                          {id &&
                            reg_card?.map((item) => {
                              return (
                                <a
                                  className="btn btn-link p-0"
                                  onClick={(e) =>
                                    getTrucksRegistrationCard(
                                      id,
                                      item.id,
                                      item.file_ext
                                    )
                                  }
                                >
                                  <i className="far fa-fw fa-file" />
                                  {`card-${item.id}.${item.file_ext}`}
                                </a>
                              );
                            })}
                        </label>
                        <div className="input-group">
                          <div className="custom-file">
                            <input
                              type="file"
                              className="custom-file-input"
                              id="exampleInputFile"
                              name="reg_card"
                              multiple
                              accept="image/*,.pdf"
                              onChange={fileHandle}
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="exampleInputFile"
                            >
                              {reg_card && reg_card.map((file) => file.name)}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="insuranceInputFile">
                          Insurance
                          {id &&
                            insurance?.map((item) => {
                              return (
                                <a
                                  className="btn btn-link p-0"
                                  onClick={(e) =>
                                    getTrucksInsurance(
                                      id,
                                      item.id,
                                      item.file_ext
                                    )
                                  }
                                >
                                  <i className="far fa-fw fa-file" />
                                  {`card-${item.id}.${item.file_ext}`}
                                </a>
                              );
                            })}
                        </label>
                        <div className="input-group">
                          <div className="custom-file">
                            <input
                              type="file"
                              className="custom-file-input"
                              id="insuranceInputFile"
                              name="insurance"
                              multiple
                              accept="image/*,.pdf"
                              onChange={fileHandle}
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="insuranceInputFile"
                            >
                              {insurance && insurance.map((file) => file.name)}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="truckInputFile">
                          Truck Photo (Optional)
                          {id &&
                            truck_photo?.map((item) => {
                              return (
                                <a
                                  className="btn btn-link p-0"
                                  onClick={(e) =>
                                    getTrucksPhoto(id, item.id, item.file_ext)
                                  }
                                >
                                  <i className="far fa-fw fa-file" />
                                  {`card-${item.id}.${item.file_ext}`}
                                </a>
                              );
                            })}
                        </label>
                        <div className="input-group">
                          <div className="custom-file">
                            <input
                              type="file"
                              name="truck_photo"
                              className="custom-file-input"
                              id="truckInputFile"
                              multiple
                              accept="image/*,.pdf"
                              onChange={fileHandle}
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="truckInputFile"
                            >
                              {truck_photo &&
                                truck_photo.map((file) => file.name)}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group pl-2">
                        <label htmlFor="truckno">Truck No</label>
                        <input
                          type="text"
                          name="truck_no"
                          value={truck_no}
                          placeholder="Truck Registration No"
                          onChange={dataHandle}
                          className="form-control"
                        />
                      </div>
                      {/* <div className="form-group">
                        <label htmlFor="tradeLicenseValidityDate">
                          Registration Expiry Date
                        </label>
                        <input
                          type="date"
                          name="reg_expiry_date"
                          value={
                            reg_expiry_date
                              ? new Date(reg_expiry_date).toLocaleDateString(
                                  "en-CA"
                                )
                              : ""
                          }
                          placeholder="Registration Expiry Date"
                          onChange={dataHandle}
                          className="form-control"
                        />
                      </div> */}

                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label htmlFor="tradeLicenseValidity">
                            Registration Expiry Date
                          </label>
                          <div className="input-group">
                            <DatePicker
                              name="reg_expiry_date"
                              // selected={
                              //   userdata.reg_expiry_date 
                              //   && userdata.reg_expiry_date ? 
                              //   new Date(userdata.reg_expiry_date) : null
                              //   }  
                              value = {
                                reg_expiry_date ?
                                new Date(reg_expiry_date).toLocaleDateString("en-GB", {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                })
                                : reg_expiry_date }                                 
                              onChange={(date) => setDate(date, "reg_expiry_date")}                              
                              showYearDropdown 
                              scrollableYearDropdown 
                              dateFormat="dd/MM/yyyy"
                              placeholderText="dd/MM/yyyy"
                              minDate={new Date()}
                              className="form-control" 
                              popperPlacement="top"                                 
                            />
                            <div className="input-group-append position-relative">
                              <span
                                className="input-group-text position-absolute d-flex align-items-center"
                                style={{ height: "100%" }}
                              >
                                <i className="fa fa-calendar"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                        {/* <div className="form-group">
                          <label htmlFor="tradeLicenseValidityDate">
                            Insurance Validity
                          </label>
                          <input
                            type="date"
                            name="insurane_validity"
                            value={
                              insurane_validity
                                ? new Date(insurane_validity).toLocaleDateString(
                                    "en-CA"
                                  )
                                : ""
                            }
                            placeholder="Insurance Validity Date"
                            onChange={dataHandle}
                            className="form-control"
                          />
                        </div> */}

                          <div className="col-sm-12 col-md-6">
                            <div className="form-group">
                              <label htmlFor="tradeLicenseValidity">
                                 Insurance Validity
                              </label>
                              <div className="input-group">
                                <DatePicker
                                  name="insurane_validity"
                                  // selected={
                                  //   userdata.insurane_validity 
                                  //   && userdata.insurane_validity ? 
                                  //   new Date(userdata.insurane_validity) : null
                                  //   }   
                                  value = {
                                    insurane_validity ?
                                    new Date(insurane_validity).toLocaleDateString("en-GB", {
                                      day: "2-digit",
                                      month: "2-digit",
                                      year: "numeric",
                                    })
                                   : insurane_validity }                                 
                                  onChange={(date) => setDate(date, "insurane_validity")}
                                  showYearDropdown 
                                  scrollableYearDropdown 
                                  dateFormat="dd/MM/yyyy"
                                  placeholderText="dd/MM/yyyy"
                                  minDate={new Date()}
                                  className="form-control" 
                                  popperPlacement="top"                                 
                                />
                                <div className="input-group-append position-relative">
                                  <span
                                    className="input-group-text position-absolute d-flex align-items-center"
                                    style={{ height: "100%" }}
                                  >
                                    <i className="fa fa-calendar"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                     
                      <div className="form-group pl-2">
                        <label htmlFor="cargoInsuranceInputFile">
                          Cargo Insurance (Optional)
                          {id &&
                            cargo_insurance?.map((item) => {
                              return (
                                <a
                                  className="btn btn-link p-0"
                                  onClick={(e) =>
                                    getTrucksCargoInsurance(
                                      id,
                                      item.id,
                                      item.file_ext
                                    )
                                  }
                                >
                                  <i className="far fa-fw fa-file" />
                                  {`card-${item.id}.${item.file_ext}`}
                                </a>
                              );
                            })}
                        </label>
                        <div className="input-group">
                          <div className="custom-file">
                            <input
                              type="file"
                              className="custom-file-input"
                              id="cargoInsuranceInputFile"
                              name="cargo_insurance"
                              multiple
                              accept="image/*,.pdf"
                              onChange={fileHandle}
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="cargoInsuranceInputFile"
                            >
                              {cargo_insurance &&
                                cargo_insurance.map((file) => file.name)}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <div className="row">
                        <div className="col-md-4 col-sm-4">
                          <div className="form-group">
                            <label htmlFor="compliances">Compliances</label>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-4">
                          <div className="custom-control custom-checkbox">
                            <input
                              className="custom-control-input"
                              type="checkbox"
                              id="regular"
                              defaultValue="Regular"
                              // disabled={                                
                              //   compilances.includes("Hazardous") 
                              // }
                              checked={compilances.includes("Regular")}
                              onChange={userCompliance}
                            />
                            <label
                              htmlFor="regular"
                              className="custom-control-label"
                            >
                              Regular
                            </label>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-4">
                          <div className="custom-control custom-checkbox">
                            <input
                              className="custom-control-input"
                              type="checkbox"
                              id="foodgrade"
                              defaultValue="Food Grade"
                              disabled={                                
                                compilances.includes("Hazardous")
                              }
                              checked={compilances.includes("Food Grade")}
                              onChange={userCompliance}
                            />
                            <label
                              htmlFor="foodgrade"
                              className="custom-control-label"
                            >
                              Food Grade
                            </label>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-4"></div>
                        <div className="col-md-4 col-sm-4">
                          <div className="custom-control custom-checkbox">
                            <input
                              className="custom-control-input"
                              type="checkbox"
                              id="oil_gas"
                              defaultValue="Oil & Gas"
                              // disabled={
                              //   compilances.includes("Hazardous") 
                              // }
                              checked={userdata.compilances.includes(
                                "Oil & Gas"
                              )}
                              onChange={userCompliance}
                            />
                            <label
                              htmlFor="oil_gas"
                              className="custom-control-label"
                            >
                              Oil & Gas
                            </label>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-4">
                          <div className="custom-control custom-checkbox">
                            <input
                              className="custom-control-input"
                              type="checkbox"
                              id="hazardous"
                              checked={userdata.compilances.includes(
                                "Hazardous"
                              )}
                              disabled={                                
                                compilances.includes("Food Grade")                                 
                              }
                              defaultValue="Hazardous"
                              onChange={userCompliance}
                            />
                            <label
                              htmlFor="hazardous"
                              className="custom-control-label"
                            >
                              Hazardous
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              <div className=" text-right">
                <button
                  type="reset"
                  className="btn btn-warning mr-3"
                  onClick={handleReset}
                >
                  Reset
                </button>
                <button
                  type="button"
                  className="btn btn-info mr-3"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
              </div>

            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ManageTruck;
