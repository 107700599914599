import React, { useEffect, useState } from "react";
import { Accordion, Card, Button, Row, Col } from "react-bootstrap";
import ContentHeader from "../../components/content-header/ContentHeader";

const DriverOrderDetails = () => {
  return (
    <div>
      <ContentHeader title="Order Details" />
      <section className="content">
        <div className="container-fluid">DriverOrderDetails</div>
      </section>
      
    </div>
  );
};

export default DriverOrderDetails;
