import ContentHeader from "../../components/content-header/ContentHeader";
import "../../styles/Customer.css";
import React, { useState, useRef, useEffect } from "react";
import MaterialTable, { Column } from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import PageLoader from "../../components/PageLoader";
import {
  getAllFAQ,
  updateFAQdetails,
  deleteFAQ,
  getFAQdetails,
} from "../../services/data/masters/faq";
import { hasPermission } from "../../utils/permissions";
import { Link } from "react-router-dom";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";
import { toast } from "react-toastify";
import { Tooltip as ReactTooltip } from "react-tooltip";


const FAQ = () => {
  const [data, setData] = useState([]);
  console.log('FAQ',data)
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [deleteFaqId, setDeleteFaqId] = useState();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [activeStatus, SetActiveStatus] =  useState('');


  function onConfirm() {    
    // toast.warn("Not Implemented!");
    deleteFAQ(deleteFaqId).then((response) => {
      toast.success("FAQ Deleted");
      setShowDeleteConfirm(false);
    });
  }
  
  function onClose() {
    setShowDeleteConfirm(false);
  }

  const changeStatus = (isActive, id) => {
    const payload = {
      is_active: isActive,
    };
    updateFAQdetails(id, payload).then((response) => {
      if(isActive){
        toast.success("FAQ Activated!");
      }else{
        toast.info("FAQ Deactivated!");
      }      
      getAllFAQ().then((response) => {
        setData(response.data);
        SetActiveStatus(isActive)
      });
    });
  };

  const columns = [
    {      
      title: "Question",
      render: (rowData)=>{
        const question = rowData.question ?? '-'
      return(
          <div>
              <>
                {
                  question && question.length > 20 ?
                  (                
                    <>
                      <p data-tooltip-id={`my-tooltip-${rowData.id}`}>
                      {question.substring(0,21)}...
                      </p>
                      <ReactTooltip
                        id={`my-tooltip-${rowData.id}`}
                        place="top"
                        variant="info"
                        content={question}                        
                      />
                    </>
                  ):(
                    <p>{question}</p>
                  )
                }            
              </>
              <p>
                  <Link
                      className="btn ml-0 pl-0 mr-0 pr-0"
                      to={`/managefaq/${rowData.id}`}
                  >
                      {/* <i className="fas fa-edit text-info" /> */}
                      <i className="fas fa-pencil-alt text-info"></i>
                  </Link>{" "}
                  {/* {hasPermission("users.delete") && (
                      <button
                      className="btn  mr-0 pr-0"
                      onClick={(e) => {
                          setDeleteFaqId(rowData.id);
                          setShowDeleteConfirm(true);
                      }}
                      >
                      <i className="fas fa-times text-danger"></i>
                      </button>
                  )}{" "}                         */}
              </p>
          </div>
        )
      }
    }, 
    {
      title: "Answer",
      render: (rowData, rowIndex) => {
        const answer = rowData.answer ?? '-';
        return (
          <>
            {answer && answer.length > 20 ? (
              <>
                <p data-tooltip-id={`my-tooltip-${rowData.id}`}>
                  {answer.substring(0, 21)}...
                </p>
                <ReactTooltip
                  id={`my-tooltip-${rowData.id}`}
                  place="top"
                  variant="info"
                  content={answer}
                  style={{ width: '50vw' }}
                />
              </>
            ) : (
              <p>{answer}</p>
            )}
          </>
        );
      }
    }, 
    { 
      title: "Status", 
      render : (rowData)=>{
        return(
          // <h3>
          //     {rowData.status ?? '-'}
          // </h3>

              
          <h3>
              {rowData.status === "In Active" && (
              <button
                  type="button"
                  className="btn btn-warning"
                  onClick={(e) => changeStatus(true, rowData.id)}
              >
                  In Active
              </button>
              )}
              {rowData.status === "Active" && (
              <button
                  type="button"
                  className="btn btn-success"
                  onClick={(e) => changeStatus(false, rowData.id)}
              >
                  Active
              </button>
              )} 
         </h3>
        )
      }         
    }, 
  ]
  useEffect(() => {
    setIsLoading(true);
    getAllFAQ().then((response) => {
      setData(response.data);
      setIsPageLoading(false);
      setIsLoading(false);
    });
  }, [showDeleteConfirm, activeStatus]);
  return (
    <div>
      <ContentHeader title="FAQ" />
      {
        isPageLoading?
        (PageLoader):(
          <section className="content">
            <div className="container-fluid">
              <div className="card">
                <div className="card-header">
                  {/* <h3 className="card-title">Title</h3> */}
                  <div className="float-right">
                    <Link
                      to="/managefaq"
                      className="btn btn-info"
                    >
                      <i className="fas fa-plus" /> Add FAQ
                    </Link>
                  </div>
                </div>
                <div className="card-body p-0">
                  <MaterialTable
                    // id="pdfDatas"
                    style={{ backgroundColor: "white" }}
                    columns={columns}
                    isLoading={isLoading}
                    data={data}
                    title=""
                    options={{
                      exportAllData: true,
                      exportMenu: [
                        {
                          label: "Export CSV",
                          exportFunc: (cols, datas) =>
                            ExportCsv(cols, datas, "trux"),
                        },
                      ],
                    }}
                  />
                </div>
                {/* <div className="card-footer">Footer</div> */}
              </div>
            </div>
            {showDeleteConfirm && (
            <DeleteConfirmModal
              show={showDeleteConfirm}
              onClose={onClose}
              onConfirm={onConfirm}
            />
            )} 
          </section>
        )
      }
     
    </div>
  );
};

export default FAQ;
