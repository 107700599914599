
const Footer = () => {
  return (
    <footer className="main-footer">
      <strong>
        <span>Copyright © 2024 </span>
        <a href="https://www.godigitell.com" target="_blank" rel="noopener noreferrer">
          &nbsp; Godigitell
        </a>
      </strong>
      {/* <div className="float-right d-none d-sm-inline-block">
        <b>0.1.0</b>
      </div> */}
    </footer>
  );
};

export default Footer;
