import { initializeApp } from "firebase/app";

import { getMessaging, getToken, onMessage } from "firebase/messaging";

//Firebase Config values imported from .env file
const firebaseConfig = {
  apiKey: "AIzaSyAH9HfQ6OmWCLCK2sQQGygJYRbJO-S-sss",
  authDomain: "trux-f9ca7.firebaseapp.com",
  projectId: "trux-f9ca7",
  storageBucket: "trux-f9ca7.appspot.com",
  messagingSenderId: "1017477156666",
  appId: "1:1017477156666:web:816d92a2334a1211018a03",
  measurementId: "G-ZLEDTEST1M"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Messaging service
export const messaging = getMessaging(app);

export const generateToken = async () => {
  try {
    const permission = await Notification.requestPermission();
    console.log(permission)
    if (permission === 'granted') {
      const token = await getToken(messaging);
      // console.log('FCM Token:', token);
      return token;
    } else {
      console.log('Unable to get permission to notify.');
    }
  } catch (error) {
    console.log('Error getting permission:', error);
  }
}

// onMessage(messaging, (response) => {
//   console.log('Message received. ', response);
// });

