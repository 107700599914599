import http from "../common/http-common";

export async function getAllUserManagementApi() {
  return await http.get("/api/v1/users");
}

export async function addUserManagementApi(data) {
  return await http.post("/api/v1/users", data);
}

export async function getUserManagementDetails(id) {
  return await http.get(`/api/v1/users/${id}`);
}

export async function updateUserApi(id, data) {
  return await http.put(`/api/v1/users/${id}`, data);
}

export async function deleteUserApi(id) {
  return await http.delete(`/api/v1/users/${id}`);
}
