import http from "../common/http-common";
export async function getAllQuotationsApi() {
  return await http.get("/api/v1/quotations");
}
export async function getAllBookingNotificationApi() {
  return await http.get("/api/v1/booking/booking_notification");
}

export async function getQuotationApi(id) {
  return await http.get(`/api/v1/quotations?booking_id=${id}`);
}

export async function addQuotationApi(data) {
  return await http.post("/api/v1/quotations", data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
  });
}

export async function updateQuotationApi(id, data) {
  return await http.put(`/api/v1/quotations?booking_id=${id}`, data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
  });
}

export async function deleteQuotationApi(id) {
  return await http.delete(`/api/v1/quotations  ?booking_id=${id}`);
}
