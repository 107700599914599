import ContentHeader from "../../components/content-header/ContentHeader";
import {createRoot} from "react-dom/client";
import {
  APIProvider,
  Map,
  AdvancedMarker,
  MapCameraChangedEvent,
  Pin
} from '@vis.gl/react-google-maps';



// type Poi ={ key: string, location: google.maps.LatLngLiteral }
// const locations: Poi[] = [
//   {key: 'operaHouse', location: { lat: -33.8567844, lng: 151.213108  }},
//   {key: 'tarongaZoo', location: { lat: -33.8472767, lng: 151.2188164 }},
//   {key: 'manlyBeach', location: { lat: -33.8209738, lng: 151.2563253 }},
//   {key: 'hyderPark', location: { lat: -33.8690081, lng: 151.2052393 }},
//   {key: 'theRocks', location: { lat: -33.8587568, lng: 151.2058246 }},
//   {key: 'circularQuay', location: { lat: -33.858761, lng: 151.2055688 }},
//   {key: 'harbourBridge', location: { lat: -33.852228, lng: 151.2038374 }},
//   {key: 'kingsCross', location: { lat: -33.8737375, lng: 151.222569 }},
//   {key: 'botanicGardens', location: { lat: -33.864167, lng: 151.216387 }},
//   {key: 'museumOfSydney', location: { lat: -33.8636005, lng: 151.2092542 }},
//   {key: 'maritimeMuseum', location: { lat: -33.869395, lng: 151.198648 }},
//   {key: 'kingStreetWharf', location: { lat: -33.8665445, lng: 151.1989808 }},
//   {key: 'aquarium', location: { lat: -33.869627, lng: 151.202146 }},
//   {key: 'darlingHarbour', location: { lat: -33.87488, lng: 151.1987113 }},
//   {key: 'barangaroo', location: { lat: - 33.8605523, lng: 151.1972205 }},
// ];



// const GoogleMap = () => (
//   <APIProvider apiKey={'AIzaSyByytejIevs5DusyXV_UQJECIxqV4fHhYQ'} onLoad={() => console.log('Maps API has loaded.')}>
//      <Map
//       defaultZoom={13}
//       defaultCenter={ { lat: 12.990466, lng: 80.182487 } }
//       onCameraChanged={ (ev: MapCameraChangedEvent) =>
//         console.log('camera changed:', ev.detail.center, 'zoom:', ev.detail.zoom)
//       }>
//     </Map>
//   </APIProvider>
//  );
 
// //  const root = createRoot(document.getElementById('app'));
// //  root.render(<GoogleMap />);
 

// const PoiMarkers = (props: {pois: Poi[]}) => {
//   return (
//     <>
//       {props.pois.map( (poi: Poi) => (
//         <AdvancedMarker
//           key={poi.key}
//           position={poi.location}>
//         <Pin background={'#FBBC04'} glyphColor={'#000'} borderColor={'#000'} />
//         </AdvancedMarker>
//       ))}
//     </>
//   );
// };

// type Poi ={ key: string, location: google.maps.LatLngLiteral }
const locations = [
  {key: 'operaHouse', location: { lat: -33.8567844, lng: 151.213108  }},
  {key: 'tarongaZoo', location: { lat: -33.8472767, lng: 151.2188164 }},
];

const PoiMarkers = (props) => {
  console.log("Initiated PoiMarkers");  
  return (
      <>
        {props.pois.map( (poi) => (
          <AdvancedMarker
            key={poi.key}
            position={poi.location}>
          <Pin background={'#FBBC04'} glyphColor={'#000'} borderColor={'#000'} />
          </AdvancedMarker>
        ))}
      </>
    );
  };


const GoogleMap = () => {
    return (
      <>            
       <APIProvider apiKey='AIzaSyByytejIevs5DusyXV_UQJECIxqV4fHhYQ' onLoad={() => console.log('Maps API has loaded.')}>
        <div style={{ height: '400px', width: '100%' }}>
            <Map
            defaultZoom={13}
            defaultCenter={ { lat: -33.860664, lng: 151.208138 } }
            mapId='733686b328fec0ad'
            // defaultCenter={ { lat: 12.990466, lng: 80.182487 } }
        >
            <PoiMarkers pois={locations} />
            </Map>
        </div>
        </APIProvider>
      </>
    );
  };

  export default GoogleMap


//   const GoogleMap = () => {
//     return (
//       <> 
//        <h1>Welcome</h1>      
//        <APIProvider apiKey={'AIzaSyByytejIevs5DusyXV_UQJECIxqV4fHhYQ'} onLoad={() => console.log('Maps API has loaded.')}>
//         <div style={{ height: '400px', width: '100%' }}>
//             <Map
//             defaultZoom={13}
//             defaultCenter={ { lat: 12.990466, lng: 80.182487 } }
//         >
//             </Map>
//         </div>
//         </APIProvider>
//       </>
//     );
//   };

//   export default GoogleMap


// const GoogleMap = () => {
//   console.log("Initiated Google Map");
//   <APIProvider apiKey={'AIzaSyDe-EwigWEOR2DjhBxIexv_z9YUiHdsw14'} 
//                 onLoad={() => console.log('Maps API has loaded.')}>
//     <h1>Hello, world!</h1>
//   </APIProvider>
//   const root = createRoot(document.getElementById('app'));
//   console.log("Initiation Completed");
// };

// export default GoogleMap;
